import React, { useEffect, useState } from 'react'
import Header from '../../common/Header'
import Sidebar from '../../common/Sidebar'
import Footer from '../../common/Footer'
import PageHeader from '../PageHeader'
import AutocompleteSearch from '../AutocompleteSearch'
import axios from 'axios'
import { downloadDataInCsv, downloadDataInExcel, getToken, get_user_menu_permission, simpleAlert, triggerAlert } from '../../utils'
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Loader from '../../common/Loader'
import Pagination from '../PaginationComponent'
import { menuIdData } from '../../utils/constants'

export default function BalanceHistory() {
    const mainHeading = "Balance History";
    const heading = "Billing / Balance History";

    const api_url = process.env.REACT_APP_API_BASE_URL;
    const token = getToken();
    const [isLoading, setIsLoading] = useState(false);
    const [balanceHistoryList, setBalanceHistoryList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [grandTotalSelected, setGrandTotalSelected] = useState(0);
    const pageSize = process.env.REACT_APP_API_SHOW_ENTRIES;

    // Menu Permissions
    //   const [addPermission, setAddPermission] = useState(false);
    //   const [editPermission, setEditPermission] = useState(false);
    //   const [deletePermission, setDeletePermission] = useState(false);
    const [exportPermission, setExportPermission] = useState(false);

    const fetchBalanceHistory = async (searchkey) => {
        setIsLoading(true);
        // const page_size = perPageLimit;
        try {
            let apiUrl = `${api_url}billing/get_balance_history`;
            // Set default values for page_size and page_number
            let params = ``;
            params += `?page_number=${currentPage}`;
            params += `&page_size=${pageSize}`
            if (searchkey) {
                params += `&keyword=${searchkey}`;
            }
            // if (customerId) {
            //     params += `&customer_id=${customerId}`;
            // }
            const response = await axios.get(apiUrl + params, token);
            const response_data = response.data;
            if (response_data.error_code === 200) {
                const itemsArray = response_data.results.data;
                const total_pages = response_data.results.data.pagination.total_pages;
                const grandTotal = response_data.results.total_amount;
                setPageCount(total_pages);
                setGrandTotalSelected(grandTotal);
                setBalanceHistoryList(itemsArray);
                setIsLoading(false);
            } else if (response.status === 204) {
                setBalanceHistoryList([]);
                setPageCount(0);
                setIsLoading(false);
            } else {
                triggerAlert('error', 'Oops...', "Couldn't get the details");
            }
        } catch (error) {
            console.group(error);
            setIsLoading(false);
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    }

    const deleteUserBalance = () => {
        simpleAlert('Coming Soon!');
    }

    const editUserBalance = () => {
        simpleAlert('Coming Soon!');
    }

    const exportToExcel = () => {
        const customHeaders = {
            user_name: 'Username',
            company_name: 'Company Name',
            wallet_amount: 'Grand Total Bill($)',

        };
        downloadDataInCsv(balanceHistoryList.data, 'balance_report.csv', customHeaders, 'wallet_amount');
    }


    const handlePageChange = event => {
        // setCurrentPage(0);
        // if (event.target.value) {
        //     setPerPageLimit(event.target.value);
        //     setPageLimitSelected(event.target.value);
        // } else {
        //     setPerPageLimit(10);
        //     setPageLimitSelected(10);
        // }
    };

    const handlePageClick = (selected) => {
        //console.log(selected);
        const selectedPage = selected.selected;
        setCurrentPage(selectedPage + 1);
        // fetchBalanceHistory(selectedPage + 1); // Increment the page number by 1 for server-side pagination
    };

    let pgntn_props = {
        pageCount: pageCount,
        handlePageClick: handlePageClick,
        selectedPage: currentPage - 1
    }

    const handleChange = (e) => {
        setSearchQuery(e.target.value);
        const searchkey = e.target.value;
        fetchBalanceHistory(searchkey); // Update search results on every change
    };

    useEffect(() => {
        fetchBalanceHistory();
    }, [currentPage]);

    useEffect(() => {
        const menu_id = menuIdData?.balance_history;
        // setAddPermission(get_user_menu_permission(menu_id, 'add'));
        // setEditPermission(get_user_menu_permission(menu_id, 'edit'));
        // setDeletePermission(get_user_menu_permission(menu_id, 'delete'));
        setExportPermission(get_user_menu_permission(menu_id, 'export'));
    }, []);

    return (
        <>
            <div id="layout-wrapper">

                <Header />
                <Sidebar />

                {/* <!-- ============================================================== -->
                    <!-- Start right Content here -->
                    <!-- ============================================================== --> */}
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}
                            {/* <div className="row  mt-4"> */}
                            {/* <div className="col-xl-12">
                                    <div className="">
                                        <div className="card-body">
                                            <div className="row">

                                                <form data-select2-id="21">
                                                    <div className="row g-3" data-select2-id="20">
                                                        <div className="col-md-2">
                                                            <label className="form-label" for="formtabs-first-name">User</label>
                                                            <AutocompleteSearch {...autoCompleteProps} />
                                                        </div>

                                                        <div className="col-md-2">
                                                            <label className="form-label" for="formtabs-last-name">&nbsp;</label>
                                                            <div className="button-items">
                                                                <button type="button"
                                                                    className="btn btn-primary w-md" onClick={() => fetchBalanceHistory(currentPage + 1)}>Search</button>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <label className="form-label" for="formtabs-last-name">&nbsp;</label>
                                                            <div className="d-grid  d-md-flex justify-content-md-end mb-3">
                                                                <button className="btn btn-primary w-md " type="button" >Credit Note</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                            <div className="row mt-5">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                {/* <div className="col-sm-12 col-md-6">
                                                        <div className="dataTables_length" id="datatable_length">
                                                            <label>Show
                                                                <select name="example_length" aria-controls="example" classNameName="table_length" onChange={handlePageChange} value={pageLimitSelected}>
                                                                    <option value={10}>10</option>
                                                                    <option value={20}>20</option>
                                                                    <option value={50}>50</option>
                                                                    <option value={100}>100</option>
                                                                </select> entries
                                                            </label>
                                                        </div>
                                                    </div> */}
                                                <div className="col-sm-12 col-md-12 flex-end mb-1">
                                                    <div className="btn-group float-end mb-3">
                                                        {exportPermission &&
                                                            <button className="btn btn-success w-md px-3" type="button" onClick={exportToExcel}><i className="mdi mdi-file-export-outline" style={{ fontSize: 'large' }}></i> Export to Excel</button>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-12 flex-end mb-2">
                                                    <div className="btn-group float-end col-md-2">
                                                        <input type="search" placeholder="Search..." value={searchQuery} className="form-control form-control-sm" aria-controls="example" onChange={handleChange} />
                                                        &nbsp;&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-overflow" >
                                                <Table id="balanceTable" className="nowrap w-100 dt-responsive table-responsive table-bordered">
                                                    <thead id='thead-overflow' style={{ backgroundColor: '#ededed' }} >
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Username</th>
                                                            <th>Company Name</th>
                                                            <th>Balance</th>
                                                            {/* <th>Actions</th> */}
                                                        </tr>
                                                    </thead>
                                                    {isLoading ? (
                                                        <div className='loader-overlay text-white'>
                                                            <Loader />
                                                        </div>
                                                    ) : (
                                                        <tbody>
                                                            {Object.keys(balanceHistoryList).length > 0 ? (
                                                                <>
                                                                    {balanceHistoryList.data.map((balance, index) => (

                                                                        <tr>
                                                                            <th scope="row"> {index + 1} </th>
                                                                            <td> {balance.user_name ? balance.user_name : '-'} </td>
                                                                            <td> {balance.company_name ? balance.company_name : '-'} </td>
                                                                            <td>$ {balance.wallet_amount ? balance.wallet_amount : '0.000'} </td>
                                                                            {/* <td>
                                                                            <button className="btn btn-sm btn-default p-0" title="delete" onClick={deleteUserBalance}><i
                                                                                className="fas   fas fa-trash-alt text-danger fa-eye-c" ></i></button>
                                                                            <button className="btn btn-sm btn-default p-0 mx-2" title="Edit" onClick={editUserBalance}><i
                                                                                className="fas  fas fa-edit fa-edit-c "></i></button>
                                                                        </td> */}
                                                                        </tr>


                                                                    ))}
                                                                    < tr >
                                                                        <td colSpan="3" className="text-end pe-3 py-3">
                                                                            <p className="mb-2 px-2">Grand Total</p>
                                                                        </td>
                                                                        <td colSpan="4" className="py-3">
                                                                            <p className='fw-semibold mb-2'>$ {grandTotalSelected}</p>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="4" className="text-center">No data available</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    )}
                                                </Table>
                                            </div>
                                            <hr />
                                            <Pagination {...pgntn_props} />
                                        </div>
                                    </div>
                                </div> {/*  end col */}
                            </div> {/*  end row */}
                            {/* </div> */}
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}

                    <Footer />
                </div>
                {/* <!-- end main content--> */}
            </div >
            {/* <!-- END layout-wrapper --> */}

            {/* <!-- Right bar overlay--> */}
            <div className="rightbar-overlay"></div>
        </>
    )
}
