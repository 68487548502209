import React, { useState, useEffect } from "react";
import Header from "../../common/Header";
import Sidebar from "../../common/Sidebar";
import PageHeader from "../PageHeader";
import Footer from "../../common/Footer";
import {
  ConfirmationAlert,
  exportToCsv,
  getToken,
  get_user_menu_permission,
  handleTableRowClick,
  pageReload,
  simpleAlert,
  triggerAlert,
} from "../../utils";
import axios from "axios";
import { Table } from "react-bootstrap";
import Loader from "../../common/Loader";
import Pagination from "../PaginationComponent";
import { Controller, useForm } from "react-hook-form";
import AutoUserSearch from "../AutoUserSearch";
import AutoSearchSelect from "../CommonSelectDynamic";
import CommonSelect from "../CommonSelectStatic";
import DataTable from 'react-data-table-component';
import { Modal, ModalBody } from "react-bootstrap";
import {
  cardType,
  invoiceType,
  onlyAlphabetsandSpaces,
  onlyNumbers,
  paymentType,
  createPattern,
  menuIdData
} from "../../utils/constants";

export default function PaymentDetails() {
  const api_url = process.env.REACT_APP_API_BASE_URL;
  const customer_api_url = process.env.REACT_APP_API_CUSTOMER_URL;
  const show50Entries = process.env.REACT_APP_API_SHOW_ENTRIES;
  const token = getToken();
  const mainHeading = "Payments Details";
  const heading = "Billing / Payments Details";
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    control,
  } = useForm();
  const {
    register: registerAdd,
    handleSubmit: handleSubmitAdd,
    formState: { errors: errorsAdd },
    setValue: setValueAdd,
    getValues: getValuesAdd,
    control: controlAdd,
    unregister: unregisterAdd,
    watch: watchAdd,
    reset: resetAdd,
    clearErrors:clearErrorsAdd
  } = useForm();
  const {
    register: registerUpdate,
    handleSubmit: handleSubmitUpdate,
    formState: { errors: errorsUpdate },
    setValue: setValueUpdate,
    control: controlUpdate,
    watch: watchUpdate,
    reset: resetUpdate,
    setError: setErrorUpdate
  } = useForm();
  const columns = [
    // {
    //     name: <label className="DarkBlue4_Color font_weight_500 font_14">S.No</label>, sortable: true, selector: row => row.Num, cell: row =>
    //         <>
    //             <div className="DarkBlue4_Color font_weight_300 font_14 align-middle align-middle">
    //                 <span>{row.Num}</span>
    //             </div>
    //         </>,
    // },
    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">User</label>, sortable: true, selector: row => row.customer__contact_person, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_16 align-middle"  >
            {row.customer__contact_person}
          </div>
        </>,
    },
    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">Company Name</label>, sortable: true, selector: row => row.customer__cmp_name, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
            {row.customer__cmp_name}
          </div>
        </>,
    },
    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">Transaction No</label>, sortable: true, selector: row => row.transaction_id, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
            {row.transaction_id}
          </div>
        </>,
    },
    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">Payment Gateway</label>, sortable: true, selector: row => row.payment_gateway, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
            {row.payment_gateway}
          </div>
        </>,
    },
    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">Invoice number</label>, sortable: true, selector: row => row.invoice_number, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
            {row.invoice_number}
          </div>
        </>,
    },
    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">Payment date</label>, sortable: true, selector: row => row.payment_date, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
            {row.payment_date}
          </div>
        </>,
    },



    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">Payment method</label>, sortable: true, selector: row => row.payment_method, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
            {row.payment_method}
          </div>
        </>,
    },
    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">Subscription</label>, sortable: true, selector: row => row.subscription__subscription_plan__plan_name, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
            {row.subscription__subscription_plan__plan_name}
          </div>
        </>,
    },
    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">Amount paid</label>, sortable: true, selector: row => row.amount_paid, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
            {row.amount_paid}
          </div>
        </>,
    },
    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">Currency</label>, sortable: true, selector: row => row.currency, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
            {row.currency}
          </div>
        </>,
    },
    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">Payment Status</label>, sortable: true, selector: row => row.payment_status, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
            {row.payment_status}
          </div>
        </>,
    },


    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14" style={{marginLeft:'20px'}}>Action</label>, sortable: true, selector: row => row.is_deleted, cell: row =>
        <>
          {customeredit || customerdelete ? 
          <div className="btn-group float-end col-md-1  ml-10">
            <button type="button" className="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Action <i className="mdi mdi-chevron-down"></i>
            </button>
            
            <div className="dropdown-menu dropdown-menu-end  ">
              {customeredit ? <div className="dropdown-item hand_icon" onClick={() => { onloadedvaluesonedit(row);setCreatenewUser(true);  clearErrorsAdd(); seteditid(row.id); settextdisplay("Update") }}>Edit</div> : ""}
              {/* {customerdelete ? <div className="dropdown-item hand_icon" >Delete  </div> : ""} */}
            </div> 
          </div>: ""}
        </>,
    },



  ];
  const [textdisplay, settextdisplay] = useState("Add")
  const onloadedvaluesonedit = (data) => {
    setValueAdd('customer', data.customer_id)
    setValueAdd('payment_method', data.payment_method)
    setValueAdd('payment_gateway', data.payment_gateway)
    setValueAdd('payment_status', data.payment_status)
    setValueAdd('invoice_amount', data.amount_paid)
    setValueAdd('currency', data.currency)
    setValueAdd('payment_date', data.payment_date)
    setValueAdd('subscription', data.subscription__subscription_plan__id)
    setValueAdd('billing_address', data.billing_address)
    setValueAdd('payment_type', data.subscription__subscription_type)
    setcustomerId(data.customer_id)
  }
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPrim, setIsLoadingPrim] = useState(false);
  const [isLoadingSingle, setIsLoadingSingle] = useState(false);
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const [isLoadingUpd, setIsLoadingUpd] = useState(false);
  const [isSearchBtnDisabled, setIsSearchBtnDisabled] = useState(true);
  const [customerId, setcustomerId] = useState(null);
  const [customerIdAdd, setcustomerIdAdd] = useState(null);
  const [customerIdUpdate, setcustomerIdUpdate] = useState(null);
  const [data, setData] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);

  const [invoice_Id, setInvoiceId] = useState(0);
  const [showUpdatePage, setShowUpdatePage] = useState(false);
  const [showAddPage, setShowAddPage] = useState(false);
  const [CreatenewUser, setCreatenewUser] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageSlNo, setPageSlNo] = useState(0);
  const [perPageLimit, setPerPageLimit] = useState(show50Entries);
  const [pageLimitSelected, setPageLimitSelected] = useState(10);
  const [countryData, setCountriesData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [stateData, setStatesData] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("card");
  const [updatePaymentType, setUpdatePaymentType] = useState("");
  // Menu Permissions
  const [addPermission, setAddPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [exportPermission, setExportPermission] = useState(false);
  const [totalRows, setTotalRows] = useState(10);

  const [perPage, setPerPage] = useState(10);

  // const filteredStates = stateData.filter((state) => state.country_code_char2 === selectedCountryCode);
  // console.log('filteredStates', filteredStates)
  //for card expiry year
  const currentYear = new Date().getFullYear();
  const futureYears = 15;

  const options = [];
  for (let i = 0; i <= futureYears; i++) {
    const year = currentYear + i;
    options.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }

  const fetchPaymentDetails = async (data, page_number, searchkey=searchQuery) => {
    const page_size = perPageLimit;
    setIsLoading(true);

    try {
      let token =localStorage.getItem("admin_user_token")
      //let apiUrl = `${api_url}billing/get_invoice_data`;
      let apiUrl = `/apiV1/edit-payment/`;
      let params = `?page_size=${page_size || 10}&page_number=${page_number || 1}${searchkey ? `&search=${searchkey}` : ""}`;
      if (data.customer_id) params += `&customer=${data.customer_id}`;

      if (data.payment_type) params += `&payment_status=${data.payment_type}`;
      const response = await axios.get(apiUrl + params,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        }
      );
      //console.log(response);
      const response_data = response.data;
      if (response.status == 200) {
        const itemsArray = response_data.results;
        const total_pages = response_data.count;
        setTotalRows(total_pages);
        setPaymentDetails(itemsArray);
        setIsLoading(false);
      } else if (response.status === 204) {
        setPaymentDetails([]);
        setIsLoading(false);
      } else {
        triggerAlert("error", "Oops...", "Couldn't get the details");
      }
    } catch (error) {
      //console.log(error);
      setIsLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const onSubmit = (data) => {
    setData(data);
    fetchPaymentDetails(data);
  };
  const [editid, seteditid] = useState()
  const onSubmitCreate = (data) => {
    console.log(data,'data');

    data.order_id = orderid;
    data.customer = customerId;
    data.amount_paid = data.invoice_amount
    if (editid) {
      axios.patch(`apiV1/create-payment/${editid}/`, data).then((response) => {
        fetchPaymentDetails(1);
        setCreatenewUser(false)
        resetAdd();
        triggerAlert('success', 'success', 'Data updated successfully');
      })
        .catch((error) => {
          triggerAlert('error', 'Oops...', 'Something went wrong here..');
        });
    } else {
      axios.post("apiV1/create-payment/", data).then((response) => {
        fetchPaymentDetails(1);
        setCreatenewUser(false)
        resetAdd();
        triggerAlert('success', 'success', 'Data added successfully');
      })
        .catch((error) => {
          triggerAlert('error', 'Oops...', 'Something went wrong here..');
        });
    }
  }

  const fetchSingleCustomerData = async (id) => {
    setIsLoadingSingle(true);
    try {
      const response = await axios.get(
        customer_api_url +
        `billing/fetch_customer_data_with_payment?customer_id=${id}`,
        token
      );
      const response_data = response.data;
      //console.log(response_data);
      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        const status = itemsArray.card_status;
        setDefaultValues(itemsArray, "add");

        // setShowUpdatePage(true);
        setIsLoadingSingle(false);
        // if (status) {
        //     simpleAlert("The customer's bank or card information is already available. You can update the details.")
        // }
      } else if (response.status === 204) {
        setIsLoadingSingle(false);
        simpleAlert("No Customer details found");
      } else {
        setIsLoadingSingle(false);
        triggerAlert("error", "Oops...", "Couldn't get the customer details");
      }
    } catch (error) {
      setIsLoadingSingle(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const setDefaultValues = (item, status) => {
    // console.log("setDefault", item, status);
    if (status === "add") {
      setValueAdd("first_name", item.first_name);
      setValueAdd("last_name", item.last_name);
      setValueAdd("company", item.company_name);
      setValueAdd("email", item.email);
      setValueAdd("address", item.cust_address);
      setValueAdd("city", item.cust_city);
      setValueAdd("country", item.cust_country);
      // fetchStatesData(item.cust_country);
      // setSelectedValue(item.cust_state);
      setValueAdd("state", item.cust_state);
      setValueAdd("zip", item.cust_zipcode);
      setValueAdd("phone_number", item.phone);

      // setValueAdd('account_type', item.account_type)
      // setValueAdd('account_number', item.account_number)
      // setValueAdd('routing_number', item.routing_number)
      // setValueAdd('name_on_account', item.name_on_account)
      // setValueAdd('echeck_type', item.echeck_type)
      // setValueAdd('bank_name', item.bank_name)

      // setValueAdd('card_number', item.card_number)
      // setValueAdd('card_type', item.card_type)
      // setValueAdd('month', item.month)
      // setValueAdd('year', item.year)
      // setValueAdd('cvv_code', item.cvv_code)
    } else {
      // setValueUpdate('customerID', String(item.customer));
      setValueUpdate("customerID", item.user_name + ", " + item.company_name);
      setValueUpdate("card_id", item.card_id);
      setValueUpdate("first_name", item.first_name);
      setValueUpdate("last_name", item.last_name);
      setValueUpdate("company", item.company_name);
      setValueUpdate("email", item.email);
      setValueUpdate("address", item.address);
      setValueUpdate("city", item.city);
      setValueUpdate("country", item.country);
      setValueUpdate("state", item.state);
      setValueUpdate("zip", item.zipcode);
      setValueUpdate("phone_number", item.phone);
      setUpdatePaymentType(item.type);
      if (item.type === "Card") {
        // setValueUpdate("card_number", `XXXXXXXXXXXX` + item.card_no);
        setValueUpdate("card_type", item.card_type);
        //console.log(" item.expiry_month", item.expiry_month);
        setValueUpdate("card_expiry_month", item.expiry_month);
        setValueUpdate("card_expiry_year", item.expiry_year);
        setValueUpdate("cvv_code", item.cvv_code);
      } else {
        setValueUpdate("account_type", item.account_type);
        setValueUpdate("account_number", item.account_number);
        setValueUpdate("routing_number", item.routing_number);
        setValueUpdate("name_on_account", item.card_holder_name);
        setValueUpdate("echeck_type", item.echeck_type);
        setValueUpdate("bank_name", item.bank_name);
      }
    }
  };

  const handleCancelbtn = () => {
    // setSelectedTabName('Assigned DIDs');
    setShowUpdatePage(false);
    reset();
    pageReload();
    // setPageCount(0);
    // setCurrentPage(0);
    // setPerPageLimit(10);
    // setPageLimitSelected(10);

    // fetchAssignedDids(currentPage + 1);
  };

  const handleUserSelect = (selected) => {
    setValue("customer_id", selected ? selected.value : null);

    setPageCount(0);
    // setPaymentDetails([]);

    setPerPageLimit(show50Entries);
    setPageLimitSelected(10);
    setIsSearchBtnDisabled(false);
  };

  const handleTypeSelect = (selected) => {
    setValue("type", selected ? selected.value : null);
    setPageCount(0);
    // setPaymentDetails([]);

    setPerPageLimit(show50Entries);
    setPageLimitSelected(10);
    setIsSearchBtnDisabled(false);
  };
  const handleCardTypeSelect = (selected) => {
    setValue("card_type", selected ? selected.value : null);
    setPageCount(0);
    // setPaymentDetails([]);

    setPerPageLimit(show50Entries);
    setPageLimitSelected(10);
    setIsSearchBtnDisabled(false);
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    const searchkey = e.target.value;
    fetchPaymentDetails(data, 1, searchkey);
  };

  const addPaymentDetails = () => {
    setShowAddPage(true);
  };

  //Add Payment details functionality
  const AddCardSubmit = async (data) => {
    //console.log("AddCardSubmit", data);
    setIsLoadingAdd(true);
    try {
      let endpoint = "";
      let postData = {};
      if (selectedPaymentMethod === "card") {
        endpoint = "billing/create_customer_profile_card";
        data.customer_id = customerIdAdd;
        postData = data;

      } else {
        endpoint = "billing/create_customer_profile_bank";
        data.customer_id = customerIdAdd;
        postData = data;

      }

      const response = await axios.post(
        customer_api_url + endpoint,
        postData,
        token
      );
      const response_data = response.data;

      if (response_data.error_code === 200) {
        // handlePaymentClose();

        triggerAlert("success", "Success", response_data.message);
        setIsLoadingAdd(false);
        resetAdd();

        pageReload();
      } else {
        setIsLoadingAdd(false);
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
    } catch (error) {
      const response_data = error?.response?.data;

      setIsLoadingAdd(false);
      triggerAlert("error", "Oops...", response_data.message);
    }
  };

  //Update functionality
  const editRequest = () => {
    if (selectedRow) {
      setShowUpdatePage(true);
    } else {
      triggerAlert("error", "Oops...", "Please select a row");
    }
  };

  const UpdateCardSubmit = async (data) => {
    setIsLoadingUpd(true);
    const { customerID, ...updatedData } = data;
    //console.log("UpdateCardSubmit", updatedData);
    try {
      let endpoint = "";
      let postData = {};
      if (updatePaymentType === "Card") {
        endpoint = "billing/update_card_details";
        updatedData.customer_id = customerIdUpdate;
        updatedData.primary_card_status = selectedRow.primary_card_status;
        postData = updatedData;
      } else {
        endpoint = "billing/update_bank_details";
        updatedData.customer_id = customerIdUpdate;
        updatedData.primary_card_status = selectedRow.primary_card_status;
        postData = updatedData;
      }

      // console.log(dataadd);
      const response = await axios.post(
        customer_api_url + endpoint,
        postData,
        token
      );
      const response_data = response.data;

      if (response_data.error_code === 200) {
        // handlePaymentClose();

        triggerAlert("success", "Success", response_data.message);
        setIsLoadingUpd(false);
        resetAdd();

        pageReload();
      } else {
        setIsLoadingUpd(false);
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
    } catch (error) {
      const response_data = error.response.data;

      setIsLoadingUpd(false);
      triggerAlert("error", "Oops...", response_data.message);
    }
  };

  //delete payment details
  //console.log("paymentDetails.length > 0", paymentDetails.length > 0);

  const deletePaymentDetails = async () => {
    if (selectedRowId) {
      // if (paymentDetails.length > 0) {
      // If there are payment details, check if the selected row has primary status 1
      //     if (selectedRow && selectedRow.primary_card_status === 1) {
      //         // Show alert that you can't delete this card
      //         triggerAlert('error', 'Oops...', 'You cannot delete this card as it is set as primary.');
      //     } else {
      //         // If primary status is not 1, proceed with the deletion
      //         ConfirmationAlert(`You want to delete!!`, 'Delete', async () => {
      //             deleteCard();
      //         });
      //     }
      // } else {
      // If there are no payment details, check if the selected row has primary status 1
      if (selectedRow && selectedRow.primary_card_status === 1) {
        // Show confirmation alert for deleting the primary card
        ConfirmationAlert(
          `Are you sure you want to delete the primary card?`,
          "Delete",
          () => {
            // If confirmed, proceed with the deletion
            deleteCard();
          }
        );
      } else {
        // If primary status is not 1, proceed with the deletion
        ConfirmationAlert(`You want to delete!!`, "Delete", async () => {
          deleteCard();
        });
      }
      // }
    } else {
      triggerAlert("error", "Oops...", "Please select a row");
    }
  };

  const deleteCard = async () => {
    if (selectedRowId) {
      // ConfirmationAlert(`You want to delete!!`, 'Delete', async () => {
      const data = {
        card_id: selectedRowId,
        primary_card_status: selectedRow.primary_card_status,
      };
      try {
        const response = await axios.post(
          customer_api_url + "billing/delete_card_details",
          data,
          token
        );
        const response_data = response.data;
        if (response_data.error_code === 200) {
          triggerAlert("success", "Success", response_data.message);
          pageReload();
        } else {
          triggerAlert("error", "Oops...", "Something went wrong..");
        }
        // pageReload();
      } catch (error) {
        // console.log(error);
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
      // });
    } else {
      triggerAlert("error", "Oops...", "Please select a row");
    }
  };

  // const deletePaymentDetails = async () => {

  //     if (selectedRowId) {
  //         ConfirmationAlert(`You want to delete!!`, 'Delete', async () => {
  //             const data = { card_id: selectedRowId };
  //             try {
  //                 const response = await axios.post(customer_api_url + 'billing/delete_card_details', data, token);
  //                 const response_data = response.data;
  //                 //console.log(message);
  //                 if (response_data.error_code == "200") {
  //                     triggerAlert('success', 'success', response_data.message);
  //                 } else {
  //                     triggerAlert('error', 'Oops...', 'Something went wrong..');
  //                 }
  //                 pageReload();
  //             } catch (error) {
  //                 triggerAlert('error', 'Oops...', 'Something went wrong..');
  //             }

  //         })

  //     } else {
  //         triggerAlert('error', 'Oops...', 'Please select a row');
  //     }
  // }

  //set primary function
  const handlePrimary = () => {
    if (selectedRowId) {
      ConfirmationAlert(
        "You want to set this card as the primary payment method? Please note that it will be used for monthly recurring charges.",
        "Continue",
        async () => {
          const api_input = {
            card_id: selectedRowId,
            customer_id: selectedRow.customer,
          };

          try {
            setIsLoadingPrim(true);
            const response = await axios.post(
              customer_api_url + "billing/update_primary_card_status",
              api_input,
              token
            );
            const response_data = response.data;

            if (response_data.error_code === 200) {
              setIsLoadingPrim(false);
              triggerAlert("success", "success", "Success!!");
              pageReload();
            } else {
              setIsLoadingPrim(false);
              triggerAlert("error", "Oops...", "Action was unsuccessful");
            }
          } catch (error) {
            setIsLoadingPrim(false);
            const response_data = error.response.data;
            triggerAlert(
              "error",
              "Oops...",
              response_data ? response_data.message : "Something went wrong!"
            );
          }
        }
      );
    } else {
      setIsLoadingPrim(false);
      triggerAlert("error", "Oops...", "Please select a row");
    }
  };

  // console.group("selectedRow", selectedRow);
  const handlePageChange = (event) => {

    if (event.target.value) {
      setPerPageLimit(event.target.value);
      setPageLimitSelected(event.target.value);
    } else {
      setPerPageLimit(10);
      setPageLimitSelected(10);
    }
  };

  const handlePageClick = (selected) => {
    const selectedPage = selected.selected;
    setCurrentPage(selectedPage);
    fetchPaymentDetails(selectedPage + 1); // Increment the page number by 1 for server-side pagination
  };

  let pgntn_props = {
    pageCount: pageCount,
    handlePageClick: handlePageClick,
    selectedPage: currentPage,
  };

  useEffect(() => {

    //fetchCountriesData();
    fetchPaymentDetails(data);
  }, [perPageLimit]);

  useEffect(() => {
    if (customerIdAdd) {
      fetchSingleCustomerData(customerIdAdd);
    }
  }, [customerIdAdd]);

  useEffect(() => {
    if (selectedRow) {
      setDefaultValues(selectedRow, "update");
      setcustomerIdUpdate(selectedRow.customer);
    }
  }, [selectedRow]);



  // console.log("userPaymentDetails", userPaymentDetails.invoice_cat);

  const fetchCountriesData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(api_url + "customer/country_list");
      const respdata = response.data.results.data;
      setCountriesData(respdata);

      setIsLoading(false);
    } catch (error) {
      //triggerAlert('error','','No data available');
    }
  };

  const fetchStatesData = async (countryCode) => {
    setIsLoading(true);
    try {
      if (countryCode.length !== 0) {
        const response = await axios.get(
          api_url + "customer/state_list/" + countryCode
        );
        const respdata = response.data.results.data;
        setStatesData(respdata);
        setIsLoading(false);
      }
    } catch (error) {
      //triggerAlert('error','','No data available');
    }
  };

  const exportToExcel = async () => {
    setIsLoadingPrim(true);
    try {
      let apiUrl = `${api_url}billing/get_cc_details_download_data`;
      let params = "?";

      const addParam = (key, value) => {
        if (value) {
          params += `${params.length > 1 ? "&" : ""}${key}=${value}`;
        }
      };

      addParam("customer_id", data.customer_id);
      addParam("card_type", data.card_type);
      addParam("payment_type", data.payment_type);
      addParam("keyword", searchQuery);


      const response = await axios.get(apiUrl + params, token);
      const response_data = response.data;
      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        exportToCsv(itemsArray, 'payment_details');
        setIsLoadingPrim(false);
      } else if (response.status === 204) {
        setIsLoadingPrim(false);
      } else {
        triggerAlert('error', 'Oops...', "Couldn't export the file");
      }
    } catch (error) {
      //console.log(error)
      setIsLoadingPrim(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  }
  const access_privileges = JSON.parse(localStorage.getItem('modulepermissions'))
  const [customerlist, setcustomerlist] = useState(false)
  const [customeradd, setcustomeradd] = useState(false)
  const [customeredit, setcustomeredit] = useState(false)
  const [customerdelete, setcustomerdelete] = useState(false)
  useEffect(() => {
    //const menu_id = menuIdData?.payment_details;
    // setAddPermission(get_user_menu_permission(menu_id, 'add'));
    // setEditPermission(get_user_menu_permission(menu_id, 'edit'));
    // setDeletePermission(get_user_menu_permission(menu_id, 'delete'));
    // setExportPermission(get_user_menu_permission(menu_id, 'export'));
    fetchSubscriptions()
    fetchActiveCustomers();
    if(access_privileges){

    
    access_privileges.map((access, i)=>{
      access.sub_module_info.map((subaccess,k)=>{
          if(subaccess.name == "Payment Details" && subaccess.permissions.indexOf(1)!== -1){
              setcustomerlist(true)
          }
          if(subaccess.name == "Payment Details" && subaccess.permissions.indexOf(2)!== -1){
              setcustomeradd(true)
          }
          if(subaccess.name == "Payment Details" && subaccess.permissions.indexOf(3)!== -1){
              setcustomeredit(true)
          }
          if(subaccess.name == "Payment Details" && subaccess.permissions.indexOf(4)!== -1){
              setcustomerdelete(true)
          }

      })

      
    })
  }
  }, []);
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'All',
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchPaymentDetails(page, newPerPage);
    setPerPage(newPerPage);
  };
  const [subscriptionlist, setsubscriptionlist] = useState([])
  const [ActiveCustomerslist, setActiveCustomerslist] = useState([])

  const fetchActiveCustomers = async () => {
    try {
      const response = await axios.get(`apiV1/active-customers-dropdown/`);
      const response_data = response.data
      if (response.status === 200) {
        const itemsArray = response_data;
        setActiveCustomerslist(itemsArray);
      }

    } catch (error) {
      setActiveCustomerslist([]);
    }
  };
  const fetchSubscriptions = async () => {
    try {
      let token =localStorage.getItem("admin_user_token")
      const response = await axios.get(`apiV1/add-subscription-plan/`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        }
      );
      const response_data = response.data
      if (response.status === 200) {
        const itemsArray = response_data.results;
        setsubscriptionlist(itemsArray);
      }

    } catch (error) {
      setsubscriptionlist([]);
    }
  };
  const [orderid, setorderid] = useState()
  const getvaluesfromsub = async (id) => {

    let subdetails = subscriptionlist.filter((item) => item.id == id)
    setValueAdd('invoice_amount', subdetails[0].amount)

    let req = {
      "customer_id": customerId,
      "order_meta": {
        subscription_id: subdetails[0].id,
        plan_name: subdetails[0].plan_name,
        amount: subdetails[0].amount,
        total_credits: subdetails[0].total_credits
      }
    }
    try {
      const response = await axios.post(`apiV1/create-subscription-order/`, req);
      const response_data = response.data
      setorderid(response_data.data.order_id)

    } catch (error) {

    }
  }
  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />

        {/* <!-- ============================================================== -->
                <!-- Start right Content here -->
                <!-- ============================================================== --> */}
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <PageHeader mainHeading={mainHeading} heading={heading} />
              {isLoadingPrim ? <div className="loader-overlay text-white">
                <Loader />
              </div> : null}
              {!showUpdatePage && !showAddPage && (
                <>
                  
                  <div className="row  ">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row   ">
                            <div className="col-xl-12">
                              {/* Tab panes */}
                              <form data-select2-id="21" noValidate="novalidate" onSubmit={handleSubmit(onSubmit)} >
                                <div className="row g-3" data-select2-id="20">
                                  <div className="col-md-3 " style={{ zIndex: 10 }}>
                                    <label className="form-label" htmlFor="User" > User </label>

                                    <Controller name="customer_id"
                                      {...register("customer_id")}
                                      control={control}
                                      render={({ field }) => (
                                        <AutoUserSearch
                                          onSelect={(user) => {
                                            //console.log(user);
                                            setValue(
                                              "customer_id",
                                              user ? user.user_id : null
                                            );
                                            setcustomerId(
                                              user ? user.user_id : null
                                            );
                                          }}

                                          value={customerId}
                                        />
                                      )}
                                    />
                                    {errors.customer_id && (<div className="text-danger">  {errors.customer_id.message} </div>)}
                                  </div>
                                  <div className="col-md-3" style={{ zIndex: 10 }}>
                                    <label
                                      className="form-label"
                                      htmlFor="type"
                                    >
                                      Payment Type
                                    </label>
                                    <Controller
                                      name="payment_type"
                                      {...register(
                                        "payment_type"
                                        // , { required: 'Type is required' }
                                      )}
                                      control={control}
                                      render={({ field }) => {
                                        return (
                                          <CommonSelect
                                            options={paymentType}
                                            value={field.value}
                                            onChange={(selectedOption) => {
                                              if (selectedOption?.value) {
                                                field.onChange(
                                                  selectedOption?.value
                                                );
                                                handleTypeSelect(
                                                  selectedOption
                                                );
                                              } else {
                                                field.onChange("");
                                                handleTypeSelect(
                                                  selectedOption
                                                );
                                              }
                                            }}
                                            placeholder="Select Payment Type"
                                          />
                                        );
                                      }}
                                    />
                                    {errors.payment_type && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.payment_type.message}
                                      </div>
                                    )}
                                  </div>
                                  {watch("payment_type") === "Card" && (
                                    <div className="col-md-3">
                                      <label
                                        className="form-label"
                                        htmlFor="card_type"
                                      >
                                        Card Type
                                      </label>
                                      <Controller
                                        name="card_type"
                                        {...register(
                                          "card_type"
                                          // , { required: 'Card Type is required' }
                                        )}
                                        control={control}
                                        render={({ field }) => {
                                          return (
                                            <CommonSelect
                                              options={cardType}
                                              value={field.value}
                                              onChange={(selectedOption) => {
                                                if (selectedOption?.value) {
                                                  field.onChange(
                                                    selectedOption.value
                                                  );
                                                  handleCardTypeSelect(
                                                    selectedOption
                                                  );
                                                } else {
                                                  field.onChange("");
                                                  handleCardTypeSelect(
                                                    selectedOption
                                                  );
                                                }
                                              }}
                                              placeholder="Select Card Type"
                                            />
                                          );
                                        }}
                                      />
                                      {errors.card_type && (
                                        <div
                                          style={{
                                            color: "red",
                                            fontSize: "14px",
                                            marginTop: "5px",
                                          }}
                                        >
                                          {errors.card_type.message}
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  <div className="col-md-3">
                                    <label
                                      className="form-label"
                                      for="formtabs-last-name"
                                    >
                                      &nbsp;
                                    </label>
                                    <div className="button-items">
                                      <button
                                        type="submit"
                                        className="btn btn-primary w-md"
                                        disabled={isSearchBtnDisabled}
                                      >
                                        Search
                                      </button>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                  <label
                                      className="form-label"
                                      for="formtabs-last-name"
                                    >
                                      &nbsp;
                                    </label>
                                  {customeradd ?
                                  <div className="page-title-box d-sm-flex align-items-center justify-content-end">
                                    <div className="page-title-right">
                                      <button type="button" className="btn btn-primary w-md btn-md me-1" onClick={() => { setCreatenewUser(true); resetAdd(); clearErrorsAdd(); settextdisplay("Add") }} > Add Payment Details </button>
                                    </div>
                                  </div> : ""}
                                  </div>
                                  
                                </div>
                              </form>

                            </div>
                            <div className="col-md-12 d-flex justify-content-end align-items-center">
                              {exportPermission && paymentDetails.length > 0 &&
                                <button type="button" className="btn btn-success w-md" onClick={exportToExcel}>Export to Excel</button>
                              }
                              {(addPermission || editPermission || deletePermission) &&

                                <div class="btn-group ms-3">
                                  <button
                                    type="button"
                                    class="btn btn-primary w-md btn-md dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    Action <i class="mdi mdi-chevron-down"></i>
                                  </button>
                                  <div class="dropdown-menu mt-2 dropdown-menu-end py-2">
                                    {editPermission &&
                                      <>
                                        {selectedRow?.primary_card_status !== 1 && (
                                          <a
                                            href='#/'
                                            class="dropdown-item"
                                            onClick={handlePrimary}
                                          >
                                            <i class="mdi mdi-checkbox-marked-circle-outline font-size-17"></i>{" "}
                                            Set as Primary
                                          </a>
                                        )}
                                        <a
                                          href='#/'
                                          class="dropdown-item d-flex"
                                          onClick={editRequest}
                                        >
                                          <i class="far fa-edit font-size-14 me-2"></i>{" "}
                                          Edit
                                        </a>

                                      </>

                                    }
                                    {deletePermission &&
                                      <a
                                        href='#/'
                                        class="dropdown-item d-flex"
                                        onClick={deletePaymentDetails}
                                      >
                                        <i class="bx bx-trash font-size-18 me-2"></i>{" "}
                                        Delete
                                      </a>
                                    }
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-12 mb-2  ">
                              <div className="btn-group float-end col-md-2">
                                <input
                                  type="search"
                                  placeholder="Search..."
                                  value={searchQuery}
                                  className="form-control form-control-sm"
                                  aria-controls="example"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>

                          {!isLoading ?
                            <DataTable
                              columns={columns}
                              data={paymentDetails}
                              pagination
                              paginationServer
                              fixedHeaderScrollHeight='600px'
                              fixedHeader
                              persistTableHead
                              paginationTotalRows={totalRows}
                              paginationComponentOptions={paginationComponentOptions}
                              paginationPerPage={perPage}
                              paginationDefaultPage={currentPage}
                              onChangeRowsPerPage={handlePerRowsChange}
                              onChangePage={handlePageChange}


                            /> :
                            <div className='loader-overlay text-white'>
                              <Loader />
                            </div>
                          }



                        </div>
                      </div>
                    </div>{" "}
                    {/* end col */}
                  </div>{" "}
                  {/* end row */}
                </>
              )}
            </div>
            {/* <!-- container-fluid --> */}
          </div>
          {/* <!-- End Page-content --> */}

          <Footer />
        </div>
        {/* <!-- end main content--> */}
      </div>
      {/* <!-- END layout-wrapper*/}

      {/* <!-- Right bar overlay--> */}
      <div className="rightbar-overlay"></div>


      <Modal size='lg' show={CreatenewUser} onHide={() => setCreatenewUser(false)} className='  modal fade ' backdrop="static" keyboard={false} >
        <Modal.Header className='modal-header_box bg-my-primary p-3 d-flex justify-content-between ' closeButton> <h1 className="modal-title fs-5 ">{textdisplay} Payment Details </h1> </Modal.Header>

        <form onSubmit={handleSubmitAdd(onSubmitCreate)}>
          <ModalBody>
            <div className="row">
              <div className="col-md-6 ">
                <label className="form-label" htmlFor="formtabs-first-name">User <span className='text-danger'>*</span></label>

                <select className="form-select"  {...registerAdd('customer', { required: 'User is required' })} onChange={(e) => setcustomerId(e.target.value)} >
                  <option value="">Select</option>
                  {ActiveCustomerslist.map(item => (
                    <option key={item.id} value={item.id}>{item.contact_person}</option>
                  ))}
                </select>

                {errorsAdd.customer && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsAdd.customer.message}</div>}
              </div>
              <div className="col-md-6  ">
                <label className="form-label" htmlFor='subscription'>Subscription <span className='text-danger'>*</span></label>
                <select className="form-select"  {...registerAdd('subscription', { required: 'Subscription is required' })} onChange={(e) => getvaluesfromsub(e.target.value)} >
                  <option value="">Select</option>
                  {subscriptionlist.map(item => (
                    <option key={item.id} value={item.id}>{item.plan_name}</option>
                  ))}
                </select>

                {errorsAdd.subscription && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsAdd.subscription.message}</div>)}
              </div>

              <div className="col-md-6  ">
                <label className="form-label" htmlFor='subscription'>Payment Method <span className='text-danger'>*</span></label>
                <select className="form-select"  {...registerAdd('payment_method', { required: 'Payment Method is required' })}  >
                  <option value="">Select</option>
                  <option value="credit_card">Credit card</option>
                  <option value="debit_card">Debit card</option>
                  <option value="cash">Cash</option>
                </select>
                {errorsAdd.payment_method && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsAdd.payment_method.message}</div>)}
              </div>
              <div className="col-md-6  ">
                <label className="form-label" htmlFor='subscription'>Payment Status <span className='text-danger'>*</span></label>
                <select className="form-select"  {...registerAdd('payment_status', { required: 'Payment Status is required' })}  >
                  <option value="">Select</option>
                  <option value="pending">Pending</option>
                  <option value="completed">Completed</option>
                  <option value="failed">Failed</option>
                  <option value="refunded">Refunded</option>

                </select>
                {errorsAdd.payment_status && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsAdd.payment_status.message}</div>)}
              </div>
              <div className="col-md-6  ">
                <label className="form-label" htmlFor='subscription'>Payment Type <span className='text-danger'>*</span></label>
                <select className="form-select"  {...registerAdd('payment_type', { required: 'Payment Type is required' })}  >
                  <option value="">Select</option>
                  <option value="NEW">NEW</option>
                  <option value="RENEWAL">RENEWAL</option>

                </select>
                {errorsAdd.payment_type && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsAdd.payment_type.message}</div>)}
              </div>
              <div className="col-md-6  ">
                <label className="form-label" htmlFor='subscription'>Currency <span className='text-danger'>*</span></label>
                <select className="form-select"  {...registerAdd('currency', { required: 'Currency is required' })}  >
                  <option value="">Select</option>
                  <option value="USD">USD</option>
                  <option value="Rupees">Rupees</option>
                  <option value="CAD">CAD</option>
                </select>
                {errorsAdd.currency && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsAdd.currency.message}</div>)}
              </div>
              <div className="col-md-6 ">
                <label className="form-label">Invoice Amount <span className='text-danger'>*</span></label>
                <input type="text" min={0} className="form-control" {...registerAdd('invoice_amount')} placeholder="Enter Invoice Amount" disabled />
                {errorsAdd.invoice_amount && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsAdd.invoice_amount.message}</div>)}
              </div>

              <div className="col-md-6">
                <label className="form-label" htmlFor="subject">Payment Gateway <span className='text-danger'>*</span></label>
                <textarea
                  {...registerAdd('payment_gateway')}
                  id="basicpill-address-input"
                  className="form-control"
                  rows="1"
                  placeholder="Enter payment_gateway.."
                />
                {errorsAdd.payment_gateway && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsAdd.payment_gateway.message}</div>)}
              </div>

              <div className="col-md-6">
                <label className="form-label" htmlFor='plan_tax'>Payment Date<span className='text-danger'>*</span></label>
                <div className="input-group">
                  <input type="date" min={0} className="form-control" {...registerAdd('payment_date')} name='payment_date' />

                </div>
                {errorsAdd.payment_date && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsAdd.payment_date.message}</div>)}
              </div>
              <div className="col-md-6">
                <label className="form-label" htmlFor="comments">Billing Address <span className='text-danger'>*</span></label>
                <textarea
                  {...registerAdd('billing_address', {
                    required: 'Billing address are required',
                    // pattern: createPattern('!@')
                    // pattern: onlyAlphabetsandSpaces
                  })}
                  id="basicpill-address-input"
                  className="form-control md-2"

                  name='billing_address'
                  placeholder="Enter Comment.."
                  rows={5}
                />
                {errorsAdd.billing_address && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsAdd.billing_address.message}</div>)}
              </div>
            </div>



          </ModalBody>
          <Modal.Footer>
            <div className="py-2 mb-3">
              <div className="row">
                <div className="col-12 text-center">
                  <button className='btn btn-warning w-md btn-md me-2' type="button" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" onClick={() => { setCreatenewUser(false); }} > Cancel </button>
                  <button className='btn btn-primary w-md btn-md ' type="submit" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn"  > Save </button>
                </div>
              </div>
            </div>
          </Modal.Footer>

        </form>
      </Modal>

    </>
  );
}
