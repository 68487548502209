import React, { useState, useEffect, useCallback } from 'react';
import Footer from '../../common/Footer'
import PageHeader from '../PageHeader'
import Header from '../../common/Header'
import Sidebar from '../../common/Sidebar'
import { Table, Tab, Tabs } from 'react-bootstrap';
import AutocompleteSearch from '../AutocompleteSearch'
import axios from 'axios'
import { exportToCsv, formatDate, getToken, simpleAlert, triggerAlert, getPeriodStartAndEndInUnixTimestamp, secondsToTime, formattedDateTime, transformText, get_user_menu_permission } from '../../utils'
import { Controller, useForm } from 'react-hook-form'
// for DatePicker
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../../common/Loader'
import Pagination from '../PaginationComponent'
import * as XLSX from "xlsx";
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { subMonths, startOfDay, endOfDay, getUnixTime } from 'date-fns';
import AutoSearchSelect from '../CommonSelectDynamic'
import CommonSelect from "../CommonSelectStatic";
import { reportType, reportStatus, cdrCallType, menuIdData } from "../../utils/constants";
import AutoUserSearch from '../AutoUserSearch';

export default function CallHistory() {

    const api_url = process.env.REACT_APP_API_CUSTOMER_URL;
    const api_url_admin = process.env.REACT_APP_API_BASE_URL;
    const token = getToken();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const action = queryParams.get('action');
    const { state } = location;
    const mainHeading = 'Call History';
    const heading = 'Reports / Call History';

    const [forceUpdate, setForceUpdate] = useState(false);
    const [selectedTab, setSelectedTab] = useState("cdr");
    const [selectedTabName, setSelectedTabName] = useState("CDRs");
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState([]);
    const [postData, setPostData] = useState(null);
    const [apiSessKey, setApiSessKey] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [pageSlNo, setPageSlNo] = useState(0);
    const [perPageLimit, setPerPageLimit] = useState(100);
    const [pageLimitSelected, setPageLimitSelected] = useState(100);

    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    //const [periodStart, setPeriodStart]     = useState("");

    const [period_start, setPeriodStart] = useState("");
    const [period_end, setPeriodEnd] = useState("");

    const [isLoading, setIsLoading] = useState();

    const { register, handleSubmit, formState: { errors }, setValue, control, watch, reset, unregister, setError, clearErrors, getValues } = useForm({
        defaultValues: {
            s_call_type: 'all',
            call_point: 'outbound',
            // s_call_type:'all',
        }
    });
    const { control: control2, register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, setValue: setValue2, reset: reset2, unregister: unregister2, getValues: getValues2, setError: setError2, clearErrors: clearErrors2 } = useForm({
        defaultValues: {
            direction: 'OUT'
        }
    });
    const periodStartWatch = watch('period_start');
    const [minEndDate, setMinEndDate] = useState(periodStartWatch ? new Date(periodStartWatch) : null);


    // Menu Permissions
    //  const [addPermission, setAddPermission] = useState(false);
    //  const [editPermission, setEditPermission] = useState(false);
    //  const [deletePermission, setDeletePermission] = useState(false);
    const [exportPermission, setExportPermission] = useState(false);


    // const handleUser = (selected) => {
    //     // console.log('kotji',selected); 
    //     // setValue('mor_account_id', selected ? selected.mor_account_id : null);
    //     // setValue('m4_account_id', selected ? selected.m4_account_id : null);
    //     setValue('customer_id', selected ? selected.user_id : null);
    // }

    const handleUser = (selected, tab) => {
        if (tab === 'cdr') {
            setValue('customer_id', selected ? selected.user_id : null);
        } else if (tab === 'sms') {
            setValue2('customer_id', selected ? selected.user_id : null);
        }
    }


    const handleTabChange = (tab) => {
        // console.log("tab", tab)
        unregister("customer_id");
        setSelectedTab(tab);
        // console.log("selected Tab", selectedTab);
        if (tab === "cdr") {
            formReset();
            setFormData([]);
            setSelectedTabName("CDRs");
            setData([]);
            setPostData(null);
            setApiSessKey('');
            setCurrentPage(0);
            setPageCount(0);

            setValue('s_call_type', 'all');
            setValue('call_point', 'outbound');
        } else {
            formReset();
            setFormData([]);
            setSelectedTabName("SMS Reports");
            setData([]);
            setPostData(null);
            setApiSessKey('');
            setCurrentPage(0);
            setPageCount(0);
            setValue2('direction', 'OUT');
        }
        setCurrentPage(0);
    };

    const handleDownload = async () => {
        // flatten object like this {id: 1, title:'', category: ''};
        if (selectedTab === "cdr") {

            setIsLoading(true);


            try {

                let apiUrl = `${api_url}services/get_cdr_data_record_download`

                const response = await axios.post(apiUrl, postData, token);
                const response_data = response.data;
                if (response_data.error_code === 200) {
                    const itemsArray = response_data.results.data;
                    // console.log('itemsArray', itemsArray)
                    exportToCsv(itemsArray, 'Cdr_report')
                    triggerAlert('success', 'Success', "Successfully downloaded the file");
                    setIsLoading(false);
                } else if (response.status === 204) {

                    triggerAlert('error', 'Oops...', "No data available");
                    setIsLoading(false);
                } else {
                    triggerAlert('error', 'Oops...', "Couldn't download the file");
                }
            } catch (error) {
                console.log(error)
                setIsLoading(false);
                triggerAlert("error", "Oops...", "Something went wrong..");
            }
        } else if (selectedTab === "sms") {

            try {

                let apiUrl = `${api_url}services/get_sms_data_download`

                const response = await axios.post(apiUrl, postData, token);
                const response_data = response.data;
                if (response_data.error_code === 200) {
                    const itemsArray = response_data.results.data;
                    // console.log('itemsArray', itemsArray)
                    exportToCsv(itemsArray, 'Sms_report')
                    triggerAlert('success', 'Success', "Successfully downloaded the file");
                    setIsLoading(false);
                } else if (response.status === 204) {

                    triggerAlert('error', 'Oops...', "No data available");
                    setIsLoading(false);
                } else {
                    triggerAlert('error', 'Oops...', "Couldn't download the file");
                }
            } catch (error) {
                console.log(error)
                setIsLoading(false);
                triggerAlert("error", "Oops...", "Something went wrong..");
            }
            //  File(workbook1, "ReportForSMS.xlsx", { compression: true });
        }
    };


    const callPointChange = (selected) => {
        setPageCount(0);
        setApiSessKey('');
        setCurrentPage(0);
        // console.log(selected);
        setValue('call_point', selected ? selected.value : null)
    }
    const directionChange = (selected) => {
        setPageCount(0);
        setApiSessKey('');
        setCurrentPage(0);
        // console.log(selected);
        setValue2('direction', selected ? selected.value : null)
    }

    const handleStatusChange = (selected) => {
        setPageCount(0);
        setApiSessKey('');
        setCurrentPage(0);
        // console.log(selected);
        setValue('s_call_type', selected ? selected.value : null)
    }

    const fetchData = async (page, postData) => {
        // console.log("fetchData", selectedTab);
        setIsLoading(true);
        const per_page = Number(perPageLimit);
        try {
            setPageSlNo((page - 1) * per_page);
            // postData.sess_key = apiSessKey ? apiSessKey : '';
            postData.page = page;
            postData.per_page = per_page;
            var response;
            if (selectedTab === 'cdr') {
                response = await axios.post(api_url + 'services/get_cdr_data', postData, token);
            } else {
                response = await axios.post(api_url + 'services/get_sms_data', postData, token);
            }
            const response_data = response.data
            // console.log(response_data);
            // const total_count = response_data.pagination.total_items;
            if (response_data.error_code === 200) {
                // if (response_data.results.pagination !== undefined) {
                const total_pages = response_data.results.pagination.total_pages;
                setData(response_data.results.data);
                setPageCount(total_pages);
                setApiSessKey(response_data.results.sess_key);
                // }
                setIsLoading(false);
            } else if (response.status === 204) {
                setData([]);
                setPageCount(0);
                setApiSessKey('');
                setIsLoading(false);
                simpleAlert("No Data Available");
            } else {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }
        } catch (error) {
            console.log('error', error.message);
            setIsLoading(false);
            triggerAlert('error', 'Oops...', 'Something went wrong..');
        }
    };

    const cdrSearchSubmit = async (data) => {
        setApiSessKey('');
        setCurrentPage(0); // Reset page to 0 for server-side pagination
        setPageCount(0);
        const type = data.s_call_type;
        const point = data.call_point;

        setFormData(data)
        var postData;
        if (selectedTab === 'cdr') {
            postData = {
                period_start: formatDate(data.period_start, 'yyyy-mm-dd'),
                period_end: formatDate(data.period_end, 'yyyy-mm-dd'),
                s_device: 7,
                s_call_type: type,
                s_destination: "1469",
                s_origination_point: "17324447765",
                s_termination_point: "14692139502",
                call_point: point,
                customer_id: data.customer_id,
                // m4_account_id: data.m4_account_id,
                // mor_account_id: data.mor_account_id
            }
        } else {
            postData = {
                filter_type: 'date_wise',
                from_date: formatDate(data.period_start, 'yyyy-mm-dd'),
                to_date: formatDate(data.period_end, 'yyyy-mm-dd'),
                direction: data.direction,
                search_key: "",
                customer_id: data.customer_id,
            }

        }
        setPostData(postData);
        fetchData(1, postData);
    };

    // console.log('formData', formData)

    const handleCurrentDateSearch = async () => {
        setApiSessKey('');
        setCurrentPage(0); // Reset page to 0 for server-side pagination
        setPageCount(0);
        const customerValue = getValues('customer_id');
        const s_call_type = getValues('s_call_type');
        const call_point = getValues('call_point');
        const customerValue2 = getValues2('customer_id');
        const direction = getValues2('direction');
        // console.log('customerValue', customerValue, customerValue2)
        if (!customerValue && !customerValue2) {
            // If customerValue is not available
            // Set an error message for the customer_id field using setError or setError2
            if (selectedTab === 'cdr') {
                setError('customer_id', {
                    type: 'manual',
                    message: 'User is required'
                });
            } else {
                setError2('customer_id', {
                    type: 'manual',
                    message: 'User is required'
                });
            }
            return; // Exit the function early
        }

        // If the user is selected, clear the error message for the customer_id field
        clearErrors('customer_id');
        clearErrors2('customer_id');

        //for CDRS and SMS fetching previous date
        const currentDateCDR = new Date();
        const previousDate = new Date(currentDateCDR);
        previousDate.setDate(previousDate.getDate() - 1); // Get previous date

        let postData = '';
        if (selectedTab === 'cdr') {
            postData = {
                period_start: formatDate(previousDate, 'yyyy-mm-dd'),
                period_end: formatDate(previousDate, 'yyyy-mm-dd'),
                s_device: 7,
                s_call_type: s_call_type || 'all',
                s_destination: "1469",
                s_origination_point: "17324447765",
                s_termination_point: "14692139502",
                call_point: call_point || 'outbound',
                type: 'cur',
                // m4_account_id: "5",
                // mor_account_id: "5",
                customer_id: formData?.customer_id || customerValue || customerValue2,
                sess_key: "",
            }
        } else {
            postData = {
                filter_type: "date_wise",
                from_date: formatDate(previousDate, 'yyyy-mm-dd'),
                to_date: formatDate(previousDate, 'yyyy-mm-dd'),
                direction: direction || 'OUT',
                customer_id: formData?.customer_id || customerValue || customerValue2,
                search_key: ""
            }
        }
        setPostData(postData);
        fetchData(1, postData);
    }

    const handleWeekSearch = async () => {
        setApiSessKey('');
        setCurrentPage(0); // Reset page to 0 for server-side pagination
        setPageCount(0);
        const customerValue = getValues('customer_id');
        const s_call_type = getValues('s_call_type');
        const call_point = getValues('call_point');
        const customerValue2 = getValues2('customer_id');
        const direction = getValues2('direction');
        // console.log('customerValue', customerValue, customerValue2)
        if (!customerValue && !customerValue2) {
            // If customerValue is not available
            // Set an error message for the customer_id field using setError or setError2
            if (selectedTab === 'cdr') {
                setError('customer_id', {
                    type: 'manual',
                    message: 'User is required'
                });
            } else {
                setError2('customer_id', {
                    type: 'manual',
                    message: 'User is required'
                });
            }
            return; // Exit the function early
        }

        // If the user is selected, clear the error message for the customer_id field
        clearErrors('customer_id');
        clearErrors2('customer_id');

        const currentDateCDR = new Date();
        const weekEndDate = new Date(currentDateCDR);
        weekEndDate.setDate(weekEndDate.getDate() - 7); // Get previous date
        var postData;
        if (selectedTab === 'cdr') {
            postData = {
                period_start: formatDate(weekEndDate, 'yyyy-mm-dd'),
                period_end: formatDate(currentDateCDR, 'yyyy-mm-dd'),
                s_device: 7,
                s_call_type: s_call_type || 'all',
                s_destination: "1469",
                s_origination_point: "17324447765",
                s_termination_point: "14692139502",
                call_point: call_point || 'outbound',
                // m4_account_id: "5",
                // mor_account_id: "5",
                customer_id: formData?.customer_id || customerValue || customerValue2,
                sess_key: "",
            }
        } else {
            postData = {
                from_date: formatDate(weekEndDate, 'yyyy-mm-dd'),
                to_date: formatDate(currentDateCDR, 'yyyy-mm-dd'),
                filter_type: 'last_7_days',
                direction: direction || "OUT",
                customer_id: formData?.customer_id || customerValue || customerValue2,
            }
        }

        setPostData(postData);
        fetchData(1, postData);
    }

    const handleMonthSearch = async () => {
        setApiSessKey('');
        setCurrentPage(0); // Reset page to 0 for server-side pagination
        setPageCount(0);
        const customerValue = getValues('customer_id');
        const s_call_type = getValues('s_call_type');
        const call_point = getValues('call_point');
        const customerValue2 = getValues2('customer_id');
        const direction = getValues2('direction');
        if (!customerValue && !customerValue2) {
            // If customerValue is not available
            // Set an error message for the customer_id field using setError or setError2
            if (selectedTab === 'cdr') {
                setError('customer_id', {
                    type: 'manual',
                    message: 'User is required'
                });
            } else {
                setError2('customer_id', {
                    type: 'manual',
                    message: 'User is required'
                });
            }
            return; // Exit the function early
        }

        // If the user is selected, clear the error message for the customer_id field
        clearErrors('customer_id');
        clearErrors2('customer_id');

        const currentDate = new Date();
        // console.log('handleMonthSearch');

        // Calculate the date exactly one month ago
        const oneMonthAgo = subMonths(currentDate, 1);
        var postData;
        if (selectedTab === 'cdr') {
            postData = {
                period_start: formatDate(oneMonthAgo, 'yyyy-mm-dd'),
                period_end: formatDate(currentDate, 'yyyy-mm-dd'),
                s_device: 7,
                s_call_type: s_call_type,
                s_destination: "1469",
                s_origination_point: "17324447765",
                s_termination_point: "14692139502",
                call_point: call_point,
                // m4_account_id: "5",
                // mor_account_id: "5",
                customer_id: formData?.customer_id || customerValue || customerValue2,
                sess_key: "",
            }
        } else {
            postData = {
                from_date: formatDate(oneMonthAgo, 'yyyy-mm-dd'),
                to_date: formatDate(currentDate, 'yyyy-mm-dd'),
                filter_type: 'last_1_month',
                direction: direction || "OUT",
                customer_id: formData?.customer_id || customerValue || customerValue2,
            }
        }

        setPostData(postData);
        fetchData(1, postData);
    };


    const formReset = () => {
        reset();
        reset2();
        setValue('customer_id', null);
        setValue2('customer_id', null);
        setForceUpdate(prevState => !prevState);
        //handleCurrentDateSearch();
    }

    const handlePageChange = event => {
        // console.log(event.target.value);
        const perPageValue = event.target.value;
        if (perPageValue) {
            setPerPageLimit(perPageValue);
            setPageLimitSelected(perPageValue);
        } else {
            setPerPageLimit(100);
            setPageLimitSelected(100);
        }
    };

    const handlePageClick = (selected) => {
        const selectedPage = selected.selected;
        setCurrentPage(selectedPage);
        fetchData(selectedPage + 1, postData); // Increment the page number by 1 for server-side pagination
    };

    let pgntn_props = {
        pageCount: pageCount,
        handlePageClick: handlePageClick,
        selectedPage: currentPage
    }

    const fetchCustomerm4Data = async (custId) => {
        const custData = await axios.get(api_url_admin + 'dashboard/get_all_customers', token);
        const custResponse = custData.data;
        if (custResponse.error_code === 200) {
            const OptionsList = custResponse.results;
            const userOptions = OptionsList.find((options) => options.id === custId);
            return userOptions;
        } else {
            return '';
        }
    }

    useEffect(() => {
        const fetchData123 = async () => {
            //console.log(state);
            if (action === 'frm_dash') {
                // setSelectedTab(state?.call_type);
                // console.log('state', state)
                const custm4Data = await fetchCustomerm4Data(state.customer_id);
                setValue('customer_id', state.customer_id);
                // setValue('m4_account_id',custm4Data.m4_account_id);
                // setValue('mor_account_id',custm4Data.mor_account_id);
                // setValue('m4_account_id', '5');
                // setValue('mor_account_id', '5');
                setValue('period_start', state.period_start);
                setValue('period_end', state.period_end);
                setValue('s_call_type', state.s_call_type);
                setValue('call_point', state.call_point);
                // state.m4_account_id = '5';//custm4Data.m4_account_id;
                // state.mor_account_id = '5';//custm4Data.mor_account_id;
                await cdrSearchSubmit(state);
            }
        };
        if (selectedTab === "cdr" && action) {
            fetchData123();
        } else {
            const newLocation = { ...location, state: null };
            navigate(newLocation.pathname, { state: null });
        }
        //console.log("selected Tab",selectedTab);
    }, [selectedTab]);

    // useEffect(() => {

    //     fetchData(currentPage + 1, )
    // }, [perPageLimit]);

    useEffect(() => {
        const menu_id = menuIdData?.call_history;
        // setAddPermission(get_user_menu_permission(menu_id, 'add'));
        // setEditPermission(get_user_menu_permission(menu_id, 'edit'));
        // setDeletePermission(get_user_menu_permission(menu_id, 'delete'));
        setExportPermission(get_user_menu_permission(menu_id, 'export'));
    }, []);


    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}

                            <div className="row  mt-4">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <Tabs defaultActiveKey="cdr" id="uncontrolled-tab-example" className="nav nav-pills nav-justified" activeKey={selectedTab} onSelect={handleTabChange} >
                                                <Tab eventKey="cdr" title={<span style={{ color: selectedTab === "cdr" ? 'white' : 'black', fontWeight: 'bold' }}>CDRs</span>} className='cdr'></Tab>
                                                <Tab eventKey="sms" title={<span style={{ color: selectedTab === "sms" ? 'white' : 'black', fontWeight: 'bold' }}>SMS Reports</span>} className='sms'></Tab>
                                            </Tabs>

                                            {/* <h4 class="card-title mb-4 h4-card">{selectedTabName}</h4> */}
                                            {selectedTab === 'cdr' && (
                                                <form id="creditCardForm1" class="row g-3 fv-plugins-bootstrap5 fv-plugins-framework fv-plugins-icon-container" novalidate="novalidate" onSubmit={handleSubmit(cdrSearchSubmit)}>
                                                    <div class="row g-3 d-flex justify-content-evenly align-items-center" data-select2-id="20">
                                                        <div class="col-md-2">
                                                            <div class="row">
                                                                <label for="example-date-input" class="col-form-label">User <span style={{ color: 'red' }}>*</span> </label>
                                                                <Controller
                                                                    key={forceUpdate}
                                                                    name="customer_id"
                                                                    {...register('customer_id'
                                                                        , { required: 'User is required' }
                                                                    )}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <AutoUserSearch onSelect={(selected) => handleUser(selected, 'cdr')} value={field.value} />
                                                                    )}
                                                                />

                                                                {errors.customer_id && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.customer_id.message}</div>}
                                                                {/* {!formData.customer_id && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>User is required</div>} */}
                                                            </div>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <div class="row">
                                                                <label for="example-date-input" class="col-form-label">From <span style={{ color: 'red' }}>*</span></label>
                                                                <div class="col-md-12">
                                                                    <Controller className="px-3" control={control} name="period_start" render={({ field }) => (
                                                                        <DatePicker className="px-3 form-control" placeholderText='MM/DD/YYYY' selected={field.value} {...register('period_start', { required: 'From Date is required' })}
                                                                            onChange={(date) => {
                                                                                setValue('period_start', date);
                                                                                field.onChange(date);
                                                                                setMinEndDate(date); // Update minDate for period_end 
                                                                                // setIsSearchBtnDisabled(!date || !period_end);
                                                                                // if (date) setSelectedTab('datewise');

                                                                            }} showMonthDropdown showYearDropdown maxDate={new Date()} autoComplete='off' />
                                                                    )} />
                                                                    {errors.period_start && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.period_start.message}</div>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-2">
                                                            <div class="row">
                                                                <label for="example-date-input" class="col-form-label">To <span style={{ color: 'red' }}>*</span></label>
                                                                <div class="col-md-12">
                                                                    <Controller control={control} name="period_end" render={({ field }) => (
                                                                        <DatePicker className="px-3 form-control" placeholderText='MM/DD/YYYY' selected={field.value}  {...register('period_end', { required: 'To Date is required' })}
                                                                            onChange={(date) => {
                                                                                setValue('period_end', date); // Update the state variable
                                                                                field.onChange(date);// Update the form value
                                                                                // setIsSearchBtnDisabled(!period_start || !date);
                                                                                // if (date) setSelectedTab('datewise');

                                                                            }} showMonthDropdown showYearDropdown maxDate={new Date()} minDate={minEndDate} autoComplete='off' />
                                                                    )} />
                                                                    {errors.period_end && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.period_end.message}</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label for="example-date-input" class="col-form-label">Status <span style={{ color: 'red' }}>*</span></label>

                                                            <Controller
                                                                name="s_call_type"
                                                                {...register('s_call_type'
                                                                    , { required: 'Status is required' }
                                                                )}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <CommonSelect
                                                                        options={reportStatus}
                                                                        value={field.value}
                                                                        // onChange={(value) => field.onChange(value)}
                                                                        onChange={handleStatusChange}
                                                                        placeholder="Select Type"
                                                                    />
                                                                )}
                                                            />
                                                            {errors.s_call_type && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.s_call_type.message}</div>}
                                                        </div>
                                                        <div class="col-md-2">
                                                            <label for="example-date-input" class="col-form-label">Type of Call <span style={{ color: 'red' }}>*</span></label>
                                                            <Controller
                                                                name="call_point"
                                                                {...register('call_point'
                                                                    , { required: 'Type of Call is required' }
                                                                )}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <CommonSelect
                                                                        options={cdrCallType}
                                                                        value={field.value}
                                                                        // onChange={(value) => field.onChange(value)}
                                                                        onChange={callPointChange}
                                                                        placeholder="Select Type"
                                                                    />
                                                                )}
                                                            />
                                                            {errors.call_point && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.call_point.message}</div>}

                                                        </div>
                                                        <div class="col-md-2">
                                                            <div class="row">
                                                                <label class="form-label" for="formtabs-last-name">&nbsp;</label>
                                                                <div className='d-flex'>
                                                                    <button type="submit" className="btn btn-primary  waves-effect waves-light btn-md me-md-2 px-5">Search  </button>
                                                                    {/* <button type="button" class="btn btn-warning waves-effect waves-light px-4" onClick={formReset}>Cancel  </button> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row d-flex justify-content-start mb-4'>
                                                        <div class="col-md-4">
                                                            <label class="form-label" for="formtabs-last-name">&nbsp;</label>
                                                            <div class="button-items  d-grid gap-2 d-md-flex justify-content-md- mt-2 mb-3 ">
                                                                <button type="button" class="btn btn-primary waves-effect waves-light me-1" onClick={handleCurrentDateSearch}>Current Date  </button>
                                                                <button type="button" class="btn btn-success waves-effect waves-light me-1" onClick={handleWeekSearch}>Last 7 Days</button>
                                                                <button type="button" class="btn btn-info waves-effect waves-light" onClick={handleMonthSearch}>Last 1 Month</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            )}
                                            {selectedTab === 'sms' && (
                                                <form id="creditCardForm1" class="row g-3 fv-plugins-bootstrap5 fv-plugins-framework fv-plugins-icon-container" novalidate="novalidate" onSubmit={handleSubmit2(cdrSearchSubmit)}>
                                                    <div className="row g-3 d-flex justify-content-evenly align-items-center" data-select2-id="20">
                                                        <div class="col-md-2">
                                                            <div class="row">
                                                                <label for="example-date-input" class="col-form-label">User <span style={{ color: 'red' }}>*</span></label>
                                                                <Controller
                                                                    key={forceUpdate}
                                                                    name="customer_id"
                                                                    {...register2('customer_id'
                                                                        , { required: 'User is required' }
                                                                    )}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <AutoUserSearch onSelect={(selected) => handleUser(selected, 'sms')} />
                                                                    )}
                                                                />
                                                                {errors2.customer_id && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.customer_id.message}</div>}
                                                            </div>
                                                        </div>

                                                        {/* <input type='hidden' {...register2('m4_account_id')} />
                                                        <input type='hidden' {...register2('mor_account_id')} /> */}

                                                        <div class="col-md-2">
                                                            <div class="row">
                                                                <label for="example-date-input" class="col-form-label">From <span style={{ color: 'red' }}>*</span></label>
                                                                <div class="col-md-12">
                                                                    <Controller className="px-3" control={control} name="period_start" render={({ field }) => (
                                                                        <DatePicker className="px-3 form-control" placeholderText='MM/DD/YYYY' selected={field.value} {...register2('period_start', { required: 'From Date is required' })}
                                                                            onChange={(date) => {
                                                                                setValue2('period_start', date);
                                                                                field.onChange(date);
                                                                                setMinEndDate(date); // Update minDate for period_end 
                                                                                // setIsSearchBtnDisabled(!date || !period_end);
                                                                                // if (date) setSelectedTab('datewise');

                                                                            }} showMonthDropdown showYearDropdown maxDate={new Date()} autoComplete='off' />
                                                                    )} />
                                                                    {errors2.period_start && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.period_start.message}</div>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-2">
                                                            <div class="row">
                                                                <label for="example-date-input" class="col-form-label">To <span style={{ color: 'red' }}>*</span></label>
                                                                <div class="col-md-12">
                                                                    <Controller control={control} name="period_end" render={({ field }) => (
                                                                        <DatePicker className="px-3 form-control" placeholderText='MM/DD/YYYY' selected={field.value}  {...register2('period_end', { required: 'To Date is required' })}
                                                                            onChange={(date) => {
                                                                                setValue2('period_end', date); // Update the state variable
                                                                                field.onChange(date);// Update the form value
                                                                                // setIsSearchBtnDisabled(!period_start || !date);
                                                                                // if (date) setSelectedTab('datewise');

                                                                            }} showMonthDropdown showYearDropdown maxDate={new Date()} minDate={minEndDate} autoComplete='off' />
                                                                    )} />
                                                                    {errors2.period_end && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.period_end.message}</div>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <label for="example-date-input" class="col-form-label">Type of Call <span style={{ color: 'red' }}>*</span></label>

                                                            <Controller
                                                                name="direction"
                                                                {...register2('direction'
                                                                    , { required: 'Type of Call is required' }
                                                                )}
                                                                control={control2}
                                                                render={({ field }) => (
                                                                    <CommonSelect
                                                                        options={reportType}
                                                                        value={field.value}
                                                                        // onChange={(value) => field.onChange(value)}
                                                                        onChange={directionChange}
                                                                        placeholder="Select Type of Call"
                                                                    />
                                                                )}
                                                            />
                                                            {errors2.direction && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.direction.message}</div>}
                                                        </div>
                                                        <div className="col-md-3 mt-4">
                                                            <div className="mt-4">
                                                                <button type="submit" className="btn btn-primary  waves-effect waves-light btn-md me-md-2 px-5">Search  </button>
                                                                {/* <button type="button" className="btn btn-warning waves-effect waves-light px-4" onClick={formReset}>Cancel  </button> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row d-flex justify-content-start mb-4'>
                                                        <div className="col-md-4">
                                                            <label className="form-label" for="formtabs-last-name">&nbsp;</label>
                                                            <div class="button-items  d-grid gap-2 d-md-flex justify-content-md- mt-2 mb-3 ">
                                                                <button type="button" className="btn btn-primary waves-effect waves-light me-1" onClick={handleCurrentDateSearch}>Current Date  </button>
                                                                <button type="button" className="btn btn-success waves-effect waves-light me-1" onClick={handleWeekSearch}>Last 7 Days</button>
                                                                <button type="button" className="btn btn-info waves-effect waves-light" onClick={handleMonthSearch} >Last 1 Month</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            )}

                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="d-flex justify-content-between">
                                                                <h4 class="card-title h4-card  ">{selectedTabName}</h4>
                                                                <div class="btn-group mb-3 float-end">
                                                                    {exportPermission &&
                                                                        data.length > 0 ? (
                                                                        <div class="btn-group mb-3 float-end">
                                                                            <button type="button" class="btn btn-success waves-effect waves-light w-50" onClick={handleDownload} > Download</button>
                                                                        </div>) : null

                                                                    }
                                                                </div>
                                                            </div>
                                                            {selectedTab === 'cdr' && (
                                                                <>
                                                                    {/* <div className="dataTables_length" id="example_length">
                                                                        <label>Show
                                                                            <select name="example_length" aria-controls="example" className="table_length" onChange={handlePageChange} value={pageLimitSelected}>
                                                                                <option value={100}>100</option>
                                                                                <option value={200}>200</option>
                                                                                <option value={500}>500</option>
                                                                            </select> entries
                                                                        </label>
                                                                    </div> */}
                                                                    {/* <TableData {...table_props} /> */}

                                                                    <Table style={{ width: '100%' }} className='mt-3'>
                                                                        <thead style={{ backgroundColor: '#ededed' }}>
                                                                            <tr>
                                                                                <th width="">#</th>
                                                                                <th>Source</th>
                                                                                <th>Destination</th>
                                                                                <th>Duration</th>
                                                                                <th>Date</th>
                                                                                {/*<th>Status</th>*/}
                                                                                <th>Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        {isLoading ? (
                                                                            <div className='loader-overlay text-white'>
                                                                                <Loader />
                                                                            </div>

                                                                        ) : (
                                                                            <tbody>
                                                                                {data.length > 0 ? (
                                                                                    data.map((item, index) => (
                                                                                        <tr className={selectedRowId === item.uniqueid ? 'row_selected' : ''}  >
                                                                                            <td>{pageSlNo + index + 1}</td>
                                                                                            <td>{item.src}</td>
                                                                                            <td>{item.dst}</td>
                                                                                            <td>{secondsToTime(item.duration)}</td>
                                                                                            <td>{formattedDateTime(item.calldate2, 'YYYY-MM-DD HH:mm:ss')}</td>
                                                                                            <td>
                                                                                                <td>
                                                                                                    {item.dispod === "CANCEL" ? (<span className="badge bg-warning rounded-pill font-size-12">{transformText(item.dispod, 'capitalize')}</span>) :
                                                                                                        item.dispod === "NO ANSWER" ? (<span className="badge bg-secondary rounded-pill font-size-12">{transformText(item.dispod, 'capitalize')}</span>) :
                                                                                                            item.dispod === "ANSWERED" ? (<span className="badge bg-success rounded-pill font-size-12">{transformText(item.dispod, 'capitalize')}</span>) :
                                                                                                                item.dispod === "FAILED" ? (<span className="badge bg-danger rounded-pill font-size-12">{transformText(item.dispod, 'capitalize')}</span>) :
                                                                                                                    item.dispod === "BUSY" ? (<span className="badge bg-info rounded-pill font-size-12">{transformText(item.dispod, 'capitalize')}</span>) :
                                                                                                                        <span className="badge bg-danger rounded-pill font-size-12">{transformText(item.dispod, 'capitalize')}</span>
                                                                                                    }
                                                                                                </td>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))
                                                                                ) : (
                                                                                    <tr>
                                                                                        <td colSpan="6" className="text-center">No data available</td>
                                                                                    </tr>
                                                                                )}

                                                                            </tbody>
                                                                        )}
                                                                    </Table>
                                                                    <hr />
                                                                    <Pagination {...pgntn_props} />
                                                                </>
                                                            )}

                                                            {selectedTab === 'sms' && (
                                                                <>
                                                                    {/* <div className="dataTables_length" id="example_length">
                                                                        <label>Show
                                                                            <select name="example_length" aria-controls="example" className="table_length" onChange={handlePageChange} value={pageLimitSelected}>
                                                                                <option value={100}>100</option>
                                                                                <option value={200}>200</option>
                                                                                <option value={500}>500</option>
                                                                            </select> entries
                                                                        </label>
                                                                    </div> */}
                                                                    {/* <TableData {...table_props} /> */}

                                                                    <Table style={{ width: '100%' }} className='mt-3'>
                                                                        <thead style={{ backgroundColor: '#ededed' }}>
                                                                            <tr>
                                                                                <th width="">#</th>
                                                                                <th>Source</th>
                                                                                <th>Destination</th>
                                                                                <th>Message</th>
                                                                                {/*<th>Direction</th>*/}
                                                                                {/*<th>Duration</th>
                                                                                <th>Status</th>*/}
                                                                                <th>Created On</th>
                                                                            </tr>
                                                                        </thead>
                                                                        {isLoading ? (
                                                                            <div className='loader-overlay text-white'>
                                                                                <Loader />
                                                                            </div>

                                                                        ) : (
                                                                            <tbody>
                                                                                {data.length > 0 ? (
                                                                                    data.map((item, index) => (
                                                                                        <tr className={selectedRowId === item.uniqueid ? 'row_selected' : ''}  >
                                                                                            <td>{pageSlNo + index + 1}</td>
                                                                                            <td>{item.src}</td>
                                                                                            <td>{item.dst}</td>
                                                                                            <td>{item.msg}</td>
                                                                                            {/*<td>{item.direction === "OUT"?"Outbound":"Inbound"}</td>*/}
                                                                                            <td>{formattedDateTime(item.created_date)}</td>
                                                                                            {/*<td>{secondsToTime(item.duration)}</td>

                                                                                            <td>
                                                                                                {item.dispod === "CANCEL" ? (<span className="badge bg-danger font-size-12">Cancel</span>) :
                                                                                                    item.dispod === "NO ANSWER(18)" ? (<span className="badge bg-danger font-size-12">No Answer</span>) :
                                                                                                        item.dispod === "ANSWERED(16)" ? (<span className="badge bg-success font-size-12">Answered</span>) :
                                                                                                            <span className="badge bg-danger font-size-12">Failed</span>
                                                                                                }
                                                                                            </td>*/}
                                                                                        </tr>
                                                                                    ))
                                                                                ) : (
                                                                                    <tr>
                                                                                        <td colSpan="6" className="text-center">No data available</td>
                                                                                    </tr>
                                                                                )}

                                                                            </tbody>
                                                                        )}
                                                                    </Table>
                                                                    <hr />
                                                                    <Pagination {...pgntn_props} />
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> {/*end col */}
                            </div> {/*end row */}
                        </div> {/* container-fluid */}
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </>
    )
}
