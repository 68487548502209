import Header from "../../../common/Header";
import Sidebar from "../../../common/Sidebar";
import Footer from "../../../common/Footer";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { triggerAlert, pageReload, getToken } from "../../../utils";
import Loader from "../../../common/Loader";

import Editor from "../../Editor";

const Add = () => {
  const api_url = process.env.REACT_APP_API_BASE_URL;
  const token = getToken();
  const navigate = useNavigate();
  const [editorInstance, setEditorInstance] = useState(null);
  const [base64Image, setBase64Image] = useState('');
  const [field, setField] = useState(""); // Define and initialize 'field'

  useEffect(() => {
    if (editorInstance) {
      const editor = editorInstance;
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    }
  }, [editorInstance]);



  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = useForm();

  const formSubmit = async (data) => {
    try {
      setIsLoading(true);
      const params = {
        predefined_subject: data.subject,
        predefined_message: data.editorContent,
        status: data.type,
      };
      const response = await axios.post(`/apiV1/predefined_replies/add_data`,params
      );
      if (response.status === 201) {
        triggerAlert(
          "success",
          "Success",
          `Pre Defined Replies Added Successfully!!!`
        );
        navigate("/support/predefined_replies");
        setIsLoading(false);
      } else {
        throw new Error("Unable to Add Pre Defined Replies Successfully!!!");
      }
    } catch (err) {
      if (err.response.data.message) {
        triggerAlert("error", "Oops...", err.response.data.message);
      }
      else{
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
      setIsLoading(false);
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      setBase64Image('');
      return;
    }

    try {
      // Convert file to base64
      const base64 = await convertFileToBase64(file);
      setBase64Image(base64);

      // Image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error converting file to base64:', error);
      setBase64Image('');
      setPreviewImage('');
    }
  };
  const [previewImage, setPreviewImage] = useState('');

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };



  const handleEditorChange = (event, editor, field) => {
    const data = editor.getData();
    field.onChange(data); // Update the form field value
  };


  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div class="main-content">
          {isLoading ? (
            <div className="loader-overlay text-white">
              <Loader />
            </div>
          ) : (
            ""
          )}
          <div class="page-content">
            <div class="container-fluid">
              {/* <!-- start page title --> */}
              <div class="row">
                <div class="col-12">
                  <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0 font-size-18">
                      Create Pre Defined Replies
                    </h4>
                    <div class="page-title-right">
                      <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item">
                          <a href="javascript: void(0);">Dashboard</a>
                        </li>
                        <li class="breadcrumb-item">
                          <a href="javascript: void(0);">
                            {" "}
                            Pre Defined Replies List
                          </a>
                        </li>
                        <li class="breadcrumb-item active">
                          Create Pre Defined Replies
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}
              <div class="row mt-4">
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body p-4">
                      <div class="row">
                        <div class="col-lg-12">
                          <div>
                            <form
                              id="creditCardForm"
                              key={2}
                              novalidate="novalidate"
                              enctype="multipart/form-data"
                              onSubmit={handleSubmit(formSubmit)}
                            >
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="mb-3">
                                    <label
                                      class="form-label"
                                      for="formrow-name-input"
                                    >
                                      Subject{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      name="subject"
                                      id="formrow-name-input"
                                      class="form-control"
                                      placeholder="Enter your Subject"
                                      {...register("subject", {
                                        required: "Subject is required",
                                        pattern: {
                                          value: /^[A-Za-z\s]+$/,
                                          message:
                                            "Subject should have only alphabets",
                                        },
                                      })}
                                    />
                                    {errors.subject && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.subject.message}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="mb-3">
                                    <label
                                      class="form-label"
                                      for="formrow-lname-input"
                                    >
                                      Type{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <select
                                      class="form-select"
                                      {...register("type", {
                                        required: "Type is required",
                                      })}
                                    >
                                      <option value="">Please Select</option>
                                      <option value="A">Visible To All</option>
                                      <option value="P">Personal</option>
                                    </select>
                                    {errors.type && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.type.message}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <label
                                    class="form-label"
                                    for="formrow-lname-input"
                                  >
                                    Description{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Controller
                                    name="editorContent"
                                    control={control}
                                    defaultValue="Default content" // Provide a default value
                                    rules={{ required: "Content is required" }} // Keep the required rule
                                    render={({ field, fieldState }) => (
                                      <>
                                        <Editor

                                          onChange={field.onChange}
                                          value={field.value}
                                        />                                        <div style={{ color: "red" }}>
                                          {fieldState?.error?.message}
                                        </div>
                                      </>
                                    )}
                                  />

                                </div>
                              </div>
                              <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
                                <button
                                  class="btn btn-warning w-md"
                                  type="button"
                                  onClick={() => {reset();navigate('/support/predefined_replies')}}
                                >
                                  Cancel
                                </button>
                                <button
                                  class="btn btn-primary w-md me-md-2"
                                  type="submit"
                                >
                                  Create
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- container-fluid --> */}
          </div>
          {/* <!-- End Page-content --> */}
          <Footer />
        </div>
        {/* end main content*/}
      </div>
      {/* END layout-wrapper */}

      {/* Right bar overlay*/}
      <div className="rightbar-overlay"></div>
    </>
  );
};

export default Add;
