import Header from "../../../common/Header";
import Sidebar from "../../../common/Sidebar";
import Footer from "../../../common/Footer";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useLocation } from "react-router-dom";
import { triggerAlert, getToken, getBase64, capitalizeFirst, simpleAlert, downloadFile } from '../../../utils';
import Loader from '../../../common/Loader';
import PageHeader from "../../PageHeader";
import AutoSearchSelect from '../../CommonSelectDynamic'
import AutoUserSearch from "../../AutoUserSearch";


function ManageList() {
    const api_url = process.env.REACT_APP_API_BASE_URL;
    const api_url_admin = process.env.REACT_APP_API_BASE_URL;
    const token = getToken();
    const navigate = useNavigate();
    const { search, state } = useLocation();
    const params = new URLSearchParams(search);
    const mode = params.get('mode');
    const [isLoading, setIsLoading] = useState(false);
    const [imageData, setImageData] = useState();
    const [userData, setUserData] = useState();
    const [documentData, setDocumentData] = useState();
    const [userDocData, setUserDocData] = useState();
    const mainHeading = `${capitalizeFirst(mode)} User Documents`;
    const heading = 'Supports / User Documents';
    const { control, register, handleSubmit, formState: { errors }, setValue, reset, clearErrors } = useForm();

    const formSubmit = async (data) => {
        if (data.user) {
            try {
                setIsLoading(true);
                const params = {
                    id: data.id,
                    uid: data.user,
                    document_id: data.document,
                    user_document_file: imageData
                };
                var response;
                if (mode === "update") {
                    response = await axios.put(api_url + `update_user_document/` + data.id + `/update`, params, token);
                } else {
                    response = await axios.post(api_url + `add_user_document`, params, token);
                }
                if (response.status === 200) {
                    triggerAlert('success', 'Success', response.message);
                    navigate('/others/user_documents', { state: { uid: userDocData?.uid ? userDocData.uid : data.user } });
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    throw new Error(response.message);
                }
            } catch (err) {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', err.message);
            }
        }
        else {
            simpleAlert('Search and Select the User!!');
        }

    }

    // const handleFileChange = async (event) => {
    //     console.log("handleFileChangeevent", event)
    //     setValue('user_document', event.target.files[0]);
    //     const file = event.target.files[0];
    //     console.log("handleFileChange", file)
    //     const base64Data = await getBase64(file);
    //     // console.log(base64Data);
    //     setImageData(base64Data);
    // }
    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        // Check if a file is selected
        if (file) {
            // setValue('user_document', file);
            const base64Data = await getBase64(file);
            setImageData(base64Data);
        }
    };


    const setEditValues = () => {
        // console.log(state);
        if (state != null) {
            setUserDocData(state);
            setValue('user', state.uid);
            setValue('document', state.document_id);
            setValue('id', state.id);
            setValue('user_document', state.user_document_file);
            setImageData(state.user_document_file)
            // imageUrl = state.user_document_file;
        } else {
            navigate('/others/user_documents');
        }
    }

    const getCustomersList = async () => {
        try {
            const response = await axios.get(api_url + `dashboard/get_all_customers`, token);
            const response_data = response.data;
            if (response_data.error_code === 200) {
                const itemsArray = response_data.results;
                // console.log(itemsArray);
                setUserData(itemsArray);
            } else if (response.status === 204) {
                setUserData([]);
            } else {
                triggerAlert('error', 'Oops...', "Couldn't get the user data");
            }
        } catch (error) {
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    };


    const handleUser = (selected) => {
        setValue('user', selected ? selected.value : null)
    }


    const getDocumentList = async () => {
        try {
            const response = await axios.get(api_url + `get_all_doc_names?type=no_pagination`, token);
            const response_data = response.data;
            if (response_data.error_code === 200) {
                const itemsArray = response_data.results;
                setDocumentData(itemsArray);
            } else if (response.status === 204) {
                setDocumentData([]);
            } else {
                triggerAlert('error', 'Oops...', "Couldn't get the user data");
            }
        } catch (error) {
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    };


    useEffect(() => {
        if (mode === "update") {
            setEditValues();
        }
        getCustomersList();
        getDocumentList();
    }, [mode]);

    const handleDownload = (url) => {
        const filename = url?.split("/")?.pop();
        downloadFile(url, filename);
    };

    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    {isLoading ? <div className='loader-overlay text-white'>
                        <Loader />
                    </div> : ""}
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* <!-- start page title --> */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* <!-- end page title --> */}
                            <div className="row mt-4">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body p-4">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div>
                                                        <form id="creditCardForm" key={2} novalidate="novalidate" enctype="multipart/form-data" onSubmit={handleSubmit(formSubmit)}>
                                                            <div className="row">
                                                                <div class="col-md-4">
                                                                    <div class="mb-3">
                                                                        <label class="form-label" for="formrow-PhoneNumber-input">User <span style={{ color: 'red' }}>*</span></label>
                                                                        <Controller
                                                                            name="user"
                                                                            {...register('user'
                                                                                , { required: 'User is required' }
                                                                            )}
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <AutoSearchSelect
                                                                                    // {...field}
                                                                                    value={field.value}
                                                                                    apiUrl={api_url_admin + 'dashboard/get_all_customers'}
                                                                                    onSelect={handleUser}
                                                                                    placeholder="Search and select user"
                                                                                    mapOption={result => ({
                                                                                        value: result.id,
                                                                                        label: result.user_name + ', ' + result.company_name.substring(0, 15),
                                                                                        m4_account_id: result.m4_account_id,
                                                                                        mor_account_id: result.mor_account_id
                                                                                        // additionalField: result.additionalField,
                                                                                        // Add additional fields as needed
                                                                                    })}
                                                                                />
                                                                            )}
                                                                        />
                                                                        {/* <Controller control={control} name="user" id="user" rules={{ required: "User is required", }} render={({ field }) => (														

                                                                           
                                                                        <AutoUserSearch onSelect={(user) => {	
                                                                          
                                                                                setValue('user', user? user.user_id : null);
                                                                                
                                                                            
                                                                        }} />

                                                                        )}
                                                                            /> */}
                                                                        {errors.user && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.user.message}</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" for="formrow-lname-input">Document   <span style={{ color: 'red' }}>*</span></label>
                                                                        <select className="form-select" {...register('document', { required: 'Document is required' })} >
                                                                            <option value="">Please Select</option>
                                                                            {documentData && documentData.map((doc, index) => (
                                                                                <option value={doc.id} selected={userDocData?.document_id ? userDocData.document_id == doc.id : ""}>{doc.user_document_name} </option>
                                                                            ))}
                                                                        </select>
                                                                        {errors.document && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.document.message}</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="mb-3">
                                                                        {/* <label className="form-label" for="formrow-name-input">Upload<span style={{ color: 'red' }}>*</span></label> */}
                                                                        <input type="hidden" name="id" {...register('id')} />
                                                                        {mode === "update" ? (
                                                                            <>
                                                                                <label className="form-label" for="formrow-name-input">Upload</label>
                                                                                <input type="file" name="user_document" id="formrow-name-input" className="form-control" placeholder="Enter your File" {...register('user_document', {
                                                                                    // required: 'file is required',
                                                                                })} onChange={(e) => handleFileChange(e)} />
                                                                                <div className="mt-2">
                                                                                    {userDocData?.user_document_path && (
                                                                                        <>
                                                                                            {userDocData.user_document_path.endsWith('.png') ||
                                                                                                userDocData.user_document_path.endsWith('.PNG') ||
                                                                                                userDocData.user_document_path.endsWith('.jpg') ||
                                                                                                userDocData.user_document_path.endsWith('.JPG') ||
                                                                                                userDocData.user_document_path.endsWith('.jpeg') ||
                                                                                                userDocData.user_document_path.endsWith('.JPEG')
                                                                                                ? (
                                                                                                    <div>
                                                                                                        <img src={userDocData.user_document_path} alt="File Preview" style={{ width: '100px' }} />
                                                                                                        <button className="btn" onClick={() => handleDownload(userDocData.user_document_path)} >
                                                                                                            <span className="text-primary">{userDocData.user_document_path.split('/').pop()}</span>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <button className="btn" onClick={() => handleDownload(userDocData.user_document_path)} >
                                                                                                        <span className="text-primary">Download {userDocData.user_document_path.split('/').pop()}</span>
                                                                                                    </button>
                                                                                                )}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                                {errors.user_document && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.user_document.message}</div>}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <label className="form-label" for="formrow-name-input">Upload<span style={{ color: 'red' }}>*</span></label>
                                                                                <input type="file" name="user_document" id="formrow-name-input" className="form-control" placeholder="Enter your File" {...register('user_document', {
                                                                                    required: 'file is required',
                                                                                })} onChange={(e) => handleFileChange(e)} />
                                                                                {errors.user_document && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.user_document.message}</div>}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-grid gap-2 d-md-flex justify-start-md-end mt-4 mb-3">
                                                                <button className="btn btn-primary w-md me-md-2" type="submit"> {mode === "update" ? "Update" : "Create"}</button>
                                                                <button className="btn btn-warning w-md" type="button" onClick={() => navigate('/others/user_documents', { state: { uid: userDocData?.uid } })}>Cancel</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                    <Footer />
                </div>
                {/* end main content*/}
            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </>
    );

}

export default ManageList;