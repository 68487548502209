import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import Sidebar from "../../common/Sidebar";
import Footer from "../../common/Footer";
import PageHeader from "../PageHeader";
import Pagination from "../PaginationComponent";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  triggerAlert,
  formatDate,
  pageReload,
  ConfirmationAlert,
  getToken,
  simpleAlert,
  capitalizeFirst,
  triggerAlertHtml,
} from "../../utils";
import Loader from "../../common/Loader";
import AutoUserSearch from "../AutoUserSearch";
import { useLocation, useNavigate } from "react-router-dom";
function AssignedDids() {
  const api_url = process.env.REACT_APP_API_BASE_URL;
  const customer_api_url = process.env.REACT_APP_API_CUSTOMER_URL;
  const show50Entries = process.env.REACT_APP_API_SHOW_ENTRIES;
  const token = getToken();
  const state = useLocation();
  const navigate = useNavigate();
  const [count, setCount] = useState(() => {
    const storedCount = localStorage.getItem("did_count");
    return storedCount ? parseInt(storedCount, 10) : 0;
  });
  const [selectedTabName, setSelectedTabName] = useState("Assigned DIDs");
  const [showAddDids, setShowAddDids] = useState(false);
  const [showDeleteDids, setShowDeleteDids] = useState(false);
  const [showEditDids, setShowEditDids] = useState(false);
  const [selectedDidType, setSelectedDidType] = useState("");
  const [countryDataLoaded, setCountryDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [assignedDids, setAssignedDids] = useState([]);
  const [userEditDetails, setUserEditDetails] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [customerId, setcustomerId] = useState(null);
  const [lineId, setLineId] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchBtnDisabled, setIsSearchBtnDisabled] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [perPageLimit, setPerPageLimit] = useState(show50Entries);
  const [pageLimitSelected, setPageLimitSelected] = useState(show50Entries);
  const [showBulkUploadDids, setBulkUploadDids] = useState(false);
  const [showRoutingDids, setShowRoutingDids] = useState(false);
  const [pageSlNo, setPageSlNo] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
    reset,
  } = useForm();
  const {
    register: registerAdd,
    handleSubmit: handleSubmitAdd,
    formState: { errors: errorsAdd },
    setValue: setValueAdd,
    control: controlAdd,
    watch: watchAdd,
    reset: resetAdd,
  } = useForm();
  const {
    register: registerDelete,
    handleSubmit: handleSubmitDelete,
    formState: { errors: errorsDelete },
    setValue: setValueDelete,
    reset: resetDelete,
    watch: watchDelete,
  } = useForm();
  const {
    register: registerEdit,
    handleSubmit: handleSubmitEdit,
    formState: { errors: errorsEdit },
    setValue: setValueEdit,
    reset: resetEdit,
    watch: watchEdit,
  } = useForm();

  const {
    register: registerBulkUpload,
    handleSubmit: handleSubmitBulkUpload,
    formState: { errors: errorsBulkUpload },
    setValue: setValueBulkUpload,
    control: controlBulkUpload,
    reset: resetBulkUpload,
    watch: watchBulkUpload,
    setError: setErrorBulkUpload,
  } = useForm();
  const {
    register: registerRouteDids,
    handleSubmit: handleSubmitRouteDids,
    formState: { errors: errorsRouteDids },
    setValue: setValueRouteDids,
    reset: resetRouteDids,
    watch: watchRouteDids,
    setError: setErrorRouteDids,
  } = useForm();
  const {
    register: removeRoutings,
    handleSubmit: handleSubmitRemoveRouting,
    formState: { errors: errorsRemoveRouting },
    setValue: setValueRemoveRouting,
    reset: resetRemoveRouting,
  } = useForm();

  const mainHeading = selectedTabName;
  const heading = `DIDs / ${selectedTabName}`;

  const fetchAssignedDids = async (page_number, searchkey, custId = "") => {
    setIsLoading(true);
    const page_size = perPageLimit;
    const line_type = watch("did_type");

    try {
      let apiUrl = `${api_url}dids/get_did_list_data`;
      // Set default values for page_size and page_number
      let params = `?page_size=${page_size || 10}&page_number=${page_number || 1
        }`;
      // Add line_type, customer_id, and searchkey if they are present
      if (custId) params += `&customer_id=${custId}`;
      if (customerId) params += `&customer_id=${customerId}`;
      if (searchkey) params += `&keyword=${searchkey}`;
      if (line_type) params += `&line_type=${line_type}`;

      setPageSlNo((page_number - 1) * page_size);

      const response = await axios.get(apiUrl + params, token);

      const response_data = response.data;

      if (response_data.error_code === 200) {
        const itemsArray = response_data.results.data;

        const total_pages = response_data.results.pagination.total_pages;
        setPageCount(total_pages);
        setAssignedDids(itemsArray);
        setIsLoading(false);
      } else if (response.status === 204) {
        setAssignedDids([]);
        setPageCount(0);
        setIsLoading(false);
      } else {
        triggerAlert("error", "Oops...", "Couldn't get the card details");
      }
    } catch (error) {
      setIsLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const onSubmit = () => {
    fetchAssignedDids(currentPage + 1);
  };

  const addDids = async (formData) => {
    formData.line = formData.line?.replace(/,+$/, "");
    // console.log('adddids', formData);
    if (customerId) {
      formData.customer = customerId;
      try {
        const response = await axios.post(
          api_url + `dids/add_did_data`,
          formData,
          token
        );
        const response_data = response.data;
        if (response_data.error_code === 201) {
          const results = response_data.results;
          if (results === "") {
            triggerAlert("success", "Success", "DIDs Added Successfully");
          } else {
            triggerAlertHtml(
              "success",
              "Success",
              "DIDs Added Successfully",
              `<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>Some of the numbers you gave have duplicates numbers: ${results}   </div>`
            );
          }
          setSelectedTabName("Assigned DIDs");
          setShowAddDids(false);
          resetAdd();
          setPageCount(0);
          setCurrentPage(0);
          setPerPageLimit(show50Entries);
          setPageLimitSelected(10);
          // pageReload();
          // fetchAssignedDids(currentPage + 1);
        } else if (response_data.error_code === 200) {
          const results = response_data.results;

          triggerAlertHtml(
            "error",
            "Error",
            "Unable to Add DIDs",
            `<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}> All the numbers you gave was duplicate: ${results}   </div>`
          );

          setSelectedTabName("Assigned DIDs");
          setShowAddDids(false);
          resetAdd();
          setPageCount(0);
          setCurrentPage(0);
          setPerPageLimit(show50Entries);
          setPageLimitSelected(10);
          // pageReload();
        } else {
          triggerAlert("error", "Oops...", "Unable To Add DIDs");
        }
      } catch (error) {
        console.log(error);
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
    } else {
      simpleAlert("Search and Select the User!!");
    }
  };

  const handleCancelbtn = () => {
    setShowAddDids(false);
    resetAdd();
    resetEdit();
    resetDelete();
    resetBulkUpload();
    resetRouteDids();
    resetRemoveRouting();
    pageReload();
    setSelectedTabName("Assigned DIDs");
    // setPageCount(0);
    // setCurrentPage(0);
    // setPerPageLimit(10);
    // setPageLimitSelected(10);

    // fetchAssignedDids(currentPage + 1);
  };

  const handleAddDids = () => {
    setSelectedTabName("Add DIDs");
    setShowAddDids(true);
    setcustomerId(null);
    reset();
  };

  const handleBulkUploadDids = () => {
    setSelectedTabName("Bulk Upload DIDs");
    setBulkUploadDids(true);
    setcustomerId(null);
    resetBulkUpload();
  };

  const fetchInternationalList = async () => {
    try {
      const response = await axios.get(
        customer_api_url + `services/get_international_country_list`,
        token
      );
      const response_data = response.data;
      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        setCountryData(itemsArray);
      } else if (response.status === 204) {
        setCountryData([]);
        // setIsLoading(false);
      } else {
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
    } catch (error) {
      // setIsLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const handleAddDidChange = (e) => {
    setSelectedDidType(e.target.value);
    const selectedDidType = e.target.value;

    if (selectedDidType === "international" && !countryDataLoaded) {
      // International Country list
      fetchInternationalList();
      setCountryDataLoaded(true);
    }
  };

  const handleDidChange = () => {
    setPageCount(0);
    setCurrentPage(0);
    setPerPageLimit(show50Entries);
    setPageLimitSelected(10);
    setIsSearchBtnDisabled(true);
    setSelectedCheckboxes({});
  };

  const handleDeleteDid = (line_id) => {
    setShowDeleteDids(true);
    setLineId(line_id);
    setSelectedTabName("DID - Port out or Deactivate");
    setcustomerId(null);
    reset();
  };

  // Delete func
  const deleteAssignedDid = (data) => {
    if (lineId) {
      ConfirmationAlert("You want to continue?", "Continue", async () => {
        try {
          data.line_id = lineId;
          const response = await axios.post(
            api_url + `dids/delete_did_data`,
            data,
            token
          );
          const response_data = response.data;

          if (response_data.error_code === 200) {
            triggerAlert("success", "Success", `DID deleted successfully!!!`);
            pageReload();
          } else {
            triggerAlert("error", "Oops...", "Unable to delete DID!!!");
            setShowDeleteDids(false);
          }
        } catch (error) {
          triggerAlert("error", "Oops...", "Something went wrong..");
          setShowDeleteDids(false);
        }
      });
    } else {
      triggerAlert("error", "Oops...", "Something went wrong..");
      setShowDeleteDids(false);
    }
  };
  //fetch single data for edit
  const handleEditDid = async (line_id, line_type) => {
    setLineId(line_id);

    // function that returns a promise to fetch international data
    const fetchInternationalData = () => {
      return new Promise((resolve) => {
        // Simulate an asynchronous delay (you can replace this with your actual API call)
        setTimeout(async () => {
          await fetchInternationalList();
          resolve();
        }, 1000);
      });
    };

    try {
      // If line_type is 'international', fetch international data first
      if (line_type === "international") {
        await fetchInternationalData();
      }

      // API call to get did data
      const response = await axios.get(
        api_url + `dids/get_did_data?line_id=${line_id}`,
        token
      );
      const response_data = response.data;

      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        if (line_type === "international") {
          const country = response_data.results.country;
          setValueEdit("country", country);
        }
        setUserEditDetails(itemsArray);
        setShowEditDids(true);
      } else if (response.status === 204) {
        setUserEditDetails([]);
        simpleAlert("No details found");
      } else {
        triggerAlert("error", "Oops...", "Couldn't get the details");
      }
    } catch (error) {
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  //edit functionality
  const editAssignedDid = (updData) => {
    setSelectedDidType("");

    // const lineType = updData.line_type;
    updData.customer_id = Number(updData.customer_id);

    // switch (lineType) {
    // 	case 'local':
    // 		updData.dids_cost = didCost;
    // 		break;
    // 	case 'toll_free':
    // 		updData.tollfree_dids_cost = didCost;
    // 		break;
    // 	case 'vfax':
    // 		updData.vfax_dids_cost = didCost;
    // 		break;
    // 	case 'international':
    // 		updData.international_dids_cost = didCost;
    // 		break;

    // 	default:
    // 		break;
    // }
    const { cost, ...updatedDataWithoutCost } = updData;

    if (lineId) {
      ConfirmationAlert("You want to continue?", "Continue", async () => {
        try {
          // updatedDataWithoutCost.line_id = lineId;
          const response = await axios.put(
            api_url + `dids/update_did_data/${lineId}/update`,
            updatedDataWithoutCost,
            token
          );
          const response_data = response.data;

          if (response_data.error_code === 200) {
            triggerAlert("success", "Success", `Data updated successfully!!!`);
            pageReload();
            // fetchAssignedDids(currentPage + 1);
            // setShowEditDids(false);
          } else {
            triggerAlert("error", "Oops...", "Unable to update data!!!");
          }
        } catch (error) {
          console.log(error);
          triggerAlert("error", "Oops...", "Something went wrong..");
        }
      });
    } else {
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    const searchkey = e.target.value;
    fetchAssignedDids(1, searchkey); // Update search results on every change
  };

  const handlePageChange = (event) => {
    setCurrentPage(0);
    if (event.target.value) {
      setPerPageLimit(event.target.value);
      setPageLimitSelected(event.target.value);
    } else {
      setPerPageLimit(10);
      setPageLimitSelected(10);
    }
  };

  const handlePageClick = (selected) => {
    const selectedPage = selected.selected;
    setCurrentPage(selectedPage);
    fetchAssignedDids(selectedPage + 1, "", state?.state?.user_id); // Increment the page number by 1 for server-side pagination
  };

  let pgntn_props = {
    pageCount: pageCount,
    handlePageClick: handlePageClick,
    selectedPage: currentPage,
  };

  const getCostDefaultValue = (userEditDetails) => {
    const {
      line_type,
      dids_cost,
      tollfree_dids_cost,
      vfax_dids_cost,
      international_dids_cost,
    } = userEditDetails;

    switch (line_type) {
      case "local":
        return dids_cost ? dids_cost : 0;
      case "toll_free":
        return tollfree_dids_cost ? tollfree_dids_cost : 0;
      case "vfax":
        return vfax_dids_cost ? vfax_dids_cost : 0;
      case "international":
        return international_dids_cost ? international_dids_cost : 0;
      default:
        return 0;
    }
  };

  //get customerid from user select
  const handleUserSelect = (userData) => {
    // You can use the userData in your logic
    setValue('user_id', userData ? userData.user_id : null);
    setcustomerId(userData.user_id);
    setPageCount(0);
    setAssignedDids([]);
    setCurrentPage(0);
    setPerPageLimit(show50Entries);
    setPageLimitSelected(10);
    setIsSearchBtnDisabled(false);
    setSelectedCheckboxes({});
  };

  // Bulk upload

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const file_data = file.name?.split(".");
    var file_type = "";
    if (file_data) {
      file_type = file_data[1];
    }

    if (file_type != "csv") {
      setValueBulkUpload("base64_files", "");
      setErrorBulkUpload("base64_files", {
        type: "manual",
        message: "Invalid file type",
      });
    } else {
      setErrorBulkUpload("base64_files", "");
      if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64String = reader.result.split(",")[1];
          setValueBulkUpload("base64_files", base64String);
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const bulkUploadDids = async (formData) => {
    if (customerId) {
      formData.customer = customerId;
      try {
        const response = await axios.post(
          api_url + `dids/bulk_upload_did_data`,
          formData,
          token
        );
        const response_data = response.data;
        if (response_data.error_code === 201) {
          const results = response_data.results;
          if (results === "") {
            triggerAlert("success", "Success", "DIDs Added Successfully");
          } else {
            triggerAlertHtml(
              "success",
              "Success",
              "DIDs Added Successfully",
              `<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>Some of the numbers you gave have duplicates numbers: ${results}   </div>`
            );
          }
          setSelectedTabName("Assigned DIDs");
          setBulkUploadDids(false);
          resetBulkUpload();
          setPageCount(0);
          setCurrentPage(0);
          setPerPageLimit(show50Entries);
          setPageLimitSelected(10);
          // pageReload();
          // fetchAssignedDids(currentPage + 1);
        } else if (response_data.error_code === 200) {
          const results = response_data.results;

          triggerAlertHtml(
            "error",
            "Error",
            "Unable to Add DIDs",
            `<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}> All the numbers you gave was duplicate: ${results}   </div>`
          );

          setSelectedTabName("Assigned DIDs");
          setBulkUploadDids(false);
          resetBulkUpload();
          setPageCount(0);
          setCurrentPage(0);
          setPerPageLimit(show50Entries);
          setPageLimitSelected(10);
          // pageReload();
        } else {
          triggerAlert("error", "Oops...", "Unable To Add DIDs");
        }
      } catch (error) {
        console.log(error);
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
    } else {
      simpleAlert("Search and Select the User!!");
    }
  };
  ////////////////////////////////////////////////////
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [endpoints, setEndpoints] = useState([]);

  ////check all functionality ///
  const handleCheckAllChange = (event) => {
    const isChecked = event.target.checked;
    const newSelectedCheckboxes = { ...selectedCheckboxes };

    assignedDids.forEach((row) => {
      if (isChecked && row.delete_type !== "delete") {
        //not storing the deleted numbers for routing
        newSelectedCheckboxes[row.line] = {
          number: row.line,
          provider: row.provider,
          item: row.item,
          isChecked: true,
        };
      } else {
        delete newSelectedCheckboxes[row.line];
      }
    });

    setSelectedCheckboxes(newSelectedCheckboxes);
  };

  ///single checkbox function///
  const handleCheckboxChange = (event, number, provider, deleted, item) => {
    const isChecked = event.target.checked;

    setSelectedCheckboxes((prevState) => {
      // Create a copy of the previous state
      const updatedState = { ...prevState };

      // If checkbox is checked, add it to the state
      if (isChecked && deleted !== "delete") {
        //not storing the deleted numbers for routing
        updatedState[number] = {
          number: number,
          provider: provider,
          // line_id: id,
          item: item, // Include the item
          isChecked: true,
        };
      } else {
        // If checkbox is unchecked, remove it from the state
        delete updatedState[number];
      }

      // Return the updated state
      return updatedState;
    });
  };

  //fetch ip address for routing////
  const fetchIpAddress = async () => {
    // console.log("customerId", customerId);
    try {
      const response = await axios.get(
        api_url + `routing/get_end_point_data?customer_id=${customerId}`,
        token
      );
      const response_data = response.data;
      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        setEndpoints(itemsArray);
      } else if (response.status === 204) {
        setEndpoints([]);
        // setIsLoading(false);
      } else {
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
    } catch (error) {
      // setIsLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };
  ////Route Dids/////
  const handleRouteDids = () => {
    if (Object.keys(selectedCheckboxes).length > 0) {
      // console.log('RouteDids', selectedCheckboxes)
      setSelectedTabName("Route DIDs");
      setShowRoutingDids(true);
      // setcustomerId(null)
      resetRouteDids();
      fetchIpAddress();
      const nullIpArray = [];
      const notNullIpArray = [];

      for (const key in selectedCheckboxes) {
        // console.log("key", key);
        if (selectedCheckboxes.hasOwnProperty(key)) {
          const entry = selectedCheckboxes[key];
          console.group("entry", entry, entry?.item?.ip_address);
          if (entry?.item?.ip_address === null) {
            nullIpArray.push(key);
          } else {
            notNullIpArray.push(key);
          }
        }
      }
      setSelectedRoutingNoWithIp(notNullIpArray);
      setSelectedRoutingNoWithoutIp(nullIpArray);
      const did_numbers = Object.values(selectedCheckboxes)
        .filter((item) => item.item && item.item.ipaddress === null) // Filter items where ipaddress is null
        .map((item) => item.number) // Extract numbers from filtered items
        .join(", ");

      // console.log("did_numbers", did_numbers)
      setValueRouteDids("dids", nullIpArray);
      setValueRouteDids("did_data", nullIpArray);
    } else {
      simpleAlert("Please select a checkbox or checkboxes");
    }
  };

  ///Route Dids submit func ////
  const RouteDidSubmit = async (routeData) => {
    try {
      setIsLoading(true);
      let selected_endpoint = JSON.parse(routeData.ip_address);
      let didsArray = Array.isArray(routeData.dids)
        ? routeData.dids
        : [routeData.dids];
      // console.log('didsArray', didsArray)
      let post_data = {
        customer_id: customerId,
        dids: didsArray.map(Number), // No need for routeData.dids.split(',').map(Number)
        device_id: selected_endpoint.mor_device_id,
        ip_address: selected_endpoint.ipaddr,
        cp_id: selected_endpoint.endpoint_id,
      };

      const response = await axios.post(
        customer_api_url + "services/routing/did_device_assign",
        post_data,
        token
      );
      const response_data = response.data;
      if (response_data.error_code === 200) {
        triggerAlert("success", "Success", "Routing updated");
        setIsLoading(false);
        pageReload();
      } else {
        triggerAlert("error", "Oops...", "Unable to update Routing");
        setIsLoading(false);
        // pageReload();
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong.");
    }
  };

  // /////////////////////////Remove Routing /////////////////////////////////////
  const [routingRemoveShow, setRoutingRemoveShow] = useState(false);
  const [selectedRoutingNoWithIp, setSelectedRoutingNoWithIp] = useState([]);
  const [selectedRoutingNoWithoutIp, setSelectedRoutingNoWithoutIp] = useState(
    []
  );

  const removeRouting = () => {
    // console.log("Selected checkboxes", selectedCheckboxes);

    if (Object.keys(selectedCheckboxes)?.length > 0) {
      setSelectedTabName("Remove Routing");
      const nullIpArray = [];
      const notNullIpArray = [];

      for (const key in selectedCheckboxes) {
        // console.log("key", key);
        if (selectedCheckboxes.hasOwnProperty(key)) {
          const entry = selectedCheckboxes[key];
          console.group("entry", entry, entry?.item?.ip_address);
          if (entry?.item?.ip_address === null) {
            nullIpArray.push(key);
          } else {
            notNullIpArray.push(key);
          }
        }
      }

      handleRoutingRemoveShow();

      // console.log("notNullIpArray", notNullIpArray)
      // console.log("nullIpArray", nullIpArray)

      setSelectedRoutingNoWithIp(notNullIpArray);
      setSelectedRoutingNoWithoutIp(nullIpArray);
    } else {
      simpleAlert("Please select a checkbox or checkboxes.");
    }
  };

  const handleRoutingRemoveShow = () => setRoutingRemoveShow(true);

  const handleRoutingRemovClose = () => {
    setRoutingRemoveShow(false);
    resetRemoveRouting();
  };

  const handleRoutingRemoveSubmit = async (data) => {
    if (selectedRoutingNoWithIp.length > 0) {
      const buttonText = "Remove Routing";
      const confirmationTitle = "Are you sure you want to remove routing?";

      ConfirmationAlert(confirmationTitle, buttonText, async () => {
        setIsLoading(true);
        try {
          const api_input = {
            dids: selectedRoutingNoWithIp,
            customer_id: customerId,
          };
          // console.log("api_input", api_input);

          const response = await axios.post(
            customer_api_url + "services/routing/did_device_unassign",
            api_input,
            token
          );
          const response_data = response.data;
          setIsLoading(false);
          if (response_data.error_code === 200) {
            setIsLoading(false);
            triggerAlert(
              "success",
              "Success",
              `Routing removed Successfully!!`
            );
            pageReload();
          } else {
            setIsLoading(false);
            triggerAlert("error", "Oops...", "Something went wrong..");
          }
        } catch (error) {
          setIsLoading(false);
          triggerAlert("error", "Oops...", "Something went wrong..");
        }
      });
      handleRoutingRemovClose();
    } else {
      simpleAlert("Please select a row or checkboxes.");
    }
  };
  // console.log('selectedRoutingNoWithoutIp,selectedRoutingNoWithIp', selectedRoutingNoWithoutIp, selectedRoutingNoWithIp)
  // console.log('selectedCheckboxes', selectedCheckboxes)

  useEffect(() => {
    setCurrentPage(0);
    if (state?.state?.user_id && count == 0) {
      fetchAssignedDids(1, "", state.state.user_id);
      setcustomerId(state.state.user_id);
      localStorage.setItem("did_count", "1");
      setValue('user_id', state.state.user_id);
    } else if (state?.state?.user_id && count > 0) {
      const newLocation = { ...state, state: null };
      navigate(newLocation.pathname, { state: null });
      localStorage.setItem("did_count", "0");
    }
  }, [perPageLimit]);

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />

        <div className="main-content" style={{ minHeight: "100vh" }}>
          <div className="page-content">
            <div className="container-fluid">
              {/* start page title */}
              <PageHeader mainHeading={mainHeading} heading={heading} />
              {/* end page title */}
              {!showAddDids &&
                !showDeleteDids &&
                !showEditDids &&
                !showBulkUploadDids &&
                !showRoutingDids &&
                !routingRemoveShow && (
                  <>
                    <div className="row  mt-4">
                      <div className="col-xl-12">
                        <div className="">
                          <div className="card-body">
                            <form
                              data-select2-id="21"
                              noValidate="novalidate"
                              onSubmit={handleSubmit(onSubmit)}
                            >
                              <div className="row g-3" data-select2-id="20">
                                <div className="col-md-3">
                                  <label
                                    className="form-label"
                                    htmlFor="formtabs-first-name"
                                  >
                                    DID Type
                                  </label>
                                  <select
                                    className="form-select"
                                    name="did_type"
                                    {...register(
                                      "did_type"
                                      //  { required: 'DID type is required' }
                                    )}
                                    aria-label="Default select example"
                                    onChange={handleDidChange}
                                  >
                                    <option value="" hidden>
                                      Select DID Type
                                    </option>
                                    <option value="local">Local DIDs</option>
                                    <option value="toll_free">
                                      Toll free DIDs
                                    </option>
                                    <option value="vfax">Vfax DIDs</option>
                                    <option value="international">
                                      International DIDs
                                    </option>
                                  </select>
                                  {errors.did_type && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors.did_type.message}
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-3">
                                  <label
                                    className="form-label"
                                    htmlFor="formtabs-first-name"
                                  >
                                    User <span className="text-danger">*</span>
                                  </label>
                                  {/* <AutoUserSearch
                                    onSelect={handleUserSelect}
                                    userId={state?.state?.user_id}
                                  /> */}
                                  <Controller
                                    control={control}
                                    name="user_id"
                                    id="user_id"
                                    rules={{ required: "User is required" }}
                                    render={({ field }) => (
                                      <AutoUserSearch
                                        onSelect={(user) => {
                                          setcustomerId(user ? user.user_id : null);
                                          setIsSearchBtnDisabled(false);
                                          setValue(
                                            "user_id",
                                            user ? user.user_id : null
                                          );
                                        }}
                                        value={field.value}
                                      />
                                    )}
                                  />
                                  {errors.user_id && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors.user_id.message}
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-6">
                                  <div className="float-start button-items d-flex flex-column">
                                    <label
                                      className="form-label"
                                      htmlFor="formtabs-last-name"
                                    >
                                      &nbsp;
                                    </label>
                                    <button
                                      type="submit"
                                      className="btn btn-primary w-md"
                                    // disabled={isSearchBtnDisabled}
                                    >
                                      Search
                                    </button>
                                  </div>
                                  {customerId && (
                                    <div className="float-end d-flex flex-column ms-2">
                                      <label
                                        className="form-label"
                                        htmlFor="formtabs-last-name"
                                      >
                                        &nbsp;
                                      </label>
                                      <button
                                        type="button"
                                        className="btn btn-primary w-md btn-md"
                                        onClick={removeRouting}
                                      >
                                        Remove Routing
                                      </button>
                                    </div>
                                  )}
                                  {customerId && (
                                    <div className="float-end d-flex flex-column ms-2">
                                      <label
                                        className="form-label"
                                        htmlFor="formtabs-last-name"
                                      >
                                        &nbsp;
                                      </label>
                                      <button
                                        type="button"
                                        className="btn btn-primary w-md btn-md"
                                        onClick={handleRouteDids}
                                      >
                                        Route DID's
                                      </button>
                                    </div>
                                  )}

                                  <div className="float-end d-flex flex-column ms-2">
                                    <label
                                      className="form-label"
                                      htmlFor="formtabs-last-name"
                                    >
                                      &nbsp;
                                    </label>
                                    <button
                                      type="button"
                                      className="btn btn-primary w-md btn-md"
                                      onClick={handleAddDids}
                                    >
                                      Add DID
                                    </button>
                                  </div>

                                  <div className="float-end d-flex flex-column">
                                    <label
                                      className="form-label"
                                      htmlFor="formtabs-last-name"
                                    >
                                      &nbsp;
                                    </label>
                                    <button
                                      type="button"
                                      className="btn btn-primary w-md btn-md"
                                      onClick={handleBulkUploadDids}
                                    >
                                      Bulk Upload DID
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* <!-- end col --> */}
                    </div>
                    <div className="row mt-5">
                      <div className="col-12">
                        <div className="card">
                          {/* <div className="card-header"> */}

                          {/* </div> */}
                          <div className="card-body">
                            <div
                              id="datatable_wrapper"
                              className="dataTables_wrapper dt-bootstrap4 no-footer"
                            >
                              <div className="row">
                                {/* <div className="col-sm-12 col-md-6">
																<div className="dataTables_length" id="datatable_length">
																	<label>Show&nbsp;
																		<select name="example_length" aria-controls="example" className="table_length" onChange={handlePageChange} value={pageLimitSelected}>
																			<option value={10}>10</option>
																			<option value={20}>20</option>
																			<option value={50}>50</option>
																			<option value={100}>100</option>
																		</select> entries
																	</label>
																</div>
															</div>  sahiti commented*/}
                                <div className="col-sm-12 col-md-12 mb-2">
                                  <div className="btn-group float-end ">
                                    <input
                                      type="search"
                                      placeholder="Search..."
                                      value={searchQuery}
                                      className="form-control form-control-sm"
                                      aria-controls="example"
                                      onChange={handleChange}
                                    />
                                    &nbsp;&nbsp;
                                  </div>
                                </div>
                              </div>
                              <Table
                                style={{ width: "100%" }}
                                className="table-responsive table-bordered"
                              >
                                <thead style={{ backgroundColor: "#ededed" }}>
                                  <tr>
                                    {/* <th scope="col" width="">#</th> */}
                                    <th>
                                      <div className="form-check ">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="flexCheckDefault"
                                          checked={
                                            Object.keys(selectedCheckboxes)
                                              ?.length > 0
                                              ? Object.keys(selectedCheckboxes)
                                                ?.length === assignedDids.length
                                              : false
                                          }
                                          onChange={handleCheckAllChange}
                                        />
                                      </div>
                                    </th>
                                    <th scope="col">Username</th>
                                    <th scope="col">DID Number</th>
                                    <th scope="col">DID Type</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Service</th>
                                    <th scope="col">Rate</th>
                                    <th scope="col">Provider</th>
                                    <th scope="col">Routing IP</th>
                                    <th scope="col">Added On</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                {isLoading ? (
                                  <div className="loader-overlay text-white">
                                    <Loader />
                                  </div>
                                ) : (
                                  <tbody>
                                    {assignedDids.length > 0 ? (
                                      assignedDids.map((did, index) => (
                                        <tr
                                          key={index}
                                          className={
                                            index % 2 === 0 ? "even" : "odd"
                                          }
                                        >
                                          {/* <th scope="row">{pageSlNo + index + 1}</th> */}
                                          <th scope="row">
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id={`flexCheckDefault${did.line}`}
                                                checked={
                                                  selectedCheckboxes[did.line]
                                                    ?.isChecked || false
                                                }
                                                onChange={(e) => {
                                                  // setSelectedRow(item)
                                                  handleCheckboxChange(
                                                    e,
                                                    did.line,
                                                    did.provider,
                                                    did.delete_type,
                                                    did
                                                  );
                                                }}
                                              />
                                            </div>
                                          </th>
                                          <td>{did.user_name}</td>
                                          {did.deleted_date ? (
                                            <td className="text-danger ">
                                              <OverlayTrigger
                                                placement="right"
                                                overlay={
                                                  <Tooltip
                                                    className="in "
                                                    id="tooltip-top"
                                                  >{`Will be deleted on ${formatDate(
                                                    did.deleted_date
                                                  )}`}</Tooltip>
                                                }
                                              >
                                                <span>{did.line}</span>
                                              </OverlayTrigger>
                                            </td>
                                          ) : (
                                            <td>{did.line}</td>
                                          )}

                                          <td>
                                            {did.line_type === "local"
                                              ? "Local DID"
                                              : did.line_type === "toll_free"
                                                ? "Toll free DID"
                                                : did.line_type === "vfax"
                                                  ? "Vfax DID"
                                                  : did.line_type ===
                                                    "international"
                                                    ? "International DID"
                                                    : "-"}
                                          </td>
                                          <td>{capitalizeFirst(did.status)}</td>
                                          <td>
                                            {capitalizeFirst(did.line_status)}
                                          </td>
                                          <td>
                                            $ &nbsp;
                                            {did.line_type === "local"
                                              ? did.dids_cost
                                                ? did.dids_cost
                                                : 0
                                              : did.line_type === "toll_free"
                                                ? did.tollfree_dids_cost
                                                  ? did.tollfree_dids_cost
                                                  : 0
                                                : did.line_type === "vfax"
                                                  ? did.vfax_dids_cost
                                                    ? did.vfax_dids_cost
                                                    : 0
                                                  : did.line_type ===
                                                    "international"
                                                    ? did.international_dids_cost
                                                      ? did.international_dids_cost
                                                      : 0
                                                    : "-"}
                                          </td>
                                          <td>
                                            {" "}
                                            {did.provider === "V"
                                              ? "Vitelity"
                                              : did.provider === "VI"
                                                ? "VoIP Innovations"
                                                : did.provider === "I"
                                                  ? "Inteliquent"
                                                  : did.provider === "B"
                                                    ? "Bandwidth"
                                                    : "-"}
                                          </td>
                                          <td>
                                            {did.ip_address
                                              ? did.ip_address
                                              : "-"}
                                          </td>
                                          <td>
                                            {formatDate(did.created_date)}
                                          </td>
                                          <td>
                                            <button
                                              className={`btn btn-sm btn-default p-0 ${did.deleted_date
                                                ? "disabled"
                                                : ""
                                                }`}
                                              title="Delete"
                                              onClick={() =>
                                                handleDeleteDid(did.line_id)
                                              }
                                            >
                                              <i className="fas   fas fa-trash-alt text-danger fa-eye-c"></i>
                                            </button>
                                            <button
                                              className={`btn btn-sm btn-default p-0 mx-2 ${did.deleted_date
                                                ? "disabled"
                                                : ""
                                                }`}
                                              title="Edit"
                                              onClick={() =>
                                                handleEditDid(
                                                  did.line_id,
                                                  did.line_type
                                                )
                                              }
                                            >
                                              <i className="fas  fas fa-edit fa-edit-c "></i>
                                            </button>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan="10"
                                          className="text-center"
                                        >
                                          No data available
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                )}
                              </Table>
                              <hr />
                              <Pagination {...pgntn_props} />
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                      {/* <!-- end col -->*/}
                    </div>
                  </>
                )}

              {showAddDids && (
                <div className="row  mt-4">
                  <div className="col-xl-12">
                    <div className="">
                      <div className="card-body">
                        <form
                          data-select2-id="21"
                          novalidate="novalidate"
                          onSubmit={handleSubmitAdd(addDids)}
                        >
                          <div className="row g-3" data-select2-id="20">
                            <div className="col-md-3">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                DID Type <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className="form-select"
                                name="line_type"
                                {...registerAdd("line_type", {
                                  required: "DID type is required",
                                })}
                                aria-label="Default select example"
                                onChange={handleAddDidChange}
                              >
                                <option value="" hidden>
                                  Select DID Type
                                </option>
                                <option value="local">Local DIDs</option>
                                <option value="toll_free">
                                  Toll free DIDs
                                </option>
                                <option value="vfax">Vfax DIDs</option>
                                <option value="international">
                                  International DIDs
                                </option>
                              </select>
                              {errorsAdd.line_type && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsAdd.line_type.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                User <span style={{ color: "red" }}>*</span>
                              </label>
                              <Controller
                                control={controlAdd}
                                name="user_id"
                                id="user_id"
                                {...registerAdd("user_id", {
                                  required: "User is required",
                                })}
                                render={({ field }) => (
                                  <AutoUserSearch
                                    onSelect={(user) => {
                                      setValueAdd(
                                        "user_id",
                                        user ? user.user_id : null
                                      );
                                      setcustomerId(user ? user.user_id : null);
                                    }}
                                  />
                                )}
                              />
                              {errorsAdd.user_id && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsAdd.user_id.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                DIDs <span style={{ color: "red" }}>*</span>
                              </label>
                              <Controller
                                name="line" // Replace with your desired form field name
                                control={controlAdd}
                                rules={{
                                  required: "DIDs are required",
                                  pattern: {
                                    value: /^[0-9,]+$/,
                                    message:
                                      "Only commas and numbers are allowed",
                                  },
                                  // validate: (value) => {
                                  // 	// Validate the length of each DID
                                  // 	const didsArray = value.split(',').map((did) => did.trim());

                                  // 	for (const did of didsArray) {
                                  // 		if (did.length !== 10) {
                                  // 			return 'Each DID must be 10 digits';
                                  // 		}
                                  // 	}

                                  // 	return true; // Validation passed
                                  // },
                                }}
                                render={({ field }) => (
                                  <>
                                    <textarea
                                      {...field}
                                      id="basicpill-address-input"
                                      className="form-control"
                                      rows="1"
                                      placeholder="Separate each DIDs with a comma"
                                    />
                                    {errorsAdd.line && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errorsAdd.line.message}
                                      </div>
                                    )}
                                  </>
                                )}
                              />
                            </div>
                            <div className="col-md-3">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                Line Status{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className="form-select"
                                name="line_status"
                                {...registerAdd("line_status", {
                                  required: "Line Status is required",
                                })}
                                aria-label="Default select example"
                              >
                                <option value="" hidden>
                                  Select Line Status
                                </option>
                                <option value="free">Free</option>
                                <option value="paid">Paid</option>
                              </select>
                              {errorsAdd.line_status && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsAdd.line_status.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                Calling Provider
                                {/* <span style={{ color: 'red' }}>*</span> */}
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                name="provider"
                                {...registerAdd(
                                  "provider"
                                  // { required: 'Calling Provider is required' }
                                )}
                              >
                                <option value="" hidden>
                                  Select Calling Provider
                                </option>
                                <option value="V">Vitelity</option>
                                <option value="VI">VoIP Innovation</option>
                                <option value="B">Bandwidth</option>
                                <option value="I">Intelliquent</option>
                              </select>
                              {errorsAdd.provider && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsAdd.provider.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                SMS Provider
                                {/* <span style={{ color: 'red' }}>*</span> */}
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                name="sms_provider"
                                {...registerAdd(
                                  "sms_provider"
                                  // { required: 'SMS Provider is required' }
                                )}
                              >
                                <option value="" hidden>
                                  Select SMS Provider
                                </option>
                                <option value="V">Vitelity</option>
                                <option value="VI">Voip Innovation</option>
                                <option value="B">Bandwidth</option>
                                <option value="I">Intelliquent</option>
                              </select>
                              {errorsAdd.sms_provider && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsAdd.sms_provider.message}
                                </div>
                              )}
                            </div>

                            {selectedDidType === "international" && (
                              <div className="col-md-3">
                                <label className="form-label" htmlFor="country">
                                  Select Country:{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  className="form-select"
                                  name="country"
                                  {...registerAdd("country", {
                                    required: "Country is required",
                                  })}
                                  aria-label="Default select example"
                                >
                                  <option value="" hidden>
                                    Select Country
                                  </option>
                                  {countryData.map((country) => (
                                    <option
                                      key={country.st}
                                      value={country.country}
                                    >
                                      {country.country}
                                    </option>
                                  ))}
                                </select>
                                {errorsAdd.country && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {errorsAdd.country.message}
                                  </div>
                                )}
                              </div>
                            )}

                            <div className="col-md-3">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                CNAM Inbound Status
                                {/* <span style={{ color: 'red' }}>*</span> */}
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                name="cnam_inbound"
                                {...registerAdd(
                                  "cnam_inbound"
                                  // { required: 'CNAM Inbound Status is required' }
                                )}
                              >
                                <option value="" hidden>
                                  Select Status
                                </option>
                                <option value="yes">Enable</option>
                                <option value="no">Disable</option>
                              </select>
                              {errorsAdd.cnam_inbound && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsAdd.cnam_inbound.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                CNAM Outbound
                                {/* <span style={{ color: 'red' }}>*</span> */}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...registerAdd(
                                  "cnam"
                                  // { required: 'CNAM Outbound is required' }
                                )}
                                placeholder="Enter CNAM"
                                name="cnam"
                              />
                              {errorsAdd.cnam && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsAdd.cnam.message}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12 d-flex justify-content-end ">
                            <div>
                              <label
                                className="form-label"
                                htmlFor="formtabs-last-name"
                              >
                                &nbsp;
                              </label>
                              <div className="button-items">
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                            <div className="ms-5">
                              <label
                                className="form-label"
                                htmlFor="formtabs-last-name"
                              >
                                &nbsp;
                              </label>
                              <div className="button-items">
                                <button
                                  type="button"
                                  className="btn btn-warning w-md me-md-2"
                                  onClick={handleCancelbtn}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {showDeleteDids && (
                <div className="row  mt-4">
                  <div className="col-xl-12">
                    <div className="">
                      <div className="card-body">
                        <form onSubmit={handleSubmitDelete(deleteAssignedDid)}>
                          <div className="row g-3">
                            <div className="col-md-4">
                              <label className="form-label">
                                Port out / Deactivate Did:
                              </label>
                              <div className="row mt-3 ps-3">
                                <div className="col-md-4 form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="portout"
                                    {...registerDelete("type", {
                                      required: "Please select a type",
                                    })}
                                  />
                                  <label className="form-check-label">
                                    Port out
                                  </label>
                                </div>
                                <div className="col-md-4 form-check ms-5">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="delete"
                                    {...registerDelete("type", {
                                      required: "Please select a type",
                                    })}
                                  />
                                  <label className="form-check-label">
                                    Deactivate
                                  </label>
                                </div>
                              </div>

                              {errorsDelete.type && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsDelete.type.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-3 p-0">
                              <label
                                className="form-label"
                                htmlFor="description"
                              >
                                {" "}
                                Reason: <span style={{ color: "red" }}>*</span>
                              </label>
                              <textarea
                                className="form-control col-md-3"
                                rows="1"
                                placeholder="Please provide a valid reason..."
                                {...registerDelete("description", {
                                  required: "Reason is required",
                                })}
                                name="description"
                              />
                              {errorsDelete.description && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsDelete.description.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-4 d-flex justify-content-around align-items-end">
                              <button
                                type="submit"
                                className="btn btn-danger waves-effect waves-light btn-md me-md-2 px-5"
                              >
                                Delete
                              </button>
                              <button
                                className=" btn btn-warning waves-effect waves-light btn-md me-md-2 px-5"
                                type="button"
                                onClick={handleCancelbtn}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {showEditDids && (
                <div className="row  mt-4">
                  <div className="col-xl-12">
                    <div className="">
                      <div className="card-body">
                        <form onSubmit={handleSubmitEdit(editAssignedDid)}>
                          <div className="row">
                            <div className="col-md-4  ">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                DID <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={userEditDetails.line}
                                disabled
                              />
                            </div>
                            <div className="col-md-4  ">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                Username <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={userEditDetails.user_name}
                                disabled
                              />
                            </div>
                            <div className="col-md-4">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                DID Type <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className="form-select"
                                name="line_type"
                                defaultValue={userEditDetails.line_type}
                                {...registerEdit("line_type", {
                                  required: "DID type is required",
                                })}
                                onChange={handleAddDidChange}
                              >
                                <option value="" hidden>
                                  Select DID Type
                                </option>
                                <option value="local">Local DIDs</option>
                                <option value="toll_free">
                                  Toll free DIDs
                                </option>
                                <option value="vfax">Vfax DIDs</option>
                                <option value="international">
                                  International DIDs
                                </option>
                              </select>
                              {errorsEdit.line_type && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsEdit.line_type.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-4" hidden>
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                                hidden
                              >
                                Customer ID{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="hidden"
                                className="form-control"
                                defaultValue={userEditDetails.customer} //change this write conditions based on the line_type
                                {...registerEdit("customer_id", {
                                  required: "Customer ID is required",
                                })}
                                placeholder="Enter Customer ID"
                                name="customer_id"
                              />
                              {errorsEdit.customer_id && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsEdit.customer_id.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-4">
                              <label className="form-label" htmlFor="dids_cost">
                                DID Cost <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={getCostDefaultValue(
                                  userEditDetails
                                )} //change this write conditions based on the line_type
                                {...registerEdit("dids_cost", {
                                  required: "Cost is required",
                                })}
                                placeholder="Enter Cost"
                                name="dids_cost"
                              />
                              {errorsEdit.dids_cost && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsEdit.dids_cost.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-4 ">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                DID Status{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className="form-select"
                                name="line_status"
                                defaultValue={userEditDetails.line_status}
                                {...registerEdit("line_status", {
                                  required: "Line Status is required",
                                })}
                                aria-label="Default select example"
                              >
                                <option value="" hidden>
                                  Select Line Status
                                </option>
                                <option value="free">Free</option>
                                <option value="paid">Paid</option>
                              </select>
                              {errorsEdit.line_status && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsEdit.line_status.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-4 mt-2">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                Status <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className="form-select"
                                name="status"
                                defaultValue={userEditDetails.status}
                                {...registerEdit("status", {
                                  required: "Status is required",
                                })}
                                aria-label="Default select example"
                              >
                                <option value="" hidden>
                                  Select Status
                                </option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                              </select>
                              {errorsEdit.status && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsEdit.status.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-4 mt-2">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                Calling Provider
                                {/* <span style={{ color: 'red' }}>*</span> */}
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                name="provider"
                                defaultValue={userEditDetails.provider}
                                {...registerEdit(
                                  "provider"
                                  // { required: 'Calling Provider is required' }
                                )}
                              >
                                <option value="" hidden>
                                  Select Calling Provider
                                </option>
                                <option value="V">Vitelity</option>
                                <option value="VI">VoIP Innovation</option>
                                <option value="B">Bandwidth</option>
                                <option value="I">Intelliquent</option>
                              </select>
                              {errorsEdit.provider && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsEdit.provider.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-4 mt-2">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                SMS Provider
                                {/* <span style={{ color: 'red' }}>*</span> */}
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                name="sms_provider"
                                defaultValue={userEditDetails.sms_provider}
                                {...registerEdit(
                                  "sms_provider"
                                  // { required: 'SMS Provider is required' }
                                )}
                              >
                                <option value="" hidden>
                                  Select SMS Provider
                                </option>
                                <option value="V">Vitelity</option>
                                <option value="VI">Voip Innovation</option>
                                <option value="B">Bandwidth</option>
                                <option value="I">Intelliquent</option>
                              </select>
                              {errorsEdit.sms_provider && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsEdit.sms_provider.message}
                                </div>
                              )}
                            </div>
                            {userEditDetails.line_type === "international" && (
                              <div className="col-md-4 mt-2">
                                <label className="form-label" htmlFor="country">
                                  Select Country{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  className="form-select"
                                  name="country"
                                  defaultValue={userEditDetails.country}
                                  {...registerEdit("country", {
                                    required: "Country is required",
                                  })}
                                  aria-label="Default select example"
                                >
                                  <option value="" hidden>
                                    Select Country
                                  </option>
                                  {countryData.map((country) => (
                                    <option
                                      key={country.st}
                                      value={country.country}
                                    >
                                      {country.country}
                                    </option>
                                  ))}
                                </select>
                                {errorsEdit.country && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {errorsEdit.country.message}
                                  </div>
                                )}
                              </div>
                            )}
                            <div className="col-md-4 mt-2">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                CNAM Inbound Status
                                {/* <span style={{ color: 'red' }}>*</span> */}
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                name="cnam_inbound"
                                defaultValue={userEditDetails.cnam_inbound}
                                {...registerEdit(
                                  "cnam_inbound"
                                  // { required: 'CNAM Inbound is required' }
                                )}
                              >
                                <option value="" hidden>
                                  Select Status
                                </option>
                                <option value="yes">Enable</option>
                                <option value="no">Disable</option>
                              </select>
                              {errorsEdit.cnam_inbound && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsEdit.cnam_inbound.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-4 mt-2">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                CNAM Outbound
                                {/* <span style={{ color: 'red' }}>*</span> */}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={userEditDetails.cnam}
                                {...registerEdit(
                                  "cnam"
                                  // { required: 'CNAM Outbound is required' }
                                )}
                                placeholder="Enter CNAM"
                                name="cnam"
                              />
                              {errorsEdit.cnam && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsEdit.cnam.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-12 d-flex justify-content-end align-items-end mt-4">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light btn-md me-md-2 px-5"
                              >
                                Update
                              </button>
                              <button
                                className=" btn btn-warning waves-effect waves-light btn-md me-md-2 px-5"
                                type="button"
                                onClick={handleCancelbtn}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {showBulkUploadDids && (
                <div className="row  mt-4">
                  <div className="col-xl-12">
                    <div className="">
                      <div className="card-body">
                        <form
                          data-select2-id="21"
                          novalidate="novalidate"
                          onSubmit={handleSubmitBulkUpload(bulkUploadDids)}
                        >
                          <div className="row g-3" data-select2-id="20">
                            <div className="col-md-3">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                DID Type <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className="form-select"
                                name="line_type"
                                {...registerBulkUpload("line_type", {
                                  required: "DID type is required",
                                })}
                                aria-label="Default select example"
                                onChange={handleAddDidChange}
                              >
                                <option value="" hidden>
                                  Select DID Type
                                </option>
                                <option value="local">Local DIDs</option>
                                <option value="toll_free">
                                  Toll free DIDs
                                </option>
                                <option value="vfax">Vfax DIDs</option>
                                <option value="international">
                                  International DIDs
                                </option>
                              </select>
                              {errorsBulkUpload.line_type && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsBulkUpload.line_type.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                User <span style={{ color: "red" }}>*</span>
                              </label>
                              <Controller
                                control={controlBulkUpload}
                                name="user_id"
                                id="user_id"
                                {...registerBulkUpload("user_id", {
                                  required: "User is required",
                                })}
                                render={({ field }) => (
                                  <AutoUserSearch
                                    onSelect={(user) => {
                                      setValueBulkUpload(
                                        "user_id",
                                        user ? user.user_id : null
                                      );
                                      setcustomerId(user ? user.user_id : null);
                                    }}
                                  />
                                )}
                              />
                              {errorsBulkUpload.user_id && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsBulkUpload.user_id.message}
                                </div>
                              )}
                            </div>

                            <div className="col-md-3">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                Line Status{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className="form-select"
                                name="line_status"
                                {...registerBulkUpload("line_status", {
                                  required: "Line Status is required",
                                })}
                                aria-label="Default select example"
                              >
                                <option value="" hidden>
                                  Select Line Status
                                </option>
                                <option value="free">Free</option>
                                <option value="paid">Paid</option>
                              </select>
                              {errorsBulkUpload.line_status && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsBulkUpload.line_status.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                Calling Provider
                                {/* <span style={{ color: 'red' }}>*</span> */}
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                name="provider"
                                {...registerBulkUpload(
                                  "provider"
                                  // { required: 'Calling Provider is required' }
                                )}
                              >
                                <option value="" hidden>
                                  Select Calling Provider
                                </option>
                                <option value="V">Vitelity</option>
                                <option value="VI">VoIP Innovation</option>
                                <option value="B">Bandwidth</option>
                                <option value="I">Intelliquent</option>
                              </select>
                              {errorsBulkUpload.provider && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsBulkUpload.provider.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                SMS Provider
                                {/* <span style={{ color: 'red' }}>*</span> */}
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                name="sms_provider"
                                {...registerBulkUpload(
                                  "sms_provider"
                                  // { required: 'SMS Provider is required' }
                                )}
                              >
                                <option value="" hidden>
                                  Select SMS Provider
                                </option>
                                <option value="V">Vitelity</option>
                                <option value="VI">Voip Innovation</option>
                                <option value="B">Bandwidth</option>
                                <option value="I">Intelliquent</option>
                              </select>
                              {errorsBulkUpload.sms_provider && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsBulkUpload.sms_provider.message}
                                </div>
                              )}
                            </div>

                            {selectedDidType === "international" && (
                              <div className="col-md-3">
                                <label className="form-label" htmlFor="country">
                                  Select Country:{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  className="form-select"
                                  name="country"
                                  {...registerBulkUpload("country", {
                                    required: "Country is required",
                                  })}
                                  aria-label="Default select example"
                                >
                                  <option value="" hidden>
                                    Select Country
                                  </option>
                                  {countryData.map((country) => (
                                    <option
                                      key={country.st}
                                      value={country.country}
                                    >
                                      {country.country}
                                    </option>
                                  ))}
                                </select>
                                {errorsBulkUpload.country && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {errorsBulkUpload.country.message}
                                  </div>
                                )}
                              </div>
                            )}

                            <div className="col-md-3">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                CNAM Inbound Status
                                {/* <span style={{ color: 'red' }}>*</span> */}
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                name="cnam_inbound"
                                {...registerBulkUpload(
                                  "cnam_inbound"
                                  // { required: 'CNAM Inbound Status is required' }
                                )}
                              >
                                <option value="" hidden>
                                  Select Status
                                </option>
                                <option value="yes">Enable</option>
                                <option value="no">Disable</option>
                              </select>
                              {errorsBulkUpload.cnam_inbound && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsBulkUpload.cnam_inbound.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-3">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                CNAM Outbound
                                {/* <span style={{ color: 'red' }}>*</span> */}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...registerBulkUpload(
                                  "cnam"
                                  // { required: 'CNAM Outbound is required' }
                                )}
                                placeholder="Enter CNAM"
                                name="cnam"
                              />
                              {errorsBulkUpload.cnam && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsBulkUpload.cnam.message}
                                </div>
                              )}
                            </div>

                            <div className="col-md-3">
                              <label
                                className="form-label"
                                htmlFor="formtabs-first-name"
                              >
                                Upload File{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Controller
                                name="base64_files"
                                {...registerBulkUpload("base64_files", {
                                  required: "File is required",
                                })}
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange } }) => (
                                  <input
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                    onChange={(e) =>
                                      handleFileChange(e, onChange)
                                    }
                                  />
                                )}
                              />

                              {errorsBulkUpload.base64_files && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {errorsBulkUpload.base64_files.message}
                                </div>
                              )}
                              <span className="text-danger">
                                Please click here to download{" "}
                                <a
                                  href="/sample_files/did_data_upload_sample.csv"
                                  className="fw-semibold text-decoration-underline"
                                  download
                                >
                                  sample file
                                </a>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-12 d-flex justify-content-end ">
                            <div>
                              <label
                                className="form-label"
                                htmlFor="formtabs-last-name"
                              >
                                &nbsp;
                              </label>
                              <div className="button-items">
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                            <div className="ms-5">
                              <label
                                className="form-label"
                                htmlFor="formtabs-last-name"
                              >
                                &nbsp;
                              </label>
                              <div className="button-items">
                                <button
                                  type="button"
                                  className="btn btn-warning w-md me-md-2"
                                  onClick={handleCancelbtn}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {showRoutingDids && (
                <>
                  {isLoading ? (
                    <div className="loader-overlay text-white">
                      <Loader />
                    </div>
                  ) : null}
                  <div className="row  mt-4">
                    <div className="col-xl-12">
                      <div className="">
                        <div className="card-body">
                          <div
                            className={`row ${selectedRoutingNoWithIp &&
                              selectedRoutingNoWithIp.length > 0
                              ? "mb-3"
                              : ""
                              }`}
                          >
                            <div className="col-12 col-md-12">
                              <h5
                                className="form-label"
                                htmlFor="selectedNumber"
                              >
                                {selectedRoutingNoWithIp &&
                                  selectedRoutingNoWithIp.length > 0
                                  ? "The numbers below currently have routing (Please delete and add it again!):"
                                  : null}
                              </h5>
                              <div className="input-group input-group-merge has-validation">
                                <div>
                                  {selectedRoutingNoWithIp &&
                                    selectedRoutingNoWithIp.length > 0
                                    ? selectedRoutingNoWithIp
                                      .map((numberString) =>
                                        Number(numberString)
                                      )
                                      .join(", ")
                                    : null}
                                </div>
                              </div>
                            </div>
                          </div>

                          <form
                            data-select2-id="21"
                            novalidate="novalidate"
                            onSubmit={handleSubmitRouteDids(RouteDidSubmit)}
                          >
                            <div className="row g-3" data-select2-id="20">
                              {/* <h5>{setSelectedRoutingNoWithoutIp.length > 0 ? 'The numbers listed below can be routed.' : null}</h5> */}
                              <div className="col-md-3">
                                <label
                                  className="form-label"
                                  htmlFor="formtabs-first-name"
                                >
                                  DID's <span style={{ color: "red" }}>*</span>
                                </label>
                                <textarea
                                  className="form-control"
                                  name="did_data"
                                  {...registerRouteDids("did_data", {
                                    required: "DID's are required",
                                  })}
                                  placeholder="Enter DID's"
                                  disabled
                                />
                                {errorsRouteDids.did_data && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {errorsRouteDids.did_data.message}
                                  </div>
                                )}
                              </div>
                              <input
                                type="hidden"
                                name="dids"
                                {...registerRouteDids("dids", {
                                  required: "DID's are required",
                                })}
                              ></input>
                              <div className="col-md-3">
                                <label
                                  className="form-label"
                                  htmlFor="formtabs-first-name"
                                >
                                  Route IP's{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  className="form-select"
                                  name="ip_address"
                                  {...registerRouteDids("ip_address", {
                                    required: "Route IP's is required",
                                  })}
                                  aria-label="Default select example"
                                >
                                  <option value="" hidden>
                                    Select an IP address
                                  </option>
                                  {endpoints.map((ip) => (
                                    <option
                                      key={ip.endpoint_id}
                                      value={JSON.stringify(ip)}
                                    >
                                      {ip.ipaddr}
                                    </option> // change this based on api response
                                  ))}
                                </select>
                                {errorsRouteDids.ip_address && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {errorsRouteDids.ip_address.message}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12 d-flex justify-content-end ">
                              <div>
                                <label
                                  className="form-label"
                                  htmlFor="formtabs-last-name"
                                >
                                  &nbsp;
                                </label>
                                <div className="button-items">
                                  <button
                                    type="submit"
                                    className="btn btn-primary w-md"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                              <div className="ms-5">
                                <label
                                  className="form-label"
                                  htmlFor="formtabs-last-name"
                                >
                                  &nbsp;
                                </label>
                                <div className="button-items">
                                  <button
                                    type="button"
                                    className="btn btn-warning w-md me-md-2"
                                    onClick={handleCancelbtn}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {routingRemoveShow && (
                <div className="row  mt-4">
                  <div className="col-xl-12">
                    <div className="">
                      <div className="card-body">
                        <form
                          onSubmit={handleSubmitRemoveRouting(
                            handleRoutingRemoveSubmit
                          )}
                        >
                          {Object.keys(selectedRoutingNoWithIp).length > 0 ? (
                            <>
                              <div className="row">
                                <div className="col-12 col-md-12">
                                  <h5
                                    className="form-label"
                                    htmlFor="selectedNumber"
                                  >
                                    {selectedRoutingNoWithIp?.length > 0
                                      ? "The numbers below will no longer have routing:"
                                      : null}
                                  </h5>
                                  <div className="input-group input-group-merge has-validation">
                                    <div>
                                      {selectedRoutingNoWithIp?.length > 0
                                        ? selectedRoutingNoWithIp
                                          .map((numberString) =>
                                            Number(numberString)
                                          )
                                          .join(", ")
                                        : null}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row mt-3">
                                <div className="col-12 col-md-12">
                                  <h5
                                    className="form-label"
                                    htmlFor="selectedNumber"
                                  >
                                    {selectedRoutingNoWithoutIp?.length > 0
                                      ? "Routing was not enabled for the numbers below:"
                                      : null}
                                  </h5>
                                  <div className="input-group input-group-merge has-validation">
                                    <div>
                                      {selectedRoutingNoWithoutIp?.length > 0
                                        ? selectedRoutingNoWithoutIp
                                          .map((numberString) =>
                                            Number(numberString)
                                          )
                                          .join(", ")
                                        : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="row">
                                <div className="col-12 col-md-12">
                                  <div className="input-group input-group-merge has-validation">
                                    <h5 className="text-danger">
                                      {" "}
                                      No eligble numbers with routing
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <div className="row">
                            <div className="col-md-12 d-flex justify-content-end align-items-end">
                              {selectedRoutingNoWithIp.length ? (
                                <button
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light btn-md me-md-2 px-5"
                                >
                                  Save Changes
                                </button>
                              ) : (
                                <></>
                              )}

                              <button
                                className="btn btn-success waves-effect waves-light btn-md me-md-2 px-5"
                                type="button"
                                onClick={handleCancelbtn}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>{" "}
            {/* container-fluid */}
          </div>
          {/* End Page-content */}
          <Footer />
        </div>
        {/* end main content*/}
      </div>
      {/* END layout-wrapper */}

      {/* Right bar overlay*/}
      <div className="rightbar-overlay"></div>
    </>
  );
}

export default AssignedDids;
