import Header from "../../../common/Header";
import Sidebar from "../../../common/Sidebar";
import Footer from "../../../common/Footer";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate,useLocation } from "react-router-dom";
import { triggerAlert, getToken,capitalizeFirst } from '../../../utils';
import Loader from '../../../common/Loader';
import PageHeader from "../../PageHeader";


function ManageList(){
    const api_url = process.env.REACT_APP_API_BASE_URL;
    const token = getToken();
    const navigate = useNavigate();
    const { search,state } = useLocation();
    const params = new URLSearchParams(search);
    const mode = params.get('mode');
    const [isLoading, setIsLoading] = useState(false);
    const mainHeading = `${capitalizeFirst(mode)} Documents`;
	const heading = 'Supports / Documents';
    const { control, register, handleSubmit, formState: { errors }, setValue, reset, clearErrors } = useForm();

    const formSubmit = async (data) => {
        try {
            setIsLoading(true);
            const params = {
                id : data.id,
                user_document_name : data.document
            };
            var response;
            if (mode === "update") {
                response = await axios.put(api_url +`update_doc_name/`+data.id+`/update`, params, token);
            } else {
                response = await axios.post(api_url +  `add_doc_name`, params, token);
            }
            if (response.status === 200) {
                triggerAlert('success', 'Success', response.message);
                navigate('/others/documents');
                setIsLoading(false);
            } else {
                setIsLoading(false);
                throw new Error(response.message);
            }
        } catch (err) {
            console.log(err);
            setIsLoading(false);
            if (err.response && err.response.status === 400) {
                const errorMessage = err.response.data.message || 'Bad request';
                triggerAlert('error', 'Oops...', errorMessage);
            } else {
                triggerAlert('error', 'Oops...', 'An unexpected error occurred');
            }
        }
    }

    

    const setEditValues = () => {
        if (state != null) {
            setValue('document',state.user_document_name);
            setValue('id',state.id);
        } else {
            navigate('/others/user_documents');
        }
    }

    useEffect(() => {
        if (mode === "update") {
            setEditValues();
        } 
    },[mode]);


    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    {isLoading ? <div className='loader-overlay text-white'>
                        <Loader />
                    </div> : ""}
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* <!-- start page title --> */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* <!-- end page title --> */}
                            <div className="row mt-4">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body p-4">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div>
                                                        <form id="creditCardForm" key={2} novalidate="novalidate" enctype="multipart/form-data" onSubmit={handleSubmit(formSubmit)}>
                                                            <div className="row">
                                                                <div class="col-md-4">
                                                                    <div class="mb-3">
                                                                        <label class="form-label" for="formrow-Document-input">Document Name <span style={{ color: 'red' }}>*</span></label>
                                                                        <input class="form-control" type="text" id="document" {...register('document', { required: 'Document is required' })}  />
                                                                        <input type="hidden" name="id" {...register('id')}  />
                                                                        {errors.document && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.document.message}</div>}
								                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-grid gap-2 d-md-flex justify-content-md-start mt-4 mb-3">
                                                                <button className="btn btn-primary w-md me-md-2" type="submit"> { mode === "update" ? "Update" : "Create"}</button>
                                                                <button className="btn btn-warning w-md" type="button" onClick={() => navigate('/others/documents')}>Cancel</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                    <Footer />
                </div>
                {/* end main content*/}
            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </>
    );

}

export default ManageList;