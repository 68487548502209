import SunEditor from 'suneditor-react';
import plugins from 'suneditor/src/plugins';
import { en } from 'suneditor/src/lang';
import katex from 'katex';
import 'suneditor/dist/css/suneditor.min.css';
import 'katex/dist/katex.min.css';
import axios from 'axios';

const Editor = ({ name, onChange, props, value, setContents }) => {
  // const myStyles = {
  //   '[type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled), button:not(:disabled)': {
  //     color: 'black !important'
  //   }
  // };
  const options = {
    plugins: plugins,
    height: 250,
    katex: katex,
    lang: en,
    buttonList: [
      [
        'font',
        'fontSize',
        'formatBlock',
        'bold',
        'underline',
        'italic',
        'paragraphStyle',
        'blockquote',
        'strike',
        'subscript',
        'superscript',
        'fontColor',
        'hiliteColor',
        'textStyle',
        'removeFormat',
        // 'undo',
        // 'redo',
        'outdent',
        'indent',
        'align',
        'horizontalRule',
        'list',
        'lineHeight',
        'table',
        'link',
        'image',
        'video',
        'audio',
        'fullScreen',
        'showBlocks',
        'codeView',
        'preview',
        'print'
      ]
    ]
  };

  const handleImageUploadBefore = async (files, info, uploadHandler) => {
    const KEY = 'docs_upload_example_us_preset';
    const Data = new FormData();
    Data.append('file', files[0]);
    Data.append('upload_preset', KEY);
    try {
      const response = await axios.post(
        'https://api.cloudinary.com/v1_1/demo/image/upload',
        Data
      );
      const res = {
        errorMessage: response?.data?.message,
        result: [
          {
            url: response.data.secure_url,
            size: response.data.file_size,
            name: response.data.public_id
          }
        ]
      };
      uploadHandler(res);
    } catch (error) {
      //console.log(error);
    }
  };

  const handleImageUpload = (
    targetElement,
    index,
    state,
    info,
    remainingFilesCount,
    core
  ) => {
    // console.log(core);
  };

  const handleImageUploadError = (errorMessage, result) => {
    // console.log(errorMessage, result);
  };

  return (
    // <div style={{ myStyles }}>
    <SunEditor
      {...props}
      placeholder="Please type here..."
      lang="en"
      setDefaultStyle="font-family: Arial; font-size: 14px; color: black !important"
      setOptions={options}
      onImageUploadBefore={handleImageUploadBefore}
      onImageUpload={handleImageUpload}
      onImageUploadError={handleImageUploadError}
      onChange={onChange} // Remove the duplicate onChange prop here
      value={value}
      setContents={setContents}


    />
    // </div>
  );
};

export default Editor;
