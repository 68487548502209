import React, { useEffect, useState } from 'react'
import Header from '../../../common/Header'
import Sidebar from '../../../common/Sidebar'
import PageHeader from '../../PageHeader'
import Footer from '../../../common/Footer'
import CommonSelect from '../../CommonSelectStatic'
import { triggerAlert, pageReload, getToken , get_user_menu_permission } from '../../../utils';
import Loader from '../../../common/Loader' 

import { useForm, Controller } from 'react-hook-form';
import axios from 'axios'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {menuIdData} from '../../../utils/constants'

export default function ManageUserDepartment() {
    const api_url       = process.env.REACT_APP_API_CUSTOMER_URL;
    const heading       = "Admin / User Department";
    const token         = getToken();
    const [isLoading, setIsLoading]     = useState();
    const [editStatus, setEditStatus]   = useState(false);
    const [editId, setEditId]           = useState("");



    const location      = useLocation();
    const queryParams   = new URLSearchParams(location.search);
    const action        = queryParams.get('action');
    const edit_id       = queryParams.get('edit_id');
    const navigate      = useNavigate();
    const editStateData = location.state;
    const mainHeading   = action=="add" ? "Create Department" : "Update Department";
    ///////////////////////// Basic form /////////////////////////////////////////
    const { register: registerForm, handleSubmit: handleSubmitForm, formState: { errors: errorsForm }, setValue: setValueForm, reset: resetForm, control: controlForm, getValues: getValuesForm, watch } = useForm({
        defaultValues: {},
    });

    const setStatusOption = [
        { value: '1', label: 'Active' },
        { value: '2', label: 'Inactive' },
    ];

    const handleStatus = (selectedOption) => {
        setValueForm('status', selectedOption ? selectedOption.value : null);
    };

    const saveFormData = async (data) => {

        setIsLoading(true);


        data.dep_id = editId;

        try {
            var api = "";
            let response = "";
            if (editStatus) {
                api = api_url + 'manage_admin/update_department';
                response = await axios.put(api, data, token);
            } else {
                api = api_url + '/manage_admin/add_department';
                response = await axios.post(api, data, token);
            }


            if (response.status === 201) {
                setIsLoading(false);
                if (editStatus) {
                    triggerAlert('success', 'success', 'Data updated successfully');
                } else {
                    triggerAlert('success', 'success', 'Data added successfully');
                }

                setTimeout(() => {
                    navigate(`/manage_admin/user_department`);
                }, 2000);
            } else if (response.status === 204) {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }
            else {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }

        } catch (error) {
            setIsLoading(false);
            triggerAlert('error', 'Oops...', 'Something went wrong here..');
        }

    };


    ////////////////////////////////////////////////////////////////////////////////
    const fetchEditData = async (edit_id) => {
        try {
            setIsLoading(true);
            setEditStatus(true);


            const itemsArray = editStateData
            setValueForm('name', itemsArray.name);
            setValueForm('status', itemsArray.status);


            setIsLoading(false);


        } catch (error) {
            setIsLoading(false);
            triggerAlert('error', 'Oops...', 'Something went wrong..');
        }

    };



    useEffect(() => {
        const menu_id           = menuIdData.user_department;
        const add_permission    = get_user_menu_permission(menu_id,'add');
        const edit_permission   = get_user_menu_permission(menu_id,'edit');

        if(add_permission || edit_permission) {
            if (action == "update") {
                setEditId(edit_id);
                fetchEditData(edit_id);
            }
        }else{
            triggerAlert('error', 'Oops...', "You don't have permission to access this page");
            
            setTimeout(() => {
                window.history.back();
            }, 3000);
        }
    }, [])

    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                {isLoading &&
                    <div className='loader-overlay text-white'>
                        <Loader />
                    </div>
                }

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */} 
                                <>
                                     
                                    <div className="row  ">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="float-end">
                                                        <Link to="/manage_admin/user_department"  className="btn btn-primary w-md btn-md"   >Back</Link>
                                                    </div>

                                                </div>
                                                <div className="card-body">
                                                    <form noValidate="novalidate" onSubmit={handleSubmitForm(saveFormData)}>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="mb-3">
                                                                    <label for="formrow-email-input" className="form-label">Department Name <span className="text-danger">*</span></label>
                                                                    <input type="text"
                                                                        className="form-control"
                                                                        id="formrow-email-input"
                                                                        placeholder=" "
                                                                        {...registerForm('name', { required: 'Department Name is required' })}
                                                                        name="name"

                                                                    />
                                                                    {errorsForm.name && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.name.message}</div>}

                                                                </div>
                                                            </div>

                                                            <div className="col-md-3">
                                                                <div className="mb-3">
                                                                    <label for="formrow-email-input" className="form-label">Status<span className="text-danger">*</span></label>
                                                                    <Controller
                                                                        name="status"
                                                                        {...registerForm('status',
                                                                            { required: 'Status is required' }
                                                                        )}
                                                                        control={controlForm}
                                                                        defaultValue={null} // Set the default value
                                                                        render={({ field }) => (
                                                                            <CommonSelect
                                                                                options={setStatusOption}
                                                                                value={field.value}
                                                                                placeholder="Select Status"
                                                                                onChange={handleStatus}

                                                                            />
                                                                        )}
                                                                    />
                                                                    {errorsForm.status && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.status.message}</div>}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12 d-flex justify-content-end ">
                                                                <div>
                                                                    <label className="form-label" htmlFor="formtabs-last-name">&nbsp;</label>
                                                                    <div className="button-items">
                                                                        <button type="submit" className="btn btn-primary w-md">{editStatus ? "Update" : "Add"}</button>
                                                                    </div>
                                                                </div>
                                                                <div className="ms-2">
                                                                    <label className="form-label" htmlFor="formtabs-last-name">&nbsp;</label>
                                                                    <div className="button-items">
                                                                        <Link to="/manage_admin/user_department" className="btn btn-warning w-md me-md-2"  >Cancel</Link>
                                                                    </div>
                                                                </div> 
                                                            </div>
                                                        </div>

                                                    </form>

                                                </div>
                                            </div>
                                        </div> {/* <!-- end col -->*/}
                                    </div>
                                </> 



                        </div> {/* container-fluid */}
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </>
        )
    }
    
