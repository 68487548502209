import React, { useEffect, useState } from "react";
import Header from '../../../common/Header'
import Sidebar from '../../../common/Sidebar'
import Footer from '../../../common/Footer'
import PageHeader from '../../PageHeader'
import PaginationComponent from "../../PaginationComponent";
import axios from "axios";
import DataTable from 'react-data-table-component';
import { getToken, removePTags, formattedDateTime } from "../../../utils";
import Loader from '../../../common/Loader'
import { useNavigate } from 'react-router-dom'

function UserLogs() {

    const api_url = process.env.REACT_APP_API_CUSTOMER_URL;
    const show50Entries = process.env.REACT_APP_API_SHOW_ENTRIES;
    const mainHeading = "User Logs";
    const heading = "Admin / User Logs";
    const token = getToken();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState();
    const [listData, setListData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [pageSlNo, setPageSlNo] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPageLimit, setPerPageLimit] = useState(show50Entries);
    const [searchQuery, setSearchQuery] = useState('');
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState();


    const fetchListData = async (page = currentPage, size = perPage, searchkey = "") => {
        console.log(size);
        if (!searchkey) {
            setIsLoading(true);
        }
    
        try {
            setPageSlNo((page - 1) * size);
            const searchQuery = searchkey ? `&keyword=${searchkey}` : "";
            const response = await axios.get(`/apiV1/user_logs_list?page_size=${size}&current_page=${page}${searchQuery}`);
            const response_data = response.data;
    
            if (response.status === 200) {
                const itemsArray = response_data.results.data || [];
                itemsArray.map((item, i) => {
                    // Correctly calculate the serial number for each item based on the current page and page size
                    item.Num = (page - 1) * size + i + 1;
                    return item;
                });
                const total_pages = response_data.results.count;
                setTotalRows(total_pages);
                setListData(itemsArray);
                setCurrentPage(page);
                setPerPage(size);
                setIsLoading(false);
            } else if (response.status === 204) {
                setListData([]);
                setIsLoading(false);
                setTotalRows(0);
            } else {
                setListData([]);
                setIsLoading(false);
                setTotalRows(0);
                // triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }
    
        } catch (error) {
            setListData([]);
            setIsLoading(false);
            setTotalRows(0);
            // triggerAlert('error', 'Oops...', 'Something went wrong..');
        }
    };
    
    

    

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) =>index+1,
            sortable: true,
            width:'100px'
        },
        {
            name: 'Action From',
            selector: row => row.action_applied_for ? row.action_applied_for : '-',
            sortable: true,
            width:'200px'
        },
        {
            name: 'Action By Admin',
            selector: row => row.action_by_admin_name ? row.action_by_admin_name : '-',
            sortable: true,
            width:'200px'
        },
        {
            name: 'IP Address',
            selector: row => row.ip_address,
            sortable: true,
            width:'200px'
        },
        {
            name: 'Action Type',
            selector: row => row.action_type,
            sortable: true,
            width:'300px'
        },
        {
            name: 'Date',
            selector: row => formattedDateTime(row.action_date),
            sortable: true,
            width:'200px'
        },
        {
            name: 'Description',
            selector: row => removePTags(row.description),
            sortable: true,
            width:'500px'
        },
    ];



    const handlePageClick = (selected) => {
        const selectedPage = selected.selected;
        setCurrentPage(selectedPage);
        fetchListData(selectedPage + 1, searchQuery); // Increment the page number by 1 for server-side pagination
    };

    // const handlePageChange = event => {
    // 	if (event.target.value) {
    //         setCurrentPage(0);
    // 		setPerPageLimit(event.target.value);
    // 		setPageLimitSelected(event.target.value);
    // 	} else {
    // 		setPerPageLimit(10);
    // 		setPageLimitSelected(10);
    // 	}
    // };


    let pgntn_props = {
        pageCount: pageCount,
        handlePageClick: handlePageClick,
        selectedPage: currentPage
    }



    const handleKeySearch = (e) => {
        setSearchQuery(e.target.value);
        const searchkey = e.target.value;
        fetchListData(1,perPage, searchkey);
    };

    useEffect(() => {
        setCurrentPage(0);
        fetchListData(currentPage + 1);
    }, [perPageLimit]);

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchListData(page, newPerPage, searchQuery);
        setPerPage(newPerPage);
    };
    const handlePageChange = page => {
        fetchListData(page, perPage, searchQuery);
        setCurrentPage(page);
    };
    
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}
                            <>

                                <div className="row  ">
                                    <div className="col-12">
                                        <div className="card">
                                            {/* <div className="card-header">
                                                     
                                                </div> */}
                                            <div className="card-body">
                                                <div id="datatable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                    <div className="row">
                                                        {/* <div className="col-sm-12 col-md-6">
																<div className="dataTables_length" id="datatable_length">
																	<label>Show&nbsp;
																		<select name="example_length" aria-controls="example" className="table_length" onChange={handlePageChange} value={pageLimitSelected}>
																			<option value={10}>10</option>
																			<option value={20}>20</option>
																			<option value={50}>50</option>
																			<option value={100}>100</option>
																		</select> entries
																	</label>
																</div>
															</div>   */}
                                                        <div className="col-sm-12 col-md-12 mb-2">
                                                            <div className="btn-group float-end ">
                                                                <input type="search" placeholder="Search..." value={searchQuery} className="form-control form-control-sm" aria-controls="example" onChange={handleKeySearch} />
                                                                &nbsp;&nbsp;
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                    {!isLoading ?
                                                            <DataTable
                                                                columns={columns}
                                                                data={listData}
                                                                pagination
                                                                paginationServer
                                                                paginationTotalRows={totalRows}
                                                                paginationComponentOptions={paginationComponentOptions}
                                                                paginationPerPage={perPage}
                                                                paginationDefaultPage={currentPage}
                                                                onChangeRowsPerPage={handlePerRowsChange}
                                                                onChangePage={handlePageChange}
                                                            />:
                                                            <div className='loader-overlay text-white'>
                                                                <Loader />
                                                            </div>
                                                        }
                                                        
                                                    </div>
                                                    <hr />
                                                </div>

                                            </div>
                                        </div>
                                    </div> {/* <!-- end col -->*/}
                                </div>
                            </>



                        </div> {/* container-fluid */}
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </>
    );
}

export default UserLogs;
