import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Header from "../../../common/Header";
import Sidebar from "../../../common/Sidebar";
import Footer from "../../../common/Footer";
import PageHeader from "../../PageHeader";
import Loader from "../../../common/Loader";
import { useForm, Controller } from "react-hook-form";
import {
  getToken,
  triggerAlert,
  removePTags,
  formattedDateTime,
  convertDocIdsToStr,
  getBase64,
  pageReload,
  validateFileSize,
  formatingDateTime,
  downloadFile
} from "../../../utils";
import { useNavigate, useLocation } from "react-router-dom";


import Select from "react-select";
import Pagination from "../../PaginationComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Editor from "../../Editor";
import DataTable from 'react-data-table-component';

const View_tickets = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const show50Entries = process.env.REACT_APP_API_SHOW_ENTRIES;
  //const customer_api_url = process.env.REACT_APP_API_CUSTOMER_URL;
  const token = getToken();
  const location = useLocation();
  const navigate = useNavigate();
  const ticketData = location.state;
  const [ticketDetail, setTicketDetail] = useState(null);
  const [ticketNoteList, setTicketNoteList] = useState(null);
  const [ticketDocumentList, setTicketDocumentList] = useState(null);
  const [showNoteForm, setShowNoteForm] = useState(null);
  const [showCommentForm, setShowCommentForm] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [ticketPriorityList, setTicketPriorityList] = useState(null);
  const [departmentList, setDepartmentList] = useState(null);
  const [ticketStatusList, setTicketStatusList] = useState(null);
  const [helpDocumentList, setHelpDocumentList] = useState(null);
  const [ticketComments, setTicketComments] = useState([]);
  const [fetchDefinedReplies, setFetchDefinedReplies] = useState(null);
  const [customerNotes, setCustomerNotes] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [assignUserList, setAssignUserList] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showCustNotes, setShowCustNotes] = useState(null);
  const [showBtnNotes, setShowBtnNotes] = useState(true);
  const [editorContent, setEditorContent] = useState("");
  const [pastTickets, setPastTickets] = useState(null);
  const [pastLoadListData, setPastLoadListData] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageSlNo, setPageSlNo] = useState(0);
  const [totalRows, setTotalRows] = useState();
  const [perPageLimit, setPerPageLimit] = useState(show50Entries);
  const [searchQuery, setSearchQuery] = useState('');
  const [listData, setListData] = useState([]);
  const pageSize = process.env.REACT_APP_API_SHOW_ENTRIES;


  const columns = [
    {
      name: 'Ticket',
      selector: row => row.ticket_number,
      sortable: true,
      width: '100px'
    },
    {
      name: 'Category',
      selector: row => row.ticket_category,
      sortable: true,
      width: '200px'
    },
    {
      name: 'Opened',
      selector: row =>formattedDateTime(row.register_date),
      sortable: true,
      width: '200px'
    },
    {
      name: 'Updated',
      selector: row =>formattedDateTime(row.updated_date),
      sortable: true,
      width: '200px'
    },
    {
      name: 'Created By',
      selector: row => row.user_name,
      sortable: true,
      width: '300px'
    },
    {
      name: 'Assign To',
      selector: row => row.assigned_user,
      sortable: true,
      width: '200px'
    },
    {
      name: 'Status',
      selector: row => row.ticket_status_value,
      sortable: true,
    },
  ];

  const editorConfiguration = {
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "blockQuote",
    ],
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    clearErrors,
  } = useForm();
  const {
    register: registerComments,
    handleSubmit: handleCommentsUpdate,
    formState: { errors: errorsCommentsUpdate },
    setValue: setValueCommentsUpdate,
    clearErrors: clearCommentsErrors,
    reset,
    control: controlCommentsUpdate,
    watch: watchComments,
    getValues: getValuesComments,
  } = useForm();
  const mainHeading = "View Ticket";
  const heading = "Support /  View Ticket";

  const handleEditorChange = (content) => {
    if (content) {
      clearErrors('editorContent');
    }
    setValue('editorContent', content);
  };


  const fetchTicketDetail = async () => {
    try {
      if (ticketData) {
        setIsLoading(true);
        const response = await axios.get(
          `/apiV1/supports/get_ticket_data?ticket_id=${ticketData.id}`,
        );
        const response_data = response.data;
        if (response_data.error_code === 200) {
          const itemsArray = response_data.results;
          setTicketDetail(itemsArray);
          setIsLoading(false);
        } else if (response.status === 204) {
          setTicketDetail([]);
          setIsLoading(false);
        } else {
          triggerAlert(
            "error",
            "Oops...",
            "Couldn't get the ticket detail data."
          );
          setIsLoading(false);
        }
      }
    } catch (err) {
      triggerAlert("error", err.message);
      setIsLoading(false);
    }
  };

  const fetchNoteList = async () => {
    try {
      if (ticketData) {
        setIsLoading(true);
        const response = await axios.get(
          `/apiV1/supports/get_ticket_internal_notes?ticket_id=${ticketData.id}`,
        );
        const response_data = response.data;
        if (response_data.error_code === 200) {
          const itemsArray = response_data.results;
          setTicketNoteList(itemsArray);
          setIsLoading(false);
        } else if (response.status === 204) {
          setTicketNoteList([]);
          setIsLoading(false);
        } else {
          triggerAlert(
            "error",
            "Oops...",
            "Couldn't get the ticket detail data."
          );
          setIsLoading(false);
        }
      }
    } catch (err) {
      triggerAlert("error", err.message);
      setIsLoading(false);
    }
  };

  const fetchDocumentList = async () => {
    try {
      if (ticketData) {
        setIsLoading(true);
        const response = await axios.get(
          `/apiV1/supports/get_ticket_documents_data?ticket_number=${ticketData.ticket_number}`
        );
        const response_data = response.data;
        if (response_data.error_code === 200) {
          const itemsArray = response_data.results;
          setTicketDocumentList(itemsArray);
          setIsLoading(false);
        } else if (response.status === 204) {
          setTicketDocumentList([]);
          setIsLoading(false);
        } else {
          triggerAlert(
            "error",
            "Oops...",
            "Couldn't get the ticket detail data."
          );
          setIsLoading(false);
        }
      }
    } catch (err) {
      triggerAlert("error", err.message);
      setIsLoading(false);
    }
  };

  const fetchTicketPriorityList = async () => {
    try {
      const response = await axios.get(
        `/apiV1/supports/get_ticket_priority_list`,
      );
      const response_data = response.data;
      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        setTicketPriorityList(itemsArray);
      } else if (response.status === 204) {
        setTicketPriorityList([]);
      } else {
        triggerAlert(
          "error",
          "Oops...",
          "Couldn't get the ticket priority list."
        );
      }
    } catch (error) {
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const fetchDepartmentList = async () => {
    try {
      const response = await axios.get(`/apiV1/role-dropdown/`);
      console.log(response);
      const response_data = response.data;
      if (response.status === 200) {
        const itemsArray = response_data;
        console.log(itemsArray);
        setDepartmentList(itemsArray);
      } else if (response.status === 204) {
        setDepartmentList([]);
      } else {
        triggerAlert("error", "Oops...", "Couldn't get the department list.");
      }
    } catch (error) {
      //console.log(error);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const fetchTicketStatusList = async () => {
    try {
      const response = await axios.get(
        `/apiV1/supports/get_ticket_status_list/`,
      );
      const response_data = response.data;
      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        setTicketStatusList(itemsArray);
      } else if (response.status === 204) {
        setTicketStatusList([]);
      } else {
        triggerAlert(
          "error",
          "Oops...",
          "Couldn't get the ticket status list."
        );
      }
    } catch (error) {
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  // const fetchHelpDocumentList = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://devtsad.talentscan.ai/apiV1/supports/get_help_documents_list`,
  //       token
  //     );
  //     const response_data = response.data;
  //     if (response_data.error_code === 200) {
  //       const itemsArray = response_data.results;
  //       const docuOptions = [];
  //       itemsArray.forEach((element) => {
  //         docuOptions.push({
  //           value: element.help_id,
  //           label: element.help_name,
  //         });
  //       });
  //       setHelpDocumentList(docuOptions);
  //     } else if (response.status === 204) {
  //       setHelpDocumentList([]);
  //     } else {
  //       triggerAlert(
  //         "error",
  //         "Oops...",
  //         "Couldn't get the help documents list."
  //       );
  //     }
  //   } catch (error) {
  //     triggerAlert("error", "Oops...", "Something went wrong..");
  //   }
  // };

  const fetchTicketComments = async () => {
    try {
      if (ticketData) {
        const response = await axios.get(
          `/apiV1/supports/get_ticket_comments?ticket_id=${ticketData.id}`,
        );
        const response_data = response.data;
        if (response_data.error_code === 200) {
          const itemsArray = response_data.results;
          setTicketComments(itemsArray);
        } else if (response.status === 204) {
          setTicketComments([]);
        } else {
          triggerAlert("error", "Oops...", "Couldn't get the ticket comments.");
        }
      }
    } catch (error) {
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  // const fetchCustNotes = async () => {
  //   try {
  //     if (ticketData) {
  //       const response = await axios.get(
  //         `/apiV1/supports/get_customer_notes?user_id=${ticketData.user_id}`
  //       );
  //       const response_data = response.data;
  //       if (response_data.error_code === 200) {
  //         const itemsArray = response_data.results;
  //         setCustomerNotes(itemsArray);
  //       } else if (response.status === 204) {
  //         setCustomerNotes([]);
  //       } else {
  //         triggerAlert("error", "Oops...", "Couldn't get the ticket comments.");
  //       }
  //     }
  //   } catch (error) {
  //     //console.log(error);
  //     triggerAlert("error", "Oops...", "Something went wrong..");
  //   }
  // };

  const fetchPastTicketList = async (page = currentPage, size = perPage) => { 
    try {
      setPageSlNo((page - 1) * size);
      const response = await axios.get(
        `/apiV1/supports/get_past_tickets?line_type=Closed&company_name=${ticketData.company_name}&customer_name=${ticketData.customer_name}&customer_id=${ticketData.customer}&page_size=${size}&current_page=${page}`
      );
      const response_data = response.data;
  
      if (response.status === 200 && response_data.error_code === 200) {
        const itemsArray = response_data.results.data || [];
        itemsArray.map((item, i) => {
          item.Num = (page - 1) * size + i + 1;
          return item;
        });
        const total_pages = response_data.results.pagination.total_pages;
        setTotalPageCount(total_pages);
        setListData(itemsArray);
        setCurrentPage(page);
        setPerPage(size);
        setIsLoading(false);  
      } else if (response.status === 204) {
        setIsLoading(false);
        setListData([]);
        setTotalPageCount(0);
      } else {
        setIsLoading(false);
        setListData([]);
        setTotalPageCount(0);
        triggerAlert("error", "Oops...", "Couldn't get the ticket comments.");
      }
  
    } catch (error) {
      setIsLoading(false);
      setListData([]);
      setTotalPageCount(0);
      triggerAlert("error", "Oops...", "Something went wrong.");
    }
  };

  useEffect(() => {
    setCurrentPage(0);
    fetchPastTicketList(currentPage + 1);
  }, [perPageLimit]);

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchPastTicketList(page, newPerPage);
    setPerPage(newPerPage);
  };
  const handlePageChange = page => {
    fetchPastTicketList(page, perPage);
    setCurrentPage(page);
  };

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'All',
  };
  

  const handleAssignTo = async (role_id) => {
    try {
      ///const department_id = e.target.value;
      const response = await axios.get(
        `/apiV1/supports/get_all_admin_users_list?role_id=${role_id}`
      );
      const response_data = response.data;
      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        setAssignUserList(itemsArray);
      } else if (response.status === 204) {
        setAssignUserList([]);
      } else {
        triggerAlert("error", "Oops...", "Couldn't get the assign user list.");
      }
    } catch (error) {
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const updateAssigntList = async (e) => {
    try {
      const role_id = e.target.value;
      clearCommentsErrors("ticket_department");
      if (role_id) {
        handleAssignTo(role_id);
      } else {
        setValueCommentsUpdate("assign_user", "");
      }
    } catch (error) {
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };


  const notesSubmit = async (data) => {
    try {
      setIsLoading(true);
      const params = {
        ticket_id: ticketData.id,
        comments: data.editorContent,
      };
      const response = await axios.post(
        apiBaseUrl + `supports/add_ticket_notes`,
        params,
        token
      );
      if (response.status === 201) {
        triggerAlert("success", "Success", `Ticket Note Added Successfully!!!`);

        setIsLoading(false);
        setShowNoteForm(false);
        // pageReload();
        fetchNoteList();
      } else {
        setIsLoading(false);
        throw new Error("Unable to Add Ticket Note Successfully!!!");
      }
    } catch (err) {
      setIsLoading(false);
      triggerAlert("error", "Oops...", err.message);
    }
  };

  const commentSubmit = async (data) => {
    // console.log('commentSubmit', data)
    try {
      setIsLoading(true);
      const ticketStatus = data.ticket_status == 3;
      // console.log('ticketStatus', ticketStatus)
      let selectedDate;
      if (ticketStatus) selectedDate = watchComments("schedule_date");

      const params = {
        ticket_id: ticketDetail.id,
        cc_email: data.cc_email,
        predefined_replies: data.predefined_replies,
        ticket_priority: data.ticket_priority,
        status: data.ticket_status,
        ticket_assignto: data.assign_user,
        escalation_type: data.escalation_process,
        message: editorContent,
        base64_files: selectedFiles,
        user_id: data.user,
        schedule_date: selectedDate
          ? formatingDateTime(selectedDate, "YYYY-MM-DD HH:mm:ss")
          : null,
        help_document: convertDocIdsToStr(data.help_documents),
      };
      // return
      const response = await axios.post(
        `/apiV1/supports/add_ticket_comments`,
        params,
      );
      if (response.status === 201) {
        triggerAlert(
          "success",
          "Success",
          `Ticket Comment Added Successfully!!!`
        );
        // fetchTicketComments();
        // fetchDocumentList();
        setIsLoading(false);
        setShowCommentForm(false);
        reset();
        pageReload();
      } else {
        setIsLoading(false);
        throw new Error("Unable to Add Ticket Comment Successfully!!!");
      }
    } catch (err) {
      setIsLoading(false);
      triggerAlert("error", "Oops...", err.message);
    }
  };

  const handleFileChange = (e, index) => {
    const files = e.target.files;
    if (files) {
      const filesArray = Array.from(files);
      if (filesArray.length > 0) {
        //console.log("ok");
        const fileName = filesArray[0].name.split(".")[0];
        const fileExt = filesArray[0].name.split(".")[1];
        const fileSize = filesArray[0].size;
        //console.log(fileSize);
        // Check file extensions for each selected file
        const maxSizeInBytes = 1024 * 1024; // 1MB
        const allowedExtensions = [
          ".doc",
          ".docx",
          ".pdf",
          ".jpg",
          ".png",
          ".jpeg",
          ".csv",
          ".txt",
          ".xlsx",
          ".mp4",
          ".mov",
          ".avi",
          ".mkv",
          ".wmv",
          ".flv",
          ".webm",
          ".m4v",
          ".3gp",
          ".ogg",
        ];
        //const allowedExtensionsPattern = /\.(docx?|pdf|jpe?g|png|csv|txt|xlsx|mp4|mov|avi|mkv|wmv|flv|webm|m4v|3gp|ogg)$/i;
        const isValidFiles = filesArray.every((file) => {
          //allowedExtensionsPattern.test(file.name.split('.').pop().toLowerCase())
          const fileExtension = file.name.split(".").pop().toLowerCase();
          return allowedExtensions.includes("." + fileExtension);
        });
        //console.log(isValidFiles);
        if (isValidFiles) {
          if (fileSize < maxSizeInBytes) {
            // Convert each selected file to base64
            Promise.all(filesArray.map((file) => getBase64(file))).then(
              (base64Array) => {
                // Set the selectedFiles state with the base64-encoded files
                setSelectedFiles((currentFiles) => {
                  const updatedFiles = [...currentFiles];
                  // updatedFiles[index] = base64Array;
                  updatedFiles[index] = {
                    file_name: fileName,
                    file_type: fileExt,
                    file_size: fileSize,
                    file: base64Array[0],
                  };
                  return updatedFiles;
                });
              }
            );
            document.getElementById("submit").removeAttribute("disabled");
          } else {
            triggerAlert(
              "error",
              "Oops...",
              "File size should not be more than 1MB"
            );
            document.getElementById("submit").setAttribute("disabled", "true");
          }
        } else {
          // Show an error message or handle the invalid file type
          triggerAlert(
            "error",
            "Oops...",
            "Invalid file type. Please select another file."
          );
          document.getElementById("submit").setAttribute("disabled", "true");
        }
      }
      //console.log(imageData);
    }
  };

  const validateEmailList = (value) => {
    // if (!value) {
    //     return 'Email list is required';
    // }
    if (value) {
      const emailArray = value.split(",").map((email) => email.trim());
      for (const email of emailArray) {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
          return "Invalid email format";
        }
      }
      return true; // Validation passed
    }
  };

  const handlePageClick = (selected) => {
    const selectedPage = selected.selected;
    setPageNum(selectedPage + 1);
  };

  let props = {
    pageCount: totalPageCount,
    handlePageClick: handlePageClick,
    selectedPage: pageNum - 1,
  };
  const indexRef = useRef(null);
  const handleAddComment = () => {
    setShowCommentForm(true);
    if (indexRef && indexRef.current) {
      indexRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (pastLoadListData) {
      fetchPastTicketList();
    } else {
      fetchTicketDetail();
      fetchNoteList();
      // fetchCustNotes();
      fetchDocumentList();
      fetchTicketPriorityList();
      fetchDepartmentList();
      fetchTicketStatusList();
      // fetchHelpDocumentList();
      fetchTicketComments();
      fetchPreDefinedReplies();
      if (ticketData) {
        setValueCommentsUpdate("ticket_department", ticketData.department_id);
        if (ticketDetail) {
          setValueCommentsUpdate("assign_user", ticketDetail.ticket_assignto);
        }
        handleAssignTo(ticketData.department_id);
      } else {
        navigate("/support/manage_tickets");
      }
    }
  }, [ticketData, pageNum, pastLoadListData]);

  useEffect(() => {
    if (ticketDetail) {
      setValueCommentsUpdate(
        "cc_email",
        ticketDetail.cc_email != "NULL" ? ticketDetail.cc_email : ""
      );
      setValueCommentsUpdate("ticket_priority", ticketDetail.ticket_priority);
      setValueCommentsUpdate("ticket_status", ticketDetail.status);
      setValueCommentsUpdate("ticket_department", ticketDetail.department_id);
      setValueCommentsUpdate("assign_user", ticketDetail.ticket_assignto);
      setValueCommentsUpdate('predefined_replies', ticketData.predefined_replies_data)
      // Parse the date string into a Date object
      const parsedDate = new Date();
      // Set the parsed date as the value for the date picker
      setValueCommentsUpdate("schedule_date", parsedDate);
    }
  }, [ticketDetail]);

  const CustomUploadAdapterPlugin = (editor) => {
    if (editor && editor.plugins && editor.plugins.get("FileRepository")) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return {
          upload: async () => {
            const file = await loader.file;
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => {
                resolve({ default: reader.result });
              };
              reader.onerror = reject;
              reader.readAsDataURL(file);
            });
          },
        };
      };
    }
  };



  const fetchPreDefinedReplies = async () => {
    try {
      const response = await axios.get(
        `/apiV1/supports/get_predefined_replies`
      );
      const response_data = response.data;
      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        setFetchDefinedReplies(itemsArray);
      } else if (response.status === 204) {
        setFetchDefinedReplies([]);
      } else {
        triggerAlert("error", "Oops...", "Couldn't get the ticket comments.");
      }
    } catch (error) {
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const handleCommentEditorChange = (event, editor) => {
    const data = editor.getData();
    if (data) {
      clearCommentsErrors("editorCommentContent");
    }
    setEditorContent(data);
    //setValueCommentsUpdate('editorCommentContent', data);
  };

  const handlePreReplies = (e) => {
    const id = e.target.value;
    const reply = fetchDefinedReplies.find(
      (reply) => reply.predefined_id == id
    );
    if (reply && reply.predefined_message) {
      setEditorContent(reply.predefined_message);
    }
  };
  const handleDownload = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  };


  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div className="main-content">
          {isLoading ? (
            <div className="loader-overlay text-white">
              <Loader />
            </div>
          ) : (
            ""
          )}
          <div className="page-content">
            <div className="container-fluid">
              {/* start page title */}
              <PageHeader mainHeading={mainHeading} heading={heading} />
              {/* end page title */}
              <div className="row mt-5">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="float-end">
                          <div className="col-md-12 d-flex justify-content-end ">
                            {!pastLoadListData &&
                              (showBtnNotes ? (
                                <div>
                                  <label
                                    className="form-label"
                                    htmlFor="formtabs-view-notes"
                                  >
                                    &nbsp;
                                  </label>
                                  <div className="button-items">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setShowCustNotes(true);
                                        setShowBtnNotes(false);
                                      }}
                                      className="btn btn-primary w-md"
                                    >
                                      View Notes
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <label
                                    className="form-label"
                                    htmlFor="formtabs-view-notes"
                                  >
                                    &nbsp;
                                  </label>
                                  <div className="button-items">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setShowCustNotes(false);
                                        setShowBtnNotes(true);
                                      }}
                                      className="btn btn-primary w-md"
                                    >
                                      Hide Notes
                                    </button>
                                  </div>
                                </div>
                              ))}
                            <div className="ms-2">
                              <label
                                className="form-label"
                                htmlFor="formtabs-back"
                              >
                                &nbsp;
                              </label>
                              <div className="button-items">
                                <button
                                  type="button"
                                  className="btn btn-warning w-md me-md-2"
                                  onClick={() => {
                                    navigate("/support/manage_tickets");
                                  }}
                                >
                                  Back
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <ul
                            className="nav nav-tabs nav-tabs-custom"
                            role="tablist"
                          >
                            <li
                              className="nav-item"
                              onClick={() => setPastLoadListData(false)}
                            >
                              <a
                                className="nav-link active"
                                data-bs-toggle="tab"
                                href="#home1"
                                role="tab"
                              >
                                <span className="d-block d-sm-none">
                                  <i className="fas fa-home"></i>
                                </span>
                                <span className="d-none d-sm-block">
                                  Current Ticket
                                </span>
                              </a>
                            </li>
                            <li
                              className="nav-item"
                              onClick={() => setPastLoadListData(true)}
                            >
                              <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#profile1"
                                role="tab"
                              >
                                <span className="d-block d-sm-none">
                                  <i className="far fa-user"></i>
                                </span>
                                <span className="d-none d-sm-block">
                                  Past Ticket
                                </span>
                              </a>
                            </li>
                          </ul>

                          <div className="tab-content p-3 text-muted">
                            <div
                              className="tab-pane active"
                              id="home1"
                              role="tabpanel"
                            >
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title mb-3">
                                        Ticket Details
                                      </h5>
                                      {ticketDetail && (
                                        <div>
                                          <ul className="list-unstyled mb-0">
                                            <li>
                                              <a
                                                href="javascript:void(0)"
                                                className="py-2 d-block text-muted"
                                              >
                                                <i className="mdi mdi-file-document text-primary me-1"></i>{" "}
                                                Ticket :{" "}
                                                <span>
                                                  {" "}
                                                  {
                                                    ticketDetail.ticket_number
                                                  }{" "}
                                                </span>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0)"
                                                className="py-2 d-block text-muted"
                                              >
                                                <i className="mdi mdi-account text-primary me-1"></i>{" "}
                                                User Name :{" "}
                                                <span>
                                                  {" "}
                                                  {
                                                    ticketDetail.user_name
                                                  }{" "}
                                                </span>{" "}
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0)"
                                                className="py-2 d-block text-muted"
                                              >
                                                <i className="mdi mdi-crown text-primary me-1"></i>{" "}
                                                Category :{" "}
                                                <span>
                                                  {" "}
                                                  {
                                                    ticketDetail.ticket_category
                                                  }{" "}
                                                </span>{" "}
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0)"
                                                className="py-2 d-block text-muted"
                                              >
                                                <i className="mdi mdi-crown text-primary me-1"></i>{" "}
                                                Sub Category :{" "}
                                                <span>
                                                  {" "}
                                                  {
                                                    ticketDetail.ticket_sub_category
                                                  }{" "}
                                                </span>{" "}
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0)"
                                                className="py-2 d-block text-muted"
                                              >
                                                <i className="mdi mdi-flag-outline text-primary me-1"></i>{" "}
                                                Created :{" "}
                                                <span>
                                                  {" "}
                                                  {formattedDateTime(
                                                    ticketDetail.register_date
                                                  )}{" "}
                                                </span>{" "}
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0)"
                                                className="py-2 d-block text-muted"
                                              >
                                                <i className="mdi mdi-file-document text-primary me-1"></i>{" "}
                                                Updated :{" "}
                                                <span>
                                                  {" "}
                                                  {formattedDateTime(
                                                    ticketDetail.updated_date
                                                  )}{" "}
                                                </span>{" "}
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0)"
                                                className="py-2 d-block text-muted"
                                              >
                                                <i className="mdi mdi-check text-primary me-1"></i>{" "}
                                                Assign To :{" "}
                                                <span>
                                                  {" "}
                                                  {
                                                    ticketDetail.assigned_user
                                                  }{" "}
                                                </span>{" "}
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0)"
                                                className="py-2 d-block text-muted"
                                              >
                                                <i className="mdi mdi-check text-primary me-1"></i>{" "}
                                                Status :{" "}
                                                <span>
                                                  {" "}
                                                  {
                                                    ticketDetail.ticket_status_value
                                                  }{" "}
                                                </span>{" "}
                                              </a>
                                            </li>
                                            {/* <li>
                                              <a
                                                href="javascript:void(0)"
                                                className="py-2 d-block text-muted"
                                              >
                                                {" "}
                                                Customer TimeZone :{" "}
                                                <span>
                                                  {" "}
                                                  {
                                                    ticketDetail.user_time_zone
                                                  }{" "}
                                                  - {ticketDetail.user_time}{" "}
                                                </span>{" "}
                                              </a>
                                            </li> */}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {/* <div className='col-md-3'>
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <h5 className="card-title mb-3">Host Name Details</h5>
                                                                            <div>
                                                                                <ul className="list-unstyled mb-0">
                                                                                    <li>
                                                                                        <a href="javascript:void(0)" className="py-2 d-block text-muted"><i className="mdi mdi-file-document text-primary me-1"></i> Host Name : <span> vgroup </span></a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="javascript:void(0)" className="py-2 d-block text-muted"><i className="mdi mdi-account text-primary me-1"></i> IP Address : <span> 38.143.106.101 </span> </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="javascript:void(0)" className="py-2 d-block text-muted"><i className="mdi mdi-crown text-primary me-1"></i> Admin Link : <span> Open </span> </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a href="javascript:void(0)" className="py-2 d-block text-muted"><i className="mdi mdi-flag-outline text-primary me-1"></i> Portal Access  : <span> Open </span> </a>
                                                                                    </li>
                                                                                    <h5 className="card-title mb-3">Sister Companies</h5>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                <div className="col-md-9">
                                  {showCustNotes ? (
                                    <div className="card">
                                      <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                          <h5 className="card-title mb-3">
                                            Customer Journey Notes
                                          </h5>
                                        </div>
                                        <div
                                          className="row"
                                          style={{
                                            height: "230px",
                                            overflowY: "scroll",
                                          }}
                                        >
                                          {customerNotes &&
                                            customerNotes.map((note) => (
                                              <div
                                                className="col-md-12 mb-2 pt-3"
                                                style={{
                                                  borderBottom: "1px dashed",
                                                }}
                                              >
                                                <div className="text-primary">
                                                  {formattedDateTime(
                                                    note.updated_date
                                                  )}{" "}
                                                  (
                                                  <span className="m-1">
                                                    {note.updated_by}
                                                  </span>
                                                  )
                                                </div>
                                                <div class="comment_div"
                                                  dangerouslySetInnerHTML={{
                                                    __html: note.notes,
                                                  }}
                                                />
                                              </div>
                                            ))}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="card">
                                      <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                          <h5 className="card-title mb-3">
                                            Internal Notes
                                          </h5>
                                          <button
                                            type="button"
                                            className="btn btn-primary w-md"
                                            onClick={() => {
                                              setShowNoteForm(!showNoteForm);
                                            }}
                                          >
                                            Add
                                          </button>
                                        </div>
                                        {showNoteForm && (
                                          <form
                                            onSubmit={handleSubmit(notesSubmit)}
                                          >
                                            <div className="row mt-2">
                                              <div className="col-lg-12">
                                                <div className="card">
                                                  <div className="card-header">
                                                    <h4 className="card-title">
                                                      Add Internal Note{" "}
                                                      <span
                                                        style={{ color: "red" }}
                                                      >
                                                        *
                                                      </span>
                                                    </h4>
                                                  </div>
                                                  <div className="card-body">
                                                    <Controller
                                                      name="editorContent"
                                                      control={control}
                                                      defaultValue=""
                                                      rules={{
                                                        required:
                                                          "Content is required",
                                                      }}
                                                      render={({
                                                        field,
                                                        fieldState,
                                                      }) => (
                                                        <>
                                                          <Editor


                                                            onChange={(content) => {
                                                              field.onChange(content); // Update form value with editor content
                                                              handleEditorChange(content); // Handle editor data change
                                                            }}
                                                            value={field.value} // Ensure the editor value reflects the form value
                                                          />
                                                          <div
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            {
                                                              fieldState?.error
                                                                ?.message
                                                            }
                                                          </div>
                                                        </>
                                                      )}
                                                    />
                                                  </div>
                                                  <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
                                                    <button
                                                      className="btn btn-primary w-md"
                                                      type="submit"
                                                    >
                                                      Submit
                                                    </button>
                                                    <button
                                                      className="btn btn-warning w-md me-md-2"
                                                      type="button"
                                                      onClick={() => {
                                                        setShowNoteForm(false);
                                                      }}
                                                    >
                                                      Cancel
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </form>
                                        )}
                                        <div
                                          className="row"
                                          style={{
                                            height: "351px",
                                            overflowY: "scroll",
                                          }}
                                        >
                                          {ticketNoteList?.length > 0
                                            ? ticketNoteList &&
                                            ticketNoteList.map((note) => (
                                              <div className="col-md-12 mb-2">
                                                <div className="text-primary">
                                                  {formattedDateTime(
                                                    note.date_modified
                                                  )}
                                                  <span className="m-1">
                                                    ({note.updated_by_user})
                                                  </span>
                                                </div>
                                                <div class="comment_div"
                                                  dangerouslySetInnerHTML={{
                                                    __html: note.comments,
                                                  }}
                                                />
                                                {/* <div>{removePTags(note.comments)}</div> */}
                                              </div>
                                            ))
                                            : !showNoteForm && (
                                              <p>No notes yet.</p>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {/* {!showCustNotes && (
                                <div className="row">
                                  <div className="col-md-12 mb-3">
                                    <div className=" d-flex float-end">
                                      <button
                                        type="button"
                                        className="btn btn-primary w-md"
                                        onClick={handleAddComment}
                                      >
                                        Add Comments
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )} */}
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="card">
                                    <div className="card-body">
                                      <ul
                                        className="nav nav-tabs nav-tabs-custom"
                                        role="tablist"
                                      >
                                        <li className="nav-item">
                                          <a
                                            className="nav-link active"
                                            data-bs-toggle="tab"
                                            href="#description"
                                            role="tab"
                                          >
                                            <span className="d-block d-sm-none">
                                              <i className="fas fa-home"></i>
                                            </span>
                                            <span className="d-none d-sm-block">
                                              Problem Description
                                            </span>
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-bs-toggle="tab"
                                            href="#documents"
                                            role="tab"
                                          >
                                            <span className="d-block d-sm-none">
                                              <i className="far fa-user"></i>
                                            </span>
                                            <span className="d-none d-sm-block">
                                              Documents
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                      <div className="tab-content p-3 text-muted">
                                        <div
                                          className="tab-pane active"
                                          id="description"
                                          role="tabpanel"
                                        >
                                          <div class="comment_div"
                                            dangerouslySetInnerHTML={{
                                              __html: ticketData
                                                ? ticketData.ticket_desc
                                                : "",
                                            }}
                                          />
                                          {/* <div>{ticketData ? (ticketData.ticket_desc) : ""}</div> */}
                                          <div className="d-flex justify-content-between align-items-center">
                                            <h5 className="card-title mb-3">
                                              Comments Timeline
                                            </h5>
                                            <button
                                              type="button"
                                              className="btn btn-primary w-md"
                                              onClick={handleAddComment}
                                            >
                                              Add Comments
                                            </button>
                                          </div>
                                          {ticketComments.length > 0 && (
                                            <div
                                              className="row"
                                              style={{
                                                height: "500px",
                                                overflowY: "scroll",
                                              }}
                                            >
                                              <div class="timeline">
                                                <div class="timeline-container">
                                                  <div class="timeline-continue">
                                                    {ticketComments.map(
                                                      (comment, index) => (
                                                        <div className="col-md-12">
                                                          <div class="row timeline-right">
                                                            <div class="col-md-1">
                                                              <div class="timeline-icon">
                                                                {/* <i class="bx bx-briefcase-alt-2 text-primary h2 mb-0"></i> */}
                                                              </div>
                                                            </div>
                                                            <div class="col-md-11">
                                                              <div class="timeline-box">
                                                                <div class="timeline-date bg-primary text-center rounded">
                                                                  <h6 class="text-white mb-1">
                                                                    {" "}
                                                                    Update{" "}
                                                                    {index + 1}
                                                                  </h6>
                                                                  <p class="mb-0 text-white">
                                                                    {(
                                                                      comment.cdate
                                                                    )}
                                                                  </p>
                                                                </div>
                                                                <div class="event-content">
                                                                  <div class="timeline-text">
                                                                    <h3 class="font-size-18">
                                                                      Reply from
                                                                      :{" "}
                                                                      {
                                                                        comment.updated_by
                                                                      }
                                                                    </h3>
                                                                    <h6>
                                                                      CC Email :{" "}
                                                                      {comment.cc_email
                                                                        ? comment.cc_email
                                                                        : "none"}
                                                                    </h6>
                                                                    <p class="mb-0 mt-2 pt-1 text-muted">
                                                                      <div class="comment_div"
                                                                        dangerouslySetInnerHTML={{
                                                                          __html:
                                                                            comment.message,
                                                                        }}
                                                                      />
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        <div
                                          className="tab-pane"
                                          id="documents"
                                          role="tabpanel"
                                        >
                                          <div className="row">
                                            {ticketDocumentList?.length > 0 ? (
                                              ticketDocumentList &&
                                              ticketDocumentList.map(
                                                (document) => (
                                                  <div className="col-md-3">
                                                    <div className="card">
                                                      <div className="card-body">
                                                        <button

                                                          className="btn"
                                                          onClick={() =>
                                                            // e.preventDefault(); // Prevent the default action (redirect)
                                                            handleDownload(document.doc_path_local.new_presigned_url)
                                                          }


                                                        >
                                                          <h6>{document.doc_name}</h6>
                                                          <i className="mdi mdi-download text-primary me-1"></i>
                                                          <span className="text-primary">{document.doc_name
                                                            ?.split("/")
                                                            ?.pop()}</span>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              )
                                            ) : (
                                              <p>No Documents Found.</p>
                                            )}
                                          </div>
                                        </div>
                                        {showCommentForm && (
                                          <div
                                            className="row mt-2"
                                            ref={indexRef}
                                          >
                                            <form
                                              name="commentForm"
                                              onSubmit={handleCommentsUpdate(
                                                commentSubmit
                                              )}
                                            >
                                              <div className="col-lg-12">
                                                <div className="card">
                                                  <div className="card-header">
                                                    <h4 className="card-title">
                                                      Add Comment
                                                    </h4>
                                                  </div>
                                                  <div className="card-body">
                                                    <div className="col-md-8 mb-2">
                                                      <label className="form-label">
                                                        CC Email{" "}
                                                      </label>
                                                      <br></br>
                                                      <textarea
                                                        className="form-control"
                                                        {...registerComments(
                                                          "cc_email",
                                                          {
                                                            required:"CC Email is required",
                                                            validate:
                                                              validateEmailList,
                                                          }
                                                        )}
                                                      >
                                                        {/* {ticketDetail.cc_email != "NULL" ? ticketDetail.cc_email : ""} */}
                                                      </textarea>
                                                      {errorsCommentsUpdate.cc_email && (
                                                        <p
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        >
                                                          {
                                                            errorsCommentsUpdate
                                                              .cc_email.message
                                                          }
                                                        </p>
                                                      )}
                                                    </div>
                                                    <div className="row mt-3 mb-3">
                                                      <div className="col-md-3" style={{ position: 'relative', zIndex: 100 }}>
                                                        <label className="form-label">
                                                          Predefined Replies
                                                        </label>
                                                        <select
                                                          className="form-select"
                                                          aria-label="Default select example"
                                                          {...registerComments(
                                                            "predefined_replies"
                                                          )}
                                                          onChange={(e) =>
                                                            handlePreReplies(e)
                                                          }
                                                        >
                                                          <option value="">
                                                            Predefined Replies
                                                          </option>
                                                          {fetchDefinedReplies &&
                                                            fetchDefinedReplies.map(
                                                              (reply) => (
                                                                <option
                                                                  value={
                                                                    reply.predefined_id
                                                                  }
                                                                >

                                                                  {
                                                                    reply.predefined_subject
                                                                  }{" "}
                                                                  (
                                                                  {
                                                                    reply.username
                                                                  }
                                                                  )
                                                                </option>
                                                              )
                                                            )}
                                                        </select>
                                                      </div>
                                                      <div className="col-md-3" style={{ position: 'relative', zIndex: 100 }}>
                                                        <label className="form-label">
                                                          Ticket Priority{" "}
                                                          <span
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            *
                                                          </span>
                                                        </label>
                                                        <select
                                                          className="form-select"
                                                          aria-label="Default select example"
                                                          {...registerComments(
                                                            "ticket_priority",
                                                            {
                                                              required:
                                                                "Ticket Priority is required",
                                                            }
                                                          )}
                                                        >
                                                          <option value="">
                                                            Select Ticket
                                                            Priority
                                                          </option>
                                                          {ticketPriorityList &&
                                                            ticketPriorityList.map(
                                                              (
                                                                priority,
                                                                index
                                                              ) => (
                                                                <option
                                                                  key={index}
                                                                  value={
                                                                    priority.priority_id
                                                                  }
                                                                // selected={ticketDetail.ticket_priority == priority.priority_id}
                                                                >
                                                                  {
                                                                    priority.name
                                                                  }
                                                                </option>
                                                              )
                                                            )}
                                                        </select>
                                                        {errorsCommentsUpdate.ticket_priority && (
                                                          <p
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            {
                                                              errorsCommentsUpdate
                                                                .ticket_priority
                                                                .message
                                                            }
                                                          </p>
                                                        )}
                                                      </div>
                                                      <div className="col-md-3" style={{ position: 'relative', zIndex: 100 }}>
                                                        <label className="form-label">
                                                          Ticket Status{" "}
                                                          <span style={{ color: "red" }}>*</span>
                                                        </label>
                                                        <select
                                                          className="form-select"
                                                          aria-label="Default select example"
                                                          {...registerComments("ticket_status", {
                                                            required: "Ticket Status is required",
                                                          })}
                                                        >
                                                          <option value="">Select Ticket Status</option>
                                                          {ticketStatusList &&
                                                            ticketStatusList.map((status, index) => (
                                                              <option
                                                                key={index}
                                                                value={status.status_id}
                                                                // Use conditional rendering to change the display text
                                                                // based on the value of status.name
                                                                // Here, if status.name is "Pending", display "In Progress" instead
                                                                // Otherwise, display status.name as is
                                                                selected={ticketDetail.status == status.status_id}
                                                              >
                                                                {status.name === "Pending" ? "In progress" : status.name}
                                                              </option>
                                                            ))}
                                                        </select>
                                                        {errorsCommentsUpdate.ticket_status && (
                                                          <p style={{ color: "red" }}>
                                                            {errorsCommentsUpdate.ticket_status.message}
                                                          </p>
                                                        )}
                                                      </div>

                                                      {watchComments(
                                                        "ticket_status"
                                                      ) == 2 && (
                                                          <div className="col-md-3" style={{ position: 'relative', zIndex: 100, display: 'none' }}>
                                                            <label className="form-label">
                                                              Schedule Date and
                                                              Time
                                                            </label>
                                                            <Controller
                                                              control={
                                                                controlCommentsUpdate
                                                              }
                                                              name="schedule_date" // Assign the name here
                                                              render={({
                                                                field,
                                                              }) => (
                                                                <DatePicker
                                                                  selected={
                                                                    field.value
                                                                      ? new Date(
                                                                        field.value
                                                                      )
                                                                      : new Date()
                                                                  }
                                                                  // selected={field.value ? field.value : null}
                                                                  onChange={(
                                                                    date
                                                                  ) => {
                                                                    setValueCommentsUpdate(
                                                                      "schedule_date",
                                                                      date
                                                                    );
                                                                    field.onChange(
                                                                      date
                                                                        ? date
                                                                        : null
                                                                    );
                                                                    // setSelectedDate(date);

                                                                  }}
                                                                  showTimeSelect
                                                                  timeFormat="HH:mm"
                                                                  timeIntervals={
                                                                    15
                                                                  }
                                                                  dateFormat="MM/dd/yyyy HH:mm"
                                                                  // dateFormat="MMMM d, yyyy h:mm aa"
                                                                  placeholderText="MM/DD/YYYY HH:MM"
                                                                  className="px-3 form-control"
                                                                  minDate={
                                                                    new Date()
                                                                  }
                                                                // showMonthDropdown showYearDropdown
                                                                />
                                                              )}
                                                            />
                                                            {errorsCommentsUpdate.schedule_date && (
                                                              <p
                                                                style={{
                                                                  color: "red",
                                                                }}
                                                              >
                                                                {
                                                                  errorsCommentsUpdate
                                                                    .schedule_date
                                                                    .message
                                                                }
                                                              </p>
                                                            )}
                                                          </div>
                                                        )}

                                                      <div className="col-md-3" style={{ position: 'relative', zIndex: 100 }}>
                                                        <label className="form-label">
                                                          Select Department{" "}
                                                          <span
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            *
                                                          </span>
                                                        </label>
                                                        <select
                                                          className="form-select"
                                                          aria-label="Default select example"
                                                          {...registerComments(
                                                            "ticket_department",
                                                            {
                                                              required:
                                                                "Department is required",
                                                            }
                                                          )}
                                                          onChange={(e) => {
                                                            clearCommentsErrors(
                                                              "ticket_department"
                                                            );
                                                            setValueCommentsUpdate(
                                                              "ticket_department",
                                                              e.target.value
                                                            );
                                                            updateAssigntList(
                                                              e
                                                            );
                                                            setValueCommentsUpdate(
                                                              "assign_user",
                                                              ""
                                                            );
                                                          }}
                                                        >
                                                          <option value="">
                                                            Select Department
                                                          </option>
                                                          {departmentList &&
                                                            departmentList.map(
                                                              (
                                                                department,
                                                                index
                                                              ) => (
                                                                <option
                                                                  key={index}
                                                                  value={
                                                                    department.id
                                                                  }
                                                                // selected={ticketDetail.department_id == department.id}
                                                                >
                                                                  {
                                                                    department.name
                                                                  }
                                                                </option>
                                                              )
                                                            )}
                                                        </select>
                                                        {errorsCommentsUpdate.ticket_department && (
                                                          <p
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            {
                                                              errorsCommentsUpdate
                                                                .ticket_department
                                                                .message
                                                            }
                                                          </p>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="row mt-3 mb-3">
                                                      <div className="col-md-3" style={{ position: 'relative', zIndex: 100 }}>
                                                        <label className="form-label">
                                                          Assign To{" "}
                                                          <span
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            *
                                                          </span>
                                                        </label>
                                                        <select
                                                          className="form-select"
                                                          aria-label="Default select example"
                                                          {...registerComments(
                                                            "assign_user",
                                                            {
                                                              required:
                                                                "Assign User is required",
                                                            }
                                                          )}
                                                        >
                                                          <option value="">
                                                            Select User
                                                          </option>
                                                          {assignUserList &&
                                                            assignUserList.map(
                                                              (
                                                                assignUser,
                                                                index
                                                              ) => (
                                                                <option
                                                                  key={index}
                                                                  value={
                                                                    assignUser.id
                                                                  }
                                                                // selected={ticketDetail.ticket_assignto == assignUser.id}
                                                                >
                                                                  {
                                                                    assignUser.username
                                                                  }
                                                                </option>
                                                              )
                                                            )}
                                                        </select>
                                                        {errorsCommentsUpdate.assign_user && (
                                                          <p
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            {
                                                              errorsCommentsUpdate
                                                                .assign_user
                                                                .message
                                                            }
                                                          </p>
                                                        )}
                                                      </div>
                                                      <div className="col-md-3" style={{ position: 'relative', zIndex: 100 }}>
                                                        <label className="form-label">
                                                          Escalation Process{" "}
                                                        </label>
                                                        <select
                                                          className="form-select"
                                                          aria-label="Default select example"
                                                          {...registerComments(
                                                            "escalation_process",{
                                                               required:"Ecalation Process is required",
                                                          }
                                                          )}
                                                        >
                                                          <option value="">
                                                            Select Escalation
                                                            Process
                                                          </option>
                                                          <option value="Escalated To">
                                                            Escalated To
                                                          </option>
                                                          <option value="Instrumental Help">
                                                            Instrumental Help
                                                          </option>
                                                          <option value="Worked On">
                                                            Worked On
                                                          </option>
                                                          <option value="ReAssigned To">
                                                            ReAssigned To
                                                          </option>
                                                        </select>
                                                        {errorsCommentsUpdate.escalation_process && (
                                                          <p style={{color: "red"}}>{errorsCommentsUpdate.escalation_process.message}</p>
                                                        )}
                                                      </div>
                                                      {/* <div className="col-md-3" style={{ position: 'relative', zIndex: 100 }}>
                                                        <label className="form-label">
                                                          Help Documents
                                                        </label>
                                                        <Select
                                                          isMulti
                                                          options={
                                                            helpDocumentList
                                                          }
                                                          {...registerComments(
                                                            "help_documents"
                                                          )}
                                                          onChange={(
                                                            selectedOptions
                                                          ) => {
                                                            // Set the value in the form state when the selection changes
                                                            setValueCommentsUpdate(
                                                              "help_documents",
                                                              selectedOptions
                                                            );
                                                          }}
                                                        />
                                                      </div> */}
                                                    </div>
                                                    <div className="row mt-3 mb-3">
                                                      {[0, 1, 2, 3].map(
                                                        (index) => (
                                                          <div
                                                            key={index}
                                                            className="col-md-3"
                                                          >
                                                            <label
                                                              className="form-label"
                                                              htmlFor={`formFile-${index}`}
                                                            >
                                                              Upload
                                                            </label>
                                                            <input
                                                              className="form-control mb-1"
                                                              type="file"
                                                              id={`formFile-${index}`}
                                                              accept=".docx, .pdf, .jpg, .jpeg, .png, .csv, .txt, .xlsx, .mp4, .mov, .avi, .mkv, .wmv, .flv, .webm, .m4v, .3gp, .ogg"
                                                              {...registerComments(
                                                                `file${index}`,
                                                                {
                                                                  validate: {
                                                                    validFormat:
                                                                      (
                                                                        value
                                                                      ) => {
                                                                        if (
                                                                          value[0]
                                                                            ?.name
                                                                        ) {
                                                                          const allowedExtensions =
                                                                            /\.(docx?|pdf|jpe?g|png|csv|txt|xlsx|mp4|mov|avi|mkv|wmv|flv|webm|m4v|3gp|ogg)$/i;
                                                                          if (
                                                                            !value
                                                                          )
                                                                            return true; // Allow empty files
                                                                          if (
                                                                            !allowedExtensions.test(
                                                                              value[0]
                                                                                ?.name
                                                                            )
                                                                          ) {
                                                                            return "Invalid file format. Please upload a valid file.";
                                                                          }
                                                                        }
                                                                        return true;
                                                                      },
                                                                    validSize:
                                                                      validateFileSize,
                                                                  },
                                                                }
                                                              )}
                                                              onChange={(e) =>
                                                                handleFileChange(
                                                                  e,
                                                                  index
                                                                )
                                                              }
                                                            />
                                                            {errorsCommentsUpdate[
                                                              `file${index}`
                                                            ] && (
                                                                <div
                                                                  style={{
                                                                    color: "red",
                                                                    fontSize:
                                                                      "14px",
                                                                    marginTop:
                                                                      "5px",
                                                                  }}
                                                                >
                                                                  {
                                                                    errorsCommentsUpdate[
                                                                      `file${index}`
                                                                    ].message
                                                                  }
                                                                </div>
                                                              )}
                                                            {/* Additional logic can be added here to display file information or errors */}
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                    <div className="col-md-12">
                                                      <label class="form-label" for="formrow-lname-input"> Ticket Description <span style={{ color: 'red' }}>*</span></label>
                                                      <Controller
                                                        name="editorCommentContent"
                                                        control={controlCommentsUpdate}
                                                        rules={{ required: 'Content is required' }}
                                                        render={({ field, fieldState }) => (
                                                          <>
                                                            <Editor

                                                              onChange={(content) => {
                                                                setEditorContent(content); // Update editor content state
                                                                field.onChange(content); // Update form field value
                                                              }}
                                                              value={editorContent} // Ensure the editor value reflects the editorContent state
                                                              setContents={editorContent} // Set initial content and update it based on selection
                                                            />
                                                            <div style={{ color: 'red' }}>{fieldState?.error?.message}</div>
                                                          </>
                                                        )}
                                                      />


                                                    </div>
                                                  </div>
                                                  <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
                                                    <button
                                                      className="btn btn-warning w-md me-md-2"
                                                      type="button"
                                                      onClick={() => {
                                                        setShowCommentForm(
                                                          false
                                                        );
                                                      }}
                                                    >
                                                      Cancel
                                                    </button>
                                                    <button
                                                      className="btn btn-primary w-md"
                                                      id="submit"
                                                      type="submit"
                                                    >
                                                      Reply To Message
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </form>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane"
                              id="profile1"
                              role="tabpanel"
                            >
                              <div className="card">
                                {isLoading ? (
                                  <div className="loader-overlay text-white">
                                    <Loader />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {/* <div className="card-header">
                                                                    <h4 className="card-title">Table Border color</h4>
                                                                    <p className="card-title-desc">Add <code>.table-bordered</code> & <code>.border-*</code> for
                                                                        colored borders on all sides of the table and cells.</p>
                                                                </div> */}
                                <div className="table-responsive">
                                  {!isLoading ?
                                    <DataTable
                                      columns={columns}
                                      data={listData}
                                      pagination
                                      paginationServer
                                      paginationTotalRows={totalRows}
                                      paginationComponentOptions={paginationComponentOptions}
                                      paginationPerPage={perPage}
                                      paginationDefaultPage={currentPage}
                                      onChangeRowsPerPage={handlePerRowsChange}
                                      onChangePage={handlePageChange}
                                    /> :
                                    <div className='loader-overlay text-white'>
                                      <Loader />
                                    </div>
                                  }

                                </div>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- end col --> */}
              </div>
            </div>
          </div>
          {/* End Page-content */}
          <Footer />
        </div>
        {/* end main content*/}
      </div>
      {/* END layout-wrapper */}
      {/* Right bar overlay*/}
      <div className="rightbar-overlay"></div>
    </div>
  );
};

export default View_tickets;
