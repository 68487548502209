import React from 'react'

export default function PageHeader({ mainHeading, heading }) {
  return (
    <div className="row">
    <div className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">{mainHeading}</h4>

            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">{heading.split('/')[0]}</li>
                    <li className="breadcrumb-item active">{heading.split('/')[1]}</li>
                    {/* <li className="breadcrumb-item active">{heading.split('/')[2]}</li> */}
                </ol>
            </div>

        </div>
    </div>
</div>
  )
}
