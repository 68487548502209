import Header from "../../common/Header";
import Sidebar from "../../common/Sidebar";
import Footer from "../../common/Footer";
import Loader from "../../common/Loader";

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { triggerAlert, pageReload, getToken, handleTableRowClick, get_user_menu_permission } from '../../utils';

import Table from 'react-bootstrap/Table';
import AutoServiceSearch from "../AutoServiceSearch";
import Pagination from "../PaginationComponent";
import Swal from "sweetalert2";
import { menuIdData } from "../../utils/constants";

function ManageService() {

	const api_url = process.env.REACT_APP_API_BASE_URL;
	const show50Entries = process.env.REACT_APP_API_SHOW_ENTRIES;
	const token = getToken();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState([]);
	const [serviceData, setServiceData] = useState([]);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedRowId, setSelectedRowId] = useState(null);
	const [items, setItems] = useState([]);

	const [currentPage, setCurrentPage] = useState(0);
	const [pageCount, setPageCount] = useState(0);
	const [pageSlNo, setPageSlNo] = useState(0);
	const [perPageLimit, setPerPageLimit] = useState(show50Entries);
	const [pageLimitSelected, setPageLimitSelected] = useState(50);

	const [sectionVisible, setSectionVisible] = useState({
		sectionA: true,
		sectionB: false,
		sectionC: false,
	});

	// Menu Permissions
	const [addPermission, setAddPermission] = useState(false);
	const [editPermission, setEditPermission] = useState(false);
	const [deletePermission, setDeletePermission] = useState(false);
	const [exportPermission, setExportPermission] = useState(false);

	const { control, register, handleSubmit, formState: { errors }, setValue, reset, watch, getValues } = useForm();
	const { control: control2, register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, setValue: setValue2, reset: reset2 } = useForm();

	const toggleSection = (section) => {
		setSectionVisible((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	//onsubmit
	const serviceSubmit = async (data) => {
		//console.log(data);
		try {
			const response = await axios.post(api_url + 'customer/create_service', data, token);
			const response_data = response.data;
			if (response_data.error_code == "200") {
				triggerAlert('success', 'success', 'Service Created Successfully');
				pageReload();
				//navigate('/customers/customer_list');
			} else {
				setIsLoading(false);
				triggerAlert('error', 'Oops...', 'Invalid Login..');
			}
		} catch (error) {
			//console.log(error);
			setIsLoading(false);
			triggerAlert('error', 'Error', error.response.data.message);
		}
	};

	//access edit form
	const editService = () => {
		//console.log(selectedRow);
		if (selectedRowId) {
			setValue2('service_name', selectedRow.service_name);
			setValue2('service_status', selectedRow.service_status);
			setValue2('service_charge', selectedRow.service_charge);
			setValue2('buy_rate', selectedRow.buy_rate);
			setValue2('dip', selectedRow.dip);
			setValue2('setup_fee', selectedRow.setup_fee);
			setValue2('service_description', selectedRow.service_description);
			setValue2('service_type', selectedRow.service_type);

			toggleSection('sectionA');
			toggleSection('sectionC');
		} else {
			triggerAlert('error', 'Oops...', 'Please select a row');
		}
	};

	//update data
	const UpdateService = async (data) => {
		try {
			//console.log(data);
			var id = selectedRowId;
			const response = await axios.put(api_url + 'customer/update_service/' + id + '/update', data, token);
			if (response.status == "200") {
				triggerAlert('success', 'success', 'Service Updated successfully');
				pageReload();
			} else {
				triggerAlert('error', 'Oops...', 'Something went wrong..');
			}

		} catch (error) {
			triggerAlert('error', 'Oops...', 'Something went wrong..');
		}
	}

	//search by keyword
	const searchByKeyword = async (keyword) => {
		try {
			const response = await axios.get(api_url + `customer/gettotalservices?service_status=1&page_number=1&page_size=25&keyword=${keyword}`, token);
			const respdata = response.data.results.data;
			setServiceData(respdata);

			setIsLoading(false);
		} catch (error) {
			triggerAlert('error', '', 'No data available');
		}
	}

	const handleServiceSelect = (userData) => {
		//console.log(userData);
		setPageCount(0);
		setCurrentPage(0);
		setPerPageLimit(show50Entries);
		setPageLimitSelected(50);
		searchByKeyword(userData.service_name);
	};

	{/* Service Deletion */ }
	const deleteService = () => {
		//alert(selectedRowId);
		if (selectedRowId) {
			////////////////////////////////////////
			//console.log(selectedRowId);
			Swal.fire({
				icon: 'warning',
				title: 'Do you want to delete data?',
				showCancelButton: true,
				confirmButtonText: 'Delete',
			}).then(async (result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					var id = selectedRowId;
					var data = { service_status: 0 };
					try {
						const response = await axios.put(api_url + '/customer/update_service/' + id + '/update', data, token);
						const response_data = response.data;
						//console.log(message);
						if (response_data.error_code == "200") {
							triggerAlert('success', 'success', response_data.message);
						} else {
							triggerAlert('error', 'Oops...', 'Something went wrong..');
						}
						pageReload();
					} catch (error) {
						triggerAlert('error', 'Oops...', 'Something went wrong..');
					}
				}
			})

		} else {
			triggerAlert('error', 'Oops...', 'Please select a row');
		}
	};


	//default onload
	const fetchServiceData = async (page, searchkey) => {
		setIsLoading(true);
		const per_page = perPageLimit;
		const searchQuery = searchkey;
		try {
			let apiUrl = `${api_url}customer/gettotalservices`;
			let params = `?page_size=${per_page || 50}&page_number=${page || 1}&service_status=1`;
			if (searchQuery) params += `&keyword=${searchQuery}`;
			const response = await axios.get(apiUrl + params, token);
			//const response 		= await axios.get(api_url+`customer/gettotalservices?service_status=1&page_number=${page}&page_size=${per_page}`, token);
			const response_data = response.data.results.data;
			const total_pages = response.data.results.pagination.total_pages;
			setServiceData(response_data);
			setPageCount(total_pages);
			setIsLoading(false);
		} catch (error) {
			triggerAlert('error', 'Oops...', 'Something went wrong..');
		}
	}

	useEffect(() => {
		// let postData={
		//     //page:currentPage+1,
		//     //per_page:perPageLimit
		//     page:1,
		//     per_page:10
		// }
		setCurrentPage(0);
		fetchServiceData(currentPage + 1);
	}, [perPageLimit]);

	//pagination
	const handlePageClick = (selected) => {
		const selectedPage = selected.selected;
		setCurrentPage(selectedPage);
		fetchServiceData(selectedPage + 1);
	};

	let props = {
		pageCount: pageCount,
		handlePageClick: handlePageClick,
		selectedPage: currentPage
	}

	useEffect(() => {
		const menu_id = menuIdData?.manage_service;
		setAddPermission(get_user_menu_permission(menu_id, 'add'));
		setEditPermission(get_user_menu_permission(menu_id, 'edit'));
		setDeletePermission(get_user_menu_permission(menu_id, 'delete'));
		// setExportPermission(get_user_menu_permission(menu_id, 'export'));
	}, []);


	return (
		<>
			<div id="layout-wrapper">
				<Header />
				<Sidebar />
				{/* ============================================================== */}
				{/* Start right Content here */}
				{/* ============================================================== */}
				<div className="main-content">
					<div className="page-content">

						{/* Manage Service  */}
						<div hidden={!sectionVisible.sectionA} className="container-fluid">
							{/* start page title */}
							<div className="row">
								<div className="col-12">
									<div className="page-title-box d-sm-flex align-items-center justify-content-between">
										<h4 className="mb-sm-0 font-size-18">Manage Service</h4>
										<div className="page-title-right">
											<ol className="breadcrumb m-0">
												{/* <li className="breadcrumb-item"><a href="javascript: void(0);">Dashboard</a></li> */}
												<li className="breadcrumb-item "><a href="#">Customers</a></li>
												<li className="breadcrumb-item active"> Manage Service </li>
											</ol>
										</div>
									</div>
								</div>
							</div>
							{/* end page title */}

							<div className="row mb-4	">
								<div className="col-12">
									<div className="card">
										<div className="card-header">
											<div className="float-start col-md-2">
												<label>Select Service</label>
												<AutoServiceSearch onSelect={handleServiceSelect} />
											</div>
											{(addPermission || editPermission || deletePermission) &&
												<div class="btn-group   mb-3 float-end">
													<button type="button" class="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														Action <i class="mdi mdi-chevron-down"></i>
													</button>
													<div class="dropdown-menu dropdown-menu-end py-2">
														{addPermission && <a class="dropdown-item" onClick={() => { toggleSection('sectionA'); toggleSection('sectionB') }}><i class="bx bx-wrench"></i> Create Service</a>}
														{editPermission && <a class="dropdown-item" onClick={editService}><i class="far fa-edit font-size-14"></i> Edit</a>}
														{deletePermission && <a class="dropdown-item" onClick={deleteService}><i class="bx bx-trash"></i> Inactive</a>}

													</div>
												</div>
											}
										</div>
										<div className="card-body">
											<Table id="datatable" className="nowrap w-100 dt-responsive table-responsive table-bordered">
												<thead style={{ backgroundColor: '#ededed' }}>
													<tr>
														<th>#</th>
														<th>Service Name</th>
														<th>Rate</th>
														<th>Buy Rate</th>
														<th>Setup Fee</th>
														<th>Dip</th>
														<th>Status</th>
													</tr>
												</thead>
												{isLoading ? (
													<div className='loader-overlay text-white'>
														<Loader />
													</div>
												) : (
													<tbody>
														{serviceData.length > 0 ? (
															serviceData.map((item, index) => (
																<tr onClick={() => handleTableRowClick(item, selectedRow, setSelectedRow, selectedRowId, setSelectedRowId, item.list_id)} className={selectedRowId === item.list_id ? 'row_selected' : ''} >
																	{/* <td>{pageSlNo + index + 1}</td> */}
																	<th scope="row">{(currentPage) * pageLimitSelected + index + 1}</th>
																	<td>{(item.service_type === 'International' && item.service_name !== 'International') ? item.service_type + ' - ' : null} {item.service_name}</td>
																	<td>$ {item.service_charge}</td>
																	<td>$ {item.buy_rate}</td>
																	<td>$ {item.setup_fee}</td>
																	<td>$ {item.dip}</td>
																	<td>{item.service_status === 1 ? (
																		<span>Active</span>
																	) : (
																		<span>Inactive</span>
																	)}</td>

																</tr>
															))
														) : (
															<tr>
																<td colSpan="6" className="text-center">No data available</td>
															</tr>
														)}
													</tbody>
												)}
											</Table>
											<hr />
											<Pagination {...props} />
										</div>
									</div>
								</div> {/* end col */}
							</div> {/* end row */}
						</div> {/* container-fluid */}
						{/* Manage Service  */}

						{/* Create Service */}
						<div hidden={!sectionVisible.sectionB} className="container-fluid">
							{/* start page title */}
							<div className="row">
								<div className="col-12">
									<div className="page-title-box d-sm-flex align-items-center justify-content-between">
										<h4 className="mb-sm-0 font-size-18">Create Service</h4>

										<div className="page-title-right">
											<ol className="breadcrumb m-0">
												<li className="breadcrumb-item "><a href="#">Customers</a></li>
												<li className="breadcrumb-item "><a href="#">Manage Service</a></li>
												<li className="breadcrumb-item active">Create Service</li>
											</ol>
										</div>

									</div>
									<div className="float-end">
										<button class="btn btn-warning float-right" type="button" onClick={() => { toggleSection('sectionA'); toggleSection('sectionB') }}>Back</button>
									</div>
								</div>
							</div>
							{/* end page title */}
							<div className="row mt-4">
								<div className="col-lg-12">
									<div className="card">
										<div className="card-body p-4">
											<div className="row">
												<div className="col-lg-12">
													<div>
														<form id="creditCardForm" key={2} novalidate="novalidate" enctype="multipart/form-data" onSubmit={handleSubmit(serviceSubmit)}>
															<div className="row">
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-servicename-input">Service Name <span style={{ color: 'red' }}>*</span></label>
																		{/* <input type="text" className="form-control" id="formrow-servicename-input" placeholder="Enter your First Service Name"/> */}
																		<input type="text" name="service_name" id="formrow-servicename-input" class="form-control" placeholder="Enter your Service Name" {...register('service_name', {
																			required: 'Service Name is required',
																			pattern: {
																				value: /^[a-zA-Z0-9]*[\w -]*[a-zA-Z0-9]$/,
																				message: 'Service Name should have only alphabets',
																			},
																		})} />
																		{errors.service_name && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.service_name.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-Rate-input">Service Charge ( MRC ) <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="service_charge" id="formrow-servicename-input" maxLength={5} class="form-control" placeholder="Enter your Rate" {...register('service_charge', {
																			required: 'Service Charge is required',
																			pattern: {
																				value: /^\d+(\.\d{1,4})?$/, // Adjust the regex pattern to allow up to 4 decimal points
																				message: 'Service Charge ( MRC ) should have only numbers and up to 4 decimal points.',
																			},
																			maxLength: {
																				value: 5,
																				message: 'Max Length Exceeded',
																			},
																			//validate: (value) => parseFloat(value) >= parseFloat(getValues('dip')) || 'Service Charge should not be less than DIP',
																		})} />
																		{errors.service_charge && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.service_charge.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-Rate-input">DIP <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="dip" id="formrow-servicename-input" maxLength={5} class="form-control" placeholder="Enter your Rate" {...register('dip', {
																			required: 'DIP is required',
																			pattern: {
																				value: /^(\d*\.{0,1}\d{0,2}$)/,
																				message: 'DIP should have only numbers',
																			},
																			maxLength: {
																				value: 5,
																				message: 'Max Length Exceeded',
																			},
																			//validate: (value) => parseFloat(value) >= parseFloat(getValues('dip')) || 'Service Charge should not be less than DIP',
																		})} />
																		{errors.dip && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.dip.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-Rate-input">Buy Rate <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="buy_rate" id="formrow-servicename-input" maxLength={5} class="form-control" placeholder="Enter your Rate" {...register('buy_rate', {
																			required: 'Buy Rate is required',
																			pattern: {
																				value: /^\d+(\.\d{1,4})?$/, // Adjust the regex pattern to allow up to 4 decimal points
																				message: 'Buy Rate should have only numbers and up to 4 decimal points.',
																			},
																			maxLength: {
																				value: 5,
																				message: 'Max Length Exceeded',
																			},
																		})} />
																		{errors.buy_rate && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.buy_rate.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-Rate-input">Setup Fee <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="setup_fee" id="formrow-servicename-input" maxLength={5} class="form-control" placeholder="Enter Setup Fee" {...register('setup_fee', {
																			required: 'Setup Fee is required',
																			pattern: {
																				value: /^\d+(\.\d{1,4})?$/, // Adjust the regex pattern to allow up to 4 decimal points
																				message: 'Setup Fee should have only numbers and up to 4 decimal points.',
																			},
																			maxLength: {
																				value: 5,
																				message: 'Max Length Exceeded',
																			},
																		})} />
																		{errors.setup_fee && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.setup_fee.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-Rate-input">Service Status <span style={{ color: 'red' }}>*</span></label>
																		<select className="form-select" name="service_status" {...register('service_status', { required: "Service Status is required" })}>
																			<option value="">Select</option>
																			<option value="1">Active</option>
																			<option value="0">Inactive</option>
																		</select>
																		{errors.service_status && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.service_status.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-Rate-input">Service Type <span style={{ color: 'red' }}>*</span></label>
																		<select className="form-select" name="service_type" {...register('service_type', { required: "Service Type is required" })}>
																			<option value="">Select</option>
																			<option value="International">International</option>
																			<option value="Origination">Origination</option>
																			<option value="SMS/MMS">SMS/MMS</option>
																			<option value="Other">Other</option>
																			<option value="Termination">Termination</option>
																		</select>
																		{errors.service_type && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.service_type.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="service_description">Unit Type
																			{/* <span style={{ color: 'red' }}>*</span> */}
																		</label>
																		<input type="text" name="service_description" id="formrow-servicename-input" maxLength={50} class="form-control" placeholder="Enter Unit Type" {...register('service_description', {
																			// required: ' Unit Type is required',
																		})} />
																		{errors.service_description && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.service_description.message}</div>}
																	</div>
																</div>
															</div>
															<div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
																<button class="btn btn-warning w-md" type="button" onClick={() => { toggleSection('sectionA'); toggleSection('sectionB') }}>Cancel</button>
																<button class="btn btn-primary w-md me-md-2" type="submit">Create</button>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div> {/* container-fluid */}
						{/* Create Service */}

						{/* Edit Service */}
						<div hidden={!sectionVisible.sectionC} className="container-fluid">
							{/* start page title */}
							<div className="row">
								<div className="col-12">
									<div className="page-title-box d-sm-flex align-items-center justify-content-between">
										<h4 className="mb-sm-0 font-size-18">Edit Service</h4>

										<div className="page-title-right">
											<ol className="breadcrumb m-0">
												<li className="breadcrumb-item "><a href="#">Customers</a></li>
												<li className="breadcrumb-item "><a href="#">Manage Service</a></li>
												<li className="breadcrumb-item active">Edit Service</li>
											</ol>
										</div>

									</div>
									<div className="float-end">
										<button class="btn btn-warning float-right" type="button" onClick={() => { toggleSection('sectionA'); toggleSection('sectionC') }}>Back</button>
									</div>
								</div>
							</div>
							{/* end page title */}
							<div className="row mt-4">
								<div className="col-lg-12">
									<div className="card">
										<div className="card-body p-4">
											<div className="row">
												<div className="col-lg-12">
													<div>
														<form id="creditCardForm" key={2} novalidate="novalidate" enctype="multipart/form-data" onSubmit={handleSubmit2(UpdateService)}>
															<div className="row">
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-servicename-input">Service Name <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="service_name" id="formrow-servicename-input" disabled={true} class="form-control" placeholder="Enter your Service Name" {...register2('service_name', {
																			required: 'Service Name is required',
																			// pattern: {
																			// 	value: /^[a-zA-Z0-9]*[\w -]*[a-zA-Z0-9]$/,
																			// 	message: 'Service Name should have only alphabets',
																			// },
																		})} />
																		{errors2.service_name && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.service_name.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-Rate-input">Service Charge ( MRC ) <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="service_charge" id="formrow-servicename-input" maxLength={4} class="form-control" placeholder="Enter your Rate" {...register2('service_charge', {
																			required: 'Service Charge is required',
																			pattern: {
																				value: /^(\d*\.{0,1}\d{0,2}$)/,
																				message: 'Service Charge ( MRC ) should have only numbers',
																			},
																			maxLength: {
																				value: 5,
																				message: 'Max Length Exceeded',
																			},
																		})} />
																		{errors2.service_charge && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.service_charge.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-Rate-input">DIP <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="dip" id="formrow-servicename-input" maxLength={5} class="form-control" placeholder="Enter your Rate" {...register2('dip', {
																			required: 'DIP is required',
																			pattern: {
																				value: /^(\d*\.{0,1}\d{0,2}$)/,
																				message: 'DIP should have only numbers',
																			},
																			maxLength: {
																				value: 5,
																				message: 'Max Length Exceeded',
																			},
																		})} />
																		{errors2.dip && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.dip.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-Rate-input">Buy Rate <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="buy_rate" id="formrow-servicename-input" maxLength={5} class="form-control" placeholder="Enter your Rate" {...register2('buy_rate', {
																			required: 'Buy Rate is required',
																			pattern: {
																				value: /^(\d*\.{0,1}\d{0,2}$)/,
																				message: 'Service Rate should have only numbers',
																			},
																			maxLength: {
																				value: 5,
																				message: 'Max Length Exceeded',
																			},
																		})} />
																		{errors2.buy_rate && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.buy_rate.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-Rate-input">Setup Fee <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="setup_fee" id="formrow-servicename-input" maxLength={5} class="form-control" placeholder="Enter Setup Fee" {...register2('setup_fee', {
																			required: 'Setup Fee is required',
																			pattern: {
																				value: /^(\d*\.{0,1}\d{0,2}$)/,
																				message: 'Setup Fee should have only numbers',
																			},
																			maxLength: {
																				value: 5,
																				message: 'Max Length Exceeded',
																			},
																		})} />
																		{errors2.setup_fee && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.setup_fee.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-Rate-input">Service Status <span style={{ color: 'red' }}>*</span></label>
																		<select className="form-select" name="service_status" {...register2('service_status', { required: "Service Status is required" })} >
																			<option value="">Select</option>
																			<option value="1">Active</option>
																			<option value="0">Inactive</option>
																		</select>
																		{errors2.service_status && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.service_status.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-Rate-input">Service Type <span style={{ color: 'red' }}>*</span></label>
																		<select className="form-select" name="service_type" {...register2('service_type', { required: "Service Type is required" })}>
																			<option value="">Select</option>
																			<option value="International">International</option>
																			<option value="Origination">Origination</option>
																			<option value="SMS/MMS">SMS/MMS</option>
																			<option value="Other">Other</option>
																			<option value="Termination">Termination</option>
																		</select>
																		{errors2.service_type && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.service_type.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="service_description">Unit Type
																			{/* <span style={{ color: 'red' }}>*</span> */}
																		</label>
																		<input type="text" name="service_description" id="formrow-servicename-input" maxLength={50} class="form-control" placeholder="Enter Unit Type" {...register2('service_description', {
																			// required: ' Unit Type is required',
																		})} />
																		{errors2.service_description && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.service_description.message}</div>}
																	</div>
																</div>
															</div>
															<div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
																<button class="btn btn-warning w-md" type="button" onClick={() => { toggleSection('sectionA'); toggleSection('sectionC') }}>Cancel</button>
																<button class="btn btn-primary w-md me-md-2" type="submit">Update</button>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div> {/* container-fluid */}
						{/* Edit Service */}
					</div>
					{/* End Page-content */}
					<Footer />
				</div>
				{/* end main content*/}
			</div>
			{/* END layout-wrapper */}
			{/* Right bar overlay*/}
			<div className="rightbar-overlay"></div>
		</>
	);
}

export default ManageService;
