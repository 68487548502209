import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
  },
  scales: {
    x: {
      ticks: {
        autoSkip: false,
        maxRotation: 40,
        minRotation: 40,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1, 
      },
    },
  },
};

const Renewalgraph = ({ labels, data }) => {
  const chartData = {
    labels,
    datasets: [
      {
        label: "New Registered Customers",
        data,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <div>
      <Bar options={options} data={chartData} />
    </div>
  );
};

export default Renewalgraph;
