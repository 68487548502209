import React, { useEffect, useState } from 'react'
import Header from '../../../common/Header'
import Sidebar from '../../../common/Sidebar'
import PageHeader from '../../PageHeader'
import Footer from '../../../common/Footer'
import { triggerAlert, getToken, get_user_menu_permission } from '../../../utils';
import Loader from '../../../common/Loader'

import { useForm, Controller } from 'react-hook-form';
import axios from 'axios'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AutoSearchSelect from '../../CommonSelectDynamic'
import { menuIdData } from '../../../utils/constants'
import { createPattern, onlyAlphabetsandSpaces, onlyNumbers } from '../../../utils/constants'
export default function ManageUserLevel() {
    const api_url = process.env.REACT_APP_API_CUSTOMER_URL;
    const heading = "Admin / User Level";
    const token = getToken();
    const [isLoading, setIsLoading] = useState();
    const [editStatus, setEditStatus] = useState(false);
    const [editId, setEditId] = useState("");
    const [menuData, setMenuData] = useState([]);


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const action = queryParams.get('action');
    const edit_id = queryParams.get('edit_id');
    const navigate = useNavigate();
    const editStateData = location.state;
    const mainHeading = action == "add" ? "Create User Level" : "Update User Level";
    ///////////////////////// Basic form /////////////////////////////////////////
    const { register: registerForm, handleSubmit: handleSubmitForm, formState: { errors: errorsForm }, setValue: setValueForm, reset: resetForm, control: controlForm, getValues: getValuesForm, watch, clearErrors } = useForm({
        defaultValues: {},
    });



    const handleDepartment = (selectedOption) => {
        setValueForm('department_id', selectedOption ? selectedOption.value : null);
        setValueForm('department_name', selectedOption ? selectedOption.label : null);
        if (selectedOption?.value) {
            clearErrors('department_id');
        }
    };

    const saveFormData = async (data) => {


        let parentIds = [];
        let childIds = [];

        // Loop through the input data
        selectedParentMenus.forEach(item => {
            // Add parent_menu_id to parentIds if not already present
            if (!parentIds.includes(item)) {
                parentIds.push(item);
            }
        });
        selectedChildMenus.forEach(item => {
            // Add child_menu_id to childIds if not already present
            if (!childIds.includes(item)) {
                childIds.push(item);
            }
        });

        let post_data = {
            "level_name": data.level_name,
            "department_id": data.department_id,
            "department_name": data.department_name,
            "pm_id": parentIds,
            "cm_id": childIds,
        };


        setIsLoading(true);



        try {
            var api = "";
            let response = "";
            if (editStatus) {
                post_data.level_id = editId;
                api = api_url + 'manage_admin/update_user_level_permission';
                response = await axios.put(api, post_data, token);
            } else {
                api = api_url + 'manage_admin/add_user_level';
                response = await axios.post(api, post_data, token);
            }


            if (response.status === 201) {
                setIsLoading(false);
                if (editStatus) {
                    triggerAlert('success', 'success', 'Data updated successfully');
                } else {
                    triggerAlert('success', 'success', 'Data added successfully');
                }

                setTimeout(() => {
                    navigate(`/manage_admin/user_level`);
                }, 2000);
            } else if (response.status === 204) {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }
            else {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }

        } catch (error) {
            setIsLoading(false);
            triggerAlert('error', 'Oops...', 'Something went wrong here..');
        }

    };


    ////////////////////////////////////////////////////////////////////////////////
    const fetchEditData = async (edit_id) => {
        try {
            setIsLoading(true);
            setEditStatus(true);


            const itemsArray = editStateData
            setValueForm('department_id', itemsArray.department_id);
            setValueForm('status', itemsArray.status);
            setValueForm('level_name', itemsArray.level_name);
            setValueForm('department_name', itemsArray.dep_name);

            const parent_id_string = itemsArray.parent_id;
            if (parent_id_string) {
                const parent_id_array = parent_id_string.split(',').map(Number);
                parent_id_array.forEach((parent_id, index) => {
                    let parent_menu_id = "parent_menu_" + parent_id;
                    setValueForm(parent_menu_id, true);
                });


                setSelectedParentMenus(parent_id_array);
            }


            const child_id_string = itemsArray.child_id;
            if (child_id_string) {
                const child_id_array = child_id_string.split(',').map(Number);
                child_id_array.forEach((child_id, index) => {
                    let child_menu_id = "child_menu_" + child_id;
                    setValueForm(child_menu_id, true);
                });

                setSelectedChildMenus(child_id_array);
            }


            setIsLoading(false);


        } catch (error) {
            setIsLoading(false);
            triggerAlert('error', 'Oops...', 'Something went wrong..');
        }

    };


    const fetchMenuData = async () => {
        setIsLoading(true);

        try {


            const response = await axios.get(api_url + 'manage_admin/get_menu_list', token);
            const response_data = response.data

            if (response.status === 200) {
                const itemsArray = response_data.results
                setMenuData(itemsArray);

                setIsLoading(false);
            } else if (response.status === 204) {
                setMenuData([]);
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }

        } catch (error) {
            setIsLoading(false);
            // triggerAlert('error', 'Oops...', 'Something went wrong..');
        }
    };


    const [selectedMenus, setSelectedMenus] = useState([]);
    const [selectedParentMenus, setSelectedParentMenus] = useState([]);
    const [selectedChildMenus, setSelectedChildMenus] = useState([]);



    const handleChildCheckboxChange = (parentMenuArray, childMenuArray, isChecked) => {
        let parentMenu = parentMenuArray.id
        let childMenu = childMenuArray.id

        let parentIdsArray = parentMenu;


        // Check if the child_menu_id already exists in selectedMenus

        if (!childMenu) {
            // Parent value check
            let parent_menu_id = "parent_menu_" + parentMenu;
            setValueForm(parent_menu_id, isChecked);

            setSelectedParentMenus((prevMenus1) =>
                prevMenus1.filter((item) => item !== parentIdsArray)
            );
            if (isChecked) {
                setSelectedParentMenus((prevMenus1) => [...prevMenus1, parentIdsArray]);
            }

            let submenuData = parentMenuArray.submenu;
            submenuData.forEach((sub_menu_data, index) => {
                let child_menu_id = "child_menu_" + sub_menu_data.id;
                setValueForm(child_menu_id, isChecked);

                setSelectedChildMenus((prevMenus) =>
                    prevMenus.filter((item) => item !== sub_menu_data.id)
                );


                if (isChecked) {
                    setSelectedChildMenus((prevMenus) => [...prevMenus, sub_menu_data.id]);
                }


            });

        } else {

            let child_menu_id = "child_menu_" + childMenu;

            setValueForm(child_menu_id, isChecked);
            const existingIndex = selectedMenus.findIndex((item) => item.child_menu_id === childMenu);

            setSelectedChildMenus((prevMenus) =>
                prevMenus.filter((item) => item !== childMenu)
            );
            setSelectedParentMenus((prevMenus1) =>
                prevMenus1.filter((item) => item !== parentIdsArray)
            );

            if (isChecked) {
                setSelectedChildMenus((prevMenus) => [...prevMenus, childMenu]);
                setSelectedParentMenus((prevMenus1) => [...prevMenus1, parentIdsArray]);
            }

        }


    };



    useEffect(() => {
        const menu_id = menuIdData.user_level;
        const add_permission = get_user_menu_permission(menu_id, 'add');
        const edit_permission = get_user_menu_permission(menu_id, 'edit');

        if (add_permission || edit_permission) {
            if (action == "update") {
                setEditId(edit_id);
                fetchEditData(edit_id);
            }
            fetchMenuData();
        } else {
            triggerAlert('error', 'Oops...', "You don't have permission to access this page");

            setTimeout(() => {
                window.history.back();
            }, 3000);
        }
    }, [])
    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                {isLoading &&
                    <div className='loader-overlay text-white'>
                        <Loader />
                    </div>
                }

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}
                            <>

                                <div className="row  ">
                                    <div className="col-12">
                                        <div className="card">

                                            <div className="card-header">
                                                <div className="float-end">
                                                    <Link to="/manage_admin/user_level" className="btn btn-warning w-md btn-md"   >Back</Link>

                                                    <button className="btn btn-primary addBtn waves-effect waves-light w-md font-size-14 ms-2" type='button' onClick={handleSubmitForm(saveFormData)}
                                                    > {editStatus ? "Update" : "Add"}<i className="align-middle font-size-16 ms-2"></i></button>
                                                </div>



                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="mb-3">
                                                            <label for="department_id" className="form-label">Department<span className="text-danger">*</span></label>
                                                            <Controller
                                                                name="department_id"
                                                                {...registerForm('department_id',
                                                                    { required: 'Department is required' }
                                                                )}
                                                                control={controlForm}
                                                                render={({ field }) => (
                                                                    <AutoSearchSelect
                                                                        {...field}
                                                                        apiUrl={process.env.REACT_APP_API_BASE_URL + 'get_departments'}
                                                                        placeholder="Select Department"
                                                                        mapOption={result => ({
                                                                            value: result.id,
                                                                            label: result.name,
                                                                        })}
                                                                        value={field.value}
                                                                        onSelect={handleDepartment}
                                                                    />
                                                                )}
                                                            />
                                                            {errorsForm.department_id && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.department_id.message}</div>}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="mb-3">
                                                            <label for="formrow-email-input" className="form-label">Role <span className="text-danger">*</span></label>
                                                            <input type="text"
                                                                className="form-control"
                                                                id="formrow-email-input"
                                                                placeholder=" "
                                                                {...registerForm('level_name', {
                                                                    required: 'Role Name is required',
                                                                    // pattern: {
                                                                    //     value: /^[^\d]+$/,
                                                                    //     message: 'Role Name should not contain numbers',
                                                                    // },
                                                                    pattern: onlyAlphabetsandSpaces,
                                                                })}
                                                                name="level_name"

                                                            />
                                                            {errorsForm.level_name && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.level_name.message}</div>}

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    {menuData.map((parent_menu) => (
                                                        <div className="col-md-3" key={parent_menu.id}>
                                                            <div className="col-md-12">
                                                                <div className=" mb-3">
                                                                    <div className="input-group">
                                                                        <div className="input-group-text input_group_user_level">
                                                                            <Controller
                                                                                name={`parent_menu_${parent_menu.id}`}
                                                                                {...registerForm(`parent_menu_${parent_menu.id}`)}
                                                                                control={controlForm}
                                                                                defaultValue={false}
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            id={`parent_menu_${parent_menu.id}`}
                                                                                            checked={field.value}
                                                                                            {...field}
                                                                                            onChange={(e) => handleChildCheckboxChange(parent_menu, "", e.target.checked)}

                                                                                        />
                                                                                        <label className="form-check-label" htmlFor={`parent_menu_label_${parent_menu.id}`}>
                                                                                            {parent_menu.menu_name}
                                                                                        </label>
                                                                                    </>
                                                                                )}
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                {parent_menu.submenu.map((child_menu) => (
                                                                    <div key={child_menu.id} className="mb-3 ps-3">
                                                                        <div className="form-check">
                                                                            <Controller
                                                                                name={`child_menu_${child_menu.id}`}
                                                                                {...registerForm(`child_menu_${child_menu.id}`)}
                                                                                control={controlForm}
                                                                                defaultValue={false}
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            id={`child_menu_${child_menu.id}`}
                                                                                            checked={field.value}
                                                                                            {...field}
                                                                                            onChange={(f) => handleChildCheckboxChange(parent_menu, child_menu, f.target.checked)}

                                                                                        />
                                                                                        <label className="form-check-label" htmlFor={`child_menu_label_${child_menu.id}`}>
                                                                                            {child_menu.menu_name}
                                                                                        </label>
                                                                                    </>
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>


                                            </div>
                                        </div>
                                    </div> {/* <!-- end col -->*/}
                                </div>
                            </>



                        </div> {/* container-fluid */}
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </>
    )
}

