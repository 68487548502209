import React, { useState, useEffect,useRef } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../common/Footer";
import Sidebar from "../../common/Sidebar";
import Header from "../../common/Header";
import PageHeader from "../PageHeader";
import { Controller, useForm } from "react-hook-form";
import * as html2pdf from "html2pdf.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  ConfirmationAlert,
  capitalizeFirst,
  exportToCsv,
  formatDate,
  getToken,
  get_user_menu_permission,
  pageReload,
  simpleAlert,
  triggerAlert,
} from "../../utils";
import axios from "axios";
import { Table } from "react-bootstrap";
import Loader from "../../common/Loader";
import Pagination from "../PaginationComponent";
import AutoUserSearch from "../AutoUserSearch";
import companyLogo from "./companyLogo.png";
import companyLogo2 from "./logo.png";
import AutoSearchSelect from "../CommonSelectDynamic";
import CommonSelect from "../CommonSelectStatic";
import { cardType, invoiceStatus, invoiceType, menuIdData } from "../../utils/constants";
import PdfGen from "./PdfGen";
import DataTable from 'react-data-table-component';
export default function Invoices() {
  const api_url = process.env.REACT_APP_API_BASE_URL;
  const customer_api_url = process.env.REACT_APP_API_CUSTOMER_URL;
  const show50Entries = process.env.REACT_APP_API_SHOW_ENTRIES;
  const token = getToken();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    control,
  } = useForm();
  const {
    register: registerUpdate,
    handleSubmit: handleSubmitUpdate,
    formState: { errors: errorsUpdate },
    setValue: setValueUpdate,
    control: controlUpdate,
    watch: watchUpdate,
    reset: resetUpdate,
  } = useForm();
  const reportTemplateRef = useRef(null);
  const reportTemplateRef2 = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSent, setIsLoadingSent] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [isSearchBtnDisabled, setIsSearchBtnDisabled] = useState(true);
  const [downloadPDF, setDownloadPDF] = useState(false);
  const [data, setData] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [totalPlansCost, setTotalPlansCost] = useState(0);
  const [invoiceTotalCost, setInvoiceTotalCost] = useState(0);
  const [invoiceHtml, setInvoiceHtml] = useState("");
  const [customerId, setcustomerId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const currentDate = new Date();
  const [totalRows, setTotalRows] = useState(0);
  const [showsampleInvoice, setshowsampleInvoice] = useState(false)
  const [perPage, setPerPage] = useState(10);
  const [period_start, setPeriodStart] = useState("");
  const [period_end, setPeriodEnd] = useState("");
  const [minEndDate, setMinEndDate] = useState(
    period_start ? new Date(period_start) : null
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageSlNo, setPageSlNo] = useState(0);
  const [perPageLimit, setPerPageLimit] = useState(10);
  const [pageLimitSelected, setPageLimitSelected] = useState(10);

  const [showUpdatePage, setShowUpdatePage] = useState(false);
  const [invoice_Id, setInvoiceId] = useState(0);
  const [userPaymentDetails, setUserPaymentDetails] = useState([]);
  const [servicesList, setServicesList] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const action = queryParams.get("action");
  const { state } = location;

  const mainHeading = `${showUpdatePage
    ? `Update Invoice - ${userPaymentDetails?.invoice_number}`
    : "Invoice"
    }`;
  const heading = "Billing / Invoices";
  const [sendInvoiceId, setSendInvoiceId] = useState(null);
  const [sendCustomerId, setSendCustomerId] = useState(null);
  // Menu Permissions
  //  const [addPermission, setAddPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  //  const [deletePermission, setDeletePermission] = useState(false);
  const [exportPermission, setExportPermission] = useState(false);
  const [selectedInvoiceData, setselectedInvoiceData] = useState({})
  const [selectcustomer_add_info, setselectcustomer_add_info] = useState({})
  const [selectbilling_info, setselectbilling_info] = useState([])
  const [downloadsendmail, setdownloadsendmail] = useState(false)
  const columns = [
    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">Invoice Number</label>, sortable: true, selector: row => row.invoice_number, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_14 align-middle align-middle">
            <span>{row.invoice_number}</span>
          </div>
        </>,
    },
    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">User</label>, sortable: true, selector: row => row.customer__contact_person, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_16 align-middle"  >
            {row.customer__contact_person}
          </div>
        </>,
    },
    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">Company Name</label>, sortable: true, selector: row => row.customer__cmp_name, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
            {row.customer__cmp_name}
          </div>
        </>,
    },
    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">Amount</label>, sortable: true, selector: row => row.transaction_id, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
            {row.total_amount}
          </div>
        </>,
    },
    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">Credits</label>, sortable: true, selector: row => row.payment_gateway, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
            {row.total_credits}
          </div>
        </>,
    },
    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">Invoice Date</label>, sortable: true, selector: row => row.due_date, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
            {row.due_date}
          </div>
        </>,
    },
    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">Last Paid Date</label>, sortable: true, selector: row => row.date, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
            {row.date}
          </div>
        </>,
    },



    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">Status</label>, sortable: true, selector: row => row.status, cell: row =>
        <>
          <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
            {row.status}
          </div>
        </>,
    },

     

    {
      name: <label className="DarkBlue4_Color font_weight_500 font_14">Action</label>, sortable: true, selector: row => row.is_deleted, cell: row =>
        <>
          <div className="btn-group float-end col-md-1  ml-10">
          <button className="btn btn-sm btn-default p-0 mx-2" title="Edit"  > <i className="fas  fas fa-edit fa-edit-c "></i> </button>
             
            <button className="btn btn-sm btn-default p-0 mx-1" title="View" onClick={() => {setshowsampleInvoice(true); onloadembededdata(row); setselectedInvoiceData(row)} }  > <i className="fas fa-solid fa-eye"></i> </button>
            
              <button className="btn btn-sm btn-default p-0 mx-1" title="Download"  onClick={() =>  {onloadembededdata(row); downloadInvoiceAsPDF(row); setIsLoadingSent(true); setdownloadsendmail(true) }}  > <i className="fas fa-arrow-alt-circle-down"></i> </button>
            
              <>
                <button className="btn btn-sm btn-default p-0 mx-1" title="Send mail" onClick={() =>{ onloadembededdata(row);  setIsLoadingSent(true); setdownloadsendmail(true); setTimeout(() => { generatePDFAndSend(row) }, 1000); } } > <i className="fas fa-envelope-open-text"></i> </button>
                {/* {row.invoice_status ===
                  "Pending" && (
                    <button className="btn btn-sm btn-default p-0 mx-1"   title="charge" onClick={handleComingSoon} > <i className="fas fa-file-invoice-dollar"></i> </button>
                  )} */}
              </>
             
          </div>
        </>,
    },
  ];
  const [customerid, setcustomerid] = useState()
  const [invoiceid, setinvoiceid] = useState()
  const onloadembededdata = (row) => {
    setcustomerid(row.customer__id)
    setinvoiceid(row.id)
    setselectedInvoiceData(row);
    if(row.customer_add_info.length !== 0){
      setselectcustomer_add_info(row.customer_add_info[0]);
    } 

    if(row.billing_info.length !== 0){
      setselectbilling_info(row.billing_info); 
    }
    
    
  }
  
  
  const fetchInvoicesList = async (page_number, page_size=perPageLimit, searchkey, data) => {
    // if (action !== 'frm_dash') removeActionQueryParam();
    setIsLoading(true);

     console.log(data)
    try {
      let apiUrl = `/apiV1/list-invoices/`;
      let params = `?limit=${page_size}&offset=${(page_number-1)*10}`;
      if (data.customer_id) params += `&customer_id=${data.customer_id}`;
      if (data.invoice_type) params += `&invoice_type=${data.invoice_type}`;
      if (data.invoice_status)
        params += `&status=${data.invoice_status}`;
      if (searchkey) params += `&search=${searchkey}`;
      const startDate = formatDate(
        period_start || data.period_start,
        "dd-mm-yyyy"
      );
      const endDate = formatDate(period_end || data.period_end, "dd-mm-yyyy");

      if (selectedTab !== "" && selectedTab !== "datewise") {
        params += `&search_type=${selectedTab}`;
      } else if (period_start !== "" && period_end !== "") {
        params += `&start_date=${startDate}&end_date=${endDate}`;
        params += `&search_type=datewise`;
      } else if (data.period_start !== "" && data.period_end !== "") {
        params += `&start_date=${startDate}&end_date=${endDate}`;
        params += `&search_type=datewise`;
      }

      const response = await axios.get(apiUrl + params);
      const response_data = response.data;

      if (response.status == 200) {
        const itemsArray = response_data.results;
        const total_pages = response_data.count;

        setTotalRows(total_pages);
        setInvoiceList(itemsArray);
        setPerPage(page_size)
        setIsLoading(false);
      } else if (response.status === 204) {
        setInvoiceList([]);
        setPageCount(0);
        setIsLoading(false);
      } else {
        triggerAlert("error", "Oops...", "Couldn't get the details");
      }
    } catch (error) {
      setIsLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const onSubmit = (data) => {
    setData(data);
    fetchInvoicesList(currentPage, perPage, "", data);
  };

 
 
  const handleStatusSelect = (selected) => {
    setValue("invoice_status", selected ? selected.value : null);
    if (selected !== "" || selected !== null) setIsSearchBtnDisabled(false);
    setSelectedTab("");
  };

  const downloadInvoiceAsPDF = () => {
    setTimeout(() => {
      const pdfOptions = {
        margin: 10,
        filename: `invoice_number.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
      };
      // html2pdf().from(htmlContent).set(pdfOptions).save();
      html2pdf().set(pdfOptions).from(reportTemplateRef2.current).save(); //for downloading hidden element
      setshowsampleInvoice(false)
      setIsLoadingSent(false)
      setdownloadsendmail(false);
    }, 1000);
  };
  const generatePDFAndSend =  async () => {
    const pdfOptions = {
      margin: 10,
      filename: 'invoice.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
    };
    const pdfBlob = await html2pdf().set(pdfOptions).from(reportTemplateRef2.current).toPdf().output('blob');
    setshowsampleInvoice(false)
    const reader = new FileReader();
    reader.readAsDataURL(pdfBlob);  // Read the PDF Blob
    reader.onloadend = () => {
        const base64Data = reader.result.split(',')[1];
        sendInvoice(base64Data, selectedInvoiceData)
    };
   
};
const sendInvoice = async (base64Data, selectedInvoiceData) => {

  const api_input = {
      customer_id: customerid,
      invoice_id: invoiceid, // Use data.data as invoiceID
      type: "send_invoice",
      pdf_base_64: base64Data
  }

  try {
      const response = await axios.post(`apiV1/send-invoice/`, api_input);
      const response_data = response.data;
      if(response_data.error_code == 200){
        // console.log('sendInvoice', response)
        setshowsampleInvoice(false)
        triggerAlert('success', 'Success', 'Mail sent Successfully!!');
        navigate('/billing/invoice');
        setdownloadsendmail(false);
        setIsLoadingSent(false)
      }
      
      
  } catch (error) {
    setIsLoadingSent(false)
    setdownloadsendmail(false);
      console.error('Error fetching data:', error);
      triggerAlert("error", "Oops...", "Something went wrong..");
       
  }
};

  const handlePageChange = page => {
    fetchInvoicesList(page, perPage, searchQuery, data);
    setCurrentPage(page);
};
 
 

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    const searchkey = e.target.value;
    fetchInvoicesList(currentPage, perPage, searchkey, data); // Update search results on every change
  };

  useEffect(() => {
    const yesterday = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
    setValue("period_start", yesterday);
    setValue("period_end", currentDate);

    setPeriodStart(yesterday);
    setPeriodEnd(currentDate);
    setIsSearchBtnDisabled(false);
    setMinEndDate(currentDate);

    const postDate = {
      period_start: yesterday,
      period_end: currentDate,
    };
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    let obj = {};
    for (let pair of params.entries()) {
      obj[pair[0]] = pair[1]
    }
    if(obj.startDate && obj.endDate){
      setValue("period_start", obj.startDate);
      setValue("period_end", obj.endDate);
      setValue("invoice_status", obj.invoice_status);
      setValue("customer_id", obj.userid);
      let postDate2 = {
        period_start: obj.startDate,
        period_end: obj.endDate,
        customer_id:obj.userid,
        invoice_status:obj.invoice_status
      };
      
      fetchInvoicesList(currentPage,perPage, "", postDate2);
    }else{
      fetchInvoicesList(currentPage,perPage, "", postDate);
    }

    
    
  }, []);

  useEffect(() => {
    // Calculating services cost

    if (selectedInvoice) {
      let calculatedPlansCost = 0;
      let calculatedTotalCost = 0;

      const invoiceTaxCost = parseFloat(selectedInvoice.plan_tax, 10) || 0;

      if (selectedInvoice.invoice_cat === "I") {
        selectedInvoice.plan_option.forEach((plan) => {
          const planTotal = parseFloat(plan.total) || 0;
          calculatedPlansCost += planTotal;
        });
        // calculatedTotalCost = invoiceTaxCost + calculatedPlansCost;
      } else {
        calculatedPlansCost =
          parseFloat(selectedInvoice.invoice_amount, 10) || 0;
        // calculatedTotalCost = invoiceTaxCost + calculatedPlansCost;
      }
      // Calculate tax amount based on percentage
      const taxAmount = (calculatedPlansCost * invoiceTaxCost) / 100;


      // Add tax amount to the calculatedPlansCost
      calculatedTotalCost = calculatedPlansCost + taxAmount;
      setTotalPlansCost(calculatedPlansCost);
      setInvoiceTotalCost(calculatedTotalCost);
    }

    // Download PDF logic
    // if (downloadPDF && calculatedTotalCost > 0) {
    //     downloadInvoiceAsPDF(document.getElementById('invoice-preview-card-data').innerHTML);
    //     setDownloadPDF(false); // Reset the flag after download
    // }
  }, [selectedInvoice]);


  const exportToExcel = async () => {
    setIsLoading(true);
    try {
      let apiUrl = `${api_url}billing/get_invoice_download_data`;
      let params = "?";

      const addParam = (key, value) => {
        if (value) {
          params += `${params.length > 1 ? "&" : ""}${key}=${value}`;
        }
      };

      addParam("customer_id", data.customer_id);
      addParam("invoice_type", data.invoice_type);
      addParam("invoice_status", data.invoice_status);
      addParam("keyword", searchQuery);

      const startDate = formatDate(period_start || data.period_start, "dd-mm-yyyy");
      const endDate = formatDate(period_end || data.period_end, "dd-mm-yyyy");

      if (selectedTab !== "" && selectedTab !== "datewise") {
        addParam("search_type", selectedTab);
      } else if ((period_start !== "" && period_end !== "") || (data.period_start !== "" && data.period_end !== "")) {
        addParam("start_date", startDate);
        addParam("end_date", endDate);
        addParam("search_type", "datewise");
      }


      const response = await axios.get(apiUrl + params, token);
      const response_data = response.data;
      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        exportToCsv(itemsArray, 'invoice_report');
        setIsLoading(false);
      } else if (response.status === 204) {
        setIsLoading(false);
      } else {
        triggerAlert('error', 'Oops...', "Couldn't export the file");
      }
    } catch (error) {
      //console.log(error)
      setIsLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  }
  const handlePerRowsChange = async (newPerPage, page) => {
    fetchInvoicesList(page, newPerPage, searchQuery, data);
    setPerPage(newPerPage);
  };
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'All',
  };
  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />

        {/* <!-- ============================================================== -->
                <!-- Start right Content here -->
                <!-- ============================================================== --> */}
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <PageHeader mainHeading={mainHeading} heading={heading} />
              {sendInvoiceId && sendCustomerId && (
                <PdfGen
                  id={sendInvoiceId}
                  type="send_invoice"
                  customer_id={sendCustomerId}
                />
              )}
              {/* Render YourComponent if apiData is available */}
              {isLoadingSent ? (
                <div className="loader-overlay text-white">
                  <Loader />
                </div>
              ) : null}
              {!showsampleInvoice && (
                <>
                  <div className="row   ">
                    <div className="col-xl-12">
                      <div className="">
                        <div className="card-body">
                          {/* Tab panes*/}

                          <form
                            data-select2-id="21"
                            noValidate="novalidate"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <div className="row g-3" data-select2-id="20">
                              <div className="col-md-2  d-flex flex-column">
                                <label className="form-label">From</label>
                                <Controller
                                  className="px-3"
                                  control={control}
                                  name="period_start"
                                  render={({ field }) => (
                                    <DatePicker
                                      className="px-3 form-control"
                                      placeholderText="MM/DD/YYYY"
                                      selected={field.value}
                                      {...register("period_start")}
                                      onChange={(date) => {
                                        setPeriodStart(date);
                                        field.onChange(date);
                                        setMinEndDate(date); // Update minDate for period_end
                                        setIsSearchBtnDisabled(
                                          !date || !period_end
                                        );
                                        if (date) setSelectedTab("datewise");
                                      }}
                                      showMonthDropdown
                                      showYearDropdown
                                      maxDate={new Date()}
                                      autoComplete="off"
                                    />
                                  )}
                                />
                                {errors.period_start && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {errors.period_start.message}
                                  </div>
                                )}
                              </div>
                              <div className="col-md-2  d-flex flex-column">
                                <label className="form-label">To</label>
                                <Controller
                                  control={control}
                                  name="period_end"
                                  render={({ field }) => (
                                    <DatePicker
                                      className="px-3 form-control"
                                      placeholderText="MM/DD/YYYY"
                                      selected={field.value}
                                      {...register("period_end")}
                                      onChange={(date) => {
                                        setPeriodEnd(date); // Update the state variable
                                        field.onChange(date); // Update the form value
                                        setIsSearchBtnDisabled(
                                          !period_start || !date
                                        );
                                        if (date) setSelectedTab("datewise");
                                      }}
                                      showMonthDropdown
                                      showYearDropdown
                                      maxDate={new Date()}
                                      minDate={minEndDate}
                                      autoComplete="off"
                                    />
                                  )}
                                />
                                {errors.period_end && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {errors.period_end.message}
                                  </div>
                                )}
                              </div>
                              <div className="col-md-2" style={{ zIndex: 10 }}>
                                <label
                                  className="form-label"
                                  htmlFor="formtabs-first-name"
                                >
                                  Username
                                </label>
                                {/* <AutoUserSearch onSelect={handleUserSelect} /> */}
                                <Controller
                                  name="customer_id"
                                  {...register(
                                    "customer_id"
                                    // , { required: 'User is required' }
                                  )}
                                  control={control}
                                  render={({ field }) => (
                                    <AutoUserSearch
                                      onSelect={(user) => {
                                        setValue(
                                          "customer_id",
                                          user ? user.user_id : null
                                        );
                                        setcustomerId(
                                          user ? user.user_id : null
                                        );
                                      }} value={field.value}
                                    />
                                  )}
                                />
                                {errors.customer_id && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {errors.customer_id.message}
                                  </div>
                                )}
                              </div>

                              <div className="col-md-2"style={{ zIndex: 10 }}>
                                <label
                                  className="form-label"
                                  htmlFor="invoice_status"
                                >
                                  Payment Status
                                </label>
                                <Controller
                                  name="invoice_status"
                                  {...register(
                                    "invoice_status"
                                    // , { required: 'Status is required' }
                                  )}
                                  control={control}
                                  render={({ field }) => (
                                    <CommonSelect
                                      options={invoiceStatus}
                                      value={field.value}
                                      // onChange={(value) => field.onChange(value)}
                                      onChange={handleStatusSelect}
                                      placeholder="Select Status"
                                    />
                                  )}
                                />
                                {errors.invoice_status && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {errors.invoice_status.message}
                                  </div>
                                )}
                              </div>

                              <div className="col-md-2">
                                <label
                                  className="form-label"
                                  htmlFor="formtabs-last-name"
                                >
                                  &nbsp;
                                </label>
                                <div className="button-items">
                                  <button
                                    type="submit"
                                    className="btn btn-primary w-md"
                                    disabled={isSearchBtnDisabled}
                                  >
                                    Search
                                  </button>
                                </div>
                              </div>

                             
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-12 col-md-12 mb-2 d-flex justify-content-end">
                              {exportPermission && invoiceList.length > 0 &&
                                <button type="button" className="btn btn-success w-md" onClick={exportToExcel}>Export to Excel</button>
                              }
                              <div className="btn-group float-end ms-3">
                                <input
                                  type="search"
                                  placeholder="Search..."
                                  value={searchQuery}
                                  className="form-control form-control-sm"
                                  aria-controls="example"
                                  onChange={handleChange}
                                />
                                &nbsp;&nbsp;
                              </div>
                            </div>

                          </div>

                          {!isLoading ?
                            <DataTable
                              columns={columns}
                              data={invoiceList}
                              pagination
                              paginationServer
                              fixedHeaderScrollHeight='600px'
                              fixedHeader
                              persistTableHead
                              paginationTotalRows={totalRows}
                              paginationPerPage={perPage}
                              paginationComponentOptions={paginationComponentOptions}
                              paginationDefaultPage={currentPage}
                              
                              onChangeRowsPerPage={handlePerRowsChange}
                              onChangePage={handlePageChange}
                            /> :
                            <div className='loader-overlay text-white'>
                              <Loader />
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {showsampleInvoice ? 
                <div id="invoice-preview-card-data" ref={reportTemplateRef}>
                  
                  <div>
                    <div
                      className="card invoice-preview-card px-5 py-2"
                      style={{ border: "3px solid #090956 " }}
                    >
                      <div className="whole_body">
                        <div className="card-body px-0 py-0">
                          <div className="row d-flex justify-content-between flex-wrap mb-2">
                            <div className="col-4 mb-xl-0 mb-4 d-flex flex-column">
                              <span className="mb-0 fw-semibold">
                                {selectedInvoiceData.configuration__company_name}
                              </span>
                              <span className="mb-0">
                              {selectedInvoiceData.configuration__address_line_1}
                              </span>
                              <span className="mb-0">
                              {selectedInvoiceData.configuration__address_line_2}
                              </span>
                              <span className="mb-0">
                                Tel:{selectedInvoiceData.configuration__mobile_no}, Fax:{selectedInvoiceData.configuration__fax}
                              </span>
                              <span className="mb-0">{selectedInvoiceData.configuration__company_email}</span>
                            </div>
                            <div className="col-4 d-flex svg-illustration justify-content-center flex-column align-items-center">
                              <img
                                src={companyLogo2}
                                width="250"
                                alt="Vitelglobal"
                              />
                              <div className="mt-5 fw-semibold">INVOICE</div>
                            </div>

                            <div className="col-4 d-flex flex-column justify-content-center align-items-end">
                              {/* <h5 className="fw-semibold mb-0">INVOICE #{selectedInvoice?.invoice_number ? selectedInvoice.invoice_number
                                                        : '-'}</h5> */}
                              <div className="mb-0 pt-1">
                                <span>Invoice:</span>
                                <span className="fw-semibold">
                                   
                                  #
                                  {selectedInvoiceData?.invoice_number
                                    ? selectedInvoiceData.invoice_number
                                    : "-"}
                                </span>
                              </div>
                              <div className="mb-0 pt-1">
                                <span>Date:</span>
                                <span className="fw-semibold">
                                  {" "}
                                  {selectedInvoiceData?.date
                                    ? formatDate(
                                      selectedInvoiceData?.date,
                                      "dd-mm-yyyy"
                                    )
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="my-0 mb-3" />
                        <div
                          className="card-body"
                          style={{ border: "1px solid #090956 " }}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <h5 className="mb-4 h5-card text-center">
                                Customer Information
                              </h5>
                              <table className="col-md-10">
                                <tbody class="row">
                                  <tr class="d-flex justify-content-around">
                                    <td className="col-3">Company Name :</td>
                                    <td className="col-7">
                                      {selectedInvoiceData?.customer__cmp_name}
                                    </td>
                                  </tr>
                                  <tr class="d-flex justify-content-around">
                                    <td className="col-3">Contact Name :</td>
                                    <td className="col-7">
                                      {selectedInvoiceData?.customer__contact_person}
                                    </td>
                                  </tr>
                                  <tr class="d-flex justify-content-around">
                                    <td className="col-3">Email Id :</td>
                                    <td className="col-7">
                                      {selectedInvoiceData?.customer__corp_email}
                                    </td>
                                  </tr>
                                  <tr class="d-flex justify-content-around">
                                    <td className="col-3">Address :</td>
                                    <td className="col-7">
                                      {selectcustomer_add_info.address_line_1 +selectcustomer_add_info.address_line_2 + 
                                        ", " +
                                        selectcustomer_add_info.postal_code +
                                        ", " +
                                        selectcustomer_add_info.city +
                                        ", " +
                                        selectcustomer_add_info.state +
                                        ", " +
                                        selectcustomer_add_info.country +
                                        "."}{" "}
                                    </td>
                                  </tr>
                                  <tr class="d-flex justify-content-around">
                                    <td className="col-3">Phone :</td>
                                    <td className="col-7">
                                      {selectcustomer_add_info.phone_number}
                                    </td>
                                  </tr>
                                  <tr class="d-flex justify-content-around">
                                    <td className="col-3">Plan Type :</td>
                                    <td className="col-7">{selectedInvoiceData.plan_type}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <h5 className="mb-4 mt-3  h5-card text-center">
                          Billing Information
                        </h5>
                        <div className="table-responsive border-top">
                          <table className="table m-0 p-0 ">
                            <thead
                              style={{
                                backgroundColor: "#090956 ",
                                color: "#fff",
                              }}
                            >
                              <tr>
                                <th class=" p-0">DESCRIPTION</th>
                                <th class=" p-0">QUANTITY</th>
                                <th class=" p-0">UNIT PRICE</th>
                                {/* <th class=" p-0">BILL MONTHS</th> */}
                                <th class=" p-0">TOTAL</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectbilling_info.map((plan) => (
                                <tr
                                  style={{
                                    backgroundColor:
                                      plan.description === "Number of Paid DIDs"
                                        ? "white"
                                        : "#efefef",
                                  }}
                                >
                                  <td className="text-nowrap p-0">
                                    {plan?.item_name}
                                  </td>
                                  <td className="text-nowrap p-0">
                                    {plan?.total_credits ?  plan?.total_credits : 0}
                                  </td>
                                  <td className="p-0">$ {plan?.unit_price ? plan?.unit_price : 0}</td>
                                  {/* <td className="p-0">
                                    {plan?.billing_months}
                                  </td> */}
                                  <td className="p-0">$ {plan?.total ? plan?.total : 0}</td>
                                </tr>
                              ))}
                              {/* <tr>
                                                        <td className="text-nowrap">Subtotal</td>
                                                        <td className="text-nowrap"></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>${totalPlansCost}</td>
                                                    </tr> */}
                              <tr>
                                <td className="text-nowrap p-0">Tax</td>
                                <td className="text-nowrap p-0"></td>
                                <td className="p-0">{selectedInvoiceData?.tax_info.tax}</td>
                                {/* <td className="p-0">
                                  
                                </td> */}
                                <td className="p-0">
                                  ${selectedInvoiceData?.tax_info.total}
                                  
                                </td>
                              </tr>
                              {selectedInvoice?.card_type !== "AmExCard" ? (
                                <>
                                  {selectedInvoice?.discount_status === "Y" ? (
                                    <>
                                      <tr>
                                        <td className="text-nowrap p-0">
                                          Discount
                                        </td>
                                        <td className="text-nowrap p-0"></td>
                                        <td className="p-0"></td>
                                        {/* <td className="p-0"></td> */}
                                        <td className="p-0">
                                          ${selectedInvoice?.discount_amt}
                                        </td>
                                      </tr>
                                      <tr
                                        style={{
                                          backgroundColor: "#090956 ",
                                          color: "#fff",
                                        }}
                                      >
                                        <td className="text-nowrap p-0">
                                          Grand Total
                                        </td>
                                        <td className="text-nowrap p-0"></td>
                                        <td className="p-0"></td>
                                        {/* <td className="p-0"></td> */}
                                        <td className="p-0">
                                          $
                                          {invoiceTotalCost -
                                            selectedInvoice?.discount_amt}
                                        </td>
                                      </tr>
                                    </>
                                  ) : (
                                    <tr
                                      style={{
                                        backgroundColor: "#090956 ",
                                        color: "#fff",
                                      }}
                                    >
                                      <td className="text-nowrap p-0">
                                        Grand Total
                                      </td>
                                      <td className="text-nowrap p-0"></td>
                                      <td className="p-0"></td>
                                      {/* <td className="p-0"></td> */}
                                      <td className="p-0">
                                        $ {selectedInvoiceData.total_amount}
                                      </td>
                                    </tr>
                                  )}
                                </>
                              ) : (
                                <>
                                  {selectedInvoice.discount_status === "Y" ? (
                                    <>
                                      <tr>
                                        <td className="text-nowrap p-0">
                                          Discount
                                        </td>
                                        <td className="text-nowrap p-0"></td>
                                        <td className="p-0"></td>
                                        <td className="p-0"></td>
                                        <td className="p-0">
                                          ${selectedInvoice?.discount_amt}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-nowrap p-0">
                                          AmExCard Processing Fee - 4%
                                        </td>
                                        <td className="text-nowrap p-0"></td>
                                        <td className="p-0"></td>
                                        <td className="p-0"></td>
                                        <td className="p-0">
                                          $
                                          {(
                                            (invoiceTotalCost -
                                              selectedInvoice?.discount_amt) *
                                            0.04
                                          ).toFixed(3)}
                                        </td>
                                      </tr>
                                      <tr
                                        style={{
                                          backgroundColor: "#090956 ",
                                          color: "#fff",
                                        }}
                                      >
                                        <td className="text-nowrap p-0">
                                          Grand Total
                                        </td>
                                        <td className="text-nowrap p-0"></td>
                                        <td className="p-0"></td>
                                        <td className="p-0"></td>
                                        <td className="p-0">
                                          $
                                          {(
                                            (invoiceTotalCost -
                                              selectedInvoice?.discount_amt) *
                                            0.04 +
                                            (invoiceTotalCost -
                                              selectedInvoice?.discount_amt)
                                          ).toFixed(3)}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className="p-0 mt-3"
                                          align="left"
                                          height="15"
                                          colspan="5"
                                        >
                                          Note : From January 1st, 2016,
                                          payments through Amex credit cards
                                          would attract 4% processing fee and
                                          you will be charged accordingly. If
                                          you would like to avoid the charges,
                                          we request you to move to other
                                          Debit/Credit cards or e-check
                                          payments.
                                        </td>
                                      </tr>
                                    </>
                                  ) : (
                                    <>
                                      <tr>
                                        <td className="text-nowrap p-0">
                                          AmExCard Processing Fee - 4%
                                        </td>
                                        <td className="text-nowrap p-0"></td>
                                        <td className="p-0"></td>
                                        <td className="p-0"></td>
                                        <td className="p-0">
                                          $
                                          {(invoiceTotalCost * 0.04).toFixed(3)}
                                        </td>
                                      </tr>
                                      <tr
                                        style={{
                                          backgroundColor: "#090956 ",
                                          color: "#fff",
                                        }}
                                      >
                                        <td className="text-nowrap p-0">
                                          Grand Total
                                        </td>
                                        <td className="text-nowrap p-0"></td>
                                        <td className="p-0"></td>
                                        <td className="p-0"></td>
                                        <td className="p-0">
                                          $
                                          {(
                                            invoiceTotalCost * 0.04 +
                                            invoiceTotalCost
                                          ).toFixed(3)}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className="p-0 mt-3"
                                          align="left"
                                          height="15"
                                          colspan="5"
                                        >
                                          Note : From January 1st, 2016,
                                          payments through Amex credit cards
                                          would attract 4% processing fee and
                                          you will be charged accordingly. If
                                          you would like to avoid the charges,
                                          we request you to move to other
                                          Debit/Credit cards or e-check
                                          payments.
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="card-body mx-3">
                          <div className="row">
                            <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                              <span className="fw-semibold">
                                Thanks for your business
                              </span>
                              {(selectedInvoice?.did_list_free_list.length >
                                0 ||
                                selectedInvoice?.did_list_paid_list.length >
                                0) && (
                                  <span>Scroll down for detailed invoice.</span>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-end">
                  <button className="btn btn-warning  px-5 mt-2 mb-2" onClick={()=> setshowsampleInvoice(false)}> Back</button>
                    </div>
                  
                </div> : ""}
                 {downloadsendmail ?               
                <div id="invoice-preview-card-data2" ref={reportTemplateRef2} >
                  
                  <div>
                    <div
                      className="card invoice-preview-card px-5 py-2"
                      style={{ border: "3px solid #090956 " }}
                    >
                      <div className="whole_body">
                        <div className="card-body px-0 py-0">
                          <div className="row d-flex justify-content-between flex-wrap mb-2">
                            <div className="col-4 mb-xl-0 mb-4 d-flex flex-column">
                              <span className="mb-0 fw-semibold">
                                {selectedInvoiceData.configuration__company_name}
                              </span>
                              <span className="mb-0">
                              {selectedInvoiceData.configuration__address_line_1}
                              </span>
                              <span className="mb-0">
                              {selectedInvoiceData.configuration__address_line_2}
                              </span>
                              <span className="mb-0">
                                Tel:{selectedInvoiceData.configuration__mobile_no}, Fax:{selectedInvoiceData.configuration__fax}
                              </span>
                              <span className="mb-0">{selectedInvoiceData.configuration__company_email}</span>
                            </div>
                            <div className="col-4 d-flex svg-illustration justify-content-center flex-column align-items-center">
                              <img
                                src={companyLogo2}
                                width="250"
                                alt="Vitelglobal"
                              />
                              <div className="mt-5 fw-semibold">INVOICE</div>
                            </div>

                            <div className="col-4 d-flex flex-column justify-content-center align-items-end">
                              {/* <h5 className="fw-semibold mb-0">INVOICE #{selectedInvoice?.invoice_number ? selectedInvoice.invoice_number
                                                        : '-'}</h5> */}
                              <div className="mb-0 pt-1">
                                <span>Invoice:</span>
                                <span className="fw-semibold">
                                   
                                  #
                                  {selectedInvoiceData?.invoice_number
                                    ? selectedInvoiceData.invoice_number
                                    : "-"}
                                </span>
                              </div>
                              <div className="mb-0 pt-1">
                                <span>Date:</span>
                                <span className="fw-semibold">
                                  {" "}
                                  {selectedInvoiceData?.date
                                    ? formatDate(
                                      selectedInvoiceData?.date,
                                      "dd-mm-yyyy"
                                    )
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="my-0 mb-3" />
                        <div
                          className="card-body"
                          style={{ border: "1px solid #090956 " }}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <h5 className="mb-4 h5-card text-center">
                                Customer Information
                              </h5>
                              <table className="col-md-10">
                                <tbody class="row">
                                  <tr class="d-flex justify-content-around">
                                    <td className="col-3">Company Name :</td>
                                    <td className="col-7">
                                      {selectedInvoiceData?.customer__cmp_name}
                                    </td>
                                  </tr>
                                  <tr class="d-flex justify-content-around">
                                    <td className="col-3">Contact Name :</td>
                                    <td className="col-7">
                                      {selectedInvoiceData?.customer__contact_person}
                                    </td>
                                  </tr>
                                  <tr class="d-flex justify-content-around">
                                    <td className="col-3">Email Id :</td>
                                    <td className="col-7">
                                      {selectedInvoiceData?.customer__corp_email}
                                    </td>
                                  </tr>
                                  <tr class="d-flex justify-content-around">
                                    <td className="col-3">Address :</td>
                                    <td className="col-7">
                                      {selectcustomer_add_info.address_line_1 +selectcustomer_add_info.address_line_2 + 
                                        ", " +
                                        selectcustomer_add_info.postal_code +
                                        ", " +
                                        selectcustomer_add_info.city +
                                        ", " +
                                        selectcustomer_add_info.state +
                                        ", " +
                                        selectcustomer_add_info.country +
                                        "."}{" "}
                                    </td>
                                  </tr>
                                  <tr class="d-flex justify-content-around">
                                    <td className="col-3">Phone :</td>
                                    <td className="col-7">
                                      {selectcustomer_add_info.phone_number}
                                    </td>
                                  </tr>
                                  <tr class="d-flex justify-content-around">
                                    <td className="col-3">Plan Type :</td>
                                    <td className="col-7">Pay As You Go</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <h5 className="mb-4 mt-3  h5-card text-center">
                          Billing Information
                        </h5>
                        <div className="table-responsive border-top">
                          <table className="table m-0 p-0 ">
                            <thead
                              style={{
                                backgroundColor: "#090956 ",
                                color: "#fff",
                              }}
                            >
                              <tr>
                                <th class=" p-0">DESCRIPTION</th>
                                <th class=" p-0">QUANTITY</th>
                                <th class=" p-0">UNIT PRICE</th>
                                {/* <th class=" p-0">BILL MONTHS</th> */}
                                <th class=" p-0">TOTAL</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectbilling_info.map((plan) => (
                                <tr
                                  style={{
                                    backgroundColor:
                                      plan.description === "Number of Paid DIDs"
                                        ? "white"
                                        : "#efefef",
                                  }}
                                >
                                  <td className="text-nowrap p-0">
                                    {plan?.item_name}
                                  </td>
                                  <td className="text-nowrap p-0">
                                    {plan?.total_credits ?  plan?.total_credits : 0}
                                  </td>
                                  <td className="p-0">$ {plan?.unit_price ? plan?.unit_price : 0}</td>
                                  {/* <td className="p-0">
                                    {plan?.billing_months}
                                  </td> */}
                                  <td className="p-0">$ {plan?.total ? plan?.total : 0}</td>
                                </tr>
                              ))}
                              {/* <tr>
                                                        <td className="text-nowrap">Subtotal</td>
                                                        <td className="text-nowrap"></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>${totalPlansCost}</td>
                                                    </tr> */}
                              <tr>
                                <td className="text-nowrap p-0">Tax</td>
                                <td className="text-nowrap p-0"></td>
                                <td className="p-0">{selectedInvoiceData?.tax_info.tax}</td>
                                {/* <td className="p-0">
                                  
                                </td> */}
                                <td className="p-0">
                                  ${selectedInvoiceData?.tax_info.total}
                                  
                                </td>
                              </tr>
                              {selectedInvoice?.card_type !== "AmExCard" ? (
                                <>
                                  {selectedInvoice?.discount_status === "Y" ? (
                                    <>
                                      <tr>
                                        <td className="text-nowrap p-0">
                                          Discount
                                        </td>
                                        <td className="text-nowrap p-0"></td>
                                        <td className="p-0"></td>
                                        {/* <td className="p-0"></td> */}
                                        <td className="p-0">
                                          ${selectedInvoice?.discount_amt}
                                        </td>
                                      </tr>
                                      <tr
                                        style={{
                                          backgroundColor: "#090956 ",
                                          color: "#fff",
                                        }}
                                      >
                                        <td className="text-nowrap p-0">
                                          Grand Total
                                        </td>
                                        <td className="text-nowrap p-0"></td>
                                        <td className="p-0"></td>
                                        {/* <td className="p-0"></td> */}
                                        <td className="p-0">
                                          $
                                          {invoiceTotalCost -
                                            selectedInvoice?.discount_amt}
                                        </td>
                                      </tr>
                                    </>
                                  ) : (
                                    <tr
                                      style={{
                                        backgroundColor: "#090956 ",
                                        color: "#fff",
                                      }}
                                    >
                                      <td className="text-nowrap p-0">
                                        Grand Total
                                      </td>
                                      <td className="text-nowrap p-0"></td>
                                      <td className="p-0"></td>
                                      {/* <td className="p-0"></td> */}
                                      <td className="p-0">
                                        $ {selectedInvoiceData.total_amount}
                                      </td>
                                    </tr>
                                  )}
                                </>
                              ) : (
                                <>
                                  {selectedInvoice.discount_status === "Y" ? (
                                    <>
                                      <tr>
                                        <td className="text-nowrap p-0">
                                          Discount
                                        </td>
                                        <td className="text-nowrap p-0"></td>
                                        <td className="p-0"></td>
                                        {/* <td className="p-0"></td> */}
                                        <td className="p-0">
                                          ${selectedInvoice?.discount_amt}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="text-nowrap p-0">
                                          AmExCard Processing Fee - 4%
                                        </td>
                                        <td className="text-nowrap p-0"></td>
                                        <td className="p-0"></td>
                                        {/* <td className="p-0"></td> */}
                                        <td className="p-0">
                                          $
                                          {(
                                            (invoiceTotalCost -
                                              selectedInvoice?.discount_amt) *
                                            0.04
                                          ).toFixed(3)}
                                        </td>
                                      </tr>
                                      <tr
                                        style={{
                                          backgroundColor: "#090956 ",
                                          color: "#fff",
                                        }}
                                      >
                                        <td className="text-nowrap p-0">
                                          Grand Total
                                        </td>
                                        <td className="text-nowrap p-0"></td>
                                        <td className="p-0"></td>
                                        {/* <td className="p-0"></td> */}
                                        <td className="p-0">
                                          $
                                          {(
                                            (invoiceTotalCost -
                                              selectedInvoice?.discount_amt) *
                                            0.04 +
                                            (invoiceTotalCost -
                                              selectedInvoice?.discount_amt)
                                          ).toFixed(3)}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className="p-0 mt-3"
                                          align="left"
                                          height="15"
                                          colspan="5"
                                        >
                                          Note : From January 1st, 2016,
                                          payments through Amex credit cards
                                          would attract 4% processing fee and
                                          you will be charged accordingly. If
                                          you would like to avoid the charges,
                                          we request you to move to other
                                          Debit/Credit cards or e-check
                                          payments.
                                        </td>
                                      </tr>
                                    </>
                                  ) : (
                                    <>
                                      <tr>
                                        <td className="text-nowrap p-0">
                                          AmExCard Processing Fee - 4%
                                        </td>
                                        <td className="text-nowrap p-0"></td>
                                        <td className="p-0"></td>
                                        <td className="p-0"></td>
                                        <td className="p-0">
                                          $
                                          {(invoiceTotalCost * 0.04).toFixed(3)}
                                        </td>
                                      </tr>
                                      <tr
                                        style={{
                                          backgroundColor: "#090956 ",
                                          color: "#fff",
                                        }}
                                      >
                                        <td className="text-nowrap p-0">
                                          Grand Total
                                        </td>
                                        <td className="text-nowrap p-0"></td>
                                        <td className="p-0"></td>
                                        <td className="p-0"></td>
                                        <td className="p-0">
                                          $
                                          {(
                                            invoiceTotalCost * 0.04 +
                                            invoiceTotalCost
                                          ).toFixed(3)}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className="p-0 mt-3"
                                          align="left"
                                          height="15"
                                          colspan="5"
                                        >
                                          Note : From January 1st, 2016,
                                          payments through Amex credit cards
                                          would attract 4% processing fee and
                                          you will be charged accordingly. If
                                          you would like to avoid the charges,
                                          we request you to move to other
                                          Debit/Credit cards or e-check
                                          payments.
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="card-body mx-3">
                          <div className="row">
                            <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                              <span className="fw-semibold">
                                Thanks for your business
                              </span>
                              {(selectedInvoice?.did_list_free_list.length >
                                0 ||
                                selectedInvoice?.did_list_paid_list.length >
                                0) && (
                                  <span>Scroll down for detailed invoice.</span>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-end">
                  <button className="btn btn-warning  px-5 mt-2 mb-2" onClick={()=> setshowsampleInvoice(false)}> Back</button>
                    </div>
                  
                </div>
              :""}
            </div>
            {/* <!-- container-fluid --> */}
          </div>
          {/* <!-- End Page-content --> */}

          <Footer />
        </div>
        {/* <!-- end main content--> */}
      </div>
      {/* <!-- END layout-wrapper*/}

      {/* <!-- Right bar overlay--> */}
      <div className="rightbar-overlay"></div>
    </>
  );
}
