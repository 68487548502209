import React, { useEffect, useState } from 'react'
import Header from '../../../common/Header'
import Sidebar from '../../../common/Sidebar'
import PageHeader from '../../PageHeader'
import Footer from '../../../common/Footer'
import { triggerAlert, getToken, get_user_menu_permission } from '../../../utils';
import Loader from '../../../common/Loader'
import AutoSearchSelect from '../../CommonSelectDynamic'

import { useForm, Controller } from 'react-hook-form';
import axios from 'axios'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { menuIdData } from '../../../utils/constants'

export default function ChangeLevel() {
    const api_url = process.env.REACT_APP_API_CUSTOMER_URL;
    const mainHeading = "Change Level";
    const heading = "Admin / User List";
    const [isLoading, setIsLoading] = useState();
    const [editStatus, setEditStatus] = useState(false);
    const [editId, setEditId] = useState("");
    const [menuData, setMenuData] = useState([]);


    const token = getToken();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const action = queryParams.get('action');
    const edit_id = queryParams.get('edit_id');
    const navigate = useNavigate();
    const editStateData = location.state;
    ///////////////////////// Basic form /////////////////////////////////////////
    const { register: registerForm, handleSubmit: handleSubmitForm, formState: { errors: errorsForm }, setValue: setValueForm, reset: resetForm, control: controlForm, getValues: getValuesForm, watch } = useForm({
        defaultValues: {
            ismanager: "N",
            pbx_role: "No"
        },
    });



    const handleDepartment = (selectedOption) => {
        setValueForm('department_id', selectedOption ? selectedOption.value : null);
        setValueForm('department_name', selectedOption ? selectedOption.label : null);
    };


    const handleLevel = (selectedOption) => {
        setValueForm('level_id', selectedOption ? selectedOption.value : null);
    };


    const saveFormData = async (data) => {



        let post_data = {
            "admin_id": editId,
            "level_id": data.level_id
        };


        setIsLoading(true);



        try {
            var api = "";
            let response = "";

            api = api_url + 'manage_admin/update_admin_level';
            response = await axios.put(api, post_data, token);


            if (response.status === 201) {
                setIsLoading(false);
                if (editStatus) {
                    triggerAlert('success', 'success', 'Data updated successfully');
                } else {
                    triggerAlert('success', 'success', 'Data added successfully');
                }

                setTimeout(() => {
                    navigate(`/manage_admin/user_list`);
                }, 2000);
            } else if (response.status === 204) {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }
            else {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }

        } catch (error) {
            setIsLoading(false);
            triggerAlert('error', 'Oops...', 'Something went wrong here..');
        }

    };


    ////////////////////////////////////////////////////////////////////////////////
    const fetchEditData = async (edit_id) => {
        try {
            setIsLoading(true);
            setEditStatus(true);


            const itemsArray = editStateData
            setValueForm('level_id', itemsArray.level_id);
            setIsLoading(false);


        } catch (error) {
            setIsLoading(false);
            triggerAlert('error', 'Oops...', 'Something went wrong..');
        }

    };


    useEffect(() => {
        const menu_id = menuIdData.user_list;
        const add_permission = get_user_menu_permission(menu_id, 'add');
        const edit_permission = get_user_menu_permission(menu_id, 'edit');

        if (add_permission || edit_permission) {
            if (action == "update") {

                setEditId(edit_id);
                fetchEditData(edit_id);
            }
        } else {
            triggerAlert('error', 'Oops...', "You don't have permission to access this page");

            setTimeout(() => {
                window.history.back();
            }, 3000);
        }


    }, [])

    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                {isLoading &&
                    <div className='loader-overlay text-white'>
                        <Loader />
                    </div>
                }

                <div className="main-content" style={{ minHeight: '100vh' }}>

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}
                            <>

                                <div className="row  ">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="float-end">
                                                    <Link to="/manage_admin/user_list" className="btn btn-warning w-md btn-md"   >Back</Link>

                                                    <button className="btn btn-primary addBtn waves-effect waves-light w-md font-size-14 ms-2" type='button' onClick={handleSubmitForm(saveFormData)}
                                                    > {editStatus ? "Update" : "Add"}<i className="align-middle font-size-16 ms-2"></i></button>

                                                </div>

                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="mb-3">
                                                            <label for="level_id" className="form-label">Level<span className="text-danger">*</span></label>
                                                            <Controller
                                                                name="level_id"
                                                                {...registerForm('level_id',
                                                                    { required: 'Department is required' }
                                                                )}
                                                                control={controlForm}
                                                                render={({ field }) => (
                                                                    <AutoSearchSelect
                                                                        {...field}
                                                                        apiUrl={process.env.REACT_APP_API_CUSTOMER_URL + 'manage_admin/user_level_data'}
                                                                        placeholder="Select Level"
                                                                        mapOption={result => ({
                                                                            value: result.levelid,
                                                                            label: result.level_name,
                                                                        })}
                                                                        value={field.value}
                                                                        onSelect={handleLevel}
                                                                    />
                                                                )}
                                                            />
                                                            {errorsForm.level_id && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.level_id.message}</div>}
                                                        </div>
                                                    </div>

                                                </div>



                                            </div>
                                        </div>
                                    </div> {/* <!-- end col -->*/}
                                </div>
                            </>



                        </div> {/* container-fluid */}
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </>
    )
}

