import React, { useEffect, useState } from "react";
import Header from '../../../common/Header'
import Sidebar from '../../../common/Sidebar'
import Footer from '../../../common/Footer'
import PageHeader from '../../PageHeader'
import axios from "axios";
import { Controller, useForm } from 'react-hook-form';
import { triggerAlert, getToken, get_user_menu_permission } from "../../../utils";
import Loader from '../../../common/Loader'
import AutoSearchSelect from '../../CommonSelectDynamic'
import { menuIdData } from '../../../utils/constants'

export default function ManageUserMenu() {
    const api_url = process.env.REACT_APP_API_CUSTOMER_URL;
    const mainHeading = "User Menu";
    const heading = "Admin / User Menu";
    const [isLoading, setIsLoading] = useState();
    const [menuData, setMenuData] = useState([]);
    const [adminUserData, setAdminUserData] = useState([]);
    const token = getToken();
    ///////////////////////// Basic form /////////////////////////////////////////
    const { register: registerForm, handleSubmit: handleSubmitForm, formState: { errors: errorsForm }, setValue: setValueForm, reset: resetForm, control: controlForm, getValues: getValuesForm, watch } = useForm({
        defaultValues: { is_manager: "N" },
    });
    const fetchMenuDetails = (selectedOption) => {
        let admin_id = selectedOption ? selectedOption.value : null;
        fetchAdminData(admin_id);
        setSelectedParentMenus([]);
        setSelectedChildMenus([]);
        resetForm();
        setValueForm('admin_id', selectedOption ? selectedOption.value : null);

    };
    ////////////////////////////////////////////////////////////////
    const setCheckBoxData = (itemsArray) => {
        const parent_id_string = itemsArray.parent_id;
        if (parent_id_string) {
            const parent_id_array = parent_id_string.split(',').map(Number);
            parent_id_array.forEach((parent_id, index) => {
                let parent_menu_id = "parent_menu_" + parent_id;
                setValueForm(parent_menu_id, true);
            });


            setSelectedParentMenus(parent_id_array);
        }


        const child_id_string = itemsArray.child_id;
        if (child_id_string) {
            const child_id_array = child_id_string.split(',').map(Number);
            child_id_array.forEach((child_id, index) => {
                let child_menu_id = "child_menu_" + child_id;
                setValueForm(child_menu_id, true);
            });

            setSelectedChildMenus(child_id_array);
        }


    };
    ///////////////////////////////////////////////////////////////////////
    const fetchAdminData = async (admin_id) => {
        setIsLoading(true);

        try {

            const response = await axios.get(api_url + 'manage_admin/admin_menu_by_id?admin_id=' + admin_id, token);
            const response_data = response.data

            if (response.status === 200) {
                const itemsArray = response_data.results[0];

                setValueForm('is_manager', itemsArray.is_manager);

                setCheckBoxData(itemsArray);

                setAdminUserData(itemsArray);

                setIsLoading(false);
            } else if (response.status === 204) {
                setAdminUserData([]);
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }

        } catch (error) {
            setIsLoading(false);
            // triggerAlert('error', 'Oops...', 'Something went wrong..');
        }
    };
    const saveFormData = async (data) => {

        setIsLoading(true);
        let parentIds = [];
        let childIds = [];

        // Loop through the input data
        selectedParentMenus.forEach(item => {
            // Add parent_menu_id to parentIds if not already present
            if (!parentIds.includes(item)) {
                parentIds.push(item);
            }
        });
        selectedChildMenus.forEach(item => {
            // Add child_menu_id to childIds if not already present
            if (!childIds.includes(item)) {
                childIds.push(item);
            }
        });
        // console.log("parentIds", parentIds);
        //console.log("childIds", childIds);



        let post_data = {
            "admin_id": data.admin_id,
            "is_manager": data.is_manager,
            "pm_id": parentIds,
            "cm_id": childIds,
        };
        try {
            var api = "";
            let response = "";
            api = api_url + 'manage_admin/update_admin_user_menu';
            response = await axios.put(api, post_data, token);


            if (response.status === 201) {
                setIsLoading(false);
                triggerAlert('success', 'success', 'Data updated successfully');

                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            } else if (response.status === 204) {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }
            else {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }

        } catch (error) {
            setIsLoading(false);
            triggerAlert('error', 'Oops...', 'Something went wrong here..');
        }

    };
    ////////////////////////////////////////////////////////////////////////////////
    const fetchMenuData = async () => {
        setIsLoading(true);

        try {
            const response = await axios.get(api_url + 'manage_admin/get_menu_list', token);
            const response_data = response.data

            if (response.status === 200) {
                const itemsArray = response_data.results
                setMenuData(itemsArray);

                setIsLoading(false);
            } else if (response.status === 204) {
                setMenuData([]);
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }

        } catch (error) {
            setIsLoading(false);
            // triggerAlert('error', 'Oops...', 'Something went wrong..');
        }
    };
    const [selectedMenus, setSelectedMenus] = useState([]);
    const [selectedParentMenus, setSelectedParentMenus] = useState([]);
    const [selectedChildMenus, setSelectedChildMenus] = useState([]);
    const handleChildCheckboxChange = (parentMenuArray, childMenuArray, isChecked) => {
        const parentMenu = parentMenuArray.id;
        const childMenu = childMenuArray.id;
        const parentIdsArray = parentMenu;

        // Check if the child_menu_id already exists in selectedMenus
        if (!childMenu) {
            // Parent value check
            const parent_menu_id = "parent_menu_" + parentMenu;
            setValueForm(parent_menu_id, isChecked);

            setSelectedParentMenus((prevMenus1) =>
                isChecked ? [...prevMenus1, parentIdsArray] : prevMenus1.filter((item) => item !== parentIdsArray)
            );

            const submenuData = parentMenuArray.submenu;
            submenuData.forEach((sub_menu_data, index) => {
                const child_menu_id = "child_menu_" + sub_menu_data.id;
                setValueForm(child_menu_id, isChecked);

                setSelectedChildMenus((prevMenus) =>
                    isChecked ? [...prevMenus, sub_menu_data.id] : prevMenus.filter((item) => item !== sub_menu_data.id)
                );
            });
        } else {
            const child_menu_id = "child_menu_" + childMenu;

            setValueForm(child_menu_id, isChecked);

            setSelectedChildMenus((prevMenus) =>
                isChecked ? [...prevMenus, childMenu] : prevMenus.filter((item) => item !== childMenu)
            );

            if (isChecked && !selectedParentMenus.includes(parentMenu)) {
                setSelectedParentMenus((prevMenus1) => [...prevMenus1, parentMenu]);
                const parent_menu_id = "parent_menu_" + parentMenu;
                setValueForm(parent_menu_id, true);
            }
        }
    };



    // const handleChildCheckboxChange = (parentMenuArray, childMenuArray, isChecked) => {
    //     let parentMenu = parentMenuArray.id
    //     let childMenu = childMenuArray.id

    //     let parentIdsArray = parentMenu;


    //     // Check if the child_menu_id already exists in selectedMenus

    //     if (!childMenu) {
    //         // Parent value check
    //         let parent_menu_id = "parent_menu_" + parentMenu;
    //         setValueForm(parent_menu_id, isChecked);

    //         setSelectedParentMenus((prevMenus1) =>
    //             prevMenus1.filter((item) => item !== parentIdsArray)
    //         );
    //         if (isChecked) {
    //             setSelectedParentMenus((prevMenus1) => [...prevMenus1, parentIdsArray]);
    //         }

    //         let submenuData = parentMenuArray.submenu;
    //         submenuData.forEach((sub_menu_data, index) => {
    //             let child_menu_id = "child_menu_" + sub_menu_data.id;
    //             setValueForm(child_menu_id, isChecked);

    //             setSelectedChildMenus((prevMenus) =>
    //                 prevMenus.filter((item) => item !== sub_menu_data.id)
    //             );


    //             if (isChecked) {
    //                 setSelectedChildMenus((prevMenus) => [...prevMenus, sub_menu_data.id]);
    //             }


    //         });

    //     } else {

    //         let child_menu_id = "child_menu_" + childMenu;

    //         setValueForm(child_menu_id, isChecked);
    //         const existingIndex = selectedMenus.findIndex((item) => item.child_menu_id === childMenu);

    //         setSelectedChildMenus((prevMenus) =>
    //             prevMenus.filter((item) => item !== childMenu)
    //         );
    //         setSelectedParentMenus((prevMenus1) =>
    //             prevMenus1.filter((item) => item !== parentIdsArray)
    //         );

    //         if (isChecked) {
    //             setSelectedChildMenus((prevMenus) => [...prevMenus, childMenu]);
    //             setSelectedParentMenus((prevMenus1) => [...prevMenus1, parentIdsArray]);
    //         }

    //     }


    // };
    useEffect(() => {
        const menu_id = menuIdData.user_menu;
        const add_permission = get_user_menu_permission(menu_id, 'add');
        const edit_permission = get_user_menu_permission(menu_id, 'edit');

        if (add_permission || edit_permission) {
            fetchMenuData();
        } else {
            triggerAlert('error', 'Oops...', "You don't have permission to access this page");

            setTimeout(() => {
                window.history.back();
            }, 3000);
        }
    }, [])
    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                {isLoading &&
                    <div className='loader-overlay text-white'>
                        <Loader />
                    </div>
                }
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}
                            <>
                                <div className="row  ">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="btn-group float-end col-md-1  ml-10">
                                                    <button className="btn btn-primary addBtn waves-effect waves-light w-md font-size-14 ms-2" type='button' onClick={handleSubmitForm(saveFormData)}
                                                    > Update<i className="align-middle font-size-16 ms-2"></i></button>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="col-xl-12">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            {/*  <h4 className="card-title mb-4">Ring Groups</h4> */}
                                                            <div className="row">
                                                                <div class="col-md-3">
                                                                    <div class="mb-3">
                                                                        <label for="admin_id" class="form-label">User Name<span className="text-danger">*</span></label>
                                                                        <Controller
                                                                            name="admin_id"
                                                                            {...registerForm('admin_id',
                                                                                { required: 'User Name is required' }
                                                                            )}
                                                                            control={controlForm}
                                                                            render={({ field }) => (
                                                                                <AutoSearchSelect
                                                                                    {...field}
                                                                                    apiUrl={process.env.REACT_APP_API_CUSTOMER_URL + 'manage_admin/get_all_admin_data'}
                                                                                    placeholder="Select User Name"
                                                                                    mapOption={result => ({
                                                                                        value: result.id,
                                                                                        label: result.username,
                                                                                    })}
                                                                                    value={field.value}
                                                                                    onSelect={fetchMenuDetails}
                                                                                />
                                                                            )}
                                                                        />
                                                                        {errorsForm.admin_id && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.admin_id.message}</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <div className="mb-3">
                                                                        <label for="formrow-inputCity" className="form-label">Is Manager</label>
                                                                        <Controller
                                                                            control={controlForm}
                                                                            name="is_manager"
                                                                            render={({ field }) => (
                                                                                <div className="switch mt-1">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="is_manager"
                                                                                        switch="none"
                                                                                        checked={field.value === 'Y'}
                                                                                        onChange={(e) => setValueForm('is_manager', e.target.checked ? 'Y' : 'N')}

                                                                                    />
                                                                                    <label htmlFor="is_manager" data-on-label="Yes" data-off-label="No"></label>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                {menuData.map((parent_menu) => (
                                                                    <div class="col-md-3" key={parent_menu.id}>
                                                                        <div class="col-md-12">
                                                                            <div class=" mb-3">
                                                                                <div class="input-group">
                                                                                    <div class="input-group-text input_group_user_level">
                                                                                        <Controller
                                                                                            name={`parent_menu_${parent_menu.id}`}
                                                                                            {...registerForm(`parent_menu_${parent_menu.id}`)}
                                                                                            control={controlForm}
                                                                                            defaultValue={false}
                                                                                            render={({ field }) => (
                                                                                                <>
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        id={`parent_menu_${parent_menu.id}`}
                                                                                                        checked={field.value}
                                                                                                        {...field}
                                                                                                        onChange={(e) => handleChildCheckboxChange(parent_menu, "", e.target.checked)}
                                                                                                    />&nbsp;&nbsp;
                                                                                                    <label className="form-check-label" htmlFor={`parent_menu_label_${parent_menu.id}`}>
                                                                                                        {parent_menu.menu_name}
                                                                                                    </label>
                                                                                                </>
                                                                                            )}
                                                                                        />

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            {parent_menu.submenu.map((child_menu) => (
                                                                                <div key={child_menu.id} className="mb-3 ps-3">
                                                                                    <div className="form-check">
                                                                                        <Controller
                                                                                            name={`child_menu_${child_menu.id}`}
                                                                                            {...registerForm(`child_menu_${child_menu.id}`)}
                                                                                            control={controlForm}
                                                                                            defaultValue={false}
                                                                                            render={({ field }) => (
                                                                                                <>
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        id={`child_menu_${child_menu.id}`}
                                                                                                        checked={field.value}
                                                                                                        {...field}
                                                                                                        onChange={(f) => handleChildCheckboxChange(parent_menu, child_menu, f.target.checked)}
                                                                                                    />
                                                                                                    <label className="form-check-label" htmlFor={`child_menu_label_${child_menu.id}`}>
                                                                                                        {child_menu.menu_name}
                                                                                                    </label>
                                                                                                </>
                                                                                            )}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        {/* end card body */}
                                                    </div>
                                                    {/* end card */}
                                                </div>
                                            </div>
                                        </div>
                                    </div> {/* <!-- end col -->*/}
                                </div>
                            </>
                        </div> {/* container-fluid */}
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}
            </div>
            {/* END layout-wrapper */}
            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </>
    )
}