import React, { useEffect, useState } from "react";
import Header from '../../../common/Header'
import Sidebar from '../../../common/Sidebar'
import Footer from '../../../common/Footer'
import PageHeader from '../../PageHeader'
import axios from "axios";
import { triggerAlert, ConfirmationAlert, getToken, get_user_menu_permission, pageReload } from "../../../utils";
import Loader from '../../../common/Loader'
import { useNavigate, Link } from 'react-router-dom'
import { menuIdData } from '../../../utils/constants'
import DataTable from 'react-data-table-component';
import { Modal, ModalBody } from "react-bootstrap";
import { useForm, Controller } from 'react-hook-form';
import AutoSearchSelect from '../../CommonSelectDynamic'
import { Menu, MenuItem } from "@mui/material";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
export default function ManageUserList() {
    const api_url = process.env.REACT_APP_API_CUSTOMER_URL;
    const show50Entries = 50;
    const mainHeading = "User List";
    const heading = "Admin / User List";
    const token = getToken();
    const navigate = useNavigate();
    const [selectedRows, setSelectedRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [SuccessAlert, setSuccessAlert] = useState(false);
    const [CreatenewUser, setCreatenewUser] = useState(false);
    const [changepassword, setchangepassword] = useState(false);
    const [EditUser, setEditUser] = useState(false);
    const [userrolechange, setuserrolechange] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [listData, setListData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [pageSlNo, setPageSlNo] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageLimit, setPerPageLimit] = useState(10);
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [addPermission, setAddPermission] = useState(false);
    const [editPermission, setEditPermission] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [selectedid, setselectedid] = useState()
    const [perPage, setPerPage] = useState(10);
    const [departmentList, setDepartmentList] = useState(null);
    const { register: registerForm, handleSubmit: handleSubmitForm, formState: { errors: errorsForm }, setValue: setValueForm, reset: resetForm, control: controlForm, getValues: getValuesForm, watch } = useForm({

    });
    const { register: registerEdit, handleSubmit: handleSubmitEdit, formState: { errors: errorsEdit }, setValue: setValueEdit, reset: resetEdit, control: controlEdit, getValues: getValuesEdit, } = useForm({

    });
    const { register: registerPassword, handleSubmit: handleSubmitPassword, formState: { errors: errorsPassword }, setValue: setValuePassword, reset: resetPassword, control: controlPassword, getValues: getValuesPassword, } = useForm({

    });
    const [editid, seteditid] = useState('')
    const [editidData, seteditidData] = useState({})
    const [selectedidAction, setselectedidAction] = useState('')


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event,row) => {
        setAnchorEl(event.currentTarget);
        setselectedidAction(row.id)
        seteditidData(row)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (EditUser || userrolechange || changepassword) {
            // Call handleClose function when modal is opened
            handleClose();
        }
       
    }, [EditUser ,userrolechange ,changepassword]);

    const columns = [
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">S.No</label>, sortable: true, selector: row => row.Num, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_14 align-middle align-middle">
                        <span>{row.Num}</span>
                    </div>
                </>,
        },
        // {
        //     name: <label className="DarkBlue4_Color font_weight_500 font_14">Username</label>, sortable: true, selector: row => row.username, cell: row =>
        //         <>
        //             <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
        //                 {row.username}
        //             </div>
        //         </>,
        // },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Email Address</label>, sortable: true, selector: row => row.email, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.email}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Phone Number</label>, sortable: true, selector: row => row.phone, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.phone}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Role</label>, sortable: false, selector: row => row.role_info, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.role_info.length !== 0 ? row.role_info[0].role__name : ""}
                    </div>
                </>,
        },
        // {
        //     name: <label className="DarkBlue4_Color font_weight_500 font_14">Manager</label>, sortable: true, selector: row => row.manager, cell: row =>
        //         <>
        //             <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
        //                 {row.manager}
        //             </div>
        //         </>,
        // },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Status</label>, sortable: true, selector: row => row.is_active, cell: row =>
                <>
                    {row.is_active ? (
                        <span className="btn btn-success btn-sm btn-rounded waves-effect waves-light">Active</span>
                    ) : row.is_active == false ? (
                        <span className="btn btn-danger btn-sm btn-rounded waves-effect waves-light">Inactive</span>
                    ) : (
                        <span className="btn btn-info btn-sm btn-rounded waves-effect waves-light">Unknown</span>
                    )}
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Action</label>, sortable: true, selector: row => row.is_active, cell: row =>
                <>
                    {/* {customeredit && 
                    <div className="btn-group float-end col-md-1  ml-10">
                        <button type="button" className="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ "marginLeft": "10px" }}>
                            Action <i className="mdi mdi-chevron-down"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end  ">
                            <a className="dropdown-item hand_icon" onClick={() => { seteditid(row.id); onloadeditedData(row); setEditUser(true) }}>Edit</a>
                            {row.is_active ?
                            <a className="dropdown-item hand_icon" onClick={() => { setuserrolechange(true); setValueForm('level_id', ''); setselectedid(row.id); onloadroleslist(row.role_info) }}>Change Role</a>:''}
                            <a className="dropdown-item hand_icon" onClick={()=>{setselectedid(row.id); setchangepassword(true); resetPassword()}}>Change Password</a>
                            {row.is_active ? 
                            <a className="dropdown-item hand_icon" onClick={() => statusChange(!row.is_active, row.id)}  >Deactivate  </a> :
                            <a className="dropdown-item hand_icon" onClick={() => statusChange(!row.is_active, row.id)}>Activate</a> }
                            
                        </div>
                    </div> } */}






                    {
                        customeredit &&
                        <div>
                            <div
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(event)=>handleClick(event,row)}
                            >
                                <button
                                    type="button"
                                    className="btn btn-primary w-md btn-md dropdown-toggle"
                                    // data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    style={{ "marginLeft": "10px" }}
                                >
                                    Action
                                    <i className="mdi mdi-chevron-down"></i>
                                </button>
                            </div>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    sx: {
                                        boxShadow: '0px 0px 0px 0px rgba(0,0,0,1), 0px 0px 0px 1px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)',
                                        border: '1px solid #e0e0e0', // Custom border color
                                        outline: 'none', // Remove the black border,        
                                        minWidth: '132px'

                                    },
                                    style: {
                                        border: '1px solid #fff', // Ensure border is applied
                                    }
                                }}
                            >
                                <MenuItem onClick={() => {seteditid(selectedidAction); onloadeditedData(editidData); setEditUser(true) }}>
                                    Edit
                                </MenuItem>

                                {
                                    editidData.is_active ?
                                        <MenuItem onClick={() => { setuserrolechange(true); setValueForm('level_id', ''); setselectedid(selectedidAction); onloadroleslist(editidData.role_info) }}>
                                            Change Role
                                        </MenuItem>
                                        : ""
                                }
                                <MenuItem onClick={() => { setselectedid(selectedidAction); setchangepassword(true); resetPassword() }}>
                                    Change Password
                                </MenuItem>

                                {
                                    editidData.is_active ?
                                        <MenuItem onClick={() => statusChange(!editidData.is_active, selectedidAction)}>
                                            Deactivate
                                        </MenuItem>
                                        :
                                        <MenuItem onClick={() => statusChange(!editidData.is_active, selectedidAction)}>
                                            Activate
                                        </MenuItem>
                                }

                            </Menu>
                        </div>
                    }












                </>,
        },



    ];
    const onloadroleslist = (role) => {
        if (role.length !== 0) {
            setValueForm('level_id', role[0].role__id);
        }

    }
    const onloadeditedData = (data) => {
        setValueEdit("email", data.email)
        setValueEdit("first_name", data.first_name)
        setValueEdit("last_name", data.last_name)
        setValueEdit("username", data.username)
        setValueEdit("phone", data.phone)
    }
    const fetchListData = async (page, size = perPage, searchkey) => {
        setIsLoading(true);
        let offcet = parseInt(page) - 1
        try {
            const response = await axios.get(`apiV1/user-list/?limit=${size}&offset=${offcet * size}${searchkey ? `&search=${searchkey}` : ""}`);
            const response_data = response.data
            if (response.status === 200) {
                const itemsArray = response_data.results;
                itemsArray.map((item, i) => {
                    item.Num = i + 1;
                    return item
                })
                const total_pages = response_data.count;
                setTotalRows(total_pages);
                setListData(itemsArray);
                setIsLoading(false);
                setCurrentPage(page)
                setPerPage(size)
            } else if (response.status === 204) {
                setListData([]);
                setIsLoading(false);
                setTotalRows(0);
            }
            else {
                setListData([]);
                setIsLoading(false);
                setTotalRows(0);
            }

        } catch (error) {
            setListData([]);
            setIsLoading(false);
            setTotalRows(0);
        }
    };

    // change status
    const statusChange = (status, id) => {
        handleClose()
        ConfirmationAlert('Are you sure you want to continue!', 'Continue', async () => {
            const post_data = {
                "is_active": status
            }
            try {
                const response = await axios.delete(`apiV1/user-list/${id}/`, { data: post_data });
                const response_data = response.data;
                if (response_data.status_code === 200) {
                    triggerAlert('success', 'success', 'Status updated Successfully!!');
                    fetchListData(currentPage, perPage, searchQuery);
                } else {
                    triggerAlert('error', 'Oops...', 'Something went wrong..');
                }
            } catch (error) {
                triggerAlert('error', 'Oops...', 'Something went wrong..');
            }
        })

    };
    const onSubmitPassword = async (data) => {
        let req = {
            password: data.password,
            password2: data.confirm_password
        }
        try {
            const response = await axios.patch(`apiV1/change-passwordV2/${selectedid}/`, req);
            const response_data = response.data;
            if (response_data.status_code === 200) {
                setchangepassword(false)
                triggerAlert('success', 'success', 'Password updated Successfully!!');
                fetchListData(currentPage, perPage, searchQuery);
            } else {
                triggerAlert('error', 'Oops...', 'Something went wrong..');
            }
        } catch (error) {
            triggerAlert('error', 'Oops...', 'Something went wrong..');
        }
    }
    const handleKeySearch = (e) => {
        setCurrentPage(1);
        setSearchQuery(e.target.value);
        const searchkey = e.target.value;
        fetchListData(currentPage, perPage, searchkey);
    };
    const access_privileges = JSON.parse(localStorage.getItem('modulepermissions'))
    const [customerlist, setcustomerlist] = useState(false)
    const [customeradd, setcustomeradd] = useState(false)
    const [customeredit, setcustomeredit] = useState(false)
    const [customerdelete, setcustomerdelete] = useState(false)
    useEffect(() => {
        const menu_id = menuIdData?.user_level;
        setAddPermission(get_user_menu_permission(menu_id, 'add'));
        setEditPermission(get_user_menu_permission(menu_id, 'edit'));

        access_privileges.map((access, i) => {
            access.sub_module_info.map((subaccess, k) => {
                if (subaccess.name == "User List" && subaccess.permissions.indexOf(1) !== -1) {
                    setcustomerlist(true)
                }
                if (subaccess.name == "User List" && subaccess.permissions.indexOf(2) !== -1) {
                    setcustomeradd(true)
                }
                if (subaccess.name == "User List" && subaccess.permissions.indexOf(3) !== -1) {
                    setcustomeredit(true)
                }
                if (subaccess.name == "User List" && subaccess.permissions.indexOf(4) !== -1) {
                    setcustomerdelete(true)
                }

            })


        })
    }, []);
    const handlePageChange = page => {
        fetchListData(page, perPage, searchQuery);
        setCurrentPage(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        fetchListData(page, newPerPage, searchQuery);
        setPerPage(newPerPage);
    };
    const handleChangeCheckBox = () => {

    }
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const [ApiValidationError, setApiValidationError] = useState('')
    const onSubmit = (data) => {
        data.created_by = localStorage.getItem('admin_id')
        axios.post("/apiV1/create-user/", data).then((response) => {
            fetchListData(currentPage, perPage, searchQuery);
            setCreatenewUser(false)
            resetForm();
            triggerAlert('success', 'success', 'Data added successfully');
        })
            .catch((error) => {
                if (error.response && error.response.data) {
                    const errorData = error.response.data;
                    // Check for the specific error message related to email uniqueness
                    if (errorData.message === "email  must be unique.") {
                        triggerAlert('error', 'Oops...', 'Something went wrong here.. Email must be unique');
                    } else {
                        triggerAlert('error', 'Oops...', 'Something went wrong here..');
                    }
                }

                if (error.response) {
                    const { message } = error.response.data;
                    switch (message) {
                        case "email must be unique.":
                            triggerAlert('error', 'Oops...', 'Email already exists. Please use a different email address.');

                            break;
                        case "password Password fields didn't match.":
                            triggerAlert('error', 'Oops...', 'Password fields did not match. Please check your input.');

                            break;
                        case "password This password is too short. It must contain at least 8 characters.":
                            triggerAlert('error', 'Oops...', 'Password should contain at least 8 characters.');

                            break;
                        default:
                            console.error("Error adding customer:", error);

                    }
                }
            });
    };

    const onSubmitEdit = async (data) => {
        data.updated_by = localStorage.getItem('admin_id')
        try {

            const response = await axios.patch(`/apiV1/user-list/${editid}/`, data);
            triggerAlert('success', 'success', 'Data updated successfully');
            setEditUser(false);
            fetchListData(currentPage, perPage, searchQuery);
            resetEdit();
        } catch (error) {
            if (error.response && error.response.data && error.response.data.email) {

                if (error.response.data.email.includes("user with this email address already exists.")) {
                    triggerAlert('error', 'Oops...', 'A user with this email address already exists.');
                }
            } else {
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }
            console.error("Error updating user:", error);


        }

    }
    const changerolesfromuserside = async () => {
        if (!getValuesForm('level_id')) {
            triggerAlert('error', 'Oops...', 'Please select a role to assign.');
            return;
        }
        try {
            const req = {
                role_id: getValuesForm('level_id'),
                user_ids: [selectedid],
            };

            const response = await axios.post(`/apiV1/bulk-assign-user-role/`, req);
            setuserrolechange(false);
            triggerAlert('success', 'success', 'Role updated successfully');
            fetchListData(currentPage, perPage, searchQuery);
        } catch (error) {
            console.error("Error:", error);
        }
    }
    const handleLevel = (e) => {
        const selectedOption = e.target.value;
        setValueForm('level_id', selectedOption);
    };
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const checkpasswordtwo = (value) => {
        let password = getValuesForm("password");
        if (value.length >= password.length) {
            if (getValuesForm("password") === value) {
                // Match logic
            } else {
                triggerAlert('error', 'Oops...', 'Password & Confirm Password does not match');
            }
        }
    }
    const checkpasswordtwo2 = (value) => {
        let password = getValuesPassword("password");
        if (value.length >= password.length) {
            if (getValuesPassword("password") === value) {
                // Match logic
            } else {
                triggerAlert('error', 'Oops...', 'Password & Confirm Password does not match');
            }
        }
    }
    useEffect(() => {
        setCurrentPage(1);
        fetchListData(currentPage, perPage, searchQuery);
    }, [perPageLimit]);

    const fetchRolesList = async () => {
        try {
            const response = await axios.get('/apiV1/role-dropdown/');
            const response_data = response.data;
            if (response.status === 200) {
                const itemsArray = response_data;
                setDepartmentList(itemsArray);
            } else if (response.status === 204) {
                setDepartmentList([]);
            } else {
                triggerAlert("error", "Oops...", "Couldn't get the department list.");
            }
        } catch (error) {
            console.log(error);
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    };
    useEffect(() => {
        fetchRolesList()
    }, [])

    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}
                            <>

                                <div className="row  ">
                                    <div className="col-12">
                                        <div className="card">
                                            {customeradd &&
                                                <div className="card-header">

                                                    <div className="btn-group float-end col-md-1  ml-10">
                                                        <button type="button" className="btn btn-primary w-md btn-md" onClick={() => { setCreatenewUser(true); resetForm(); }} style={{ "marginLeft": "10px" }}>
                                                            Create User
                                                        </button>

                                                    </div>

                                                </div>}
                                            <div className="card-body">
                                                <div id="datatable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                    <div className="row">
                                                        {/* <div className="col-sm-12 col-md-6">
																<div className="dataTables_length" id="datatable_length">
																	<label>Show&nbsp;
																		<select name="example_length" aria-controls="example" className="table_length" onChange={handlePageChange} value={pageLimitSelected}>
																			<option value={10}>10</option>
																			<option value={20}>20</option>
																			<option value={50}>50</option>
																			<option value={100}>100</option>
																		</select> entries
																	</label>
																</div>
															</div>   */}
                                                        <div className="col-sm-12 col-md-12 mb-2">
                                                            <div className="btn-group float-end ">
                                                                <input type="search" placeholder="Search..." value={searchQuery} className="form-control form-control-sm" aria-controls="example" onChange={handleKeySearch} />
                                                                &nbsp;&nbsp;
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {!isLoading ?
                                                        <DataTable
                                                            columns={columns}
                                                            data={listData}
                                                            pagination
                                                            paginationServer
                                                            fixedHeaderScrollHeight='600px'
                                                            fixedHeader
                                                            persistTableHead
                                                            paginationTotalRows={totalRows}
                                                            paginationComponentOptions={paginationComponentOptions}
                                                            // selectableRows={selectedRows}
                                                            // onSelectedRowsChange={(e) => handleChangeCheckBox(e)}
                                                            clearSelectedRows={toggledClearRows}
                                                            paginationPerPage={perPage}
                                                            paginationDefaultPage={currentPage}
                                                            onChangeRowsPerPage={handlePerRowsChange}
                                                            onChangePage={handlePageChange}


                                                        /> :
                                                        <div className='loader-overlay text-white'>
                                                            <Loader />
                                                        </div>
                                                    }
                                                    <hr />
                                                </div>

                                            </div>
                                        </div>
                                    </div> {/* <!-- end col -->*/}
                                </div>
                            </>



                        </div> {/* container-fluid */}
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}

            <div className="rightbar-overlay"></div>
            <Modal size='lg' show={CreatenewUser} onHide={() => setCreatenewUser(false)} className='  modal fade ' backdrop="static" keyboard={false} >
                <Modal.Header className='modal-header_box bg-my-primary p-3 d-flex justify-content-between ' closeButton> <h1 className="modal-title fs-5 ">Create User  </h1> </Modal.Header>

                <form onSubmit={handleSubmitForm(onSubmit)}>
                    <ModalBody>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Email <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerForm('email', {
                                        required: 'Email is required', pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z.-]{2,}\.+[A-Z]{2,}$/i,
                                            message: "Please enter valid  email"
                                        },
                                    })} name="email" />
                                    {errorsForm.email && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.email.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">First Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerForm('first_name', {
                                        required: 'Firstname is required', pattern: {
                                            value: /^[A-Za-z][A-Za-z\s]*$/,
                                            message: 'Please enter only alphabets and ensure the first character is not a space',
                                        }
                                    })} name="first_name" />
                                    {errorsForm.first_name && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.first_name.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Last name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerForm('last_name', {
                                        required: 'Last name is required', pattern: {
                                            value: /^[A-Za-z][A-Za-z\s]*$/,
                                            message: 'Please enter only alphabets and ensure the first character is not a space',
                                        }
                                    })} name="last_name" />
                                    {errorsForm.last_name && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.last_name.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Username <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerForm('username', {
                                        required: 'Username is required', pattern:
                                        {
                                            value: /^[A-Za-z0-9][A-Za-z0-9\s]*$/,
                                            message: 'Please enter only alphabets and numbers ensure the first character is not a space',
                                        }
                                    })} name="username" />
                                    {errorsForm.username && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.username.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                    <label htmlFor="formrow-password-input" className="form-label">
                                        Password <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        className="form-control"
                                        {...registerForm('password', {
                                            required: 'Password is required',
                                            pattern: {
                                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/,
                                                message: 'Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character.'
                                            },
                                        })}
                                        id="formrow-password-input"
                                        name="password"
                                    />
                                    <span
                                        onClick={() => setShowPassword(!showPassword)}
                                        className="position-absolute end-0 top-50 translate-middle-y pe-3"
                                        style={{ margin: '12px', fontSize: '14px' }}
                                    >
                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                    {errorsForm.password && (
                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                            {errorsForm.password.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                    <label htmlFor="formrow-cpassword-input" className="form-label">
                                        Confirm Password <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        id="formrow-cpassword-input"
                                        className="form-control"
                                        {...registerForm('confirm_password', {
                                            required: 'Confirm password is required',
                                            pattern: {
                                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/,
                                                message: 'Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character.'
                                            }
                                        })}
                                        onKeyUp={(e) => checkpasswordtwo(e.target.value)}
                                        name="confirm_password"
                                    />
                                    <span
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        className="position-absolute end-0 top-50 translate-middle-y pe-3"
                                        style={{ margin: '12px', fontSize: '14px' }}
                                    >
                                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                    {errorsForm.confirm_password && (
                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                            {errorsForm.confirm_password.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label for="formrow-cpassword-input" className="form-label">Mobile <span className="text-danger">*</span></label>
                                    <input type="number" id='formrow-cpassword-input' className="form-control" {...registerForm('phone', {
                                        required: 'Mobile is required', pattern: { value: /^[0-9]+$/, message: "Please enter min 10 digit Phone Number" },
                                        minLength: { value: 10, message: "Please enter 10 digit phone number" },
                                        maxLength: { value: 10, message: "Please enter 10 digit phone number" },
                                    })} name="phone" />
                                    {errorsForm.phone && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.phone.message}</div>}
                                </div>
                            </div>
                        </div>


                    </ModalBody>
                    <Modal.Footer>
                        <div className="py-2 mb-3">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button className='btn btn-warning w-md btn-md me-2' type="button" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" onClick={() => { setCreatenewUser(false); }} > Cancel </button>
                                    <button className='btn btn-primary w-md btn-md ' type="submit" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn"  > Save </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>

                </form>
            </Modal>
            <Modal size='lg' show={changepassword} onHide={() => setchangepassword(false)} className='  modal fade ' backdrop="static" keyboard={false} >
                <Modal.Header className='modal-header_box bg-my-primary p-3 d-flex justify-content-between ' closeButton> <h1 className="modal-title fs-5 ">Change password  </h1> </Modal.Header>

                <form onSubmit={handleSubmitPassword(onSubmitPassword)}>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                    <label htmlFor="formrow-password-input" className="form-label">
                                        Password <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        className="form-control"
                                        {...registerPassword('password', {
                                            required: 'Password is required',
                                            pattern: {
                                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/,
                                                message: 'Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character.'
                                            },
                                        })}
                                        id="formrow-password-input"
                                        name="password"
                                    />
                                    <span
                                        onClick={() => setShowPassword(!showPassword)}
                                        className="position-absolute end-0 top-50 translate-middle-y pe-3"
                                        style={{ margin: '12px', fontSize: '14px' }}
                                    >
                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                    {errorsPassword.password && (
                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                            {errorsPassword.password.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3 position-relative">
                                    <label htmlFor="formrow-cpassword-input" className="form-label">
                                        Confirm Password <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        id="formrow-cpassword-input"
                                        className="form-control"
                                        {...registerPassword('confirm_password', {
                                            required: 'Confirm password is required',
                                            pattern: {
                                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/,
                                                message: 'Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character.'
                                            }
                                        })}
                                        onKeyUp={(e) => checkpasswordtwo2(e.target.value)}
                                        name="confirm_password"
                                    />
                                    <span
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        className="position-absolute end-0 top-50 translate-middle-y pe-3"
                                        style={{ margin: '12px', fontSize: '14px' }}
                                    >
                                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                    </span>
                                    {errorsPassword.confirm_password && (
                                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                                            {errorsPassword.confirm_password.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>


                    </ModalBody>
                    <Modal.Footer>
                        <div className="py-2 mb-3">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button className='btn btn-warning w-md btn-md me-2' type="button" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" onClick={() => { setchangepassword(false); }} > Cancel </button>
                                    <button className='btn btn-primary w-md btn-md ' type="submit" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn"  > Save </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>

                </form>
            </Modal>

            <Modal size='lg' show={EditUser} onHide={() => setEditUser(false)} className='  modal fade ' backdrop="static" keyboard={false} >
                <Modal.Header className='modal-header_box bg-my-primary p-3 d-flex justify-content-between ' closeButton> <h1 className="modal-title fs-5 ">Update User  </h1> </Modal.Header>

                <form onSubmit={handleSubmitEdit(onSubmitEdit)}>
                    <ModalBody>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Email <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerEdit('email', {
                                        required: 'Email is required', pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z.-]{2,}\.+[A-Z]{2,}$/i,
                                            message: "Please enter valid  email"
                                        },
                                    })} name="email" />
                                    {errorsEdit.email && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsEdit.email.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">First Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerEdit('first_name', {
                                        required: 'Firstname is required', pattern: {
                                            value: /^[A-Za-z\s]+$/, // Include \s for space character
                                            message: 'Please enter only alphabets',
                                        }
                                    })} name="first_name" />
                                    {errorsEdit.first_name && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsEdit.first_name.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Last name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerEdit('last_name', {
                                        required: 'Last name is required', pattern: {
                                            value: /^[A-Za-z\s]+$/, // Include \s for space character
                                            message: 'Please enter only alphabets',
                                        }
                                    })} name="last_name" />
                                    {errorsEdit.last_name && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsEdit.last_name.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Username <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerEdit('username', { required: 'Username is required' })} name="username" />
                                    {errorsEdit.username && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsEdit.username.message}</div>}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label for="formrow-cpassword-input" className="form-label">Mobile <span className="text-danger">*</span></label>
                                    <input type="number" id='formrow-cpassword-input' className="form-control" {...registerEdit('phone', {
                                        required: 'Mobile is required', pattern: { value: /^[0-9]+$/, message: "Please enter min 10 digit Phone Number" },
                                        minLength: { value: 10, message: "Please enter 10 digit phone number" },
                                        maxLength: { value: 10, message: "Please enter 10 digit phone number" },
                                    })} name="phone" />
                                    {errorsEdit.phone && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsEdit.phone.message}</div>}
                                </div>
                            </div>
                        </div>


                    </ModalBody>
                    <Modal.Footer>
                        <div className="py-2 mb-3">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button className='btn btn-warning w-md btn-md me-2' type="button" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" onClick={() => { setEditUser(false); }} > Cancel </button>
                                    <button className='btn btn-primary w-md btn-md ' type="submit" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn"  > Save </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>

                </form>
            </Modal>
            <Modal size='md' show={userrolechange} onHide={() => setuserrolechange(false)} className='  modal fade ' backdrop="static" keyboard={false} >
                <Modal.Header className='modal-header_box bg-my-primary p-3 d-flex justify-content-between ' closeButton> <h1 className="modal-title fs-5 ">Role Change  </h1> </Modal.Header>
                <ModalBody>
                    <div className="mb-3">
                        <label htmlFor="level_id" className="form-label">Role</label>
                        <select
                            className="form-select"
                            aria-label="Default select example"
                            {...registerForm('level_id')}
                            onChange={handleLevel}
                        >
                            <option value="">Select Role</option>
                            {departmentList &&
                                departmentList.map((department, index) => (
                                    <option key={index} value={department.id}>
                                        {department.name}
                                    </option>
                                ))}
                        </select>
                    </div>
                </ModalBody>
                <Modal.Footer>
                    <div className="py-2 mb-3">
                        <div className="row">
                            <div className="col-12 text-center">
                                <button className='btn btn-warning w-md btn-md me-2' type="button" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" onClick={() => { setuserrolechange(false); }} > Cancel </button>
                                <button className='btn btn-primary w-md btn-md ' type="submit" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" onClick={() => changerolesfromuserside()} > Save </button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal size='md' show={SuccessAlert} onHide={() => setSuccessAlert(false)} className='text-center  modal fade ' backdrop="static" keyboard={false} >
                <Modal.Header className='modal-header_box bg-my-primary p-3 d-flex justify-content-between ' closeButton> <h1 className="modal-title fs-5 ">Create User  </h1> </Modal.Header>
                <ModalBody>
                    <h4>User created Successfully</h4>
                </ModalBody>
                <Modal.Footer>
                    <div className="py-2 mb-3">
                        <div className="row">
                            <div className="col-12 text-center">

                                <button className='btn btn-primary w-md btn-md ' id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" onClick={() => setSuccessAlert(false)}  > Ok </button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}
