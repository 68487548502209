//Global values for static select
export const invoiceStatus = [
    { value: '', label: 'All' },
    { value: 'sent', label: 'sent' },
    { value: 'draft', label: 'Draft' },
    { value: 'unpaid', label: 'Unpaid' },
    { value: 'paid', label: 'Paid' },
    { value: 'canceled', label: 'Cancelled' },
    
     
]

export const invoiceType = [
    { value: 'I', label: 'Monthly bill' },
    { value: 'R', label: 'Refill' },
]

export const reportType = [
    { value: 'IN', label: 'Inbound' },
    { value: 'OUT', label: 'Outbound' },
]

export const reportStatus = [
    { value: 'all', label: 'All' },
    { value: 'answered', label: 'Answered' },
    { value: 'no answer', label: 'No Answer' },
    { value: 'Failed', label: 'Failed' },
]

export const cardType = [
    { value: 'MasterCard', label: 'MasterCard' },
    { value: 'VisaCard', label: 'Visa' },
    { value: 'AmExCard', label: 'American Express' },
    { value: 'DiscoverCard', label: 'Discover' },
]

export const paymentType = [
    { value: 'pending', label: 'Pending' },
    { value: 'completed', label: 'Completed' },
]
export const didType = [
    { value: 'local', label: 'Local DIDs' },
    { value: 'toll_free', label: 'Toll free DIDs' },
    { value: 'vfax', label: 'Vfax DIDs' },
    { value: 'international', label: 'International DIDs' },
]
export const cdrCallType = [
    { value: 'inbound', label: 'Inbound' },
    { value: 'outbound', label: 'Outbound' },
]
export const localOpt = [
    { value: 'state', label: 'Search By State' },
    { value: 'areacode', label: 'Search By AreaCode' },
    { value: 'npanxxx', label: 'Search By NPANXX' },
]
//common validation 

export const onlyAlphabets = {
    value: /^[A-Za-z]+$/,
    message: 'Please enter only alphabets',
};

export const onlyAlphabetsandSpaces = {
    value: /^[A-Za-z\s]+$/, // Include \s for space character
    message: 'Please enter only alphabets',
};


export const onlyNumbers = {
    value: /^[0-9]+$/,
    message: 'Please enter only numbers',
}
export const onlyNumbersandSpaces = {
    value: /^[0-9\s]+$/, // Include \s for space character
    message: 'Please enter only numbers',
}
export const onlyAlphaNumeric = {
    value: /^[a-zA-Z0-9]+$/,
    message: 'Please enter only numbers and alphabets',
};

export const onlyAlphaNumericAndSpaces = {
    value: /^[a-zA-Z0-9\s]+$/,
    message: 'Please enter only numbers and alphabets',
};

export const createPattern = (allowedSpecialChars) => {
    const pattern = new RegExp(`^[a-zA-Z0-9${allowedSpecialChars}\\s]+$`);
    const message = `Please enter only numbers, alphabets and ${allowedSpecialChars}`;
    return { value: pattern, message: message };
};

export const numberSpecialChar = (allowedSpecialChars) => {  //allows  special characters and numbers
    const pattern = new RegExp(`^[0-9${allowedSpecialChars}]+$`);
    const message = `Only numbers and ${allowedSpecialChars}  are allowed`;
    return { value: pattern, message: message };
};

export const MaxLengthValidation = (maxLength) => ({
    value: maxLength,
    message: `Maximum length exceeded. Maximum ${maxLength} characters allowed.`,
});
export const MinLengthValidation = (minLength) => ({
    value: minLength,
    message: `Minimum length is not reached. Minimum ${minLength} characters allowed.`,
});

export const menuIdData = {
    'customer_list': 3,
    'active_customers': 4,
    'customer_service': 5,
    'manage_service': 6,
    'assigned_dids': 8,
    'unassigned_dids': 9,
    'order_dids': 10,
    'billing_info': 12,
    'payment_details': 13,
    'invoice': 14,
    'create_invoice': 15,
    'balance_history': 16,
    'transaction_history': 17,
    'call_history': 21,
    'revenue_report': 20,
    'manage_tickets': 23,
    'create_tickets': 24,
    'ticket_categories': 25,
    'create_ticket_categories': 26,
    'portability_request_status': 28,
    'endpoints': 30,
    'user_documents': 32,
    'promotional_offer': 33,
    'documents_type': 34,
    'user_department': 36,
    'user_logs_list': 37,
    'user_level': 38,
    'user_list': 39,
    'user_menu': 40,
    'menu_permission': 41,
    'predefined_replies': 42,
    'customer_notes': 43,
    'merge_tickets': 45,
    'change_ticket_category': 46,
    'change_assigned_user': 47,
    'change_ticket_priority': 48,
    'help_documents': 49,
}
