import Header from "../../../common/Header";
import Sidebar from "../../../common/Sidebar";
import Footer from "../../../common/Footer";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { triggerAlert, getToken } from '../../../utils';
import Loader from '../../../common/Loader';
import PageHeader from "../../PageHeader";
import { useForm, Controller } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';

const AddCategory = () => {
    const api_url = process.env.REACT_APP_API_BASE_URL;
    const customer_api_url = process.env.REACT_APP_API_CUSTOMER_URL;
    const token = getToken();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const mainHeading = 'Add Tickets Categories';
    const heading = 'Supports / Tickets Categories';
    const { control, register, handleSubmit, formState: { errors }, setValue, reset, clearErrors } = useForm();
    const {register: registerModal, handleSubmit: handleSubmitModal,formState: { errors: errorsModal },clearErrors: clearErrorsModal,setValue: setValueModal} = useForm();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const [addpermission, setaddpermission] = useState(false)
    const [editPermission, seteditPermission] = useState(false)
    const [customerlist, setcustomerlist] = useState(false)
    const [deletePermission, setdeletePermission] = useState(false)
    const formSubmit = async (data) => {
        try {
            setIsLoading(true);
            const params = {
                'ticket_category_name': data.sub_category,
                'ticket_parent_category': data.category
            };
            const response = await axios.post('/apiV1/supports/add_ticket_sub_category', params);
            if (response.status === 201) {
                triggerAlert('success', 'Success', `Category Added Successfully!!!`);
                navigate('/support/categories');
                setIsLoading(false);
            } else {
                setIsLoading(false);
                throw new Error('Unable to Add Category Successfully!!!');
            }
        } catch (err) {
            setIsLoading(false);
            triggerAlert('error', 'Oops...', err.message);
        }
    };
    const formModalSubmit = async (data1) => {
        console.log('form submitted ');
        try {
            setIsLoading(true);
            const params = {
                'parent_category': data1.category
            };

            const response = await axios.post('/apiV1/get_ticket_parent_category/',params);
            if (response.status) {
                triggerAlert('success', 'Success', `Category Added Successfully!!!`);
                navigate('/support/categories');
                setIsLoading(false);
                setShowPopup(!showPopup)
            } else {
                setIsLoading(false);
                handleClose()
                setShowPopup(!showPopup)
                throw new Error('Unable to Add Category Successfully!!!');
            }
        } catch (err) {
            setIsLoading(false);
            if (err.response.data.message) {
                triggerAlert('error', 'Oops...', err.response.data.message);
            }else
            {

                triggerAlert('error', 'Oops...', err);
            }
            setShowPopup(!showPopup)
        }

    };

    const fetchCategoryList = async () => {
        try {
            setIsLoading(true);
            const ticketsCategoryList = await axios.get(`/apiV1/get_ticket_parent_category/`);
            const responseStatus = ticketsCategoryList.status;
            if (responseStatus == 200) {
                const itemsArray = ticketsCategoryList.data.results;
                setCategoryList(itemsArray);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                throw new Error("Something went wrong..");
            }
        } catch (err) {
            setIsLoading(false);
            triggerAlert("error", err.message);
        }
    }

    useEffect(() => {
        fetchCategoryList();
    },[]);
    const [showPopup, setShowPopup] = useState(false);
    const handleClose = () => 
        {
        setShowPopup(false);
        clearErrorsModal('category');
        setValueModal('category', '')
    }

    const handleCategory = ()=>{
        setShowPopup(true)
    }
    const access_privileges = JSON.parse(localStorage.getItem('modulepermissions'))
    useEffect(() => {
        fetchCategoryList(1)
        access_privileges.map((access, i) => {
            access.sub_module_info.map((subaccess, k) => {
                if (subaccess.name == "Create Categories" && subaccess.permissions.indexOf(1) !== -1) {
                    setcustomerlist(true)
                }
                if (subaccess.name == "Create Categories" && subaccess.permissions.indexOf(2) !== -1) {
                    setaddpermission(true)
                }
                if (subaccess.name == "Create Categories" && subaccess.permissions.indexOf(3) !== -1) {
                    seteditPermission(true)
                }
                if (subaccess.name == "Create Categories" && subaccess.permissions.indexOf(4) !== -1) {
                    setdeletePermission(true)
                }

            })


        })
    }, []);
    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    {isLoading ? <div className='loader-overlay text-white'>
                        <Loader />
                    </div> : ""}
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* <!-- start page title --> */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* <!-- end page title --> */}
                            <div className="row mt-4">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body p-4">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                {addpermission && <button className="btn btn-primary w-md me-md-2 mb-3 addCategory" onClick={handleCategory}>Add Category</button>
                                                }
                                                    <div>
                                                        <form id="creditCardForm" key={2} novalidate="novalidate" enctype="multipart/form-data" onSubmit={handleSubmit(formSubmit)}>
                                                            <div className="row">
                                                                <div className="col-md-4">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" for="formrow-lname-input">Category <span style={{ color: 'red' }}>*</span></label>
                                                                        <select className="form-select" {...register('category', { required: 'Category is required' })} >
                                                                            <option value="">Please Select</option>
                                                                            {categoryList && categoryList.map((list,index) => (
                                                                                <option value={list.id
                                                                                }>{list.parent_cat_name
                                                                                    }</option>
                                                                            ))}
                                                                        </select>
                                                                        {errors.category && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.category.message}</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" for="formrow-name-input">Sub Category <span style={{ color: 'red' }}>*</span></label>
                                                                        <input type="text" name="sub_category" id="formrow-name-input" className="form-control" placeholder="Enter your Sub Category" {...register('sub_category', {
                                                                            required: 'Sub Category is required',
                                                                        })} />
                                                                        {errors.sub_category && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.sub_category.message}</div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
                                                                <button className="btn btn-warning w-md" type="button" onClick={() => navigate('/support/categories')}>Cancel</button>
                                                                <button className="btn btn-primary w-md me-md-2" type="submit"disabled={!addpermission}>Create</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- container-fluid --> */}
                        <Modal size='md' show={showPopup} onHide={() => setShowPopup(false)} keyboard={false} backdrop="static">
                            <Modal.Header closeButton className="fs-5 fw-bolder" onClick={handleClose}>Add Category</Modal.Header>
                            <Modal.Body>
                                <form id="modalForm" key="modalForm" noValidate encType="multipart/form-data" onSubmit={handleSubmitModal(formModalSubmit)}>
                                    <div className="row">
                                        <div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="formrow-name-input">Category <span style={{ color: 'red' }}>*</span></label>
                                                <input
                                                    type="text"
                                                    name="category"
                                                    id="formrow-name-input"
                                                    className="form-control"
                                                    placeholder="Enter your Category"
                                                    autoComplete="off"
                                                    {...registerModal('category', { required: 'Category is required' })}
                                                />
                                                {errorsModal.category && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsModal.category.message}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
                                        <button className="btn btn-warning w-md" type="button" onClick={handleClose}>Cancel</button>
                                        <button className="btn btn-primary w-md me-md-2" type="submit">Create</button>
                                    </div>
                                </form>
                            </Modal.Body>
                        </Modal>
                    </div>
                    {/* <!-- End Page-content --> */}
                    <Footer />
                </div>
                {/* end main content*/}
            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>

        </>
    );

}

export default AddCategory;