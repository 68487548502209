import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { getToken, simpleAlert, triggerAlert } from '../utils';

const fetchData = async (apiUrl, token) => {
  try {
    const response = await axios.get(apiUrl);
    const responseData = response.data;

    if (responseData) {
      return responseData.results || [];
    } else if (response.status === 204) {
      // simpleAlert('No Data Available!');
      return [];
    } else {
      triggerAlert('error', 'Oops...', "Couldn't get the options");
      return [];
    }
  } catch (error) {
    console.error('Error loading options:', error);
    return [];
  }
};

const AutoSearchSelect = ({
  apiUrl,
  onSelect,
  placeholder,
  mapOption, // Mapping function to transform API response into the expected format
  value,
  disabled,
}) => {
  const [options, setOptions] = useState([]);
  // console.log(value);
  useEffect(() => {
    const token = getToken();
    fetchData(apiUrl, token).then(apiResponse => {
      if (apiResponse && Array.isArray(apiResponse)) {
        const mappedOptions = apiResponse.map(mapOption);
        setOptions(mappedOptions);
      } else {
        setOptions([]);
      }
    });
  }, [apiUrl]);
  const loadOptions = (inputValue, callback) => {
    const filteredOptions = options.filter(option =>
      option.label.toString().toLowerCase().includes(inputValue.toLowerCase())
    );
    callback(filteredOptions);
  };

  const handleSelect = selectedOption => {
    if (onSelect) {
      if (selectedOption) {
        onSelect(selectedOption);
      } else {
        onSelect('');
      }
    }
  };

  return (
    <AsyncSelect
      cacheOptions
      isClearable // to clear selected option
      loadOptions={loadOptions}
      defaultOptions={options}
      onChange={handleSelect}
      isDisabled={disabled}
      placeholder={placeholder || 'Search...'}
      value={options.find(option => option.value == value)} // Preselect based on the provided value
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          border: '1px solid #e9e9ef !important',
          boxShadow: '1px solid #f8f9fa',
          background: '#f8f9fa',
          color: '#000',
        }),
      }}
    />
  );
};

export default AutoSearchSelect;
