import Header from "../../../common/Header";
import Sidebar from "../../../common/Sidebar";
import Footer from "../../../common/Footer";
import PageHeader from "../../PageHeader";
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { getToken, triggerAlert, handleTableRowClick, formattedDateTime, pageReload, get_user_menu_permission } from '../../../utils';
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import Loader from "../../../common/Loader";
import { menuIdData } from "../../../utils/constants";
// import queryString from 'query-string';
import Pagination from "../../PaginationComponent";

function DocumentList() {
	const api_url = process.env.REACT_APP_API_BASE_URL;
	const token = getToken();
	const navigate = useNavigate();
	const mainHeading = 'Documents';
	const heading = 'Others /Documents';
	// Fetch Data Api integration 
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedRowId, setSelectedRowId] = useState(null);
	const [documents, setDocuments] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	// Menu Permissions
	const [addPermission, setAddPermission] = useState(false);
	const [editPermission, setEditPermission] = useState(false);
	const [deletePermission, setDeletePermission] = useState(false);
	//  const [exportPermission, setExportPermission] = useState(false);
	const [pageCount, setPageCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [perPageLimit, setPerPageLimit] = useState(10);
	const [pageSlNo, setPageSlNo] = useState(0);
	const [searchQuery, setSearchQuery] = useState('');
	const [pageLimitSelected, setPageLimitSelected] = useState(10);
	// const pageSize = process.env.REACT_APP_API_SHOW_ENTRIES;
	const [pageNum, setPageNum] = useState(1);
	const [totalPageCount, setTotalPageCount] = useState(0);
	const [keyword, setKeyword] = useState(null);
	const pageSize = 2;
	// const fetchDocumentData = async (page, searchkey = '') => {
	// 	setIsLoading(true);
	// 	const per_page = perPageLimit;

	// 	try {
	// 		setPageSlNo((page - 1) * per_page);



	// 		const response = await axios.get(api_url + `get_all_doc_names?page_size=${pageSize}&page_number=${pageNum}`, token);



	// 		const responseData = response.data;

	// 		if (response.status === 200 && responseData.error_code === 200) {
	// 			setIsLoading(false);
	// 			const data = responseData.results.data || [];
	// 			const total_pages = responseData.results.pagination.total_pages;

	// 			if (Array.isArray(data) && data.length > 0) {
	// 				setDocuments(data);
	// 				setPageCount(total_pages);
	// 				setPageSlNo(per_page * (page - 1)); // Update pageSlNo based on the current page
	// 			} else {
	// 				// Handle no data case directly in the UI without showing a popup
	// 				setDocuments([]);
	// 				setPageCount(0);
	// 			}
	// 		} else {
	// 			// Handle other response status or error_code
	// 			console.error('Fetch Data Error:', responseData.message || 'Something went wrong..');
	// 		}
	// 	} catch (error) {
	// 		setIsLoading(false);
	// 		// Handle error case directly in the UI without showing a popup
	// 		console.error('Fetch Data Error:', error);
	// 	}
	// };

	const fetchDocumentData = async () => {
		setIsLoading(true);
		try {
			const response = await axios.get(api_url + `get_all_doc_names?page_size=${pageSize}&page_number=${pageNum}`, token);
			const data = response.data.results.data;
			setIsLoading(false);
			setDocuments(data);
			const total_pages = response.data.results.pagination.total_pages;
			setTotalPageCount(total_pages);
		} catch (error) {
			setIsLoading(false);
			triggerAlert('error', 'Oops...', 'Something went wrong..');
		}
	};


	useEffect(() => {
		fetchDocumentData();
	}, [pageNum])


	const handleEditForm = (e) => {
		if (selectedRow) {
			navigate('/others/manage_documents?mode=update', { state: selectedRow });
		} else {
			triggerAlert('error', 'Oops...', 'Please select a row');
		}
	}

	const deleteDocuments = () => {
		if (selectedRowId) {
			Swal.fire({
				icon: 'warning',
				title: 'Do you want to delete data?',
				showCancelButton: true,
				confirmButtonText: 'Delete',
			}).then(async (result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					var id = selectedRowId;
					try {
						const response = await axios.get(api_url + `delete_user_doc_name?doc_id=${id}`, token);
						const response_data = response.data;
						if (response_data.error_code == "200") {
							triggerAlert('success', 'success', response_data.message);
							pageReload();
						} else {
							triggerAlert('error', 'Oops...', 'Something went wrong..');
						}
					} catch (error) {
						triggerAlert('error', 'Oops...', 'Something went wrong..');
					}
				}
			})
		} else {
			triggerAlert('error', 'Oops...', 'Please select a row');
		}
	};

	useEffect(() => {
		const menu_id = menuIdData?.documents_type;
		setAddPermission(get_user_menu_permission(menu_id, 'add'));
		setEditPermission(get_user_menu_permission(menu_id, 'edit'));
		setDeletePermission(get_user_menu_permission(menu_id, 'delete'));
		// setExportPermission(get_user_menu_permission(menu_id, 'export'));
	}, []);

	const handlePageClick = (selected) => {
		const selectedPage = selected.selected;
		setPageNum(selectedPage + 1);
	};

	let props = {
		pageCount: totalPageCount,
		handlePageClick: handlePageClick,
		selectedPage: pageNum - 1,
	};

	const handleKeySearch = (e) => {
		setPageNum(1);
		setKeyword(e.target.value);
	}




	return (
		<>
			<div id="layout-wrapper">
				<Header />
				<Sidebar />
				<div className="main-content">
					{isLoading ? <div className='loader-overlay text-white'>
						<Loader />
					</div> : ""}
					<div className="page-content">
						<div className="container-fluid">
							{/* start page title */}
							<PageHeader mainHeading={mainHeading} heading={heading} />
							<div class="row mt-5">
								<div class="col-12">
									<div class="card">
										{(addPermission || editPermission || deletePermission) &&
											<div className="card-header">
												<div className="row">
													<div className="col-sm-12 col-md-11">
													</div>

													<div className="btn-group   mb-3 float-end col-md-1">
														<button type="button" className="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
															Action <i className="mdi mdi-chevron-down"></i>
														</button>
														<div className="dropdown-menu dropdown-menu-end py-2">
															{addPermission && <Link to="/others/manage_documents?mode=add" className="dropdown-item"><i class="bx bx-plus"></i> Create </Link>}
															{editPermission && <a href="#" onClick={(e) => handleEditForm(e)} className="dropdown-item"><i className="far fa-edit font-size-14 text-black"></i> Edit</a>}
															{deletePermission && <Link to="#" onClick={deleteDocuments} className="dropdown-item"><i className="bx bx-trash"></i> Delete</Link>}
														</div>
													</div>

												</div>
											</div>
										}
										<div class="card-body">
											<table id="datatable" class="table table-bordered dt-responsive  table-responsive  nowrap w-100">
												<thead>
													<tr>
														<th>S No</th>
														<th>Document</th>
														<th>Created Date</th>
														<th>Updated Date</th>
													</tr>
												</thead>
												<tbody>
													{documents?.map((document, index) => (
														<tr onClick={() => handleTableRowClick(document, selectedRow, setSelectedRow, selectedRowId, setSelectedRowId, document.id)} className={selectedRowId === document.id ? 'row_selected' : ''}>
															<td>{pageNum > 1 ? (pageNum - 1) * pageSize + index + 1 : index + 1}</td>
															<td>{document.user_document_name}</td>
															<td>{formattedDateTime(document.created_at)}</td>
															<td>{formattedDateTime(document.updated_at)}</td>
														</tr>
													))}

												</tbody>
											</table>
											<Pagination {...props} />

										</div>
									</div>
								</div>
							</div>





						</div> {/* container-fluid */}
					</div>
					{/* End Page-content */}
					<Footer />
				</div>
				{/* end main content*/}

			</div>
			{/* END layout-wrapper */}




			{/* Right bar overlay*/}
			<div className="rightbar-overlay"></div>
		</>
	);
}

export default DocumentList;