import Header from "../../../common/Header";
import Sidebar from "../../../common/Sidebar";
import Footer from "../../../common/Footer";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { triggerAlert, getToken } from '../../../utils';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AutocompleteSearch from "../../AutocompleteSearch";
import Loader from '../../../common/Loader';
import AutoSearchSelect from '../../CommonSelectDynamic';
import AutoUserSearch from "../../AutoUserSearch";

import Editor from "../../Editor";
const Add = () => {
    const api_url = process.env.REACT_APP_API_BASE_URL;
    const token = getToken();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const editorConfiguration = {
        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
    };
    const { control, register, handleSubmit, formState: { errors }, setValue, reset, clearErrors } = useForm();
    const [editorInstance, setEditorInstance] = useState(null);
    const [base64Image, setBase64Image] = useState('');

    const formSubmit = async (data) => {
        try {
            setIsLoading(true);
            const params = {
                'user_id': data.user,
                'notes': data.editorContent
            };
            // console.log('kjkjf', params); 
            const response = await axios.post(api_url + `customer_notes/add_data`, params, token);
            if (response.status === 201) {
                triggerAlert('success', 'Success', `Customer Note Added Successfully!!!`);
                navigate('/support/customer_notes');
                setIsLoading(false);
            } else {
                setIsLoading(false);
                throw new Error('Unable to Add Customer Note Successfully!!!');
            }
        } catch (err) {
            setIsLoading(false);
            triggerAlert('error', 'Oops...', err.message);
        }
    };

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        if (data) {
            clearErrors('editorContent');
        }
        setValue('editorContent', data);
    };

    const handleOnFocus = () => {
        if (!dataLoaded) {
            searchCustomers();
            setDataLoaded(true);
        }
    };

    const handleOnClear = () => {
        setValue('user', '');
    };

    const handleOnSelect1 = (item) => {
        setValue('user', item.id);
        if (item.id) {
            clearErrors('user');
        }
    };

    const handleUser = (selected) => {

        setValue('user', selected ? selected.value : null);

    }


    let autoCompleteProps = {
        items: items,
        fuseOptions: { keys: ["user_name"] },
        // handleOnSearch: handleOnSearch,
        placeholder: 'Search user...',
        handleOnSelect: handleOnSelect1,
        resultString: "user_name",
        // handleOnHover: handleOnHover,
        handleOnFocus: handleOnFocus,
        handleOnClear: handleOnClear,
        // formatResult: formatResult,
        // searchQuery: searchQuery,
    };

    const searchCustomers = async () => {
        try {
            const response = await axios.get(api_url + `dashboard/get_all_customers`, token);
            const response_data = response.data;
            if (response_data.error_code === 200) {
                const itemsArray = response_data.results
                //console.log('response_itemsArray', itemsArray);
                setItems(itemsArray);
            }
            else if (response.status === 204) {
                setItems([]);
            } else {
                triggerAlert('error', 'Oops...', "Couldn't get the user data");
            }
        } catch (error) {
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    };

    useEffect(() => {
        if (editorInstance) {
            const editor = editorInstance;
            editor.ui
                .getEditableElement()
                .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                );
        }
    }, [editorInstance]);

    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div class="main-content">
                    {isLoading ? <div className='loader-overlay text-white'>
                        <Loader />
                    </div> : ""}
                    <div class="page-content">
                        <div class="container-fluid">
                            {/* <!-- start page title --> */}
                            <div class="row">
                                <div class="col-12">
                                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 class="mb-sm-0 font-size-18">Create Customer Notes</h4>
                                        <div class="page-title-right">
                                            <ol class="breadcrumb m-0">
                                                <li class="breadcrumb-item"><a href="javascript: void(0);">Dashboard</a></li>
                                                <li class="breadcrumb-item"><a href="javascript: void(0);"> Customer Notes List</a></li>
                                                <li class="breadcrumb-item active">Create Customer Notes</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div class="row mt-4">
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-body p-4">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div>
                                                        <form id="creditCardForm" key={2} novalidate="novalidate" enctype="multipart/form-data" onSubmit={handleSubmit(formSubmit)}>
                                                            <div class="row">
                                                                <div class="col-md-4" style={{ position: 'relative' }}>
                                                                    <div class="mb-3" style={{ position: 'relative', zIndex: 100 }}>
                                                                        <label class="form-label" for="formrow-lname-input">User <span style={{ color: 'red' }}>*</span></label>
                                                                        {/* <AutocompleteSearch {...autoCompleteProps} {...register('user', { required: 'User is required' })} /> */}
                                                                        <Controller
                                                                            name="user"
                                                                            {...register('user', { required: 'User is required' })}
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <AutoUserSearch onSelect={(user) => {
                                                                                    setValue('user', user ? user.user_id : null);
                                                                                }} />
                                                                            )}
                                                                        />
                                                                        {errors.user && <p style={{ color: 'red' }}>{errors.user.message}</p>}
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12">
                                                                    <label class="form-label" for="formrow-lname-input">Note <span style={{ color: 'red' }}>*</span></label>
                                                                    <Controller
                                                                        name="editorContent"
                                                                        control={control}
                                                                        defaultValue="Default content"
                                                                        rules={{ required: 'Content is required' }}
                                                                        render={({ field, fieldState }) => (
                                                                            <>
                                                                                <Editor

                                                                                    onChange={field.onChange}
                                                                                    value={field.value}
                                                                                />

                                                                                <div style={{ color: 'red' }}>{fieldState?.error?.message}</div>
                                                                            </>
                                                                        )}
                                                                    />

                                                                </div>

                                                            </div>
                                                            <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
                                                                <button class="btn btn-warning w-md" type="button" onClick={() => navigate('/support/customer_notes')}>Cancel</button>
                                                                <button class="btn btn-primary w-md me-md-2" type="submit">Create</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                    <Footer />
                </div>
                {/* end main content*/}
            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </>
    );

}

export default Add;