import Header from "../../../common/Header";
import Sidebar from "../../../common/Sidebar";
import Footer from "../../../common/Footer";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useLocation } from "react-router-dom";
import { triggerAlert, getToken, getBase64, capitalizeFirst, simpleAlert, downloadFile } from '../../../utils';
import Loader from '../../../common/Loader';
import PageHeader from "../../PageHeader";
import AutoSearchSelect from '../../CommonSelectDynamic'
import AutoUserSearch from "../../AutoUserSearch";
import { onlyAlphabetsandSpaces } from "../../../utils/constants";


export default function ManageHelpDoc() {
    const api_url = process.env.REACT_APP_API_BASE_URL;
    const api_url_admin = process.env.REACT_APP_API_BASE_URL;
    const token = getToken();
    const navigate = useNavigate();
    const { search, state } = useLocation();
    const params = new URLSearchParams(search);
    const mode = params.get('mode');
    const [isLoading, setIsLoading] = useState(false);
    const [imageData, setImageData] = useState();
    const [userData, setUserData] = useState();
    const [documentData, setDocumentData] = useState();
    const [userDocData, setUserDocData] = useState();
    const mainHeading = `${capitalizeFirst(mode)} Help Documents`;
    const heading = 'Supports / User Documents';
    const { control, register, handleSubmit, formState: { errors }, setValue, reset, clearErrors } = useForm();

    const formSubmit = async (data) => {
        // console.log("formSubmit", data)
        // return
        if (data) {
            try {
                setIsLoading(true);
                const params = {
                    help_name: data.help_name,
                    help_type: data.help_type,
                    document_file: imageData
                };
                var response;
                if (mode === "update") {
                    response = await axios.put(api_url + `help_documents/update_data/` + userDocData.help_id + `/update`, params, token);
                } else {
                    response = await axios.post(api_url + `help_documents/add_data`, params, token);
                }
                if (response.status === 201 || response.status === 200) {
                    triggerAlert('success', 'Success', response.message);
                    navigate('/support/help_documents', { state: null });
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    triggerAlert('error', 'Oops...', 'Something went wrong');
                }
            } catch (err) {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', err.message);
            }
        }
        else {
            simpleAlert('Search and Select the User!!');
        }

    }

    // const handleFileChange = async (event) => {
    //     console.log("handleFileChangeevent", event)
    //     setValue('user_document', event.target.files[0]);
    //     const file = event.target.files[0];
    //     console.log("handleFileChange", file)
    //     const base64Data = await getBase64(file);
    //     // console.log(base64Data);
    //     setImageData(base64Data);
    // }
    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        // Check if a file is selected
        if (file) {
            // setValue('user_document', file);
            const base64Data = await getBase64(file);
            setImageData(base64Data);
        }
    };


    const setEditValues = () => {
        // console.log('state', state)
        // console.log(state);
        if (state != null) {
            setUserDocData(state);
            setValue('help_name', state.help_name);
            setValue('help_type', state.help_type);
            setValue('document_file', state.help_document_file);
            setImageData(state.help_document_file)
        } else {
            navigate('/support/help_documents');
        }
    }

    // const getCustomersList = async () => {
    //     try {
    //         const response = await axios.get(api_url + `dashboard/get_all_customers`, token);
    //         const response_data = response.data;
    //         if (response_data.error_code === 200) {
    //             const itemsArray = response_data.results;
    //             // console.log(itemsArray);
    //             setUserData(itemsArray);
    //         } else if (response.status === 204) {
    //             setUserData([]);
    //         } else {
    //             triggerAlert('error', 'Oops...', "Couldn't get the user data");
    //         }
    //     } catch (error) {
    //         triggerAlert("error", "Oops...", "Something went wrong..");
    //     }
    // };


    const handleUser = (selected) => {
        setValue('user', selected ? selected.value : null)
    }


    // const getDocumentList = async () => {
    //     try {
    //         const response = await axios.get(api_url + `get_all_doc_names?type=no_pagination`, token);
    //         const response_data = response.data;
    //         if (response_data.error_code === 200) {
    //             const itemsArray = response_data.results;
    //             setDocumentData(itemsArray);
    //         } else if (response.status === 204) {
    //             setDocumentData([]);
    //         } else {
    //             triggerAlert('error', 'Oops...', "Couldn't get the user data");
    //         }
    //     } catch (error) {
    //         triggerAlert("error", "Oops...", "Something went wrong..");
    //     }
    // };


    useEffect(() => {
        if (mode === "update") {
            setEditValues();
        }
        // getCustomersList();
        // getDocumentList();
    }, [mode]);
    // console.log("mode", mode)

    const handleDownload = (url) => {
        const filename = url?.split("/")?.pop();
        downloadFile(url, filename);
    };

    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    {isLoading ? <div className='loader-overlay text-white'>
                        <Loader />
                    </div> : ""}
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* <!-- start page title --> */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* <!-- end page title --> */}
                            <div className="row mt-4">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body p-4">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div>
                                                        <form id="creditCardForm" key={2} novalidate="novalidate" enctype="multipart/form-data" onSubmit={handleSubmit(formSubmit)}>
                                                            <div className="row">
                                                                <div class="col-md-4">
                                                                    <div class="mb-3">
                                                                        <label class="form-label" for="formrow-PhoneNumber-input">Document Name <span style={{ color: 'red' }}>*</span></label>
                                                                        {/* <Controller
                                                                            name="user"
                                                                            {...register('user'
                                                                                , { required: 'User is required' }
                                                                            )}
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <AutoSearchSelect
                                                                                    // {...field}
                                                                                    value={field.value}
                                                                                    apiUrl={api_url_admin + 'dashboard/get_all_customers'}
                                                                                    onSelect={handleUser}
                                                                                    placeholder="Search and select user"
                                                                                    mapOption={result => ({
                                                                                        value: result.id,
                                                                                        label: result.user_name + ', ' + result.company_name.substring(0, 15),
                                                                                        m4_account_id: result.m4_account_id,
                                                                                        mor_account_id: result.mor_account_id
                                                                                        // additionalField: result.additionalField,
                                                                                        // Add additional fields as needed
                                                                                    })}
                                                                                />
                                                                            )}
                                                                        /> */}
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            {...register("help_name", {
                                                                                required: "Document Name is required",
                                                                                pattern: onlyAlphabetsandSpaces,
                                                                            })}
                                                                            placeholder="Enter Document Name"
                                                                        />
                                                                        {errors.help_name && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.help_name.message}</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="mb-3">
                                                                        <label className="form-label" for="formrow-lname-input">Document Type<span style={{ color: 'red' }}>*</span></label>
                                                                        <select className="form-select" {...register('help_type', { required: 'Document is required' })} >
                                                                            <option value="" hidden>Please Select</option>
                                                                            <option value="Knowledge Base">Knowledge Base</option>
                                                                            <option value="Customer Help Document">Customer Help Document</option>

                                                                            {/* {documentData && documentData.map((doc, index) => (
                                                                                <option value={doc.id} selected={userDocData?.document_id ? userDocData.document_id == doc.id : ""}>{doc.user_document_name} </option>
                                                                            ))} */}
                                                                        </select>
                                                                        {errors.help_type && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.help_type.message}</div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="mb-3">
                                                                        {/* <label className="form-label" for="formrow-name-input">Upload<span style={{ color: 'red' }}>*</span></label> */}
                                                                        <input type="hidden" name="id" {...register('id')} />
                                                                        {mode === "update" ? (
                                                                            <>
                                                                                <label className="form-label" for="formrow-name-input">Upload</label>
                                                                                <input type="file" name="document_file" id="formrow-name-input" className="form-control" placeholder="Enter your File" {...register('document_file', {
                                                                                    // required: 'file is required',
                                                                                })} onChange={(e) => handleFileChange(e)} />
                                                                                <div className="mt-2">
                                                                                    {userDocData?.help_document_file_local && (
                                                                                        <>
                                                                                            {userDocData.help_document_file_local.endsWith('.png') ||
                                                                                                userDocData.help_document_file_local.endsWith('.PNG') ||
                                                                                                userDocData.help_document_file_local.endsWith('.jpg') ||
                                                                                                userDocData.help_document_file_local.endsWith('.JPG') ||
                                                                                                userDocData.help_document_file_local.endsWith('.jpeg') ||
                                                                                                userDocData.help_document_file_local.endsWith('.JPEG')
                                                                                                ? (
                                                                                                    <div>
                                                                                                        <img src={userDocData.help_document_file_local} alt="File Preview" style={{ width: '100px' }} />
                                                                                                        <button className="btn" onClick={() => handleDownload(userDocData.help_document_file_local)} >
                                                                                                            <span className="text-primary">{userDocData.help_document_file_local.split('/').pop()}</span>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <button className="btn" onClick={() => handleDownload(userDocData.help_document_file_local)} >
                                                                                                        <span className="text-primary">Download {userDocData.help_document_file_local.split('/').pop()}</span>
                                                                                                    </button>
                                                                                                )}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                                {errors.document_file && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.document_file.message}</div>}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <label className="form-label" for="formrow-name-input">Upload<span style={{ color: 'red' }}>*</span></label>
                                                                                <input type="file" name="document_file" id="formrow-name-input" className="form-control" placeholder="Enter your File" {...register('document_file', {
                                                                                    required: 'file is required',
                                                                                })} onChange={(e) => handleFileChange(e)} />
                                                                                {errors.document_file && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.document_file.message}</div>}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-grid gap-2 d-md-flex justify-start-md-end mt-4 mb-3">
                                                                <button className="btn btn-primary w-md me-md-2" type="submit"> {mode === "update" ? "Update" : "Create"}</button>
                                                                <button className="btn btn-warning w-md" type="button" onClick={() => navigate('/support/help_documents', { state: null })}>Cancel</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                    <Footer />
                </div>
                {/* end main content*/}
            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </>
    );

}
