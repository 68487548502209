import React from "react";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { getToken, simpleAlert, triggerAlert } from "../utils";
import { useState } from "react";
import { useEffect } from "react";

const fetchData = async (status, searchKey = "", limit) => {
  const api_url = process.env.REACT_APP_API_BASE_URL;
  const token = getToken();
  try {
    let apiUrl = `apiV1/active-customers-dropdown/`;
    let params = "";

    if (limit || status || searchKey) {
      params += "?";

      if (limit) {
        params += `limit=${limit}&`;
      }
      if (status) {
        params += `customer_status=${status}&`;
      }
      if (searchKey) {
        params += `keyword=${searchKey}&`;
      }

      // Remove the trailing '&' if it's present
      params = params.slice(0, -1);
    }
    //console.log(apiUrl + params);
    //if (searchQuery) params += `&keyword=${searchQuery}`;

    const response = await axios.get(apiUrl + params);
    //const response = await axios.get(api_url + `dashboard/get_all_customers?customer_status=new`, token);
    //console.log(response);
    const response_data = response.data;
    console.log(response_data);
    if (response.status === 200) {
      const users = response_data
      return users.map((user) => ({
        value: user.id,
        user: user.user_name,
        label: getLabelBasedOnSearch(user, searchKey),
        m4_account_id: user.m4_account_id,
        mor_account_id: user.mor_account_id,
      }));
    } else if (response.status === 204) {
      simpleAlert("No Data Available!");
    } else {
      triggerAlert("error", "Oops...", "Couldn't get the user details");
    }
  } catch (error) {
    console.error("Error loading user options:", error);
    return [];
  }
};

const getLabelBasedOnSearch = (user, searchKey) => {
  // Example condition: if user's company name starts with the input value, use it; otherwise, use the user name
  if (
    user.cmp_name.toLowerCase().includes(searchKey.toLowerCase()) ||
    user.contact_person.toLowerCase().includes(searchKey.toLowerCase())
  ) {
    return user.contact_person + ", " + user.cmp_name.substring(0, 15);
  }
  // You can modify this logic based on your specific conditions for labeling
};

const AutoUserSearch = ({ status, onSelect, userId = "", value }) => {
  const [userOptions, setUserOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  useEffect(() => {
    // Fetch initial data when the component mounts
    fetchData(status).then((options) => {
      setUserOptions(options);
      // if (userId) {
      //   console.log("inside useEffect func" + userId);
      //   const optionSel1 = options.find((option) => option.value == userId);
      //   setSelectedOption(optionSel1);
      // } else {
      //   setSelectedOption('');
      // }
      const selected = options?.find((option) => option?.value == value);
       
      setSelectedOption(selected);
    });
  }, [status, value]); // Empty dependency array ensures it only runs once on mount

  const loadOptions = (inputValue, callback) => {
    // Use the locally stored options if available
    if (!userOptions) {
      simpleAlert("No Data Available!");
    } else {
      const filteredUsers = userOptions.filter((user) =>
        user.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(filteredUsers);
    }
  };

  const handleSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
    if (onSelect) {
      onSelect({
        user_id: selectedOption ? selectedOption.value : "",
        user: selectedOption ? selectedOption.user : "",
        user_m4_account_id: selectedOption ? selectedOption.m4_account_id : "",
        user_mor_account_id: selectedOption
          ? selectedOption.mor_account_id
          : "",
      });
    }
    // if (onSelect) {
    //   if (selectedOption) {
    //     onSelect({
    //       user_id: selectedOption ? selectedOption.value : null,
    //       user: selectedOption ? selectedOption.user : null,
    //       user_m4_account_id: selectedOption ? selectedOption.m4_account_id : null,
    //       user_mor_account_id: selectedOption ? selectedOption.mor_account_id : null,
    //     });
    //     const optionSel = selectedOption?.value ? userOptions.find((option) => option.value == selectedOption?.value) : "";
    //     setSelectedOption(optionSel);
    //   } else {
    //     onSelect({
    //       user_id: '',
    //       user: '',
    //       user_m4_account_id: '',
    //       user_mor_account_id: '',
    //     });
    //     setSelectedOption('');
    //   }
    // }
  };
  //var selectedOption = '';//userOptions.find((option) => option.value == userId );
  console.log(selectedOption);
  return (
    <AsyncSelect
      isClearable
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions={userOptions}
      onChange={handleSelect}
      value={selectedOption}
      placeholder="Search Username or Company"
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          border: state.isSelected ? "1px solid #e9e9ef" : "1px solid #e9e9ef",
          boxShadow: state.isHovered
            ? "1px solid #e9e9ef"
            : "1px solid #e9e9ef",
          background: "#f8f9fa",
          color: "#000",
        }),
      }}
    />
  );
};

export default AutoUserSearch;
