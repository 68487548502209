import React, { useEffect, useState } from "react";
import Header from '../../../common/Header'
import Sidebar from '../../../common/Sidebar'
import Footer from '../../../common/Footer'
import PageHeader from '../../PageHeader'
import axios from "axios";
import { Controller, useForm } from 'react-hook-form'; 
import { triggerAlert, getToken , get_user_menu_permission} from "../../../utils";
import Loader from '../../../common/Loader' 
import AutoSearchSelect from '../../CommonSelectDynamic'
import { Table } from 'react-bootstrap'
import {menuIdData} from '../../../utils/constants'

export default function MenuPermission() {

    const api_url       = process.env.REACT_APP_API_CUSTOMER_URL;
	const mainHeading = "Menu Permission";
    const heading = "Admin / Menu Permission";
    const [isLoading, setIsLoading] = useState();
    const [menuData, setMenuData] = useState([]);
    const [adminUserData, setAdminUserData] = useState([]);
    const [editId, setEditId] = useState("");


    const token = getToken();

    ///////////////////////// Basic form /////////////////////////////////////////
    const { register: registerForm, handleSubmit: handleSubmitForm, formState: { errors: errorsForm }, setValue: setValueForm, reset: resetForm, control: controlForm, getValues: getValuesForm, watch } = useForm({
        defaultValues: { is_manager: "N" },
    });



    const fetchMenuDetails = (selectedOption) => {
        let admin_id = selectedOption ? selectedOption.value : null;
        fetchAdminData(admin_id);
        setSelectedMenuAddData([]);
        resetForm();
        setValueForm('admin_id', selectedOption ? selectedOption.value : null);

    };

    const fetchAdminData = async (admin_id) => {
        setIsLoading(true);

        try {

            const response = await axios.get(api_url + 'manage_admin/user_permission_list?admin_id=' + admin_id, token);
            const response_data = response.data

            if (response.status === 200) {
                const itemsArray = response_data.results[0];

                // setValueForm('is_manager',itemsArray.is_manager);


                const menu_id_add_string = itemsArray.menu_id_add;
                const menu_id_add_array = menu_id_add_string.split(',').map(Number);
                menu_id_add_array.forEach((child_id, index) => {
                    let child_menu_id = "menu_add_" + child_id;
                    setValueForm(child_menu_id, true);
                });
                setSelectedMenuAddData(menu_id_add_array);

                ///////////////////////////////////////

                const menu_id_edit_string = itemsArray.menu_id_edit;
                const menu_id_edit_array = menu_id_edit_string.split(',').map(Number);
                menu_id_edit_array.forEach((child_id, index) => {
                    let child_menu_id = "menu_edit_" + child_id;
                    setValueForm(child_menu_id, true);
                });
                setSelectedMenuEditData(menu_id_edit_array);

                //////////////////////////////////////////////////////

                const menu_id_delete_string = itemsArray.menu_id_delete;
                const menu_id_delete_array = menu_id_delete_string.split(',').map(Number);
                menu_id_delete_array.forEach((child_id, index) => {
                    let child_menu_id = "menu_delete_" + child_id;
                    setValueForm(child_menu_id, true);
                });
                setSelectedMenuDeleteData(menu_id_delete_array);

                //////////////////////////////////////////////////////

                const excel_export_string = itemsArray.excel_export;
                const excel_export_array = excel_export_string.split(',').map(Number);
                excel_export_array.forEach((child_id, index) => {
                    let child_menu_id = "excel_sheet_" + child_id;
                    setValueForm(child_menu_id, true);
                });
                setSelectedExcelSheetData(excel_export_array);

                //////////////////////////////////////////////////////

                // const grand_total_string   = itemsArray.grand_total;
                // const grand_total_array    = grand_total_string.split(',').map(Number); 
                // grand_total_array.forEach((child_id, index) => {
                //     let child_menu_id       = "grand_total_" + child_id; 
                //     setValueForm(child_menu_id,true);
                // });
                // setSelectedGrandTotalData(grand_total_array);


                setAdminUserData(itemsArray);
                setEditId(true);
                setIsLoading(false);
            } else if (response.status === 204) {
                setAdminUserData([]);
                setSelectedMenuAddData([]);
                setSelectedMenuEditData([]);
                setSelectedMenuDeleteData([]);
                setSelectedExcelSheetData([]);
                // setSelectedGrandTotalData([]);
                setIsLoading(false);
                setEditId(false);
            }
            else {
                setIsLoading(false);
                setEditId(false);
                // triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }

        } catch (error) {
            setIsLoading(false);
            setEditId(false);
            // triggerAlert('error', 'Oops...', 'Something went wrong..');
        }
    };

    const saveFormData = async (data) => {



        let menuAddIds = [];
        let menuEditIds = [];
        let menuDeleteIds = [];
        let excelSheetIds = [];
        let grandTotalIds = [];

        selectedMenuAddData.forEach(item => {
            if (item && !menuAddIds.includes(item)) {
                menuAddIds.push(item);
            }
        });

        selectedMenuEditData.forEach(item => {
            if (item && !menuEditIds.includes(item)) {
                menuEditIds.push(item);
            }
        });

        selectedMenuDeleteData.forEach(item => {
            if (item && !menuDeleteIds.includes(item)) {
                menuDeleteIds.push(item);
            }
        });

        selectedExcelSheetData.forEach(item => {
            if (item && !excelSheetIds.includes(item)) {
                excelSheetIds.push(item);
            }
        });


        // selectedGrandTotalData.forEach(item => { 
        //     if (item && !grandTotalIds.includes(item)) {
        //         grandTotalIds.push(item);
        //     }
        // });

        let post_data = {
            "admin_id": data.admin_id,
            "add": menuAddIds,
            "edit": menuEditIds,
            "remove": menuDeleteIds,
            "exports": excelSheetIds,
            // "total":grandTotalIds, 
        };


        setIsLoading(true);



        try {
            var api = "";
            let response = "";
            if (editId) {
                api = api_url + 'manage_admin/update_user_permission';
                response = await axios.put(api, post_data, token);
            } else {
                api = api_url + 'manage_admin/add_user_permission';
                response = await axios.post(api, post_data, token);
            }



            if (response.status === 201) {
                setIsLoading(false);
                triggerAlert('success', 'success', 'Data updated successfully');

                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            } else if (response.status === 204) {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }
            else {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }

        } catch (error) {
            setIsLoading(false);
            triggerAlert('error', 'Oops...', 'Something went wrong here..');
        }

    };


    ////////////////////////////////////////////////////////////////////////////////



    const fetchMenuData = async () => {
        setIsLoading(true);

        try {


            const response = await axios.get(api_url + 'manage_admin/get_menu_list', token);
            const response_data = response.data

            if (response.status === 200) {
                const itemsArray = response_data.results
                setMenuData(itemsArray);

                setIsLoading(false);
            } else if (response.status === 204) {
                setMenuData([]);
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }

        } catch (error) {
            setIsLoading(false);
            // triggerAlert('error', 'Oops...', 'Something went wrong..');
        }
    };


    const [selectedMenuAddData, setSelectedMenuAddData] = useState([]);
    const [selectedMenuEditData, setSelectedMenuEditData] = useState([]);
    const [selectedMenuDeleteData, setSelectedMenuDeleteData] = useState([]);
    const [selectedExcelSheetData, setSelectedExcelSheetData] = useState([]);
    // const [selectedGrandTotalData, setSelectedGrandTotalData] = useState([]);



    const handleMenuAddCheckboxChange = (childMenu, isChecked) => {
        // Check if the child_menu_id already exists in selectedMenus

        let child_menu_id = "menu_add_" + childMenu;

        setValueForm(child_menu_id, isChecked);

        setSelectedMenuAddData((prevMenus) =>
            prevMenus.filter((item) => item !== childMenu)
        );

        if (isChecked) {
            setSelectedMenuAddData((prevMenus) => [...prevMenus, childMenu]);
        }

    };

    const handleMenuEditCheckboxChange = (childMenu, isChecked) => {
        // Check if the child_menu_id already exists in selectedMenus

        let child_menu_id = "menu_edit_" + childMenu;

        setValueForm(child_menu_id, isChecked);

        setSelectedMenuEditData((prevMenus) =>
            prevMenus.filter((item) => item !== childMenu)
        );

        if (isChecked) {
            setSelectedMenuEditData((prevMenus) => [...prevMenus, childMenu]);
        }

    };

    const handleMenuDeleteCheckboxChange = (childMenu, isChecked) => {
        // Check if the child_menu_id already exists in selectedMenus

        let child_menu_id = "menu_delete_" + childMenu;

        setValueForm(child_menu_id, isChecked);

        setSelectedMenuDeleteData((prevMenus) =>
            prevMenus.filter((item) => item !== childMenu)
        );

        if (isChecked) {
            setSelectedMenuDeleteData((prevMenus) => [...prevMenus, childMenu]);
        }

    };

    const handleExcelSheetCheckboxChange = (childMenu, isChecked) => {
        // Check if the child_menu_id already exists in selectedMenus

        let child_menu_id = "excel_sheet_" + childMenu;

        setValueForm(child_menu_id, isChecked);

        setSelectedExcelSheetData((prevMenus) =>
            prevMenus.filter((item) => item !== childMenu)
        );

        if (isChecked) {
            setSelectedExcelSheetData((prevMenus) => [...prevMenus, childMenu]);
        }

    };

    // const handleGrandTotalCheckboxChange = (childMenu, isChecked) => {  
    //     // Check if the child_menu_id already exists in selectedMenus 
    //     let child_menu_id  = "grand_total_" + childMenu;

    //     setValueForm(child_menu_id,isChecked);  

    //     setSelectedGrandTotalData((prevMenus) =>
    //         prevMenus.filter((item) => item !== childMenu)
    //     );

    //     if(isChecked){ 
    //         setSelectedGrandTotalData((prevMenus) => [...prevMenus, childMenu]); 
    //     } 

    // };

    const handleCheckAllCheckboxChange = (isChecked) => {
        // Check if the child_menu_id already exists in selectedMenus


        setValueForm("check_all", isChecked);

        menuData.forEach((sub_menu_data, index) => {
            let submenuData = sub_menu_data.submenu;
            submenuData.forEach((sub_menu_data, index) => {
                handleMenuAddCheckboxChange(sub_menu_data.id, isChecked);
                handleMenuEditCheckboxChange(sub_menu_data.id, isChecked);
                handleMenuDeleteCheckboxChange(sub_menu_data.id, isChecked);
                handleExcelSheetCheckboxChange(sub_menu_data.id, isChecked);
                // handleGrandTotalCheckboxChange(sub_menu_data.id,isChecked);

            });
        });

    };




    useEffect(() => {
        const menu_id           = menuIdData.menu_permission;
        const add_permission    = get_user_menu_permission(menu_id,'add');
        const edit_permission   = get_user_menu_permission(menu_id,'edit');

        if(add_permission || edit_permission) { 
            fetchMenuData();
        }else{
            triggerAlert('error', 'Oops...', "You don't have permission to access this page");
            
            setTimeout(() => {
                window.history.back();
            }, 3000);
        }

    }, [])


    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                {isLoading &&
                    <div className='loader-overlay text-white'>
                        <Loader />
                    </div>
                }
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */} 
                                <>
                                     
                                    <div className="row  ">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className="btn-group float-end col-md-1  ml-10">
                                                        <button className="btn btn-primary addBtn waves-effect waves-light w-md font-size-14 ms-2" type='button' onClick={handleSubmitForm(saveFormData)}   
                                                        > Update<i className="align-middle font-size-16 ms-2"></i></button>
                                                    </div> 
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div class="col-md-3">
                                                                            <div class="mb-3">
                                                                                <label for="admin_id" class="form-label">User Name<span className="text-danger">*</span></label>
                                                                                <Controller
                                                                                    name="admin_id"
                                                                                    {...registerForm('admin_id',
                                                                                        { required: 'User Name is required' }
                                                                                    )}
                                                                                    control={controlForm}
                                                                                    render={({ field }) => (
                                                                                        <AutoSearchSelect
                                                                                            {...field}
                                                                                            apiUrl={process.env.REACT_APP_API_CUSTOMER_URL + 'manage_admin/get_all_admin_data'}
                                                                                            placeholder="Select User Name"
                                                                                            mapOption={result => ({
                                                                                                value: result.id,
                                                                                                label: result.username,
                                                                                            })}
                                                                                            value={field.value}
                                                                                            onSelect={fetchMenuDetails}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                {errorsForm.admin_id && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.admin_id.message}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-3">
                                                                            <div className="mb-3 ps-3">
                                                                                <label for="admin_id" class="form-label"> </label>
                                                                                <div className="form-check">
                                                                                    <Controller
                                                                                        name="check_all"
                                                                                        {...registerForm('check_all')}
                                                                                        control={controlForm}
                                                                                        defaultValue={false}
                                                                                        render={({ field }) => (
                                                                                            <>
                                                                                                <input
                                                                                                    className="form-check-input form_check_input_permission"
                                                                                                    type="checkbox"
                                                                                                    id="check_all"
                                                                                                    checked={field.value}
                                                                                                    {...field}
                                                                                                    onChange={(f) => handleCheckAllCheckboxChange(f.target.checked)}

                                                                                                />
                                                                                                <label className="form-check-label" htmlFor="">
                                                                                                    Check All
                                                                                                </label>
                                                                                            </>
                                                                                        )}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        {/*  <h4 className="card-title mb-4">Ring Groups</h4> */}
                                                                        <Table style={{ width: '100%' }} className='table dataTable table-responsive table-bordered'>
                                                                            <thead >
                                                                                <tr>
                                                                                    {/* <th>Sl. No</th>  */}
                                                                                    <th>Menu Title</th>
                                                                                    <th>Menu Add</th>
                                                                                    <th>Menu Edit</th>
                                                                                    <th>Menu Delete</th>
                                                                                    <th>Excelsheet Export</th>
                                                                                    {/* <th>Grand Total</th>  */}
                                                                                </tr>
                                                                            </thead>
                                                                            {/* {isLoading ? (
                                                                                <div className='loader-overlay text-white'>
                                                                                    <Loader />
                                                                                </div>

                                                                            ) : ( */}
                                                                                <tbody>
                                                                                    {menuData.length > 0 ? (
                                                                                        menuData.map((parent_menu, index) => (
                                                                                            <React.Fragment key={index}>
                                                                                                {/* <tr>
                                                                                        <td colSpan="6">{parent_menu.menu_name}</td>
                                                                                    </tr> */}
                                                                                                {parent_menu.submenu.length > 0 ? (
                                                                                                    parent_menu.submenu.map((child_menu, index1) => (
                                                                                                        <tr key={child_menu.id}>
                                                                                                            {/* <td></td> */}
                                                                                                            <td>{child_menu.menu_name}</td>
                                                                                                            <td>
                                                                                                                <div key={child_menu.id} className="mb-3 ps-3">
                                                                                                                    <div className="form-check">
                                                                                                                        <Controller
                                                                                                                            name={`menu_add_${child_menu.id}`}
                                                                                                                            {...registerForm(`menu_add_${child_menu.id}`)}
                                                                                                                            control={controlForm}
                                                                                                                            defaultValue={false}
                                                                                                                            render={({ field }) => (
                                                                                                                                <>
                                                                                                                                    <input
                                                                                                                                        className="form-check-input form_check_input_permission"
                                                                                                                                        type="checkbox"
                                                                                                                                        id={`menu_add_${child_menu.id}`}
                                                                                                                                        checked={field.value}
                                                                                                                                        {...field}
                                                                                                                                        onChange={(f) => handleMenuAddCheckboxChange(child_menu.id, f.target.checked)}

                                                                                                                                    />
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <div key={`menuedit_${child_menu.id}`} className="mb-3 ps-3">
                                                                                                                    <div className="form-check">
                                                                                                                        <Controller
                                                                                                                            name={`menu_edit_${child_menu.id}`}
                                                                                                                            {...registerForm(`menu_edit_${child_menu.id}`)}
                                                                                                                            control={controlForm}
                                                                                                                            defaultValue={false}
                                                                                                                            render={({ field }) => (
                                                                                                                                <>
                                                                                                                                    <input
                                                                                                                                        className="form-check-input form_check_input_permission"
                                                                                                                                        type="checkbox"
                                                                                                                                        id={`menu_edit_${child_menu.id}`}
                                                                                                                                        checked={field.value}
                                                                                                                                        {...field}
                                                                                                                                        onChange={(f) => handleMenuEditCheckboxChange(child_menu.id, f.target.checked)}

                                                                                                                                    />
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <div key={child_menu.id} className="mb-3 ps-3">
                                                                                                                    <div className="form-check">
                                                                                                                        <Controller
                                                                                                                            name={`menu_delete_${child_menu.id}`}
                                                                                                                            {...registerForm(`menu_delete_${child_menu.id}`)}
                                                                                                                            control={controlForm}
                                                                                                                            defaultValue={false}
                                                                                                                            render={({ field }) => (
                                                                                                                                <>
                                                                                                                                    <input
                                                                                                                                        className="form-check-input form_check_input_permission"
                                                                                                                                        type="checkbox"
                                                                                                                                        id={`menu_delete_${child_menu.id}`}
                                                                                                                                        checked={field.value}
                                                                                                                                        {...field}
                                                                                                                                        onChange={(f) => handleMenuDeleteCheckboxChange(child_menu.id, f.target.checked)}

                                                                                                                                    />
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <div key={child_menu.id} className="mb-3 ps-3">
                                                                                                                    <div className="form-check">
                                                                                                                        <Controller
                                                                                                                            name={`excel_sheet_${child_menu.id}`}
                                                                                                                            {...registerForm(`excel_sheet_${child_menu.id}`)}
                                                                                                                            control={controlForm}
                                                                                                                            defaultValue={false}
                                                                                                                            render={({ field }) => (
                                                                                                                                <>
                                                                                                                                    <input
                                                                                                                                        className="form-check-input form_check_input_permission"
                                                                                                                                        type="checkbox"
                                                                                                                                        id={`excel_sheet_${child_menu.id}`}
                                                                                                                                        checked={field.value}
                                                                                                                                        {...field}
                                                                                                                                        onChange={(f) => handleExcelSheetCheckboxChange(child_menu.id, f.target.checked)}

                                                                                                                                    />
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            
                                                                                                        </tr>
                                                                                                    ))
                                                                                                ) : null}
                                                                                            </React.Fragment>
                                                                                        ))
                                                                                    ) :
                                                                                        <tr>
                                                                                            <td colSpan="4" className="text-center">No data available</td>
                                                                                        </tr>
                                                                                    }

                                                                                </tbody>
                                                                             {/* )} */}
                                                                        </Table>
                                                                    </div>
                                                                </div>
                                                                {/* end card body */}
                                                            </div>
                                                            {/* end card */}
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div> {/* <!-- end col -->*/}
                                    </div>
                                </> 



                        </div> {/* container-fluid */}
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </>
    )
}
