import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "../../../common/Header";
import Sidebar from "../../../common/Sidebar";
import Footer from "../../../common/Footer";
import PageHeader from "../../PageHeader";
import Loader from '../../../common/Loader';
import { useForm, Controller } from 'react-hook-form';
import { getToken, triggerAlert, formattedDateTime, removePTags, handleTableRowClick, pageReload, get_user_menu_permission, downloadFile } from '../../../utils';
import Pagination from "../../PaginationComponent";
import { Link, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AutocompleteSearch from "../../AutocompleteSearch";
import AutoUserSearch from '../../AutoUserSearch';
import { menuIdData } from '../../../utils/constants';
import Editor from '../../Editor';

export default function HelpDocList() {
    const api_url = process.env.REACT_APP_API_BASE_URL;
    const token = getToken();
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [helpDoc, setHelpDocList] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [showEditForm, setShowEditForm] = useState(false);
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const editorConfiguration = {
        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
    };
    const { control, register, handleSubmit, formState: { errors }, setValue, reset, clearErrors } = useForm();
    const [editorInstance, setEditorInstance] = useState(null);

    const mainHeading = 'Help Documents';
    const heading = `Support / Help Documents`;
    const pageSize = process.env.REACT_APP_API_SHOW_ENTRIES;

    // Menu Permissions
    const [addPermission, setAddPermission] = useState(false);
    const [editPermission, setEditPermission] = useState(false);
    const [deletePermission, setDeletePermission] = useState(false);
    const [exportPermission, setExportPermission] = useState(false);

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        if (data) {
            clearErrors('editorContent');
        }
        setValue('editorContent', data);
    };

    const handleOnFocus = () => {
        if (!dataLoaded) {
            searchCustomers();
            setDataLoaded(true);
        }
    };


    const handleOnClear = () => {
        setValue('user', '');
    };

    const handleOnSelect1 = (item) => {
        setValue('user', item.id);
        if (item.id) {
            clearErrors('user');
        }
    };

    let autoCompleteProps = {
        items: items,
        fuseOptions: { keys: ["user_name"] },
        placeholder: 'Search user...',
        handleOnSelect: handleOnSelect1,
        resultString: "user_name",
        handleOnFocus: handleOnFocus,
        handleOnClear: handleOnClear,
        inputSearchString: selectedRow ? selectedRow.customer_name : ""
    };

    const searchCustomers = async () => {
        try {
            const response = await axios.get(api_url + `dashboard/get_all_customers`, token);
            const response_data = response.data;
            if (response_data.error_code === 200) {
                const itemsArray = response_data.results
                //console.log('response_itemsArray', itemsArray);
                setItems(itemsArray);
            }
            else if (response.status === 204) {
                setItems([]);
            } else {
                triggerAlert('error', 'Oops...', "Couldn't get the user data");
            }
        } catch (error) {
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    };

    const fetchCustNotesList = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${api_url}/help_documents/list_data?page_size=${pageSize}&page_number=${pageNum} `, token);
            // console.log(response)
            // return
            const response_data = response.data;
            if (response_data.error_code == 200) {
                const itemsArray = response_data.results.data;
                const total_pages = response_data.results.pagination.total_pages;
                setHelpDocList(itemsArray);
                setTotalPageCount(total_pages);
                setIsLoading(false);
            } else {
                triggerAlert("error", "Oops...", "Something went wrong..");
            }
        } catch (err) {
            console.log(err)
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    }

    const deleteReplies = () => {
        if (selectedRowId) {
            // console.log(selectedRowId);
            Swal.fire({
                icon: 'warning',
                title: 'Do you want to delete data?',
                showCancelButton: true,
                confirmButtonText: 'Delete',
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    var id = selectedRowId;
                    try {
                        const response = await axios.get(api_url + `help_documents/delete_data?help_id=${id}`, token);
                        const response_data = response.data;
                        if (response_data.error_code == "200") {
                            triggerAlert('success', 'success', response_data.message);
                            pageReload();
                        } else {
                            triggerAlert('error', 'Oops...', 'Something went wrong..');
                        }
                    } catch (error) {
                        triggerAlert('error', 'Oops...', 'Something went wrong..');
                    }
                }
            })
        } else {
            triggerAlert('error', 'Oops...', 'Please select a row');
        }
    };

    const handlePageClick = (selected) => {
        const selectedPage = selected.selected;
        setPageNum(selectedPage + 1);
    };

    let props = {
        pageCount: totalPageCount,
        handlePageClick: handlePageClick,
        selectedPage: pageNum - 1
    }


    const handleEditForm = (e) => {

        if (selectedRow) {
            // console.log("selectedRow", selectedRow);
            navigate('/support/manage_help_documents?mode=update', { state: selectedRow });
        } else {
            triggerAlert('error', 'Oops...', 'Please select a row');
        }
    }


    const formSubmit = async (data) => {
        try {
            setIsLoading(true);
            const params = {
                'user_id': data.user,
                'notes': data.editorContent
            };
            const response = await axios.put(api_url + `customer_notes/update_data/${selectedRow.notes_id}/update`, params, token);
            if (response.status === 200) {
                triggerAlert('success', 'Success', `Customer Note Updated Successfully!!!`);
                //fetchCustNotesList();
                setIsLoading(false);
                setShowEditForm(false);
                pageReload();
            } else {
                setIsLoading(false);
                throw new Error('Unable to Updated Customer Note Successfully!!!');
            }
        } catch (err) {
            setIsLoading(false);
            triggerAlert('error', 'Oops...', err.message);
        }
    };
    const handleDownload = (url) => {
        const filename = url?.split("/")?.pop();
        downloadFile(url, filename);
    };

    useEffect(() => {
        fetchCustNotesList();
        const menu_id = menuIdData?.help_documents;
        setAddPermission(get_user_menu_permission(menu_id, 'add'));
        setEditPermission(get_user_menu_permission(menu_id, 'edit'));
        setDeletePermission(get_user_menu_permission(menu_id, 'delete'));
        setExportPermission(get_user_menu_permission(menu_id, 'export'));
    }, []);


    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    {isLoading ? <div className='loader-overlay text-white'>
                        <Loader />
                    </div> : ""}
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}
                            <div className="row mt-5">
                                <div className="col-12">


                                    <div className="card">
                                        {(addPermission || editPermission || deletePermission) &&
                                            <div className="card-header">
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-11">
                                                    </div>

                                                    <div class="btn-group   mb-3 float-end col-md-1">
                                                        <button type="button" class="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            Action <i class="mdi mdi-chevron-down"></i>
                                                        </button>
                                                        <div class="dropdown-menu dropdown-menu-end py-2">
                                                            {addPermission && <Link to="/support/manage_help_documents?mode=add" class="dropdown-item"><i class="bx bx-user"></i> Create </Link>}
                                                            {editPermission && <a href="#" onClick={(e) => handleEditForm(e)} class="dropdown-item"><i class="far fa-edit font-size-14"></i> Edit</a>}
                                                            {deletePermission && <Link to="#" onClick={deleteReplies} class="dropdown-item"><i class="bx bx-trash"></i> Delete</Link>}
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        }
                                        <div className="card-body">
                                            <table className="table table-bordered dt-responsive  table-responsive  nowrap w-100">
                                                <thead style={{ backgroundColor: '#ededed' }}>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Document Name</th>
                                                        {/* <th>Document Type</th> */}
                                                        <th>Download</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {helpDoc?.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="12" className="text-center">No data available</td>
                                                        </tr>
                                                    ) : (
                                                        helpDoc?.map((list, index) => (
                                                            <tr key={list.help_id} onClick={() => handleTableRowClick(list, selectedRow, setSelectedRow, selectedRowId, setSelectedRowId, list.help_id)} className={selectedRowId === list.help_id ? 'row_selected' : ''}>
                                                                <td>{index + 1}</td>
                                                                <td>{list.help_name ? list.help_name : '-'}</td>
                                                                {/* <td>{list.help_type ? list.help_type : '-'}</td> */}
                                                                {/* <td>{list.help_document_file ? list.help_document_file : '-'}</td> */}
                                                                {exportPermission ?
                                                                    <td>
                                                                        <a
                                                                            // href={post.user_document_file}
                                                                            href='#/'
                                                                            onClick={(e) => {
                                                                                e.preventDefault(); // Prevent the default action (redirect)
                                                                                handleDownload(list.help_document_file_local);
                                                                            }}
                                                                        >

                                                                            <i class="mdi mdi-cloud-download-outline"></i></a>
                                                                    </td>

                                                                    : <td><span className="text-danger">Permission required!</span></td>
                                                                }
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                            </table>
                                            <hr />
                                            <Pagination {...props} />
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end col --> */}
                            </div>
                        </div>
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}
            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </div>
    );
}
