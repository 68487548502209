import React, { useState, useEffect, useMemo } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { getToken, simpleAlert, triggerAlert } from '../../utils';

const fetchData = async (apiUrl, apiInput, token) => {
    try {
        const response = await axios.post(apiUrl, apiInput, token);
        const responseData = response.data;

        if (responseData.error_code === 200) {
            return responseData.results.rc;
        } else if (response.status === 204) {
            // simpleAlert('No Data Available!');
            return [];
        } else {
            triggerAlert('error', 'Oops...', "Couldn't get the options");
            return [];
        }
    } catch (error) {
        console.error('Error loading options:', error);
        return [];
    }
};

export default function IntlRateCenterSearch({
    apiUrl,
    apiInput,
    onSelect,
    placeholder,
    mapOption, // Mapping function to transform API response into the expected format
    value,
    disabled,
}) {
    const [options, setOptions] = useState([]);

    // Memoize the apiInput object
    const memoizedApiInput = useMemo(() => apiInput, [apiInput]);

    useEffect(() => {
        const token = getToken();
        fetchData(apiUrl, memoizedApiInput, token).then(apiResponse => {
            // console.log("API Response:", apiResponse); // Log the API response
            if (apiResponse) {
                const rcData = Array.isArray(apiResponse) ? apiResponse : [apiResponse];
                // Apply the mapping function to transform the API response
                const mappedOptions = rcData.map(mapOption);
                setOptions(mappedOptions);
            } else {
                console.error("API Response is invalid:", apiResponse);
            }
        }).catch(error => {
            console.error('Error loading options:', error);
        });
    }, [memoizedApiInput.country]); // Exclude apiUrl from the dependency array

    const loadOptions = (inputValue, callback) => {
        const filteredOptions = options.filter(option =>
            option.label.toString().toLowerCase().includes(inputValue.toLowerCase())
        );
        callback(filteredOptions);
    };

    const handleSelect = selectedOption => {
        if (onSelect) {
            onSelect(selectedOption);
        }
    };

    return (
        <AsyncSelect
            cacheOptions
            isClearable // to clear selected option
            loadOptions={loadOptions}
            defaultOptions={options}
            onChange={handleSelect}
            isDisabled={disabled}
            placeholder={placeholder || 'Search...'}
            value={value ? options.find(option => option.value == value) : null} // Preselect based on the provided value
            styles={{
                control: baseStyles => ({
                    ...baseStyles,
                    border: '1px solid #e8e8f7 !important',
                    boxShadow: '1px solid #e9e9ef',
                    background: '#f6f6ff',
                    color: '#000',
                }),
            }}
        />
    );
};
