
import { Navigate, Outlet } from 'react-router-dom';

import { isLogin } from '../utils';

const PrivateRoutes = () => {
    let auth = isLogin(); 
    // console.log(auth);
  return (
      auth ? <Outlet/> : <Navigate to='/login'/>
    )
  }

export default PrivateRoutes;

 