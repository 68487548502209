import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import {
  triggerAlert,
  formatDate,
  transformText,
  getToken,
  capitalizeFirst,
} from "../../utils";
import Loader from "../../common/Loader";
import AutocompleteSearch from "../AutocompleteSearch";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AutoSearchSelect from "../CommonSelectDynamic";
import moment from "moment";
import {
  CircularProgressbarWithChildren,
  CircularProgressbar,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import {
  invoiceStatus,
  invoiceType,
  cdrCallType,
  reportStatus,
} from "../../utils/constants";
import CommonSelect from "../CommonSelectStatic";
import AutoUserSearch from "../AutoUserSearch";
import Dashboardgraph from "./Dashboardgraph";
import Renewalgraph from "./Renewalgraph";

export default function DashboardSectionOne() {
  const api_url = process.env.REACT_APP_CUSTURL;
  const token = getToken();
  const [isCustomerLoading, setIsCustomerLoading] = useState(false);
  const [isBalanceHisLoading, setIsBalanceHisLoading] = useState(false);
  const [isDidStatusLoading, setIsDidStatusLoading] = useState(false);
  const [isEndPointsLoading, setIsEndPointLoading] = useState(false);
  const [isNumberPortLoading, setIsNumberPortLoading] = useState(false);
  const [totalcustomers, setTotalCustomers] = useState([]);
  const [balanceHistory, setBalanceHistory] = useState([]);
  const [didStatus, setDidStatus] = useState([]);
  const [endPoints, setEndPoints] = useState([]);
  const [numberPorts, setNumberPorts] = useState([]);
  const [items, setItems] = useState([]);
  const [customerId, setcustomerId] = useState(null);
  const [userID, setUserId] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  // const [searchQuery, setSearchQuery] = useState('');
  // const [selectedItem, setSelectedItem] = useState(null);
  const [isSearchBtnDisabled, setIsSearchBtnDisabled] = useState(true);
  const [isCHSearchBtnDisabled, setIsCHSearchBtnDisabled] = useState(true);
  // const [period_start, setPeriodStart] = useState("");
  // const [period_end, setPeriodEnd] = useState("");
  const [m4ID, setM4Id] = useState("");
  const navigate = useNavigate();
  const {
    register: registerQI,
    handleSubmit: handleSubmitQI,
    formState: { errors: errorsQI },
    setValue: setValueQI,
    watch: watchQI,
    reset: resetQI,
    control: controlQI,
    getValue: getValueQI,
    setError: setErrorQI,
    clearErrors: clearErrorsQI,
  } = useForm();
  const {
    register: registerCH,
    handleSubmit: handleSubmitCH,
    formState: { errors: errorsCH },
    setValue: setValueCH,
    watch: watchCH,
    reset: resetCH,
    control: controlCH,
    getValue: getValueCH,
    setError: setErrorCH,
    clearErrors: clearErrorsCH,
  } = useForm();
  const {
    register: registerStatus,
    handleSubmit: handleSubmitStatus,
    formState: { errors: errorsStatus },
    setValue: setValueStatus,
    watch: watchStatus,
    reset: resetStatus,
    control: controlStatus,
    getValue: getValueStatus,
  } = useForm();
  const period_start = watchQI("period_start");
  const period_end = watchQI("period_end");
  const period_start_ch = watchCH("period_start");
  const period_end_ch = watchCH("period_end");
  const [minCHEndDate, setMinCHEndDate] = useState(
    period_start_ch ? new Date(period_start_ch) : null
  );
  const [minEndDate, setMinEndDate] = useState(
    period_start ? new Date(period_start) : null
  );

  const fetchTotalCustomers = async (searchKeyword) => {
    setIsCustomerLoading(true);
    try {
      const apiUrl = searchKeyword
        ? `/apiV1/active-customers/?is_deleted=False&limit=7&keyword=${searchKeyword}`
        : `/apiV1/active-customers/?is_deleted=False&limit=7`;
      const response = await axios.get(apiUrl, token);

      const response_data = response.data;
      // console.log('response_dataCards', response_data);

      if (response.status== 200) {
        const itemsArray = response_data.results;
        // console.log('response_itemsArray', itemsArray);
        setTotalCustomers(itemsArray);
        setIsCustomerLoading(false);
      } else if (response.status === 204) {
        setTotalCustomers([]);
        setIsCustomerLoading(false);
      } else {
        triggerAlert("error", "Oops...", "Couldn't get the card details");
      }
    } catch (error) {
      setIsCustomerLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };
  const fetchBalanceHistory = async () => {
    setIsBalanceHisLoading(true);
    try {
      const response = await axios.get(
        api_url + `dashboard/get_balance_history?limit=5`,
        token
      );

      const response_data = response.data;
      // console.log('response_dataCards', response_data);

      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        // console.log('response_itemsArray', itemsArray);
        setBalanceHistory(itemsArray);
        setIsBalanceHisLoading(false);
      } else if (response.status === 204) {
        setBalanceHistory([]);
        setIsBalanceHisLoading(false);
      } else {
        triggerAlert("error", "Oops...", "Couldn't get the card details");
      }
    } catch (error) {
      setIsBalanceHisLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };
  // const searchCustomers = async () => {
  //     // setIsBalanceHisLoading(true);
  //     try {

  //         const response = await axios.get(api_url + `dashboard/get_all_customers`, token);
  //         // const response = await axios.get('https://dummyjson.com/products');

  //         const response_data = response.data;

  //         if (response_data.error_code === 200) {
  //             const itemsArray = response_data.results
  //             console.log('response_itemsArray', itemsArray);
  //             setItems(itemsArray);
  //             // setIsBalanceHisLoading(false);
  //         }
  //         else if (response.status === 204) {
  //             setItems([]);
  //             // setIsBalanceHisLoading(false);
  //         } else {
  //             triggerAlert('error', 'Oops...', "Couldn't get the card details");
  //         }
  //     } catch (error) {
  //         // setIsBalanceHisLoading(false);
  //         triggerAlert("error", "Oops...", "Something went wrong..");
  //     }
  // };

  const fetchDidStatus = async () => {
    setIsDidStatusLoading(true);
    try {
      const response = await axios.get(
        api_url + `dashboard/get_did_status?limit=10&customer_id=${customerId}`,
        token
      );

      const response_data = response.data;
      // console.log('response_dataCards', response_data);

      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        // console.log('response_itemsArray', itemsArray);
        setDidStatus(itemsArray);
        setIsDidStatusLoading(false);
      } else if (response.status === 204) {
        setDidStatus([]);
        setIsDidStatusLoading(false);
      } else {
        triggerAlert("error", "Oops...", "Couldn't get the card details");
      }
    } catch (error) {
      setIsDidStatusLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };
  const fetchEndPoints = async () => {
    setIsEndPointLoading(true);
    try {
      const response = await axios.get(
        api_url + `dashboard/get_endpoints_data?limit=10&user_id=${userID}`,
        token
      );

      const response_data = response.data;
      // console.log('response_dataCards', response_data);

      if (response_data.error_code === 200) {
        const itemsArray = response_data.results.data;
        // console.log('response_itemsArrayfetchEndPoints', itemsArray);
        setEndPoints(itemsArray);
        setIsEndPointLoading(false);
      } else if (response.status === 204) {
        setEndPoints([]);
        setIsEndPointLoading(false);
      } else {
        setEndPoints([]);
        triggerAlert("error", "Oops...", "Couldn't get end point details");
      }
    } catch (error) {
      setEndPoints([]);
      setIsEndPointLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };
  const fetchNumberPortability = async () => {
    setIsNumberPortLoading(true);
    try {
      const response = await axios.get(
        api_url +
          `dashboard/get_number_portability_status?limit=10&customer_id=${customerId}`,
        token
      );

      const response_data = response.data;
      // console.log('response_dataCards', response_data);

      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        // console.log('response_itemsArray', itemsArray);
        setNumberPorts(itemsArray);
        setIsNumberPortLoading(false);
      } else if (response.status === 204) {
        setNumberPorts([]);
        setIsNumberPortLoading(false);
      } else {
        triggerAlert("error", "Oops...", "Couldn't get the card details");
      }
    } catch (error) {
      setIsNumberPortLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  useEffect(() => {
    // console.log('customerId', customerId);
    // console.log('userID', userID);

    // This block runs when customerId is available
    if (customerId !== "" && customerId !== null) {
      fetchDidStatus();
      fetchNumberPortability();
    }
    if (userID !== "" && userID !== null) {
      fetchEndPoints();
    }
  }, [customerId, userID]);

  // Memoize the fetchTotalCustomers function
  const fetchTotalCustomersMemo = useMemo(() => fetchTotalCustomers, []);

  // Memoize the fetchBalanceHistory function
  const fetchBalanceHistoryMemo = useMemo(() => fetchBalanceHistory, []);

  // Memoize the searchCustomers function
  // const searchCustomersMemo = useMemo(() => searchCustomers, []);

  // const handleOnSearch = (string, results) => {
  //     console.log('hndleSearch',string, results);
  // };

  // const handleOnHover = (result) => {
  //     console.log(result);
  // };

  const handleOnSelect = (item) => {
    // console.log(item);
    setcustomerId(item.id);
    // setUserId(item.m4_account_id);
    setUserId(item.id);
  };

  const handleOnFocus = () => {
    // console.log("Focused");
    if (!dataLoaded) {
      // searchCustomers();
      setDataLoaded(true);
    }
    // searchCustomersMemo();
  };

  const handleOnClear = () => {
    // console.log("Cleared");
    setEndPoints([]);
    setNumberPorts([]);
    setDidStatus([]);
    setcustomerId(null);
    setUserId("");
    setM4Id("");
  };

  // const formatResult = (item) => {
  //     console.log(item);
  //     return (
  //         <div className="result-wrapper">
  //             <span className="result-span">id: {item.id}</span>
  //             <span className="result-span">name: {item.name}</span>
  //         </div>
  //     );
  // };
  const [customid, setcustomid] = useState()
  const [invoicestatusl, setinvoicestatusl] = useState()
  const handleUser = (selected) => {
    //first clearing all the state values
    setEndPoints([]);
    setNumberPorts([]);
    setDidStatus([]);
    setcustomerId(null);
    setUserId("");
    setM4Id("");
    //freshly setting the values
    setcustomerId(selected ? selected.user_id : null);
    // setUserId(selected ? selected.user_m4_account_id : null);
    setUserId(selected ? selected.user_id : null);
    setM4Id(selected ? selected.user_m4_account_id : null);
    setValueStatus("customerID", selected ? selected.user_id : null);
  };
  const handleUserSelect = (selected) => {
    setcustomid(selected.user_id)
    setValueQI("customer_id", selected ? selected.user_id : null);
  };
  const handleStatusSelect = (selected) => {
    setinvoicestatusl(selected.value)
    setValueQI("invoice_status", selected ? selected.value : null);
  };
  const handleTypeSelect = (selected) => {
    setValueQI("invoice_type", selected ? selected.value : null);
  };
  const handleCallTypeSelect = (selected) => {
    setValueCH("call_point", selected ? selected.value : null);
  };
  const handleCallStatusSelect = (selected) => {
    setValueCH("s_call_type", selected ? selected.value : null);
  };
  const handleUserCHSelect = (selected) => {
    setValueCH("customer_id", selected ? selected.user_id : null);
  };
  const handleQISubmit = () => {
      if (InvoicelistView) {
        navigate(`/billing/invoice?startDate=${moment(period_start).format('YYYY-MM-DD')}&endDate=${moment(period_end).format('YYYY-MM-DD')}${customid ? `&userid=${customid}` : ""}${invoicestatusl ? `&invoice_status=${invoicestatusl}` : ""}`);
      }

    // }
  };

  const handleCHSubmit = (data) => {
    data.period_start = period_start_ch;
    data.period_end = period_end_ch;
    navigate(`/reports/call_history?action=frm_dash`, { state: data });
  };

  const handleViewUser = () => {
    navigate(`/endpoints/endpoints`, {
      state: { user_id: m4ID, ids: customerId },
    });
  };

  const handleDidView = () => {
    navigate("/dids/assigned_dids", { state: { user_id: customerId } });
  };

  const handleViewProbability = () => {
    navigate("/number_portability/request_status", {
      state: { user_id: customerId },
    });
  };

  useEffect(() => {
     fetchTotalCustomersMemo();
    // fetchBalanceHistoryMemo();
  }, []);

  const datepickerRef = useRef(null);
  const datepickerfromRef = useRef(null);
  const toRef = useRef(null);
  const fromRef = useRef(null);
  // OPENS UP THE DATEPICKER WHEN THE CALENDAR ICON IS CLICKED FOR THE INPUT FIELD
  function handleClickDatepickerIcon() {
    const datepickerElement = datepickerRef.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setFocus(true);
  }
  function handleClickDatepickerFromIcon() {
    const datepickerElement = datepickerfromRef.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setFocus(true);
  }
  function handleClickToIcon() {
    const datepickerElement = toRef.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setFocus(true);
  }
  function handleClickFromIcon() {
    const datepickerElement = fromRef.current;
    // console.log("datepickerElement = ", datepickerElement);
    datepickerElement.setFocus(true);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////  pss changes /////////////////////////////////////////////
  const [openticketsview, setopenticketsview] = useState({});
  const [totallist, settotallist] = useState([{}]);
  useEffect(() => {
    getcustomerlist();
    getcustomerlisttickets();
  }, []);
  const getcustomerlist = async () => {
    try {
      const response = await axios.get(
        `/apiV1/dashboard/get_dashboard_count`,
        token
      );
      if (response.data) {
        let results = response.data.results;
        setopenticketsview(results);
      }
    } catch (error) {
      console.log("not found");
    }
  };
  const getcustomerlisttickets = async () => {
    try {
      const response = await axios.get(
        `/apiV1/dashboard/get_all_customers?user_type=admin_user`,
        token
      );
      if (response.data) {
        let results = response.data.results;
        settotallist(results);

        console.log(results, "asdsa");
      }
    } catch (error) {
      console.log("not found");
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [complianceReportData, setComplianceReportData] = useState();
  const [complianceReportLabels, setComplianceReportLabels] = useState();
  var currentDateTime = moment();
  var endOfMonth = moment("2024-05-31").endOf("month");
  if (endOfMonth.isAfter(currentDateTime)) {
    endOfMonth = currentDateTime;
  }

  useEffect(() => {
    getComplianceReport();
    fetchRevenueData();
  }, []);

  const getComplianceReport = async () => {
   
    try {
      const response = await axios.get(
        `/apiV1/dashboard/customer_status_graph`,
        token
      );

      if (response.data) {
        const results = response.data.results;
        const labels = Object.keys(results);
        const data = Object.values(results);

        setComplianceReportLabels(labels);
        setComplianceReportData(data);
      }
    } catch (error) {
      console.error("Error fetching compliance report:", error);
    }
  };

 

  const [totalRevenue, setTotalRevenue] = useState(0);
  const [currentMonthRevenue, setCurrentMonthRevenue] = useState(0);
  const [piechartData, setPiechartData] = useState(0);
  const fetchRevenueData = async () => {
    try {
      const response = await axios.get(
        `/apiV1/dashboard/revenue`,
        token
      );
      if (response.data) {
        const totalRevenu = response.data.results.total_revenu || 0;
        const currentMonthRevenu =
          response.data.results.current_month_revenu || 0;

        const validCurrentMonthRevenu =
          currentMonthRevenu !== null ? currentMonthRevenu : 0;
        console.log(response.data.results.total_revenu);
        setTotalRevenue(totalRevenu);
        setCurrentMonthRevenue(validCurrentMonthRevenu);

        const percentage = (validCurrentMonthRevenu / totalRevenu) * 100;
        setPiechartData(percentage);
      }
    } catch (error) {
      console.error("Error fetching revenue data:", error);
    }
  };
  const access_privileges = JSON.parse(localStorage.getItem('modulepermissions'))
  const [customerlistView, setcustomerlistView] = useState(false)
  const [InvoicelistView, setInvoiceview] = useState(false)
  useEffect(() => {
    fetchTotalCustomers(1)
    access_privileges?.map((access, i) => {
      access.sub_module_info?.map((subaccess, k) => {
        if (subaccess.name == "Customer List" && subaccess.permissions.indexOf(1) !== -1) {
          setcustomerlistView(true)
        }
      })
    })
  }, []);
  useEffect(() => {
    access_privileges?.map((access, i) => {
      access.sub_module_info?.map((subaccess, k) => {
        if (subaccess.name == "Invoice" && subaccess.permissions.indexOf(1) !== -1) {
          setInvoiceview(true)
        }
      })
    })
  }, []);
  return (
    <>
      <div className="row">
      <div className="col-xl-6">
          <div className="card  ">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1 card-title-custom">
                Total Customers
              </h4>
            </div>
            {/* <!-- end card header --> */}

            <div className="card-body px-0 pt-0">
              <div className="table-responsive">
                <table className="table mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>S.no</th>
                      <th>User</th>
                      <th>Company Name</th>
                      <th>Phone</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isCustomerLoading ? (
                      <td colSpan={6}>
                        {" "}
                        <Loader />
                      </td>
                    ) : totalcustomers.length === 0 ? (
                      <td colSpan={5} className="text-center p-4">
                        No Customers Found.
                      </td>
                    ) : (
                      totalcustomers.map((customer, index) => (
                        <>
                          <tr key={customer.id}>
                            <th scope="row">{index + 1}</th>
                            <td>{ customer.contact_person}</td>
                            <td>{customer.cmp_name}</td>
                            <td>{customer.phone}</td>
                            <td>
                              <h6
                                className={`m-0 badge ${customer.is_deleted == false
                                  ? "bg-success"
                                  : customer.is_deleted == false
                                    ? "bg-warning"
                                    : customer.status === "blocked"
                                      ? "bg-danger"
                                      : customer.status === "new"
                                        ? "bg-info"
                                        : customer.status === "inactive"
                                          ? "bg-secondary"
                                          : "bg-primary"
                                  }`}
                              >
                                {customer.is_deleted ? "Inactive" : "Active"}
                              </h6>
                            </td>
                          </tr>
                        </>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              {totalcustomers.length === 0 ? null : (
                <div className="text-center mt-3">
                  {customerlistView ? (
                    <Link
                      to="/customers/customerslist"
                      type="button"
                      className="btn btn-primary w-md btn-sm"
                    >
                      View All
                    </Link>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary w-md btn-sm"
                        onClick={() => alert("You don't have permission to view customers Please Contact Admin.")}
                      >
                        View All
                      </button>
                    </>
                  )}
                </div>
              )}


              {/* <!-- end tab content --> */}
            </div>
            {/* <!-- end card body --> */}
          </div>
          {/* <!-- end card --> */}
        </div>
        {/* <!-- end col --> */}
        {/* <div className="col-xl-4">
          <div className="card card-mh">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1 card-title-custom">
                Upcoming Renewal Customer List
              </h4>
            </div>
           

            <div className="card-body px-0 pt-0">
              <div className="table-responsive">
                <table className="table mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>#</th>
                      <th>Company Name</th>
                      <th>Last Paid On</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                  
                  </tbody>
                </table>
              </div>
              {totalcustomers.length === 0 ? null : (
                <div className="text-center mt-3">
                  <Link
                    to="/customers/customer_list"
                    type="button"
                    className="btn btn-primary w-md btn-sm"
                  >
                    View All
                  </Link>
                </div>
              )}

              
            </div>
           
          </div>
          
        </div>
        */}

        <div className="col-xl-6">
          <div className="card">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1 card-title-custom">
                New Registered Customers{" "}
                <span className="font_12 font_weight_500">
                  (Month by Month)
                </span>
              </h4>
            </div>
            <div className="card-body ">
              <Renewalgraph
                labels={complianceReportLabels}
                data={complianceReportData}
              />
            </div>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="card">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1 card-title-custom">
                Quick Invoice
              </h4>
            </div>
            {/* <!-- end card header --> */}

            <div className="card-body py-5 px-3">
              <form onSubmit={handleSubmitQI(handleQISubmit)}>
                <div className="row">
                  <div className="col-md-6 mb-3 d-flex flex-column">
                    <label className="form-label">
                      From <span className="text-danger">*</span>
                    </label>

                    <i
                      class="mdi mdi-calendar"
                      style={{
                        position: "absolute",
                        top: "3rem",
                        transform: "translateY(-50%)",
                        right: "15px",
                        zIndex: "1",
                        fontSize: "24px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleClickDatepickerFromIcon()}
                    ></i>
                    <Controller
                      className="px-3"
                      control={controlQI}
                      name="period_start"
                      render={({ field }) => (
                        <DatePicker
                          className="px-3 form-control position-relative"
                          placeholderText="MM/DD/YYYY"
                          selected={field.value}
                          // {...registerQI("period_start", {
                          //   required: "From Date is required",
                          //   pattern: {
                          //     value: /^[0-9/-]*$/,
                          //     message: "Invalid Date format",
                          //   },
                          // })}
                          onChange={(date) => {
                            // setPeriodStart(date);
                            field.onChange(date);
                            setMinEndDate(date); // Update minDate for period_end

                            // Add custom validation: Check if the To date is earlier than the From date
                            if (date && period_end && date > period_end) {
                              setErrorQI("period_start", {
                                type: "manual",
                                message:
                                  "From Date cannot be later than To Date",
                              });
                            } else {
                              clearErrorsQI("period_start"); // Clear the error message if the validation passes
                              setIsSearchBtnDisabled(!date || !period_end);
                            }
                          }}
                          showMonthDropdown
                          showYearDropdown
                          maxDate={new Date()}
                          autoComplete="off"
                          ref={datepickerfromRef}
                        />
                      )}
                    />

                    {errorsQI.period_start && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginTop: "5px",
                        }}
                      >
                        {errorsQI.period_start.message}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 mb-3 d-flex flex-column">
                    <label className="form-label">
                      To <span className="text-danger">*</span>
                    </label>

                    <i
                      class="mdi mdi-calendar"
                      style={{
                        position: "absolute",
                        top: "3rem",
                        transform: "translateY(-50%)",
                        right: "15px",
                        zIndex: "1",
                        fontSize: "24px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleClickDatepickerIcon()}
                    ></i>
                    <Controller
                      control={controlQI}
                      name="period_end"
                      render={({ field }) => (
                        <DatePicker
                          className="px-3 form-control position-relative"
                          placeholderText="MM/DD/YYYY"
                          selected={field.value}
                          // {...registerQI("period_end", {
                          //   required: "To Date is required",
                          //   pattern: {
                          //     value: /^[0-9/-]*$/,
                          //     message: "Invalid Date format",
                          //   },
                          // })}
                          onChange={(date) => {
                            // setPeriodEnd(date); // Update the state variable
                            field.onChange(date); // Update the form value

                            // Add custom validation: Check if the To date is earlier than the From date
                            if (period_start && date && period_start > date) {
                              setErrorQI("period_end", {
                                type: "manual",
                                message:
                                  "To Date cannot be earlier than From Date",
                              });
                            } else {
                              clearErrorsQI("period_end"); // Clear the error message if the validation passes
                              setIsSearchBtnDisabled(!period_start || !date);
                            }
                          }}
                          showMonthDropdown
                          showYearDropdown
                          maxDate={new Date()}
                          minDate={minEndDate}
                          autoComplete="off"
                          ref={datepickerRef}
                        />
                      )}
                    />

                    {errorsQI.period_end && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginTop: "5px",
                        }}
                      >
                        {errorsQI.period_end.message}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">User</label>
                      {/* <AutoUserSearch onSelect={handleUserSelect} /> */}
                      <Controller
                        control={controlCH}
                        name="customer_id"
                        id="customer_id"
                        rules={{ required: "User is required" }}
                        render={({ field }) => (
                          <AutoUserSearch
                            onSelect={(user) => {
                              setValueQI(
                                "customer_id",
                                user ? user.user_id : null
                              );
                              handleUserSelect(user)
                            }}
                          />
                        )}
                      />
                      {errorsQI.customer_id && (
                        <div
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginTop: "5px",
                          }}
                        >
                          {errorsQI.customer_id.message}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Status</label>
                      <Controller
                        name="invoice_status"
                        {...registerQI(
                          "invoice_status"
                          // , { required: 'Status is required' }
                        )}
                        control={controlQI}
                        render={({ field }) => (
                          <CommonSelect
                            options={invoiceStatus}
                            value={field.value}
                            // onChange={(value) => field.onChange(value)}
                            onChange={handleStatusSelect}
                            placeholder="Select Status"
                          />
                        )}
                      />
                      {errorsQI.invoice_status && (
                        <div
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginTop: "5px",
                          }}
                        >
                          {errorsQI.invoice_status.message}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Type</label>
                      <Controller
                        name="invoice_type"
                        {...registerQI(
                          "invoice_type"
                          
                        )}
                        control={controlQI}
                        render={({ field }) => (
                          <CommonSelect
                            options={invoiceType}
                            value={field.value}
                            
                            onChange={handleTypeSelect}
                            placeholder="Select Type"
                          />
                        )}
                      />
                      {errorsQI.invoice_type && (
                        <div
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginTop: "5px",
                          }}
                        >
                          {errorsQI.invoice_type.message}
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>
                <div className="text-center mt-3">
                {InvoicelistView ? (
                    <button
                    type="submit"
                    className="btn btn-primary w-md btn-sm"
                    disabled={isSearchBtnDisabled}
                  >
                    Search
                  </button>
                  ) : (
                    <>
                      <button
                        type="submit"
                        className="btn btn-primary w-md btn-sm"
                        disabled={isSearchBtnDisabled}
                        onClick={() => alert("You don't have permission to view Invoice List. Please contact admin")}
                      >
                        Search
                      </button>
                    </>
                  )}
                  
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* <div className="col-xl-3">
          <div className="col-12 mb-1">
            <div className="card h-100 pt-4 pb-5">
              <div className="card-body">
                <div className="py-1"></div>
                <div className="row">
                  <div className="col-6">
                    <h4 className="card-title mb-0 flex-grow-1 card-title-custom">
                      Total Revenue
                    </h4>
                    <p>
                      <span>&#x20b9;</span>
                      {totalRevenue}
                    </p>
                    
                  </div>
                  <div className="col-6">
                    <div className="usagepiechart" style={{ width: "90px" }}>
                      <CircularProgressbarWithChildren
                        value={piechartData}
                        text={`${Math.round(piechartData)}%`}
                        styles={{ path: { stroke: `#7695F8` }, width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-1">
            <div className="card h-100 pt-4 pb-5">
              <div className="card-body">
                <div className="py-1"></div>
                <div className="row">
                  <div className="col-6">
                    <h4 className="card-title mb-0 flex-grow-1 card-title-custom">
                      Current Month Revenue
                    </h4>
                    <p>
                      <span>&#x20b9;</span>
                      {currentMonthRevenue}
                    </p>
                    
                  </div>
                  <div className="col-6">
                    <div style={{ width: "90px" }}>
                      <CircularProgressbar
                        value={Math.round(piechartData)}
                        text={`${Math.round(piechartData)}%`}
                        strokeWidth={5}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
