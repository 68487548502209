import React, { useEffect, useState } from 'react'
import Header from '../../../common/Header'
import Sidebar from '../../../common/Sidebar'
import PageHeader from '../../PageHeader'
import Footer from '../../../common/Footer'
import { triggerAlert, getToken, pageReload, get_user_menu_permission } from '../../../utils';
import Loader from '../../../common/Loader'
import AutoSearchSelect from '../../CommonSelectDynamic'

import { useForm, Controller } from 'react-hook-form';
import axios from 'axios'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MaxLengthValidation, MinLengthValidation, menuIdData, onlyNumbers } from '../../../utils/constants'

export default function ManageUserList() {
    const api_url = process.env.REACT_APP_API_CUSTOMER_URL;
    const heading = "Admin / User List";
    const [isLoading, setIsLoading] = useState();
    const [forceUpdate, setForceUpdate] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [editId, setEditId] = useState("");
    const [menuData, setMenuData] = useState([]);
    const [nameValid, setNameValid] = useState(true);
    const [addStatus, setAddStatus] = useState(true);
    const [parentChildMapping, setParentChildMapping] = useState({});


    const token = getToken();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const action = queryParams.get('action');
    const edit_id = queryParams.get('edit_id');
    const navigate = useNavigate();
    const editStateData = location.state;
    const mainHeading = action == "add" ? "Create User " : "Update User ";
    ///////////////////////// Basic form /////////////////////////////////////////
    const { register: registerForm, handleSubmit: handleSubmitForm, formState: { errors: errorsForm }, setValue: setValueForm, reset: resetForm, control: controlForm, getValues: getValuesForm, watch } = useForm({
        defaultValues: {
            ismanager: "N"
        },
    });



    const handleDepartment = (selectedOption) => {
        setValueForm('department_id', selectedOption ? selectedOption.value : null);
        setValueForm('department_name', selectedOption ? selectedOption.label : null);
        setValueForm('level_id', null);
        setForceUpdate(prevState => !prevState);
    };


    const handleLevel = (selectedOption) => {
        let level_id = selectedOption ? selectedOption.value : null;
        setValueForm('level_id', level_id);
        fetchPermissionBasedOnLevel(level_id);

    };

    const fetchPermissionBasedOnLevel = async (level_id) => {
        setIsLoading(true);

        try {


            const response = await axios.get(api_url + 'manage_admin/user_level_data?level_id=' + level_id, token);
            const response_data = response.data

            if (response.status === 200) {
                const itemsArray = response_data.results;
                // setMenuData(itemsArray); 
                setCheckBoxData(itemsArray);
                setIsLoading(false);
            } else if (response.status === 204) {
                // setMenuData([]); 
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }

        } catch (error) {
            setIsLoading(false);
            // triggerAlert('error', 'Oops...', 'Something went wrong..');
        }
    };

    const setCheckBoxData = (admin_menu_array) => {
        const parent_id_string = admin_menu_array.parent_id;
        const parent_id_array = parent_id_string.split(',').map(Number);
        parent_id_array.forEach((parent_id, index) => {
            let parent_menu_id = "parent_menu_" + parent_id;
            setValueForm(parent_menu_id, true);
        });


        setSelectedParentMenus(parent_id_array);

        const child_id_string = admin_menu_array.child_id;
        const child_id_array = child_id_string.split(',').map(Number);
        child_id_array.forEach((child_id, index) => {
            let child_menu_id = "child_menu_" + child_id;
            setValueForm(child_menu_id, true);
        });

        setSelectedChildMenus(child_id_array);

        ////////// 
        const updatedMapping = {};
        menuData.forEach((parentmenu, index) => {

            let parentmenu_id = parentmenu.id;
            if (parent_id_array.includes(parentmenu_id)) {
                updatedMapping[parentmenu_id] = [];
                let submenu = parentmenu.submenu;
                submenu.forEach((childmenu, index) => {

                    if (child_id_array.includes(childmenu.id)) {
                        updatedMapping[parentmenu_id].push(childmenu.id);
                    }
                });
            }
        });
        setParentChildMapping(updatedMapping);

    };


    const saveFormData = async (data) => {
        // console.log('saveFormData', data)


        let parentIds = [];
        let childIds = [];


        for (const parentMenuId in parentChildMapping) {
            if (Object.hasOwnProperty.call(parentChildMapping, parentMenuId)) {
                const childMenus = parentChildMapping[parentMenuId];

                // Now you can work with parentMenuId and childIds 
                // Example: Iterate over child IDs
                if (childMenus.length) {
                    if (parentMenuId && !parentIds.includes(parentMenuId)) {
                        parentIds.push(parentMenuId);
                    }
                    childMenus.forEach(childId => {
                        if (childId && !childIds.includes(childId)) {
                            childIds.push(childId);
                        }
                    });
                }

            }
        }

        // // Loop through the input data
        // selectedParentMenus.forEach(item => {
        //     // Add parent_menu_id to parentIds if not already present
        //     if (!parentIds.includes(item)) {
        //         parentIds.push(item);
        //     }
        // });
        // selectedChildMenus.forEach(item => {
        //     // Add child_menu_id to childIds if not already present
        //     if (!childIds.includes(item)) {
        //         childIds.push(item);
        //     }
        // }); 



        let post_data = {
            "username": data.username,
            "password": data.password,
            "word_watch": data.password,
            "level_id": data.level_id,
            "user_email": data.user_email,
            "department_id": data.department_id,
            "department_name": data.department_name,
            "ismanager": data.ismanager,
            "phone_number": data.phone_number,
            "extension": data.extension,
            "pm_id": parentIds,
            "cm_id": childIds
        };
        // console.log('saveFormDatapost_data', post_data)

        setIsLoading(true);



        try {
            var api = "";
            let response = "";
            if (editStatus) {
                post_data.level_id = editId;
                api = api_url + 'manage_admin/update_user_level_permission';
                response = await axios.put(api, post_data, token);
            } else {
                api = api_url + 'manage_admin/add_admin_user';
                response = await axios.post(api, post_data, token);
            }

            console.log("response",response)
            if (response.status === 201) {
                setIsLoading(false);
                if (editStatus) {
                    triggerAlert('success', 'success', 'Data updated successfully');
                    pageReload();
                } else {
                    triggerAlert('success', 'success', 'Data added successfully');
                }

                setTimeout(() => {
                    navigate(`/manage_admin/user_list`);
                }, 3000);
            } else if (response.status === 204) {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            } 
            else {
                setIsLoading(false);
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }

        } catch (error) { 
            
            if(error?.response?.data?.error_code === 400){
                setIsLoading(false);
                triggerAlert('error', 'Oops...', error?.response?.data?.message);
            }else{
                setIsLoading(false);
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }
            
        }

    };


    ////////////////////////////////////////////////////////////////////////////////
    const fetchEditData = async (edit_id) => {
        try {
            setIsLoading(true);
            setEditStatus(true);


            const itemsArray = editStateData
            setValueForm('department_id', itemsArray.department_id);
            setValueForm('level_id', itemsArray.level_id);
            setValueForm('user_email', itemsArray.email);
            setValueForm('username', itemsArray.username);
            setValueForm('password', itemsArray.word_watch);
            // setValueForm('confirm_password',itemsArray.word_watch);
            setValueForm('ismanager', itemsArray.is_manager);

            const parent_id_string = itemsArray.parent_id;
            const parent_id_array = parent_id_string.split(',').map(Number);
            parent_id_array.forEach((parent_id, index) => {
                let parent_menu_id = "parent_menu_" + parent_id;
                setValueForm(parent_menu_id, true);
            });


            setSelectedParentMenus(parent_id_array);

            const child_id_string = itemsArray.child_id;
            const child_id_array = child_id_string.split(',').map(Number);
            child_id_array.forEach((child_id, index) => {
                let child_menu_id = "child_menu_" + child_id;
                setValueForm(child_menu_id, true);
            });

            setSelectedChildMenus(child_id_array);

            setIsLoading(false);


        } catch (error) {
            setIsLoading(false);
            triggerAlert('error', 'Oops...', 'Something went wrong..');
        }

    };


    const fetchMenuData = async () => {
        setIsLoading(true);

        try {


            const response = await axios.get(api_url + 'manage_admin/get_menu_list', token);
            const response_data = response.data

            if (response.status === 200) {
                const itemsArray = response_data.results
                setMenuData(itemsArray);

                setIsLoading(false);
            } else if (response.status === 204) {
                setMenuData([]);
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                // triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }

        } catch (error) {
            setIsLoading(false);
            // triggerAlert('error', 'Oops...', 'Something went wrong..');
        }
    };


    const [selectedParentMenus, setSelectedParentMenus] = useState([]);
    const [selectedChildMenus, setSelectedChildMenus] = useState([]);



    const handleChildCheckboxChange = (parentMenuArray, childMenuArray, isChecked) => {
        let parentMenu = parentMenuArray.id
        let childMenu = childMenuArray.id

        let parentIdsArray = parentMenu;



        // Check if the child_menu_id already exists in selectedMenus

        if (!childMenu) {
            // Parent value check
            let parent_menu_id = "parent_menu_" + parentMenu;
            setValueForm(parent_menu_id, isChecked);

            let submenuData = parentMenuArray.submenu;


            submenuData.forEach((sub_menu_data, index) => {
                checkBoxCheckUncheck(parentIdsArray, sub_menu_data.id, isChecked, "parent");
            });

        } else {

            checkBoxCheckUncheck(parentIdsArray, childMenu, isChecked, "child");

        }

    };

    const checkBoxCheckUncheck = (parentMenu, childMenu, isChecked, type) => {
        ////////////////////////////////////////////
        const updatedMapping = { ...parentChildMapping };

        // Check if the parent ID already exists in the mapping
        if (updatedMapping[parentMenu]) {
            // Parent ID exists, update the array with the new child ID
            if (isChecked) {
                updatedMapping[parentMenu].push(childMenu);
            } else {
                // If not checked, remove the child ID from the array
                if (type == "parent") {
                    updatedMapping[parentMenu] = [];
                } else {
                    updatedMapping[parentMenu] = updatedMapping[parentMenu].filter(id => id !== childMenu);
                }

            }
        } else {
            // Parent ID doesn't exist, create a new array with the child ID
            updatedMapping[parentMenu] = [childMenu];
        }
        // Update the state with the new mapping
        setParentChildMapping(updatedMapping);
        ///////////////////////////////////////////////////

        let child_menu_id = "child_menu_" + childMenu;

        setValueForm(child_menu_id, isChecked);

        setSelectedChildMenus((prevMenus) =>
            prevMenus.filter((item) => item !== childMenu)
        );

        setSelectedParentMenus((prevMenus1) =>
            prevMenus1.filter((item) => item !== parentMenu)
        );

        if (isChecked) {
            setSelectedChildMenus((prevMenus) => [...prevMenus, childMenu]);
        }
        setSelectedParentMenus((prevMenus1) => [...prevMenus1, parentMenu]);

    };





    /////////////////// Trunk name validation //////
    const validateUserName = async () => {
        var username = getValuesForm('username');

        try {
            const response = await axios.get(api_url + 'manage_admin/unique_name_search?type=admin_user&keyword=' + username, token);
            if (response.status === 200) {
                setNameValid(true);
                setAddStatus(true);
                // setIsLoading(false);
            } else if (response.status === 400) {
                setNameValid(false);
                setAddStatus(false);
                // setIsLoading(false); 
            }
            else {
                setNameValid(false);
                setAddStatus(false);
                // setIsLoading(false); 
            }

        } catch (error) {
            setNameValid(false);
            setAddStatus(false);
            // setIsLoading(false); 
        }

    };

    //////////////////////////

    const selectedDeprtment = watch('department_id');


    useEffect(() => {
        const menu_id = menuIdData.user_list;
        const add_permission = get_user_menu_permission(menu_id, 'add');
        const edit_permission = get_user_menu_permission(menu_id, 'edit');

        // if (add_permission || edit_permission) {
        //     if (action == "update") {
        //         setEditId(edit_id);
        //         fetchEditData(edit_id);
        //     }
        //     fetchMenuData();
        // } else {
        //     triggerAlert('error', 'Oops...', "You don't have permission to access this page");

        //     setTimeout(() => {
        //         window.history.back();
        //     }, 3000);
        // }

    }, [])

    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                {isLoading &&
                    <div className='loader-overlay text-white'>
                        <Loader />
                    </div>
                }

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}
                            <>

                                <div className="row  ">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="float-end">
                                                    <Link to="/manage_user/user_list" className="btn btn-warning w-md btn-md"   >Back</Link>

                                                    <button className="btn btn-primary addBtn waves-effect waves-light w-md font-size-14 ms-2" type='button' onClick={handleSubmitForm(saveFormData)} disabled={!addStatus}
                                                    > {editStatus ? "Update" : "Add"}<i className="align-middle font-size-16 ms-2"></i></button>

                                                </div>

                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    {/* <div className="col-md-3">
                                                        <div className="mb-3">
                                                            <label for="department_id" className="form-label">Department<span className="text-danger">*</span></label>
                                                            <Controller
                                                                name="department_id"
                                                                {...registerForm('department_id',
                                                                    { required: 'Department is required' }
                                                                )}
                                                                control={controlForm}
                                                                render={({ field }) => (
                                                                    <AutoSearchSelect
                                                                        {...field}
                                                                        apiUrl={process.env.REACT_APP_API_BASE_URL + 'get_departments'}
                                                                        placeholder="Select Department"
                                                                        mapOption={result => ({
                                                                            value: result.id,
                                                                            label: result.name,
                                                                        })}
                                                                        value={field.value}
                                                                        onSelect={handleDepartment}
                                                                    />
                                                                )}
                                                            />
                                                            {errorsForm.department_id && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.department_id.message}</div>}
                                                        </div>
                                                    </div> */}




                                                    {selectedDeprtment && (
                                                        <div className="col-md-3">
                                                            <div className="mb-3">
                                                                <label htmlFor="level_id" className="form-label">Level </label>
                                                                <Controller
                                                                    key={forceUpdate}
                                                                    name="level_id"
                                                                    {...registerForm('level_id',
                                                                        { required: 'Level is required' }
                                                                    )}
                                                                    control={controlForm}
                                                                    render={({ field }) => (
                                                                        <AutoSearchSelect
                                                                            {...field}
                                                                            apiUrl={`apiV1/role`}
                                                                            placeholder="Select Level"
                                                                            mapOption={result => ({
                                                                                value: result.id,
                                                                                label: result.name,
                                                                                // additionalField: result.additionalField,
                                                                                // Add additional fields as needed
                                                                            })}
                                                                            value={field.value}
                                                                            onSelect={handleLevel}
                                                                        />



                                                                    )}
                                                                />
                                                                {errorsForm.level_id && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.level_id.message}</div>}
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className="col-md-3">
                                                        <div className="mb-3">
                                                            <label for="formrow-email-input" className="form-label">Username <span className="text-danger">*</span></label>
                                                            <input type="text"
                                                                className="form-control"

                                                                {...registerForm('username', { required: 'Username is required' })}
                                                                name="username"
                                                                onBlur={() => editStatus ? undefined : validateUserName()}
                                                            />
                                                            {errorsForm.username && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.username.message}</div>}

                                                            {!nameValid && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>Username is already used</div>}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="mb-3">
                                                            <label htmlFor="password" className="form-label">Password  <span className="text-danger">*</span> </label>
                                                            <div>
                                                                <input type="password" className="form-control passwordInput" id='password' {...registerForm('password', {
                                                                    required: 'Password is required',
                                                                    pattern: {
                                                                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/,
                                                                        message: 'Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character.'
                                                                    }
                                                                })} placeholder="Enter Password" name='password' />

                                                            </div>

                                                            {errorsForm.password && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.password.message}</div>)}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="mb-3">
                                                            <label for="formrow-email-input" className="form-label">Email <span className="text-danger">*</span></label>
                                                            <input type="email"
                                                                className="form-control"
                                                                id="formrow-email-input"
                                                                placeholder=" "
                                                                {...registerForm('user_email', {
                                                                    required: 'Email is required',
                                                                    pattern: {
                                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                                        message: 'Invalid email address',
                                                                    },
                                                                })}
                                                                name="user_email"

                                                            />
                                                            {errorsForm.user_email && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.user_email.message}</div>}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="mb-3">
                                                            <label for="formrow-email-input" className="form-label">Phone Number<span className="text-danger">*</span></label>
                                                            <input type="input"
                                                                className="form-control"
                                                                placeholder="Enter Phone Number "
                                                                {...registerForm('phone_number', {
                                                                    required: 'Phone Number is required',
                                                                    pattern: onlyNumbers,
                                                                    maxLength: MaxLengthValidation(15),
                                                                    minLength: MinLengthValidation(10),
                                                                })}
                                                                name="phone_number"

                                                            />
                                                            {errorsForm.phone_number && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.phone_number.message}</div>}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="mb-3">
                                                            <label for="formrow-email-input" className="form-label">Extension Number<span className="text-danger">*</span></label>
                                                            <input type="input"
                                                                className="form-control"
                                                                placeholder="Enter Extension Number"
                                                                {...registerForm('extension', {
                                                                    required: 'Extension Number is required',
                                                                    pattern: onlyNumbers,
                                                                    maxLength: MaxLengthValidation(5),
                                                                    minLength: MinLengthValidation(3),
                                                                })}
                                                                name="extension"
                                                            />
                                                            {errorsForm.extension && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errorsForm.extension.message}</div>}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="formrow-inputCity" className="form-label">Is Manager</label>
                                                            <Controller
                                                                control={controlForm}
                                                                name="ismanager"
                                                                render={({ field }) => (
                                                                    <div className="switch mt-1">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="ismanager"
                                                                            switch="none"
                                                                            checked={field.value === 'Y'}
                                                                            onChange={(e) => setValueForm('ismanager', e.target.checked ? 'Y' : 'N')}

                                                                        />
                                                                        <label htmlFor="ismanager" data-on-label="Yes" data-off-label="No"></label>
                                                                    </div>
                                                                )}

                                                            />
                                                        </div>
                                                    </div>




                                                </div>

                                                <div className="row">

                                                    {menuData.map((parent_menu) => (
                                                        <div className="col-md-3" key={parent_menu.id}>
                                                            <div className="col-md-12">
                                                                <div className=" mb-3">
                                                                    <div className="input-group">
                                                                        <div className="input-group-text input_group_user_level">
                                                                            <Controller
                                                                                name={`parent_menu_${parent_menu.id}`}
                                                                                {...registerForm(`parent_menu_${parent_menu.id}`)}
                                                                                control={controlForm}
                                                                                defaultValue={false}
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            id={`parent_menu_${parent_menu.id}`}
                                                                                            checked={field.value}
                                                                                            {...field}
                                                                                            onChange={(e) => handleChildCheckboxChange(parent_menu, "", e.target.checked)}

                                                                                        />
                                                                                        <label className="form-check-label" htmlFor={`parent_menu_label_${parent_menu.id}`}>
                                                                                            {parent_menu.menu_name}
                                                                                        </label>
                                                                                    </>
                                                                                )}
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                {parent_menu.submenu.map((child_menu) => (
                                                                    <div key={child_menu.id} className="mb-3 ps-3">
                                                                        <div className="form-check">
                                                                            <Controller
                                                                                name={`child_menu_${child_menu.id}`}
                                                                                {...registerForm(`child_menu_${child_menu.id}`)}
                                                                                control={controlForm}
                                                                                defaultValue={false}
                                                                                render={({ field }) => (
                                                                                    <>
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            id={`child_menu_${child_menu.id}`}
                                                                                            checked={field.value}
                                                                                            {...field}
                                                                                            onChange={(f) => handleChildCheckboxChange(parent_menu, child_menu, f.target.checked)}

                                                                                        />
                                                                                        <label className="form-check-label" htmlFor={`child_menu_label_${child_menu.id}`}>
                                                                                            {child_menu.menu_name}
                                                                                        </label>
                                                                                    </>
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>


                                            </div>
                                        </div>
                                    </div> {/* <!-- end col -->*/}
                                </div>
                            </>



                        </div> {/* container-fluid */}
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </>
    )
}

