import React, { useEffect, useState } from "react";
import Header from '../../common/Header'
import Sidebar from '../../common/Sidebar'
import Footer from '../../common/Footer'
import PageHeader from '../../pages/PageHeader'
import axios from "axios";
import { triggerAlert, ConfirmationAlert, getToken, get_user_menu_permission, pageReload } from '../../utils';
import Loader from '../../common/Loader'
import { useNavigate, Link } from 'react-router-dom'
import { menuIdData } from '../../utils/constants'
import DataTable from 'react-data-table-component';
import { Modal, ModalBody } from "react-bootstrap";
import { useForm, Controller } from 'react-hook-form';
import AutoSearchSelect from '../CommonSelectDynamic'
export default function ManageSupportTickets() {
    const mainHeading = "Support";
    const heading = "Support / Manage Tickets    ";
    const [isLoading, setIsLoading] = useState(true);
    const [listData, setListData] = useState([]);
    const [SuccessAlert, setSuccessAlert] = useState(false);
    const [CreatenewUser, setCreatenewUser] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [selectedid, setselectedid] = useState()
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [editid, seteditid] = useState()
    const [searchQuery, setSearchQuery] = useState('');
    const [textbase, settextbase] = useState("Create")
    const [products, setProducts] = useState([]);
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const { register: registerForm, handleSubmit: handleSubmitForm, formState: { errors: errorsForm }, setValue: setValueForm, reset: resetForm, control: controlForm, getValues: getValuesForm, watch } = useForm({
        defaultValues: {
            ismanager: "N"
        },
    });
    const columns = [
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">S.No</label>, sortable: true, selector: row => row.ticket_number, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_14 align-middle align-middle">
                        <span>{row.ticket_number}</span>
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">User</label>, sortable: true, selector: row => row.user_name, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.user_name}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Company</label>, sortable: true, selector: row => row.company_name[0], cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.company_name[0]}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Sub Category</label>, sortable: true, selector: row => row.ticket_sub_category, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.ticket_sub_category}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Assigned User</label>, sortable: true, selector: row => row.assigned_user, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.assigned_user}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Created At</label>, sortable: true, selector: row => row.register_date, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.register_date}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Updated At</label>, sortable: true, selector: row => row.updated_date, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.updated_date}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Created By</label>, sortable: true, selector: row => row.created_by_name, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.created_by_name}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Updated By</label>, sortable: true, selector: row => row.last_updated_user, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.last_updated_user}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Time Span</label>, sortable: true, selector: row => row.time_span, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.time_span}
                    </div>
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Ticket Priority</label>, sortable: true, selector: row => row.ticket_status_value, cell: row =>
                <>
                    <div className="DarkBlue4_Color font_weight_300 font_16 align-middle">
                        {row.ticket_status_value}
                    </div>
                </>,
        },
    
        
        
         
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Status</label>, sortable: true, selector: row => row.current_setup_status, cell: row =>
                <>
                    {row.current_setup_status ? (
                         <span className="btn btn-danger btn-sm btn-rounded waves-effect waves-light">Inactive</span>
                       
                    ) : row.current_setup_status == false ? (
                        <span className="btn btn-success btn-sm btn-rounded waves-effect waves-light">Active</span>
                    ) : (
                        <span className="btn btn-info btn-sm btn-rounded waves-effect waves-light">Unknown</span>
                    )}
                </>,
        },
        {
            name: <label className="DarkBlue4_Color font_weight_500 font_14">Action</label>, sortable: true, selector: row => row.is_deleted, cell: row =>
                <>
                    <div className="btn-group float-end col-md-1  ml-10">
                        <button type="button" className="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ "marginLeft": "10px" }}>
                            Action <i className="mdi mdi-chevron-down"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end  ">
                            <div className="dropdown-item hand_icon" onClick={() => { seteditid(row.id); settextbase("Update"); setCreatenewUser(true);  onloadeditedData(row);}}>Edit</div>
                            
                            
                            {row.is_deleted ? 
                            <div className="dropdown-item hand_icon" onClick={() => statusChange(!row.is_deleted, row.id)}>Activate</div>
                           :  <div className="dropdown-item hand_icon" onClick={() => statusChange(!row.is_deleted, row.id)}  >Deactivate  </div>
                             }
                            
                        </div>
                    </div>
                </>,
        },



    ];
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };
    const handleKeySearch = (e) => {
        setCurrentPage(1);
        setSearchQuery(e.target.value);
        const searchkey = e.target.value;
        fetchListData(1, searchkey);
    };
    const onloadeditedData = (data) => {
        setValueForm("cmp_name", data.cmp_name)
        setValueForm("cont_person_email", data.cont_person_email)
        setValueForm("corp_email", data.corp_email)
        setValueForm("cmp_website", data.cmp_website)
        setValueForm("phone", data.phone)
        setValueForm("designation", data.designation)
        setValueForm("location", data.location)
        setValueForm("point_of_reach", data.point_of_reach)
        setValueForm("current_setup_status", data.current_setup_status)
        setValueForm("subs_start_date", data.subs_start_date)
        setValueForm("subs_end_date", data.subs_end_date)
        setValueForm("subs_renew_date", data.subs_renew_date)
        setValueForm("license_count", data.license_count)
        setValueForm("domain", data.domain)
        setValueForm("product", data.product)
        setValueForm("cust_zipcode", data.cust_zipcode)
        
    }
    const fetchListData = async (page, searchkey) => {
        setIsLoading(true);

        axios.defaults.headers.common = { 'Authorization': `gAAAAABmWEQfxDL7PqcxvFryABbMLaRRluv8lb50nmDDzpeN4KrfygfLKU6C-jGJ_NSAO01Z6dC3yi2KkAhGIyre7l14C2kHpMBxsHcIPCFjpw5oU8IXd7KFufCxSEjZl-fUV-yCq0jx7Lgd6XEiO4H1jPR8I_8f4dLoRY8d1M0d9BZvLBbBf00F5DDRsjN-DOj1vuMEvOIR3DKko2kVA0s7eI1QbNNpe6Ozz7HUMMAy8wEZTxngfqFYcvngELK4UzH1qU1sO9QT` }
        try {
            const response = await axios.get(`https://qaadmin.indianhr.in/payg_admin/supports/get_ticket_list?page_size=${perPage || 10}&page_number=${page || 1}${searchkey ? `&search=${searchkey}` : ""}`);
            const response_data = response.data
            if (response.status === 200) {
                const itemsArray = response_data.results.data;
                itemsArray.map((item, i) => {
                    item.Num = i + 1;
                    return item
                })
                //const total_pages = response_data.count;
               // setTotalRows(total_pages);
                setListData(itemsArray);
                setIsLoading(false);
            } else if (response.status === 204) {
                setListData([]);
                setIsLoading(false);
                setTotalRows(0);
            }
            else {
                setListData([]);
                setIsLoading(false);
                setTotalRows(0);
            }

        } catch (error) {
            setListData([]);
            setIsLoading(false);
            setTotalRows(0);
        }
    };

    const statusChange = (status, id) => {
        ConfirmationAlert('Are you sure you want to continue!', 'Continue', async () => {
            const post_data = {
                "is_deleted": status
            }
            try {
                const response = await axios.delete(`apiV1/product-list/${id}/`,  { data: post_data});
                const response_data = response.data;
                if (response_data.status_code === 200) {
                    triggerAlert('success', 'success', 'Status updated Successfully!!');
                    fetchListData(1);
                } else {
                    triggerAlert('error', 'Oops...', 'Something went wrong..');
                }
            } catch (error) {
                triggerAlert('error', 'Oops...', 'Something went wrong..');
            }
        })

    };
    const onSubmit = (data) => {
        if(editid){
            axios.patch(`apiV1/product-list/${editid}/`, data).then((response) => {
                fetchListData(1);
                setCreatenewUser(false)
                resetForm();
                triggerAlert('success', 'success', 'Data updated successfully');
            })
            .catch((error) => {
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            });
        }else{
            axios.post("apiV1/product-list/", data).then((response) => {
                fetchListData(1);
                setCreatenewUser(false)
                resetForm();
                triggerAlert('success', 'success', 'Data added successfully');
            })
            .catch((error) => {
                triggerAlert('error', 'Oops...', 'Something went wrong here..');
            });
        }
        
    };
    const handlePageChange = page => {
        fetchListData(page);
        setCurrentPage(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        fetchListData(page, newPerPage);
        setPerPage(newPerPage);
    };
    const fetchProducts = async () => {
        try {
          const response = await axios.get(`https://qaadmin.indianhr.in/product-dropdown/`, {
            params: {
              page: 1,
              limit: 50
            }
          });
  
  
          setProducts(response.data || []);
        } catch (error) {
          console.error('Error fetching products:', error);
        }
      };
      const fetchSubscriptionPlans = async () => {
        try {
          const response = await axios.get(`https://qaadmin.indianhr.in/subscription-dropdown/`, {
            params: {
              page: 1,
              limit: 50
            }
          });
           
          setSubscriptionPlans(response.data || []);
           
        } catch (error) {
          console.error('Error fetching subscription plans:', error);
        }
      };
  
      
    useEffect(() => {
        fetchListData(1);
        fetchProducts();
        fetchSubscriptionPlans();
    }, []);
    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}
                            <>
                                <div class="row">
                                    <div class="info-tiles col-md-1 me-5 mx-2 mb-5" style={{backgroundColor:"rgb(0, 95, 115)"}}>
                                        <div class="tiles-heading" data-toggle="tooltip" title="Click here">Pending</div>
                                        <div class="tiles-body"><span>5</span></div>
                                    </div>
                                    <div class="info-tiles col-md-1 me-5 mx-3 mb-5" style={{backgroundColor:"rgb(202, 103, 2)"}}>
                                        <div class="tiles-heading" data-toggle="tooltip" title="Click here">Open</div>
                                        <div class="tiles-body">1</div>
                                    </div>
                                    <div class="info-tiles col-md-1 me-5 mx-3 mb-5" style={{backgroundColor:"rgb(187, 62, 3)"}}>
                                        <div class="tiles-heading" data-toggle="tooltip" title="Click here">Close</div>
                                        <div class="tiles-body">4</div>
                                    </div>
                                </div>
                                <div className="row  ">
                                    <div className="col-12">
                                        <div className="card">
                                            {/* <div className="card-header">

                                                <div className="btn-group float-end col-md-2  ml-10">
                                                    <button type="button" className="btn btn-primary w-md btn-md" onClick={() => { setCreatenewUser(true); settextbase("Create"); seteditid(); resetForm(); }} style={{ "marginLeft": "10px" }}>
                                                        Create Customers
                                                    </button>

                                                </div>

                                            </div> */}
                                            <div className="card-body">
                                                <div id="datatable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-12 mb-2">
                                                            <div className="btn-group float-end ">
                                                                <input type="search" placeholder="Search..." value={searchQuery} className="form-control form-control-sm" aria-controls="example" onChange={handleKeySearch} />
                                                                &nbsp;&nbsp;
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {!isLoading ?
                                                        <DataTable
                                                            columns={columns}
                                                            data={listData}
                                                            pagination
                                                            paginationServer
                                                            fixedHeaderScrollHeight='600px'
                                                            fixedHeader
                                                            persistTableHead
                                                            paginationTotalRows={totalRows}
                                                            paginationComponentOptions={paginationComponentOptions}
                                                             
                                                            paginationDefaultPage={currentPage}
                                                            onChangeRowsPerPage={handlePerRowsChange}
                                                            onChangePage={handlePageChange}
                                                             

                                                        /> :
                                                        <div className='loader-overlay text-white'>
                                                            <Loader />
                                                        </div>
                                                    }
                                                    <hr />
                                                </div>

                                            </div>
                                        </div>
                                    </div> {/* <!-- end col -->*/}
                                </div>
                            </>



                        </div> {/* container-fluid */}
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}

            <div className="rightbar-overlay"></div>
            <Modal size='lg' show={CreatenewUser} onHide={() => setCreatenewUser(false)} className='  modal fade ' backdrop="static" keyboard={false} >
                <Modal.Header className='modal-header_box bg-my-primary p-3 d-flex justify-content-between ' closeButton> <h1 className="modal-title fs-5 ">{textbase} Customer  </h1> </Modal.Header>

                <form onSubmit={handleSubmitForm(onSubmit)}>
                    <ModalBody>
                        <div className="row">     
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Company Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerForm('cmp_name', { required: 'Company Name is required'})} name="cmp_name" />
                                    {errorsForm.cmp_name && <div className="text-danger">{errorsForm.cmp_name.message}</div>}
                                </div>  
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label  class="form-label" for="formrow-Email-input" > Email <span style={{ color: "red" }}>*</span> </label>     
                                    <input type="email" name="corp_email" id="user" class="form-control" placeholder="sample@gmail.com"
                                      {...registerForm("corp_email", { required: "Email is required",
                                        pattern: {
                                           value: /^(?!.*\s)[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                          message: "Invalid Email Id format",
                                        },
                                      })}
                                    />
                                    
                                    {errorsForm.corp_email && ( <div className="text-danger"> {errorsForm.corp_email.message}  </div> )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label  class="form-label" for="formrow-Email-input" > Company Email <span style={{ color: "red" }}>*</span> </label>     
                                    <input type="email" name="cont_person_email" id="user" class="form-control" placeholder="sample@gmail.com"
                                      {...registerForm("cont_person_email", { required: "Email is required",
                                        pattern: {
                                           value: /^(?!.*\s)[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                          message: "Invalid Email Id format",
                                        },
                                      })}
                                    />
                                    
                                    {errorsForm.cont_person_email && ( <div className="text-danger"> {errorsForm.cont_person_email.message}  </div> )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label for="formrow-cpassword-input" className="form-label">Mobile <span className="text-danger">*</span></label>
                                    <input type="number" id='formrow-cpassword-input' className="form-control" {...registerForm('phone', {
                                        required: 'Mobile is required', pattern: { value: /^[0-9]+$/, message: "Please enter min 10 digit Phone Number" },
                                        minLength: { value: 10, message: "Please enter 10 digit phone number" },
                                        maxLength: { value: 10, message: "Please enter 10 digit phone number" },
                                    })} name="phone" />
                                    {errorsForm.phone && <div className="text-danger">{errorsForm.phone.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Contact Person Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerForm('contact_person', {
                                        required: 'contact_person is required', pattern: {
                                            value: /^[A-Za-z\s]+$/, // Include \s for space character
                                            message: 'Please enter only alphabets',
                                        }
                                    })} name="first_name" />
                                    {errorsForm.contact_person && <div className="text-danger">{errorsForm.contact_person.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Company Website <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerForm('cmp_website', {
                                        required: 'Firstname is required',  pattern: {
                                            // Updated pattern to accept http, https, or www
                                            value: /^(https?:\/\/)?(www\.)?([\w-]+(\.[\w-]+)+)([\/\w .-]*)*\/?$/,
                                            message: "Please enter a valid URL (http, https, or www).",
                                          }
                                    })} name="cmp_website" />
                                    {errorsForm.cmp_website && <div className="text-danger">{errorsForm.cmp_website.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Location(City) <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" {...registerForm('location', {
                                        required: 'location is required', pattern: {
                                            value: /^[A-Za-z\s]+$/, // Include \s for space character
                                            message: 'Please enter only alphabets',
                                        }
                                    })} name="first_name" />
                                    {errorsForm.location && <div className="text-danger">{errorsForm.location.message}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label for="formrow-email-input" className="form-label">Category<span className="text-danger">*</span></label>
                                    <select type="text" className="form-select" {...registerForm('category', {
                                        required: 'Please select category', 
                                    })} name="product_type" >
                                        <option value="">Select category</option>
                                        <option value="Trial">Trial</option>
                                    </select>
                                    {errorsForm.category && <div className="text-danger">{errorsForm.category.message}</div>}
                                </div> 
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label" for="formrow-PhoneNumber-input" > Product  <span style={{ color: "red" }}>*</span> </label>
                                    <select className="form-select form-control" id="product" {...registerForm("product")} >
                                        <option value="">-- Please choose --</option>
                                        {products.map(product => (
                                            <option key={product.id} value={product.id}>{product.product_name}</option>
                                        ))}
                                    </select>
                                    {errorsForm.product && (<div className="text-danger"> {errorsForm.product.message} </div> )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label" for="formrow-PhoneNumber-input" > Found us via?  <span style={{ color: "red" }}>*</span> </label>
                                    <select className="form-select form-control" {...registerForm("point_of_reach", { required: "This field is required." })} >
                                        <option value="">--Please choose--</option>
                                        <option value="Events And Promotions">Events and Promotions</option>
                                        <option value="Social media">Social Media</option>
                                        <option value="Word Of Mouth">Word of Mouth</option>
                                        <option value="Referral">Referral</option>
                                        <option value="INDIASOFT 2024">INDIASOFT 2024</option>
                                    </select>
                                    {errorsForm.point_of_reach && ( <div className="text-danger"> {errorsForm.point_of_reach.message} </div> )}
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label" for="formrow-PhoneNumber-input"  >  Tenat Type  <span style={{ color: "red" }}>*</span> </label>
                                    <select class="form-select" name="tenant_type" {...registerForm("tenant_type")} >
                                        <option value="">Select</option>
                                        <option value="Single_Tenant">Single Company</option>
                                        <option value="Multi_Tenant">Multiple Company</option>
                                    </select>
                                    {errorsForm.tenant_type && ( <div className="text-danger"> {errorsForm.tenant_type.message} </div> )}
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label" for="formrow-PhoneNumber-input" > Subscription Plan  <span style={{ color: "red" }}>*</span> </label>
                                    <select className="form-select form-control" {...registerForm("subscription", {  required: "This field is required."  })} >
                                        <option value="">-- Please choose --</option>
                                        {subscriptionPlans.map(plan => (
                                        <option key={plan.id} value={plan.id}>{plan.plan_name}</option>
                                        ))}
                                    </select>
                                    {errorsForm.subscription && ( <div  className="text-danger">  {errorsForm.subscription.message} </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <Modal.Footer>
                        <div className="py-2 mb-3">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button className='btn btn-warning w-md btn-md me-2' type="button" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" onClick={() => { setCreatenewUser(false); }} > Cancel </button>
                                    <button className='btn btn-primary w-md btn-md ' type="submit" id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn"  > Save </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>

                </form>
            </Modal>
             

            <Modal size='md' show={SuccessAlert} onHide={() => setSuccessAlert(false)} className='text-center  modal fade ' backdrop="static" keyboard={false} >
                <Modal.Header className='modal-header_box bg-my-primary p-3 d-flex justify-content-between ' closeButton> <h1 className="modal-title fs-5 ">{textbase} Product  </h1> </Modal.Header>
                <ModalBody>
                    <h4>Product {textbase} Successfully</h4>
                </ModalBody>
                <Modal.Footer>
                    <div className="py-2 mb-3">
                        <div className="row">
                            <div className="col-12 text-center">
                                <button className='btn btn-primary w-md btn-md ' id="AnomalyDetailsWriteYourReason_Modal_AlertSucc_OkayBtn" onClick={() => setSuccessAlert(false)}  > Ok </button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )

}
