import Header from "../../common/Header";
import Sidebar from "../../common/Sidebar";
import Footer from "../../common/Footer";

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { triggerAlert, pageReload, getToken, handleTableRowClick, formattedDateTime, get_user_menu_permission, exportToCsv } from '../../utils';
import Loader from '../../common/Loader';

import Table from 'react-bootstrap/Table';
import CountUp from 'react-countup';
import Pagination from "../PaginationComponent";
import AutoUserSearch from '../AutoUserSearch'
import Swal from "sweetalert2";
import { menuIdData } from "../../utils/constants";
//import AutoCompanySearch from '../AutoCompanySearch'

function ActivateCustomers() {
	const api_url = process.env.REACT_APP_API_BASE_URL;
	const show50Entries = process.env.REACT_APP_API_SHOW_ENTRIES;
	const token = getToken();

	const [isLoading, setIsLoading] = useState([]);
	const [isLoadingFull, setIsLoadingFull] = useState(false);
	const [inactiveData, setInactiveData] = useState([]);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedRowId, setSelectedRowId] = useState(null);
	const [custCountData, setCustCountData] = useState(null);
	const [items, setItems] = useState([]);
	const [approveUserId, setApproveUserId] = useState(0);
	const [MSAStatusId, setMSAStatusId] = useState(0);
	const [paymentStatusId, setPaymentStatusId] = useState(0);
	const [configStatus, setConfigStatus] = useState(0);

	const [currentPage, setCurrentPage] = useState(0);
	const [perPageLimit, setPerPageLimit] = useState(show50Entries);
	const [pageCount, setPageCount] = useState(0);
	const [pageLimitSelected, setPageLimitSelected] = useState(10);

	// Menu Permissions
	//  const [addPermission, setAddPermission] = useState(false);
	const [editPermission, setEditPermission] = useState(false);
	// const [deletePermission, setDeletePermission] = useState(false);
	const [exportPermission, setExportPermission] = useState(false);

	const [sectionVisible, setSectionVisible] = useState({
		sectionA: true,
		sectionB: false,
		sectionC: false,
		sectionD: false,
		sectionE: false,
	});

	const { control, register, handleSubmit, formState: { errors }, setValue, reset, watch } = useForm();
	const { control: control2, register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, setValue: setValue2, reset: reset2 } = useForm();
	const { control: control3, register: register3, handleSubmit: handleSubmit3, formState: { errors: errors3 }, setValue: setValue3, reset: reset3 } = useForm();
	const { control: control1, register: register1, handleSubmit: handleSubmit1, formState: { errors: errors1 }, setValue: setValue1, reset: reset1 } = useForm();

	const searchCustomers = async () => {
		try {
			const response = await axios.get(`apiV1/active-customers-dropdown/`);
			const response_data = response.data;

			if (response_data.error_code === 200) {
				const itemsArray = response_data
				//console.log('response_itemsArray', itemsArray);
				setItems(itemsArray);
			}
			else if (response.status === 204) {
				setItems([]);
			} else {
				triggerAlert('error', 'Oops...', "Couldn't get the customer details");
			}
		} catch (error) {
			triggerAlert("error", "Oops...", "Something went wrong..");
		}
	};

	const searchByKeyword = async (keyword) => {
		try {
			const response = await axios.get(api_url + `customer/get_registered_users?&page_number=1&page_size=25&keyword=${keyword}`, token);
			const respdata = response.data.results.data;
			setInactiveData(respdata);

			setIsLoading(false);
		} catch (error) {
			triggerAlert('error', '', 'No data available');
		}
	}

	const toggleSection = (section) => {
		setSectionVisible((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	const handleUserSelect = (userData) => {
		//console.log(userData);
		setPageCount(0);
		setCurrentPage(0);
		setPerPageLimit(show50Entries);
		setPageLimitSelected(10);
		searchByKeyword(userData.user);
	};

	const approveRequest = async (id) => {
		Swal.fire({
			icon: 'warning',
			title: 'Do you want to Approve User?',
			showCancelButton: true,
			confirmButtonText: 'Yes',
		}).then(async (result) => {
			if (result.isConfirmed) {
				let data = { status: "active" };
				try {
					//var id = approveUserId;
					const response = await axios.put(api_url + 'customer/customer_status_update/' + id + '/update', data, token);
					//console.log(response);
					const response_data = response.data;
					if (response_data.error_code == "200") {
						triggerAlert('success', 'success', 'User Approved Successfully');
						pageReload();
					} else {
						triggerAlert('error', 'Oops...', 'Something went wrong..');
					}
				} catch (error) {
					triggerAlert('error', 'Oops...', 'Something went wrong..');
				}
			}
		});
		//alert(id);
		// setApproveUserId(id);
		// setValue3('status', 'N');
		// toggleSection('sectionA');
		// toggleSection('sectionE');
		// if(selectedRowId){
		// 	//setValue('configuration_date', selectedRow.configuration_date);
		// 	setValue3('configuration_status', selectedRow.configuration_status);
		// 	toggleSection('sectionA');
		// 	toggleSection('sectionE');
		// }else{
		// 	//triggerAlert('error','Oops...','Please select a row');
		// }

		// try {
		//     const response 		= await axios.get(api_url+'permission_details/'+id+'/', token);
		//     const respdata 	    = response.data.results.data;
		//     if (respdata.length === 0) {
		//         setValue4('user_id', id);
		//         handleMgUsersPermissionShow();
		//     } else {
		//         let parent = respdata[0].parent_id;
		//         let child = respdata[0].child_id;
		//         // let assignlines = respdata[0].assigned_lines;
		//         setValue4('user_id', respdata[0].user_id);
		//         // //const selectedValuesArray = assignlines.split(',');
		//         setParentChecked(parent);
		//         setChildChecked(child);
		//         // setIsChecked(assignlines);
		//         // //setCheckboxValues(assignlines);
		//         handleMgUsersPermissionShow();
		//     }
		// } catch (error) {
		//     triggerAlert('error','Oops...','Something went wrong..');
		// }
	}

	// const ApproveUserStatus = async(data) => {
	// 	//console.log(data);
	// 	//console.log('approved user',approveUserId);
	// 	try {
	// 	    var id = approveUserId;
	// 	    const response 		= await axios.put(api_url+'customer/customer_status_update/'+id+'/update', data, token);
	// 		console.log(response);
	// 		const response_data = response.data;
	// 		if(response_data.error_code == "200"){
	// 			triggerAlert('success','success','User Approved Successfully');
	// 			pageReload();
	// 		}else{
	// 			triggerAlert('error','Oops...','Something went wrong..');
	// 		}
	// 	} catch (error) {
	// 		triggerAlert('error','Oops...','Something went wrong..');
	// 	}
	// }

	const deletemsaStatus = async (id) => {
		Swal.fire({
			icon: 'warning',
			title: 'Do you want to disapprove MSA Agreement?',
			showCancelButton: true,
			confirmButtonText: 'Yes',
		}).then(async (result) => {
			if (result.isConfirmed) {
				let data = { "msa_status": "N" }
				try {
					//var id = MSAStatusId;
					const response = await axios.put(api_url + 'customer/update_msa_status/' + id + '/update', data, token);
					//	console.log(response);
					const response_data = response.data;
					if (response_data.error_code == "200") {
						triggerAlert('success', 'success', 'MSA Status Updated successfully');
						pageReload();
					} else {
						triggerAlert('error', 'Oops...', 'Something went wrong..');
					}
				} catch (error) {
					//triggerAlert('error','Oops...','Something went wrong..');
				}
			}
		});

	}

	const activeMSAStatus = async (id) => {
		Swal.fire({
			icon: 'warning',
			title: 'Do you want to approve MSA Agreement?',
			showCancelButton: true,
			confirmButtonText: 'Yes',
		}).then(async (result) => {
			if (result.isConfirmed) {
				let data = { "msa_status": "Y" }
				try {
					//var id = MSAStatusId;
					const response = await axios.put(api_url + 'customer/update_msa_status/' + id + '/update', data, token);
					//console.log(response);
					const response_data = response.data;
					if (response_data.error_code == "200") {
						triggerAlert('success', 'success', 'MSA Status Updated successfully');
						pageReload();
					} else {
						triggerAlert('error', 'Oops...', 'Something went wrong..');
					}
				} catch (error) {
					//triggerAlert('error','Oops...','Something went wrong..');
				}
			}
		});

	}

	const msaStatusRequest = async (id) => {
		//console.log(selectedRow);
		setMSAStatusId(id);
		try {
			const response = await axios.get(api_url + 'customer/get_customer_data?id=' + id, token);
			const respdata = response.data.results;
			//console.log('response',respdata);
			// console.log(respdata.user_name);
			// console.log(respdata.email);
			// console.log(respdata.msa_status);
			if (respdata.length === 0) {
				// setValue4('user_id', id);
				// handleMgUsersPermissionShow();
			} else {
				setValue2('user_name', respdata.user_name);
				setValue2('email', respdata.email);
				setValue2('msa_status', respdata.msa_status);

				toggleSection('sectionA');
				toggleSection('sectionB');
			}
		} catch (error) {
			triggerAlert('error', 'Oops...', 'Something went wrong..');
		}
	};

	//msa status submission
	const msaStatusSubmit = async (data) => {
		//console.log(data);
		//console.log(MSAStatusId);
		try {
			var id = MSAStatusId;
			const response = await axios.put(api_url + 'customer/update_msa_status/' + id + '/update', data, token);
			console.log(response);
			const response_data = response.data;
			if (response_data.error_code == "200") {
				triggerAlert('success', 'success', 'MSA Status Updated successfully');
				pageReload();
			} else {
				triggerAlert('error', 'Oops...', 'Something went wrong..');
			}
		} catch (error) {
			triggerAlert('error', 'Oops...', 'Something went wrong..');
		}
	}

	//payment status submission
	const paymentStatusRequest = async (id) => {
		//console.log(selectedRow);
		Swal.fire({
			icon: 'warning',
			title: 'Are you sure you want to confirm receipt of the payment?',
			showCancelButton: true,
			confirmButtonText: 'Yes',
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let data = { "payment_status": "Y" }
					//var id = selectedRowId;
					const response = await axios.put(api_url + 'customer/update_payment_status/' + id + '/update', data, token);
					//console.log(response);
					const response_data = response.data;
					if (response_data.error_code == "200") {
						triggerAlert('success', 'success', 'Payment Status Updated successfully');
						pageReload();
					} else {
						triggerAlert('error', 'Oops...', 'Something went wrong..');
					}
				} catch (error) {
					triggerAlert('error', 'Oops...', 'Something went wrong..');
				}
			}
		});

		// setPaymentStatusId(id);
		// 	try {
		// 		const response 		= await axios.get(api_url+'customer/get_customer_data?id='+id, token);
		// 		const respdata 	    = response.data.results;
		// 		console.log(respdata);
		// 		if (respdata.length === 0) {
		// 			// setValue4('user_id', id);
		// 			// handleMgUsersPermissionShow();
		// 		} else {
		// 			setValue1("payment_status", respdata.payment_status);
		// 			toggleSection('sectionA');
		// 			toggleSection('sectionC');
		// 		}
		// 	} catch (error) {
		// 		triggerAlert('error','Oops...','Something went wrong..');
		// 	}
		// if(selectedRowId){
		// 	let payment_status ="N";
		// 	if(selectedRow.payment_status == "Payment Not Done"){
		// 		payment_status = "N";
		// 	}else{
		// 		payment_status = "Y";
		// 	}

		// 	setValue1("payment_status", payment_status);
		// 	toggleSection('sectionA');
		// 	toggleSection('sectionC');
		// }else{
		// 	//triggerAlert('error','Oops...','Please select a row');
		// }
	};

	// const paymentStatusSubmit = async (data) =>{
	// 	try {
	// 		console.log(data);
	// 		console.log(paymentStatusId);
	// 	    var id = selectedRowId;
	// 	    const response 		= await axios.put(api_url+'customer/update_payment_status/'+id+'/update', data, token);
	// 		console.log(response);
	// 		const response_data = response.data;
	// 		if(response_data.error_code == "200"){
	// 			triggerAlert('success','success','Payment Status Updated successfully');
	// 			pageReload();
	// 		}else{
	// 			triggerAlert('error','Oops...','Something went wrong..');
	// 		}
	// 	} catch (error) {
	// 		triggerAlert('error','Oops...','Something went wrong..');
	// 	}
	// }

	//configuration submission
	const configStatusRequest = async (id) => {
		setConfigStatus(id);
		try {
			const response = await axios.get(api_url + 'customer/get_customer_data?id=' + id, token);
			const respdata = response.data.results;
			//console.log(respdata);
			if (respdata.length === 0) {
				// setValue4('user_id', id);
				// handleMgUsersPermissionShow();
			} else {
				setValue('configuration_date', respdata.configuration_date);
				setValue('configuration_status', respdata.configuration_status);
				toggleSection('sectionA');
				toggleSection('sectionD');
			}
		} catch (error) {
			triggerAlert('error', 'Oops...', 'Something went wrong..');
		}


		// if(selectedRowId){
		// 	setValue('configuration_date', selectedRow.configuration_date);
		// 	setValue('configuration_status', selectedRow.configuration_status);
		// 	toggleSection('sectionA');
		// 	toggleSection('sectionD');
		// }else{
		// 	//triggerAlert('error','Oops...','Please select a row');
		// }
	}

	const configStatusSubmit = async (data) => {
		//console.log(data);
		data.configuration_status = "Y";
		//console.log(configStatus);
		try {
			var id = configStatus;
			const response = await axios.put(api_url + 'customer/update_configuration_status/' + id + '/update', data, token);
			//	console.log(response);
			const response_data = response.data;
			if (response_data.error_code == "200") {
				triggerAlert('success', 'success', 'Configuration Status Updated successfully');
				pageReload();
			} else {
				triggerAlert('error', 'Oops...', 'Something went wrong..');
			}
		} catch (error) {
			triggerAlert('error', 'Oops...', 'Something went wrong..');
		}
	}



	//default
	const fetchInactiveCustomerData = async (page, searchkey) => {
		const per_page = perPageLimit;
		const searchQuery = searchkey;
		setIsLoading(true);
		try {
			let apiUrl = `${api_url}customer/get_registered_users`;
			let params = `?page_size=${per_page || 50}&page_number=${page || 1}`;
			if (searchQuery) params += `&keyword=${searchQuery}`;
			const response = await axios.get(apiUrl + params, token);
			//const response 		= await axios.get(api_url+`customer/get_registered_users?page_number=${page}&page_size=${per_page}`, token);
			const response_data = response.data.results.data;
			const total_pages = response.data.results.pagination.total_pages;
			setInactiveData(response_data);
			setPageCount(total_pages);
			setIsLoading(false);
		} catch (error) {
			triggerAlert('error', 'Oops...', 'Something went wrong..');
		}
	}

	const fetchData = async () => {
		setIsLoading(true);
		try {
			const response = await axios.get(api_url + 'customer/get_customer_counts', token);
			const activatecust = response.data.results.data.new_customer;
			//const respdata = response.data.results.data;
			setCustCountData(activatecust);

			setIsLoading(false);
		} catch (error) {
			triggerAlert('error', '', 'No data available');
		}
	};

	useEffect(() => {
		setCurrentPage(0);
		fetchInactiveCustomerData(currentPage + 1);
		fetchData();
	}, [perPageLimit]);

	//pagination
	const handlePageClick = (selected) => {
		const selectedPage = selected.selected;
		setCurrentPage(selectedPage);
		fetchInactiveCustomerData(selectedPage + 1);
	};

	let props = {
		pageCount: pageCount,
		handlePageClick: handlePageClick,
		selectedPage: currentPage
	}

	const exportToExcel = async () => {
		setIsLoadingFull(true);
		// if (customerId) {  //check this sahiti while implementing
		try {
			let apiUrl = `${api_url}billing/get_transaction_history_download_data`;
			let params = "";
			// if (customerId) params += `?customer_id=${customerId}`;

			// return true
			const response = await axios.get(apiUrl + params, token);
			const response_data = response.data;
			if (response_data.error_code === 200) {
				const itemsArray = response_data.results;
				exportToCsv(itemsArray, 'transaction_history_report.csv');
				setIsLoadingFull(false);
			} else if (response.status === 204) {
				setIsLoadingFull(false);
			} else {
				triggerAlert('error', 'Oops...', "Couldn't export the file");
			}
		} catch (error) {
			//console.log(error)
			setIsLoadingFull(false);
			triggerAlert("error", "Oops...", "Something went wrong..");
		}
		// } else {
		// 	simpleAlert('Select User!!')
		// }
	}
	useEffect(() => {
		const menu_id = menuIdData?.active_customers;
		// setAddPermission(get_user_menu_permission(menu_id, 'add'));
		setEditPermission(get_user_menu_permission(menu_id, 'edit'));
		// setDeletePermission(get_user_menu_permission(menu_id, 'delete'));
		setExportPermission(get_user_menu_permission(menu_id, 'export'));
	}, []);

	return (
		<>
			<div id="layout-wrapper">
				<Header />
				<Sidebar />
				{/* ============================================================== */}
				{/* Start right Content here */}
				{/* ============================================================== */}
				<div className="main-content">
					<div className="page-content">
						{isLoadingFull && <div className='loader-overlay text-white'>
							<Loader />
						</div>}
						{/* Inactive Customer List */}
						<div hidden={!sectionVisible.sectionA} className="container-fluid">
							{/* start page title */}
							<div className="row">
								<div className="col-12">
									<div className="page-title-box d-sm-flex align-items-center justify-content-between">
										<h4 className="mb-sm-0 font-size-18">Activate Customers</h4>
										<div className="page-title-right">
											<ol className="breadcrumb m-0">
												<li className="breadcrumb-item "><a href="#">Customers</a></li>
												<li className="breadcrumb-item active">Activate Customer</li>
											</ol>
										</div>
									</div>
								</div>
							</div>
							{/* end page title */}

							{/* <div className="row">
							<div className="col-xl-3 col-md-6">
								 card 
								<div className="card card-h-100 card-custom-bg">
									 card body 
									<div className="card-body mt-3">
										<div className="row align-items-center">
											<div className="col-8">
												<span className="text-org  mb-3 lh-1 d-block  ">Activate Customers</span>
												<h4 className="mb-3">
													<CountUp start={0} end={custCountData} duration={5} />
													<CountUp start={0} end={custCountData && custCountData.customer_count.length !== 0
															? (custCountData.customer_count.find(item => item.status === "New")
																? custCountData.customer_count.find(item => item.status === "New").count
																: 0)
															: 0
													} duration={5} />
												</h4>
											</div>
											<div className="col-4">
												<div className="toll-free"><img src="/assets/images/new-customer.png" className="img-thumbnail rounded-circle  "/></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div> */}

							<div className="row">
								<div className="col-12">
									<div className="card">
										<div className="card-header d-flex justify-content-between">
											<div className="col-md-4">
												<label>Select Customer</label>
												{/* <AutoCompanySearch onSelect={handleUserSelect} status={"new"}/> */}
												<AutoUserSearch onSelect={handleUserSelect} status={"activate_customer"} />
											</div>
											{/* {exportPermission &&
												<div class="col-md-4 d-flex align-items-center justify-content-end">
													<button type="button" class="btn btn-success w-md btn-md" onClick={exportToExcel}>
														Export to Excel
													</button>
												</div>
											} */}

											{/* <div class="btn-group   mb-3 float-end">
												<button type="button" class="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													Action <i class="mdi mdi-chevron-down"></i>
												</button>
												<div class="dropdown-menu dropdown-menu-end py-2">
													<a class="dropdown-item" onClick={msaStatusRequest}><i class="far fa-edit font-size-14"></i> MSA Status</a>
													<a class="dropdown-item" onClick={paymentStatusRequest}><i class="mdi mdi-cash"></i> Payment Status</a>
													<a class="dropdown-item" onClick={configStatusRequest}><i class="bx bx-wrench"></i> Configuration Status</a>
												</div>
											</div> */}
										</div>
										<div className="card-body">
											<Table id="datatable" className="nowrap w-100 dt-responsive table-responsive table-bordered">
												<thead style={{ backgroundColor: '#ededed' }}>
													<tr>
														{/* <th>#</th> */}
														<th>Username</th>
														<th>Company Name</th>
														<th>Registered Date</th>
														<th>Status</th>
														<th>MSA Status</th>
														<th>Configuration Status</th>
														<th>Payment Status</th>
													</tr>
												</thead>
												{isLoading ? (
													<div className='loader-overlay text-white'>
														<Loader />
													</div>
												) : (
													<tbody>
														{inactiveData.length > 0 ? (
															// const date = new Date(item.register_date); // Convert the string to a Date object
															// const formattedDate = date.toISOString().slice(0, 19).replace('T', ' '); // Format the date
															inactiveData.map((item, index) => (
																<tr onClick={() => handleTableRowClick(item, selectedRow, setSelectedRow, selectedRowId, setSelectedRowId, item.id)} className={selectedRowId === item.id ? 'row_selected' : ''} >
																	{/* <th scope="row">{(currentPage) * pageLimitSelected + index + 1}</th> */}
																	<td>{item.user_name}</td>
																	<td>{item.company_name}</td>
																	<td>{formattedDateTime(item.register_date)}</td>
																	<th>{(item.status === "active" || item.status === "Active") ? (
																		<span>Activated</span>
																	) : (
																		editPermission ?
																			<span onClick={() => approveRequest(item.id)} style={{ color: "#7367f0", cursor: 'pointer' }}>Approve User</span>
																			: <span className="text-danger">Permission required!</span>
																	)}</th>
																	<td>{(item.msa_status === "MSA Agreement" || item.msa_status === "MSA Submitted" && item.status === "active") ? (
																		<>
																			{/* <span onClick={() => msaStatusRequest(item.id)} style={{color:"#7367f0"}}>{item.msa_status}</span> */}MSA Submitted&nbsp;
																			<a href="{item.msa_agreement}" data-bs-toggle="tooltip" title="" data-bs-placement="top">
																				<i class="bx bxs-show"></i>
																			</a>&nbsp;
																			{editPermission ?
																				<>
																					<span onClick={() => activeMSAStatus(item.id)} style={{ color: "#7367f0", cursor: 'pointer' }}><i class="bx bxs-edit"></i></span>&nbsp;
																					{item.msa_status === "MSA Submitted" ? " " : (<span onClick={() => deletemsaStatus(item.id)} style={{ color: "#7367f0", cursor: 'pointer' }}><i class="bx bxs-trash"></i></span>)}
																				</>
																				: <span className="text-danger">Permission required!</span>}
																		</>
																	) : (
																		<span>{item.msa_status}</span>
																	)}
																		{/* {item.msa_status} */}
																	</td>
																	<td>{(item.configuration_status === "configuration Pending" && item.msa_status === "MSA Submitted") ? (
																		editPermission ?
																			<span onClick={() => configStatusRequest(item.id)} style={{ color: "#7367f0", cursor: 'pointer' }}>{item.configuration_status}</span>
																			: <span className="text-danger">Permission required!</span>
																	) : (
																		<span>{item.configuration_status}</span>
																	)}
																	</td>
																	<td>{(item.payment_status === "Payment Not Done" && item.configuration_status === "configuration Completed" && item.msa_status === "MSA Submitted") ? (
																		editPermission ?
																			<span onClick={() => paymentStatusRequest(item.id)} style={{ color: "#7367f0", cursor: 'pointer' }}>{item.payment_status}</span>
																			: <span className="text-danger">Permission required!</span>
																	) : (
																		<span>{item.payment_status}</span>
																	)}
																	</td>
																</tr>
															))
														) : (
															<tr>
																<td colSpan="8" className="text-center">No data available</td>
															</tr>
														)}
													</tbody>
												)}
											</Table>
											<hr />
											<Pagination {...props} />
										</div>
									</div>
								</div> {/* end col */}
							</div> {/* end row */}
						</div> {/* container-fluid */}
						{/* Inactive Customer List */}
						{/* MSA Status */}
						<div hidden={!sectionVisible.sectionB} class="container-fluid">
							{/* <!-- start page title --> */}
							<div class="row">
								<div class="col-12">
									<div class="page-title-box d-sm-flex align-items-center justify-content-between">
										<h4 class="mb-sm-0 font-size-18">MSA Status</h4>
										<div class="page-title-right">
											<ol class="breadcrumb m-0">
												<li class="breadcrumb-item"><a href="#">Customers</a></li>
												<li class="breadcrumb-item"><a href="#">Activate Customer</a></li>
												<li class="breadcrumb-item active">MSA Status</li>
											</ol>
										</div>
									</div>
									<div className="float-end">
										<button class="btn btn-warning w-md" type="button" onClick={() => { toggleSection('sectionA'); toggleSection('sectionB') }}>Back</button>
									</div>
								</div>
							</div>

							{/* <!-- end page title --> */}
							<div class="row mt-4">
								<div class="col-lg-12">
									<div class="card">
										<div class="card-body p-4">
											<div class="row">
												<div class="col-lg-12">
													<div>
														<form id="creditCardForm1" novalidate="novalidate" enctype="multipart/form-data" onSubmit={handleSubmit2(msaStatusSubmit)}>
															<div class="row">
																<div class="col-md-4">
																	<div class="mb-3">
																		<label class="form-label" for="formrow-Username-input">User Name</label>
																		<input type="text" name="user_name" id="formrow-Username-input" disabled={true} class="form-control" placeholder="Enter User Name" {...register2('user_name', {
																			required: 'Username is required',
																			pattern: {
																				value: /^[A-Za-z0-9-_]+$/,
																				message: 'Username should have only alphabets',
																			},
																		})} />
																		{errors2.user_name && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.user_name.message}</div>}
																	</div>
																</div>
																<div class="col-md-4">
																	<div class="mb-3">
																		<label class="form-label" for="formrow-Email-input">Email</label>
																		<input type="email" name="email" id="user" class="form-control" placeholder="sample@gmail.com" {...register2('email', {
																			required: 'Email is required',
																			pattern: {
																				value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
																				message: 'Invalid Email Id format',
																			},
																		})} />
																		{errors2.email && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.email.message}</div>}
																	</div>
																</div>
																<div class="col-md-4">
																	<div class="mb-3">
																		<label class="form-label" for="formrow-PhoneNumber-input">MSA Status</label>
																		<Controller
																			control={control2}
																			name="msa_status"
																			rules={{
																				required: "MSA Status is required",
																			}}
																			render={({ field }) => (
																				<select class="form-select" name="msa_status" {...register2('msa_status')} onChange={(e) => {
																					field.onChange(e);
																					//console.log(e.target.value);
																				}} value={field.value}>
																					<option value="">Select</option>
																					<option value="Y">Active</option>
																					<option value="N">Inactive</option>
																				</select>
																			)}
																		/>
																		{errors2.msa_status && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors2.msa_status.message}</div>}
																	</div>
																</div>
															</div>
															<div class="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
																<button class="btn btn-warning w-md" type="button" onClick={() => { toggleSection('sectionA'); toggleSection('sectionB') }}>Cancel</button>
																<button class="btn btn-primary w-md me-md-2" type="submit">Update</button>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* MSA Status */}
						{/* Payment Status */}
						{/* <div hidden={!sectionVisible.sectionC} class="container-fluid">
						<!-- start page title -->
						<div class="row">
							<div class="col-12">
								<div class="page-title-box d-sm-flex align-items-center justify-content-between">
									<h4 class="mb-sm-0 font-size-18">Payment Status</h4>
									<div class="page-title-right">
										<ol class="breadcrumb m-0">
										<li class="breadcrumb-item"><a href="#">Customers</a></li>
										<li class="breadcrumb-item"><a href="#">Activate Customer</a></li>
										<li class="breadcrumb-item active">Payment Status</li>
										</ol>
									</div>
								</div>
								<div className="float-end">
									<button class="btn btn-warning w-md" type="button" onClick={() => {toggleSection('sectionA');toggleSection('sectionC')}}>Back</button>
								</div>
							</div>
						</div>
						
						<!-- end page title -->
						<div class="row mt-4">
						<div class="col-lg-12">
							<div class="card">
							<div class="card-body p-4">
								<div class="row">
								<div class="col-lg-12">
									<div>
										<form id="creditCardForm2"  novalidate="novalidate" enctype="multipart/form-data" onSubmit={handleSubmit1(paymentStatusSubmit)}>
											<div class="row">
												<div class="col-md-4">
													<div class="mb-3">
													<label class="form-label" for="formrow-PhoneNumber-input">Payment Status</label>
													<Controller
														control={control1}
														name="payment_status"
														rules={{
															required: "MSA Status is required",
														}}
														render={({ field }) => (
														<select class="form-select" name="payment_status" {...register1('payment_status')} onChange={(e) => {
															field.onChange(e);
															console.log(e.target.value);
															}}  value={field.value}>
															<option value="">Select</option>
															<option value="Y">Active</option>
															<option value="N">Inactive</option>
														</select>
														)}
													/>
													{errors1.payment_status && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors1.payment_status.message}</div>}
													</div>
												</div>
											</div>
											<div class="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
												<button class="btn btn-warning w-md" type="button"  onClick={() => {toggleSection('sectionA');toggleSection('sectionC')}}>Cancel</button>
												<button class="btn btn-primary w-md me-md-2" type="submit">Update</button>
											</div>
										</form>
									</div>
								</div>
								</div>
							</div>
							</div>
						</div>
						</div> 
					</div> */}
						{/* Payment Status */}
						{/* MSA Status */}
						<div hidden={!sectionVisible.sectionD} class="container-fluid">
							{/* <!-- start page title --> */}
							<div class="row">
								<div class="col-12">
									<div class="page-title-box d-sm-flex align-items-center justify-content-between">
										<h4 class="mb-sm-0 font-size-18">Configuration Status</h4>
										<div class="page-title-right">
											<ol class="breadcrumb m-0">
												<li class="breadcrumb-item"><a href="#">Customers</a></li>
												<li class="breadcrumb-item"><a href="#">Activate Customer</a></li>
												<li class="breadcrumb-item active">Configuration Status</li>
											</ol>
										</div>
									</div>
									<div className="float-end">
										<button class="btn btn-warning w-md" type="button" onClick={() => { toggleSection('sectionA'); toggleSection('sectionD') }}>Back</button>
									</div>
								</div>
							</div>

							{/* <!-- end page title --> */}
							<div class="row mt-4">
								<div class="col-lg-12">
									<div class="card">
										<div class="card-body p-4">
											<div class="row">
												<div class="col-lg-12">
													<div>
														<form id="creditCardForm3" novalidate="novalidate" enctype="multipart/form-data" onSubmit={handleSubmit(configStatusSubmit)}>
															<div class="row">
																<div class="col-md-4">
																	<div class="mb-3">
																		<label class="form-label" for="formrow-Username-input">Configuration Date</label>
																		<input type="date" name="configuration_date" id="formrow-Username-input" class="form-control" placeholder="Enter your User Name" {...register('configuration_date', {
																			required: 'Configuration Date is required',
																			// pattern: {
																			// 	value: /^[A-Za-z0-9]+$/,
																			// 	message: 'Username should have only alphabets',
																			// },
																		})} />
																		{errors.configuration_date && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.configuration_date.message}</div>}
																	</div>
																</div>
																<div class="col-md-4">
																	<div class="mb-3">
																		{/*<input type="hidden" name="configuration_status" value="Y"/>
													<label class="form-label" for="formrow-PhoneNumber-input">Configuration Status</label>
													<Controller
														control={control}
														name="configuration_status"
														rules={{
															required: "Configuration Status is required",
														}}
														render={({ field }) => (
														<select class="form-select" name="configuration_status" {...register('configuration_status')} onChange={(e) => {
															field.onChange(e);
															console.log(e.target.value);
															}}  value={field.value}>
															<option value="">Select</option>
															<option value="Y">Active</option>
															<option value="N">Inactive</option>
														</select>
														)}
													/>
													{errors.configuration_status && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.configuration_status.message}</div>}*/}
																	</div>
																</div>
															</div>
															<div class="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
																<button class="btn btn-warning w-md" type="button" onClick={() => { toggleSection('sectionA'); toggleSection('sectionD') }}>Cancel</button>
																<button class="btn btn-primary w-md me-md-2" type="submit">Update</button>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* MSA Status */}

						{/* Approve User */}
						{/* <div hidden={!sectionVisible.sectionE} class="container-fluid">
						<!-- start page title -->
						<div class="row">
							<div class="col-12">
								<div class="page-title-box d-sm-flex align-items-center justify-content-between">
									<h4 class="mb-sm-0 font-size-18">Configuration Status</h4>
									<div class="page-title-right">
										<ol class="breadcrumb m-0">
											<li class="breadcrumb-item"><a href="#">Customers</a></li>
											<li class="breadcrumb-item"><a href="#">Activate Customer</a></li>
											<li class="breadcrumb-item active">Configuration Status</li>
										</ol>
									</div>
								</div>
								<div className="float-end">
									<button class="btn btn-warning w-md" type="button" onClick={() => {toggleSection('sectionA');toggleSection('sectionE')}}>Back</button>
								</div>
							</div>
						</div>
						
						 <!-- end page title --> 
						<div class="row mt-4">
						<div class="col-lg-12">
							<div class="card">
							<div class="card-body p-4">
								<div class="row">
								<div class="col-lg-12">
									<div>
										<form id="creditCardForm3"   novalidate="novalidate" enctype="multipart/form-data" onSubmit={handleSubmit3(ApproveUserStatus)}>
											<div class="row">
												<div class="col-md-4">
													<div class="mb-3">
													<label class="form-label" for="formrow-Username-input">Configuration Date</label>
													<input type="date" name="configuration_date" id="formrow-Username-input" class="form-control" placeholder="Enter your User Name" {...register('configuration_date', { required: 'Configuration Date is required',
														// pattern: {
														// 	value: /^[A-Za-z0-9]+$/,
														// 	message: 'Username should have only alphabets',
														// },
													})} />
													{errors.configuration_date && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.configuration_date.message}</div>}
													</div>
												</div>
												<div class="col-md-4">
													<div class="mb-3">
													<label class="form-label" for="formrow-PhoneNumber-input">Status</label>
													<Controller
														control={control3}
														name="status"
														rules={{
															required: "Status is required",
														}}
														render={({ field }) => (
														<select class="form-select" name="status" {...register3('status')} onChange={(e) => {
															field.onChange(e);
															console.log(e.target.value);
															}}  value={field.value}>
															<option value="">Select</option>
															<option value="active">Active</option>
															<option value="inactive">Inactive</option>
														</select>
														)}
													/>
													{errors3.configuration_status && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors3.configuration_status.message}</div>}
													</div>
												</div>
											</div>
											<div class="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
												<button class="btn btn-warning w-md" type="button"  onClick={() => {toggleSection('sectionA');toggleSection('sectionE')}}>Cancel</button>
												<button class="btn btn-primary w-md me-md-2" type="submit">Update</button>
											</div>
										</form>
									</div>
								</div>
								</div>
							</div>
							</div>
						</div>
						</div> 
					</div> */}
						{/* Approve User */}


					</div>
					{/* End Page-content */}
					<Footer />
				</div>
				{/* end main content*/}
			</div>
			{/* END layout-wrapper */}
			{/* Right bar overlay*/}
			<div className="rightbar-overlay"></div>
		</>
	);
}

export default ActivateCustomers;
