import React, { useEffect, useState } from 'react'
import Header from "../../common/Header";
import Sidebar from "../../common/Sidebar";
import Footer from "../../common/Footer";
import PageHeader from "../PageHeader";
import { ConfirmationAlert, getToken, simpleAlert, triggerAlert } from '../../utils';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Pagination from '../PaginationComponent';
import { Table } from 'react-bootstrap';
import Loader from '../../common/Loader';
import AutoUserSearch from '../AutoUserSearch';
export default function UnassignedDids() {
  const mainHeading = 'Unassigned  DIDs';
  const heading = 'DIDs / Unassigned Dids';
  const api_url = process.env.REACT_APP_API_BASE_URL;
  const show50Entries = process.env.REACT_APP_API_SHOW_ENTRIES;
  const token = getToken();
  const { register, handleSubmit, formState: { errors }, setValue, watch, reset } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [customerId, setcustomerId] = useState(null);

  const [unassignedDids, setUnassignedDids] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [perPageLimit, setPerPageLimit] = useState(show50Entries);
  const [pageLimitSelected, setPageLimitSelected] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});

  const fetchUnassignedDids = async (page_number, searchkey) => {

    setIsLoading(true);
    const page_size = perPageLimit;
    const line_type = watch('did_type');

    try {

      let apiUrl = `${api_url}dids/get_unassigned_did_data`;

      // Set default values for page_size and page_number
      let params = `?page_size=${page_size || 10}&page_number=${page_number || 1}`;

      // Add line_type, customer_id, and searchkey if they are present

      if (searchkey) {
        params += `&keyword=${searchkey}`;
      }
      const response = await axios.get(apiUrl + params, token);

      const response_data = response.data;


      if (response_data.error_code === 200) {
        const itemsArray = response_data.results.data

        const total_pages = response_data.results.pagination.total_pages;
        setPageCount(total_pages);
        setUnassignedDids(itemsArray);
        setIsLoading(false);
      } else if (response.status === 204) {
        setUnassignedDids([]);
        setPageCount(0);
        setIsLoading(false);
      } else {
        triggerAlert('error', 'Oops...', "Couldn't get the card details");
      }
    } catch (error) {
      setIsLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }

  };

  const assignDids = (formData) => {
    if (Object.keys(selectedCheckboxes).length > 0) {
      formData.customer_id = customerId;
      formData.selected_did = Object.keys(selectedCheckboxes).map((key) => {
        const {
          did_id,
          did_number,
          calling_provider,
          sms_provider,
          line_type,
          country,
        } = selectedCheckboxes[key];

        return {
          did_id,
          did_number,
          calling_provider,
          sms_provider: sms_provider || "", // Default to an empty string if sms_provider is null
          line_type,
          country,
        };
      });

      ConfirmationAlert('You want to continue?', 'Continue', async () => {
        try {
          const response = await axios.post(api_url + `dids/assign_did_data`, formData, token);
          const response_data = response.data;
          if (response_data.error_code === 200) {
            triggerAlert('success', 'Success', 'DIDs Assigned Successfully');
            reset();
            setPageCount(0);
            setCurrentPage(0);
            setPerPageLimit(show50Entries);
            setPageLimitSelected(10);
            setSelectedCheckboxes({});
            fetchUnassignedDids(currentPage + 1);
          } else {
            triggerAlert('error', 'Oops...', "Unable To Assign DIDs");
          }
        } catch (error) {
          console.log(error);
          triggerAlert("error", "Oops...", "Something went wrong..");
        }
      });
    } else {
      simpleAlert('Please select atleast one checkbox.')
    }

  }


  const onSubmit = (formData) => {
    if (customerId) {
      assignDids(formData);
    } else {
      simpleAlert('Search and Select the User!!');
    }
  }

  const deleteDids = async () => {
    if (Object.keys(selectedCheckboxes).length > 0) {
      const api_input = {};
      api_input.selected_did = Object.keys(selectedCheckboxes).map(
        (key) => selectedCheckboxes[key].did_id
      );




      ConfirmationAlert('You want to continue?', 'Continue', async () => {
        try {
          const response = await axios.post(api_url + `dids/delete_available_did_data`, api_input, token);
          const response_data = response.data;
          if (response_data.error_code === 200) {
            triggerAlert('success', 'Success', 'DIDs Deleted Successfully');
            reset();
            setPageCount(0);
            setCurrentPage(0);
            setPerPageLimit(show50Entries);
            setPageLimitSelected(10);
            setSelectedCheckboxes({})
            fetchUnassignedDids(currentPage + 1);
          } else {
            triggerAlert('error', 'Oops...', "Unable To Delete DIDs");
          }
        } catch (error) {
          console.log(error);
          triggerAlert("error", "Oops...", "Something went wrong..");
        }
      });
    } else {
      simpleAlert('Please select atleast one checkbox.')
    }

  }

  const handleDidChange = () => {
    setPageCount(0);
    setCurrentPage(0);
    setPerPageLimit(show50Entries);
    setPageLimitSelected(10);
  }

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    const searchkey = e.target.value;
    fetchUnassignedDids(1, searchkey); // Update search results on every change

  }

  const handlePageChange = event => {
    if (event.target.value) {
      setPerPageLimit(event.target.value);
      setPageLimitSelected(event.target.value);
    } else {
      setPerPageLimit(10);
      setPageLimitSelected(10);
    }
  };

  const handlePageClick = (selected) => {

    const selectedPage = selected.selected;
    setCurrentPage(selectedPage);
    fetchUnassignedDids(selectedPage + 1); // Increment the page number by 1 for server-side pagination
  };

  let pgntn_props = {
    pageCount: pageCount,
    handlePageClick: handlePageClick,
    selectedPage: currentPage
  }

  //checkAll func
  const handleCheckAllChange = (event) => {
    const isChecked = event.target.checked;
    const newSelectedCheckboxes = { ...selectedCheckboxes };


    unassignedDids.forEach((row) => {
      if (isChecked) {
        newSelectedCheckboxes[row.did_id] = {
          did_id: row.did_id,
          did_number: row.did_number,
          calling_provider: row.calling_provider,
          sms_provider: row.sms_provider,
          line_type: row.line_type,
          country: row.country,
          isChecked: true
        };
      } else {
        delete newSelectedCheckboxes[row.did_id];
      }
    });


    setSelectedCheckboxes(newSelectedCheckboxes);
  };

  //checkbox func
  const handleCheckboxChange = (event, id, number, provider, smsProvider, type, country) => {
    const isChecked = event.target.checked;


    setSelectedCheckboxes((prevState) => {
      // Create a copy of the previous state
      const updatedState = { ...prevState };

      // If checkbox is checked, add it to the state
      if (isChecked) {
        updatedState[id] = {
          did_id: id,
          did_number: number,
          calling_provider: provider,
          sms_provider: smsProvider,
          line_type: type,
          country: country,
          isChecked: true,
        };

      } else {
        // If checkbox is unchecked, remove it from the state
        delete updatedState[id];
      }

      // Return the updated state
      return updatedState;
    });

  }
  const handleClearClick = () => {
    setSelectedCheckboxes({});

  };

  const handleUserSelect = (userData) => {
    // You can use the userData in your logic
    setcustomerId(userData.user_id);
    // setPageCount(0);
    // setUnassignedDids([]);
    // setCurrentPage(0);
    // setPerPageLimit(show50Entries);
    // setPageLimitSelected(10);
    // setIsSearchBtnDisabled(false);
  };

  useEffect(() => {
    setCurrentPage(0);
    fetchUnassignedDids(currentPage + 1);

  }, [perPageLimit])

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />

        <div className="main-content">

          <div className="page-content">
            <div className="container-fluid">
              {/* start page title */}
              <PageHeader mainHeading={mainHeading} heading={heading} />
              {/* end page title */}

              <div className="row mt-5">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row ">
                        <div className="col-xl-12">
                          <div className="">
                            <div className="card-body pt-1 ps-2">
                              <form data-select2-id="21" noValidate="novalidate" onSubmit={handleSubmit(onSubmit)}>
                                <div className="row g-3" data-select2-id="20">
                                  <div className="col-md-3">
                                    <label className="form-label" for="formtabs-first-name">Assign to User</label>
                                    <AutoUserSearch onSelect={handleUserSelect} />
                                  </div>
                                  <div className="col-md-3">
                                    <label className="form-label" htmlFor="formtabs-first-name">DID Status</label>
                                    <select className="form-select" name="line_status" {...register('line_status', { required: 'Line Status is required' })} aria-label="Default select example" onChange={handleDidChange}>
                                      <option value="" hidden>Select Line Status</option>
                                      <option value="free">Free</option>
                                      <option value="paid">Paid</option>
                                    </select>
                                    {errors.line_status && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.line_status.message}</div>}
                                  </div>

                                  <div className="col-md-6">
                                    <label className="form-label" for="formtabs-last-name">&nbsp;</label>
                                    <div className="button-items">
                                      {unassignedDids.length ?
                                        (<><button type="submit" className="btn btn-primary w-md" >Assign</button>
                                          <button type="button" className="btn btn-danger w-md mx-3" onClick={deleteDids}>Delete</button></>) : ""}
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        {/* <div className="col-sm-12 col-md-6">
                          <div className="dataTables_length" id="datatable_length">
                            <label>Show
                              <select name="example_length" aria-controls="example" classNameName="table_length" onChange={handlePageChange} value={pageLimitSelected}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select> entries
                            </label>
                          </div>
                        </div> */}
                        <div className="col-sm-12 col-md-12 mb-2">
                          <div className="btn-group float-end ">
                            <input type="search" placeholder="Search..." value={searchQuery} className="form-control form-control-sm" aria-controls="example" onChange={handleChange} />
                            &nbsp;&nbsp;
                          </div>
                        </div>
                      </div>
                      <Table id="datatable" style={{ width: '100%' }} className="nowrap w-100 dt-responsive table-responsive table-bordered">
                        <thead style={{ backgroundColor: '#ededed' }}>
                          <tr>
                            <th>
                              <div className="form-check ">
                                <input className="form-check-input"
                                  type="checkbox" value=""
                                  id="flexCheckDefault"
                                  // checked={Object.keys(selectedCheckboxes).length === unassignedDids.length}
                                  checked={Object.keys(selectedCheckboxes)
                                    ?.length > 0
                                    ? Object.keys(selectedCheckboxes)
                                      ?.length === unassignedDids.length
                                    : false}

                                  onChange={handleCheckAllChange} />
                              </div>
                            </th>
                            <th>DID Number</th>
                            <th>DID Type</th>
                            <th>Calling Provider </th>
                            <th>SMS Provider </th>

                          </tr>
                        </thead>
                        {isLoading ? (
                          <div className='loader-overlay text-white'>
                            <Loader />
                          </div>

                        ) : (
                          <tbody>
                            {unassignedDids.length > 0 ? (
                              unassignedDids.map((did, index) => (
                                <tr key={index} className={index % 2 === 0 ? 'even' : 'odd'}  >
                                  <th scope="row" >
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id={`flexCheckDefault${did.did_id}`}
                                        checked={selectedCheckboxes[did.did_id]?.isChecked || false}
                                        onChange={(e) => handleCheckboxChange(e, did.did_id, did.did_number, did.calling_provider, did.sms_provider, did.line_type, did.country)}
                                      />
                                    </div>
                                  </th>

                                  <td>{did.did_number}</td>

                                  <td>
                                    {
                                      did.line_type === 'local' ? 'Local DID' :
                                        did.line_type === 'toll_free' ? 'Toll Free DID' :
                                          did.line_type === 'sms' ? 'SMS' :
                                            did.line_type === 'international' ? 'International' :
                                              did.line_type === 'vfax' ? 'VFax' : '-'
                                    }
                                  </td>
                                  <td> {
                                    did.calling_provider === "V" ? 'Vitelity' :
                                      did.calling_provider === "VI" ? 'VoIP Innovations' :
                                        did.calling_provider === "I" ? 'Inteliquent' :
                                          did.calling_provider === "B" ? 'Bandwidth' : '-'}
                                  </td>
                                  <td> {
                                    did.sms_provider === "V" ? 'Vitelity' :
                                      did.sms_provider === "VI" ? 'VoIP Innovations' :
                                        did.sms_provider === "I" ? 'Inteliquent' :
                                          did.sms_provider === "B" ? 'Bandwidth' : '-'}
                                  </td>


                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="8" className="text-center">No data available</td>
                              </tr>
                            )}

                          </tbody>
                        )}
                      </Table>
                      <hr />
                      <Pagination {...pgntn_props} />
                    </div>
                  </div>
                </div> {/* end col -->*/}
              </div> {/* end row -->*/}

            </div> {/* container-fluid S*/}
          </div>{/* End Page-content S*/}

          <Footer />
        </div>
        {/* end main content*/}

      </div >
      {/* END layout-wrapper */}

      {/* Right bar overlay*/}
      <div className="rightbar-overlay"></div>
    </>
  )
}
