import Header from "../../common/Header";
import Sidebar from "../../common/Sidebar";
import Footer from "../../common/Footer";

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
//import { useNavigate } from "react-router-dom";
import { triggerAlert, pageReload, getToken, handleTableRowClick, formattedDateTime, simpleAlert, get_user_menu_permission } from '../../utils';
import Loader from '../../common/Loader';
import AutoUserSearch from '../AutoUserSearch'
//import AutoCompanySearch from '../AutoCompanySearch'
import Table from 'react-bootstrap/Table';
import Swal from "sweetalert2";
import AutoSearchSelect from "../CommonSelectDynamic";
import { menuIdData } from "../../utils/constants";

function CustomerService() {

	const api_url = process.env.REACT_APP_API_BASE_URL;
	const customer_url = process.env.REACT_APP_API_CUSTOMER_URL;
	const show50Entries = process.env.REACT_APP_API_SHOW_ENTRIES;
	const token = getToken();

	const [isLoading, setIsLoading] = useState([]);
	const [isLoadingList, setIsLoadingList] = useState(false);
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedRowId, setSelectedRowId] = useState(null);

	const [customerId, setcustomerId] = useState(null);
	const [customerName, setCustomerName] = useState(null);
	const [services, setServices] = useState([]);
	const [serviceAdd, setServiceAdd] = useState([]);
	const [userservices, setUserServices] = useState([]);
	const [isSearchBtnDisabled, setIsSearchBtnDisabled] = useState(true);
	const [error, setError] = useState(false);

	//pagination
	const [currentPage, setCurrentPage] = useState(0);
	const [pageSlNo, setPageSlNo] = useState(0);
	const [perPageLimit, setPerPageLimit] = useState(show50Entries);
	const [pageCount, setPageCount] = useState(0);
	const [pageLimitSelected, setPageLimitSelected] = useState(50);

	const [sectionVisible, setSectionVisible] = useState({
		sectionA: true,
		sectionB: false,
		sectionC: false,
	});

	const { control, register, handleSubmit, formState: { errors }, setValue, reset, watch } = useForm();
	const { control: control1, register: register1, handleSubmit: handleSubmit1, formState: { errors: errors1 }, setValue: setValue1, reset: reset1 } = useForm();

	const [expandedRows, setExpandedRows] = useState(Array(userservices.length).fill(false));

	//Menu permission
	const [editPermission, setEditPermission] = useState(false);

	const handleToggle = (index) => {
		const newExpandedRows = [...expandedRows];
		newExpandedRows[index] = !newExpandedRows[index];
		setExpandedRows(newExpandedRows);
	};

	const toggleSection = (section) => {
		setSectionVisible((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	//get services based on user id
	const getUserWiseServices = async (id) => {
		setIsLoadingList(true);
		// try {
		// 	const response = await axios.get(api_url + `customer/getuserservice?user_id=` + id, token);
		// 	const response_data = response.data;
		// 	if (response_data.error_code == 200) {
		// 		alert('hi')
		// 		const itemsArray = response_data.results
		// 		console.log('response_itemsArray', itemsArray);

		// 		setUserServices(itemsArray);
		// 		setIsLoadingList(false);
		// 	} else if (response.status == 204) {
		// 		setUserServices('');
		// 		setIsLoadingList(false);
		// 	} else {
		// 		triggerAlert('error', 'Oops...', "Couldn't get the customer details");
		// 		setIsLoadingList(false);
		// 	}
		// } catch (error) {
		// 	setIsLoadingList(false);
		// 	triggerAlert("error", "Oops...", "Something went wrong..");
		// }

		//sahitis code
		if (id) {
			try {

				const response = await axios.get(api_url + `customer/get_customer_rate_card?id=${id}`, token);

				const response_data = response.data;
				// console.log('response_dataCards', response_data);

				if (response_data.error_code === 200) {
					const itemsArray = response_data.results;
					setUserServices(itemsArray);
					setIsLoadingList(false);
				} else if (response.status === 204) {
					setUserServices([]);
					setIsLoadingList(false);
				} else {
					triggerAlert('error', 'Oops...', "Couldn't get the card details");
				}
			} catch (error) {
				setIsLoadingList(false);
				triggerAlert("error", "Oops...", "Something went wrong..");
			}
		}
	};

	//assign service
	const assignServiceSubmit = async (data) => {
		//console.log(data)
		// const parts = data?.service_name?.split(',');
		// data.service_name = parts[0];
		data.service = serviceAdd;
		data.user = customerId;
		if (customerId) {
			try {
				const response = await axios.post(api_url + 'customer/createuserservice', data, token);
				const response_data = response.data;
				if (response_data.error_code == "200") {
					triggerAlert('success', 'success', 'Service Assigned successfully');
					pageReload();
				} else {
					setIsLoading(false);
					triggerAlert('error', 'Oops...', 'Something went wrong..');
				}
			} catch (error) {
				//console.log(error);
				setIsLoading(false);
				if (error?.response?.status == "400") {
					triggerAlert('error', 'Oops...', error.response.data.message);
				} else {
					triggerAlert('error', 'Oops...', 'Something went wrong..');
				}
			}
		}
		else {
			simpleAlert("Select the user!!");
		}
	};

	//edit service onload page data
	const editService = () => {
		//console.log(selectedRow);
		if (selectedRowId) {
			setValue1('user', selectedRow.user);
			// setValue1('user_name', selectedRow.user_name);
			setValue1('user_name', customerName);
			setValue1('service_name', selectedRow.service_name);
			setValue1('service_lines', selectedRow.service_lines);
			if (selectedRow.user_id !== 0) {
				setValue1('service_charge', selectedRow.user_price);
				setValue1('service_description', selectedRow.user_service_description);
				setValue1('setup_fee', selectedRow.user_setup_fee);

			} else {
				setValue1('service_charge', selectedRow.service_charge);
				setValue1('service_description', selectedRow.service_description);
				setValue1('setup_fee', selectedRow.setup_fee);
			}


			toggleSection('sectionA');
			toggleSection('sectionC');
		} else {
			triggerAlert('error', 'Oops...', 'Please select a row');
		}
	};

	//update service
	const updateService = async (data) => {
		try {
			var id = selectedRowId;
			// data.service = selectedRowId;
			data.user = customerId
			const response = await axios.put(api_url + 'customer/update_user_service/' + id + '/update', data, token);
			//console.log(response);
			if (response.status == "200") {
				triggerAlert('success', 'success', 'Service Updated successfully');
				pageReload();
			} else {
				triggerAlert('error', 'Oops...', 'Something went wrong..');
			}
		} catch (error) {
			if (error?.response?.status == "400") {
				triggerAlert('error', 'Oops...', error.response.data.message);
			} else {
				triggerAlert('error', 'Oops...', 'Something went wrong..');
			}
		}
	}

	//search on listing page
	const handleUserSelect = (userData) => {
		//console.log(userData);
		setcustomerId(userData.user_id);
		setCustomerName(userData.user);
		setPageCount(0);
		setCurrentPage(0);
		setPerPageLimit(show50Entries);
		setPageLimitSelected(10);
		setIsSearchBtnDisabled(false);
		getUserWiseServices(userData.user_id);
	};

	//search in assign form
	const handleUserSelect1 = (userData) => {
		//console.log('jothi', userData);
		if (userData.user_id == null) {
			setError(true);
		} else {
			setError(false);
			setcustomerId(userData.user_id);
			setPageCount(0);
			setCurrentPage(0);
			setPerPageLimit(show50Entries);
			setPageLimitSelected(10);
		}
	};

	const handleServiceSelect = (selected) => {
		setValue('service_name', selected ? selected.label : null);
		setValue('service_charge', selected ? selected.service_charge : null)
		setServiceAdd(selected ? selected.value : '');
	}

	//default
	const getServices = async () => {
		try {
			const response = await axios.get(api_url + `customer/get_all_services`, token);

			const response_data = response.data;

			if (response_data.error_code === 200) {
				const itemsArray = response_data.results
				//console.log('response_itemsArray', itemsArray);
				setServices(itemsArray);
			} else {
				triggerAlert('error', 'Oops...', "Couldn't get the Services details");
			}
		} catch (error) {
			triggerAlert("error", "Oops...", "Something went wrong..");
		}
	};

	const deleteCustService = () => {
		//alert(selectedRowId);
		if (selectedRowId) {
			////////////////////////////////////////
			//console.log(selectedRowId);
			Swal.fire({
				icon: 'warning',
				title: 'Do you want to delete data?',
				showCancelButton: true,
				confirmButtonText: 'Delete',
			}).then(async (result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					var id = selectedRowId;
					var data = { "user_service_id": id };
					try {
						const response = await axios.post(api_url + 'customer/delete_userservice', data, token);
						const response_data = response.data;
						//console.log(message);
						if (response_data.error_code == "200") {
							triggerAlert('success', 'success', response_data.message);
						} else {
							triggerAlert('error', 'Oops...', 'Something went wrong..');
						}
						pageReload();
					} catch (error) {
						triggerAlert('error', 'Oops...', 'Something went wrong..');
					}
				}
			})

		} else {
			triggerAlert('error', 'Oops...', 'Please select a row');
		}
	};

	useEffect(() => {
		getServices();
	}, [perPageLimit]);

	useEffect(() => {
		const menu_id = menuIdData?.customer_service;
		// setAddPermission(get_user_menu_permission(menu_id, 'add'));
		setEditPermission(get_user_menu_permission(menu_id, 'edit'));
		// setDeletePermission(get_user_menu_permission(menu_id, 'delete'));
		// setExportPermission(get_user_menu_permission(menu_id, 'export'));
	}, []);

	return (
		<>
			<div id="layout-wrapper">
				<Header />
				<Sidebar />
				{/* ============================================================== */}
				{/* Start right Content here */}
				{/* ============================================================== */}
				<div className="main-content" style={{ minHeight: '100vh' }}>
					<div className="page-content">

						{/* Manage Service  */}
						<div hidden={!sectionVisible.sectionA} className="container-fluid">
							{/* start page title */}
							<div className="row">
								<div className="col-12">
									<div className="page-title-box d-sm-flex align-items-center justify-content-between">
										<h4 className="mb-sm-0 font-size-18">Customer Service</h4>
										<div className="page-title-right">
											<ol className="breadcrumb m-0">
												<li className="breadcrumb-item "><a href="#">Customers</a></li>
												<li className="breadcrumb-item active">Customer Service</li>
											</ol>
										</div>
									</div>
								</div>
							</div>
							{/* end page title */}

							<div className="row mb-4	">
								<div className="col-12">
									<div className="card">
										<div className="card-header">
											<div className="float-start col-md-4">
												<label>Select Customer</label>
												{/* <AutoCompanySearch onSelect={handleUserSelect} status={"active"}/> */}
												<AutoUserSearch onSelect={handleUserSelect} status={"active"} />
											</div>
											{editPermission &&
												<div class="btn-group   mb-3 float-end">
													<button type="button" class="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														Action <i class="mdi mdi-chevron-down"></i>
													</button>
													<div class="dropdown-menu dropdown-menu-end py-2">
														<a class="dropdown-item" onClick={() => { toggleSection('sectionA'); toggleSection('sectionB') }}><i class="bx bx-wrench"></i> Assign Service</a>
														<a class="dropdown-item" onClick={editService}><i class="far fa-edit font-size-14"></i> Edit</a>
														{/* <a class="dropdown-item" onClick={deleteCustService}><i class="bx bx-trash"></i> Delete</a> */}
													</div>
												</div>
											}
										</div>
										<div className="card-body">
											<Table id="datatable" className="nowrap w-100 dt-responsive table-responsive table-bordered">
												<thead style={{ backgroundColor: '#ededed' }}>
													<tr>
														{/* <th>#</th> */}
														{/* <th>Username</th> */}
														<th style={{ width: '60%' }}>Service Name</th>
														<th style={{ width: '10%' }}>Quantity</th>
														<th style={{ width: '10%' }}>Setup Fee</th>
														<th style={{ width: '10%' }}>Rate</th>
														<th style={{ width: '10%' }}>Unit Type</th>
														{/* <th>Created Date</th> */}
													</tr>
												</thead>
												{/* <tbody>
													{userservices.length > 0 ? (
														userservices.map((item, index) => (
															<tr onClick={() => handleTableRowClick(item, selectedRow, setSelectedRow, selectedRowId, setSelectedRowId, item.user_service_id)} className={selectedRowId === item.user_service_id ? 'row_selected' : ''} >
																<td>{pageSlNo + index + 1}</td>
																<td>{item.user_name}</td>
																<td>{(item.service_type === 'International' && item.service_name !== 'International') ? item.service_type + ' - ' : null}{item.service_name}</td>
																<td>{item.service_lines}</td>
																<td>$ {item.service_charge}</td>
																<td>{formattedDateTime(item.created_date)}</td>
															</tr>
														))
													) : (
														<tr>
															<td colSpan="6" className="text-center">No data available</td>
														</tr>
													)}
</tbody> */}
												{isLoadingList ?
													<Loader /> : (
														<tbody>
															{userservices?.length > 0 ? (
																userservices?.map((serviceTypeData, index) => (
																	Object.entries(serviceTypeData).map(([serviceType, services]) => (
																		<React.Fragment key={index}>
																			<tr className='acc_row' data-bs-toggle="collapse" data-bs-target={`#accordion-${index}`} aria-expanded="false" aria-controls={`accordion-${index}`}
																				onClick={() => handleToggle(index)}
																				style={{ backgroundColor: '#fff5f5' }}>

																				<td colSpan={5}>
																					<div className='d-flex align-items-center'>
																						<span className="toggle-icon" >
																							{expandedRows[index] ? (
																								<i className="mdi mdi-chevron-down font-size-20"></i>
																							) : (
																								<i className="mdi mdi-chevron-right font-size-20"></i>
																							)}
																						</span>
																						<span>{serviceType}</span>
																					</div>

																				</td>
																			</tr>
																			<tr>
																				<td colSpan="5" className="p-0">
																					<div id={`accordion-${index}`} className={`accordion-collapse collapse${expandedRows[index] ? ' show' : ''}`} data-bs-parent="#accordionExample">
																						<div className="accordion-body">
																							<div className="table-responsive">
																								<table className="table table-bordered m-0">
																									<tbody>
																										{(Array.isArray(services) ? services : []).map((service, subIndex) => (
																											<tr key={subIndex} onClick={() => handleTableRowClick(service, selectedRow, setSelectedRow, selectedRowId, setSelectedRowId, service.list_id)} className={selectedRowId === service.list_id ? 'row_selected' : ''}>
																												<td
																													// colSpan={1}
																													style={{ width: '60%' }}
																												>{service.service_name}</td>
																												<td style={{ width: '10%' }}>{service.service_lines}</td>
																												<td style={{ width: '10%' }}>$ {service.user_id !== 0 ? service.user_setup_fee : service.setup_fee}</td>
																												<td style={{ width: '10%' }}>$ {service.user_id !== 0 ? service.user_price : service.service_charge}</td>
																												<td style={{ width: '10%' }}>{service.user_id !== 0 ? service.user_service_description : service.service_description}</td>
																												{/* <td>{formattedDateTime(service.created_date)}</td> */}
																											</tr>
																										))}
																									</tbody>
																								</table>
																							</div>
																						</div>
																					</div>
																				</td>
																			</tr>
																		</React.Fragment>
																	))
																))
															) : (
																<tr>
																	<td colSpan="6" className="text-center">{customerId === null ? 'Please select a user to view data.' : 'No data available'}</td>
																</tr>
															)}
														</tbody>
													)}
											</Table>
										</div>
									</div>
								</div> {/* end col */}
							</div> {/* end row */}
						</div> {/* container-fluid */}
						{/* Manage Service  */}

						{/* Assign Service */}
						<div hidden={!sectionVisible.sectionB} className="container-fluid">
							{/* start page title */}
							<div className="row">
								<div className="col-12">
									<div className="page-title-box d-sm-flex align-items-center justify-content-between">
										<h4 className="mb-sm-0 font-size-18">Assign Service</h4>
										<div className="page-title-right">
											<ol className="breadcrumb m-0">
												<li className="breadcrumb-item"><a href="#">Customers</a></li>
												<li className="breadcrumb-item"><a href="#">Customer Service</a></li>
												<li className="breadcrumb-item active">Assign Service</li>
											</ol>
										</div>
									</div>
									<div className="float-end">
										<button class="btn btn-warning w-md" type="button" onClick={() => { toggleSection('sectionA'); toggleSection('sectionB') }}>Back</button>
									</div>
								</div>
							</div>
							{/* end page title */}
							<div className="row mt-4">
								<div className="col-lg-12">
									<div className="card">
										<div className="card-body p-4">
											<div className="row">
												<div className="col-lg-12">
													<div>
														<form id="creditCardForm" key={2} novalidate="novalidate" enctype="multipart/form-data" onSubmit={handleSubmit(assignServiceSubmit)}>
															<div className="row">
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-servicename-input">Username <span style={{ color: 'red' }}>*</span></label>
																		<Controller control={control} name="user_id" id="user_id" rules={{ required: "User is required", }} render={({ field }) => (
																			<AutoUserSearch onSelect={(user) => {
																				//console.log('gfgfg', user);
																				setValue('user_id', user ? user.user_id : null);
																				setcustomerId(user ? user.user_id : null);

																			}} />

																		)}
																		/>
																		{errors.user_id && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.user_id.message}</div>}
																	</div>
																</div>

																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-servicename-input">Service Name <span style={{ color: 'red' }}>*</span></label>
																		{/* <Controller
																			control={control}
																			name="service_name"
																			rules={{
																				required: "Service Name is required",
																			}}
																			render={({ field }) => (
																				<select class="form-select" name="service_name" {...register('service_name')} onChange={(e) => {
																					field.onChange(e);
																					console.log(e.target.value);
																				}} value={field.value}>
																					<option value="">Select</option>
																					{services.map((item, index) => (
																						<option value={item.service_name + ',' + item.list_id}>{item.service_name}</option>
																					))}
																				</select>
																			)}
																		/> */}
																		<Controller
																			name="service_name"
																			{...register('service_name', { required: "Service Name is required" })}
																			control={control}
																			render={({ field }) => (
																				<AutoSearchSelect
																					{...field}
																					apiUrl={api_url + `customer/get_all_services`}
																					// onSelect={(value) => field.onChange(value)}
																					onSelect={handleServiceSelect}
																					placeholder="Select Service Name"
																					mapOption={result => ({
																						value: result.list_id,
																						label: result.service_name,
																						service_charge: result.service_charge,
																						// additionalField: result.additionalField,
																						// Add additional fields as needed
																					})}
																				/>
																			)}
																		/>
																		{errors.service_name && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.service_name.message}</div>}
																	</div>

																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-Rate-input">Service Charge ( MRC ) <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="service_charge" id="formrow-servicename-input" maxLength={10} class="form-control" placeholder="Enter your Rate" {...register('service_charge', {
																			required: 'Service Cost is required',
																			// pattern: {
																			// 	value: /^(\d*\.{0,1}\d{0,2}$)/,
																			// 	message: 'Service Charge should have only numbers',
																			// },
																			pattern: {
																				value: /^\d+(\.\d{1,4})?$/, // Adjust the regex pattern to allow up to 4 decimal points
																				message: 'Service Charge ( MRC ) should have only numbers and up to 4 decimal points.',
																			},
																			maxLength: {
																				value: 10,
																				message: 'Max Length Exceeded',
																			},
																		})} allow-decimal-numbers />
																		{errors.service_charge && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.service_charge.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-PhoneNumber-input">Quantity <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="service_lines" id="formrow-servicename-input" maxLength={10} class="form-control" placeholder="Enter your Quantity" {...register('service_lines', {
																			required: 'Quantity is required',
																			pattern: {
																				value: /^[0-9]+$/,
																				message: 'Quantity should have only numbers',
																			},
																			maxLength: {
																				value: 10,
																				message: 'Max Length Exceeded',
																			},
																			minLength: {
																				value: 1,
																				message: 'Min Length is not Reached',
																			},
																		})} />
																		{errors.service_lines && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.service_lines.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-Rate-input">Set Up Fee <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="setup_fee" id="formrow-servicename-input" maxLength={10} class="form-control" placeholder="Enter Set Up Fee" {...register('setup_fee', {
																			required: 'Set Up Fee is required',
																			pattern: {
																				value: /^\d+(\.\d{1,4})?$/, // Adjust the regex pattern to allow up to 4 decimal points
																				message: 'Set Up Fee should have only numbers and up to 4 decimal points.',
																			},
																			maxLength: {
																				value: 10,
																				message: 'Max Length Exceeded',
																			},
																		})} allow-decimal-numbers />
																		{errors.setup_fee && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.setup_fee.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="service_description">Unit Type <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="service_description" id="formrow-servicename-input" maxLength={50} class="form-control" placeholder="Enter Unit Type" {...register('service_description', {
																			required: 'Set Up Fee is required',
																		})} />
																		{errors.service_description && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.service_description.message}</div>}
																	</div>
																</div>
															</div>
															<div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
																<button class="btn btn-warning w-md" type="button" onClick={() => { toggleSection('sectionA'); toggleSection('sectionB') }}>Cancel</button>
																<button class="btn btn-primary w-md me-md-2" type="submit">Assign</button>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div> {/* container-fluid */}
						{/* Assign Service */}

						{/* Edit Service */}
						<div hidden={!sectionVisible.sectionC} className="container-fluid">
							{/* start page title */}
							<div className="row">
								<div className="col-12">
									<div className="page-title-box d-sm-flex align-items-center justify-content-between">
										<h4 className="mb-sm-0 font-size-18">Edit Service</h4>
										<div className="page-title-right">
											<ol className="breadcrumb m-0">
												<li className="breadcrumb-item"><a href="#">Customers</a></li>
												<li className="breadcrumb-item"><a href="#">Customer Service</a></li>
												<li className="breadcrumb-item active">Edit Service</li>
											</ol>
										</div>
									</div>
									<div className="float-end">
										<button class="btn btn-warning w-md" type="button" onClick={() => { toggleSection('sectionA'); toggleSection('sectionC') }}>Back</button>
									</div>
								</div>
							</div>
							{/* end page title */}
							<div className="row mt-4">
								<div className="col-lg-12">
									<div className="card">
										<div className="card-body p-4">
											<div className="row">
												<div className="col-lg-12">
													<div>
														<form id="creditCardForm" key={2} novalidate="novalidate" enctype="multipart/form-data" onSubmit={handleSubmit1(updateService)}>
															<div className="row">
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-servicename-input">Username <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="user_name" id="formrow-Username-input" disabled={true} class="form-control" {...register1('user_name')} placeholder="Enter User Name" />
																		{/* <Controller
																	control={control1}
																	name="user"
																	rules={{
																		required: "Username is required",
																	}}
																	disabled={true}
																	render={({ field }) => (
																	<select class="form-select" name="user" {...register1('user')} onChange={(e) => {
																		field.onChange(e);
																		console.log(e.target.value);
																		}}  value={field.value}>
																		{userservices.map((item,index) => (
																			<option value={item.user} selected>{item.user_name}</option>
																		))}
																	</select>                    
																	)}
																/>
																{errors1.user && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors1.user.message}</div>} */}
																	</div>
																</div>

																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-servicename-input">Service Name <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="service_name" id="formrow-Username-input" disabled={true} class="form-control" {...register1('service_name')} placeholder="Enter User Name" />
																		{/* <Controller
																	control={control}
																	name="service_name"
																	rules={{
																		required: "Service Name is required",
																	}}
																	render={({ field }) => (
																	<select class="form-select" name="service_name" {...register1('service_name')} onChange={(e) => {
																		field.onChange(e);
																		console.log(e.target.value);
																		}}  value={field.value}>
																		<option value="">Select</option>
																		{services.map((item,index) => (
																			<option value={item.service_name}>{item.service_name}</option>
																		))}
																	</select>
																	)}
																/>
																{errors1.service_name && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors1.service_name.message}</div>} */}
																	</div>

																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-Rate-input">Service Charge ( MRC ) <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="service_charge" id="formrow-servicename-input" maxLength={10} class="form-control" placeholder="Enter Your Service Charge" {...register1('service_charge', {
																			required: 'Service Cost is required',
																			pattern: {
																				value: /^\d+(\.\d{1,4})?$/, // Adjust the regex pattern to allow up to 4 decimal points
																				message: 'Service Charge ( MRC ) should have only numbers and up to 4 decimal points.',
																			},
																			maxLength: {
																				value: 10,
																				message: 'Max Length Exceeded',
																			},
																		})} allow-decimal-numbers />
																		{errors1.service_charge && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors1.service_charge.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-PhoneNumber-input">Quantity <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="service_lines" id="formrow-servicename-input" maxLength={10} class="form-control" placeholder="Enter your Quantity" {...register1('service_lines', {
																			required: 'Quantity is required',
																			pattern: {
																				value: /^[0-9]+$/,
																				message: 'Quantity should have only numbers',
																			},
																			maxLength: {
																				value: 10,
																				message: 'Max Length Exceeded',
																			},
																			minLength: {
																				value: 1,
																				message: 'Min Length is not Reached',
																			},
																		})} />
																		{errors1.service_lines && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors1.service_lines.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="formrow-Rate-input">Set Up Fee <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="setup_fee" id="formrow-servicename-input" maxLength={10} class="form-control" placeholder="Enter Set Up Fee" {...register1('setup_fee', {
																			required: 'Set Up Fee is required',
																			pattern: {
																				value: /^\d+(\.\d{1,4})?$/, // Adjust the regex pattern to allow up to 4 decimal points
																				message: 'Set Up Fee should have only numbers and up to 4 decimal points.',
																			},
																			maxLength: {
																				value: 10,
																				message: 'Max Length Exceeded',
																			},
																		})} allow-decimal-numbers />
																		{errors1.setup_fee && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors1.setup_fee.message}</div>}
																	</div>
																</div>
																<div className="col-md-4">
																	<div className="mb-3">
																		<label className="form-label" for="service_description">Unit Type <span style={{ color: 'red' }}>*</span></label>
																		<input type="text" name="service_description" id="formrow-servicename-input" maxLength={50} class="form-control" placeholder="Enter Unit Type" {...register1('service_description', {
																			required: 'Set Up Fee is required',
																		})} />
																		{errors1.service_description && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors1.service_description.message}</div>}
																	</div>
																</div>
															</div>
															<div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
																<button class="btn btn-warning w-md" type="button" onClick={() => { toggleSection('sectionA'); toggleSection('sectionC') }}>Cancel</button>
																<button class="btn btn-primary w-md me-md-2" type="submit">Update</button>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div> {/* container-fluid */}
						{/* Edit Service */}


					</div>
					{/* End Page-content */}
					<Footer />
				</div >
				{/* end main content*/}
			</div >
			{/* END layout-wrapper */}
			{/* Right bar overlay*/}
			<div className="rightbar-overlay"></div>
		</>
	);
}

export default CustomerService;
