import React from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { getToken, simpleAlert, triggerAlert } from '../utils';
import { useState } from 'react';
import { useEffect } from 'react';




const fetchData = async () => {
  const api_url = process.env.REACT_APP_API_BASE_URL;
  const token = getToken();
  try {
    const response = await axios.get(api_url + `customer/gettotalservices?service_status=1&page_number=1&page_size=100`, token);
    //console.log(response.data.results.data);
    const response_data = response.data;

    if (response_data.error_code === 200) {
        const services = response_data.results.data;
        //console.log(services);
        return services.map(service => ({
            value: service.service_name,
            // user: user.user_name,
            label: service.service_name,
            //m4_account_id: user.m4_account_id,
        }));
    } else if (response.status === 204) {
      simpleAlert('No Data Available!');
    } else {
      triggerAlert('error', 'Oops...', "Couldn't get the service details");
    }
  } catch (error) {
    console.error('Error loading user options:', error);
    return [];
  }
};

const AutoServiceSearch = ({ onSelect }) => {
  const [serviceOptions, setServiceOptions] = useState([]);

  useEffect(() => {
    // Fetch initial data when the component mounts
    fetchData().then(options => setServiceOptions(options));
  }, []); // Empty dependency array ensures it only runs once on mount

  const loadOptions = (inputValue, callback) => {
    // Use the locally stored options if available
    const filteredServices = serviceOptions.filter(service =>
      service.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    callback(filteredServices);
  };

  const handleSelect = (selectedOption) => {
    if (onSelect) {
      onSelect({
        service_name: selectedOption.value,
        //user: selectedOption.user,
        //user_m4_account_id: selectedOption.m4_account_id,
      });
    }
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions={serviceOptions}
      onChange={handleSelect}
      placeholder='Search Service'
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          border: state.isSelected ? '1px solid #e9e9ef' : '1px solid #e9e9ef',
          boxShadow: state.isHovered ? '1px solid #e9e9ef' : '1px solid #e9e9ef',
          background: '#f8f9fa',
          color: '#000'
        }),
      }}
    />
  );
};

export default AutoServiceSearch;
