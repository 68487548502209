import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "../../../common/Header";
import Sidebar from "../../../common/Sidebar";
import Footer from "../../../common/Footer";
import PageHeader from "../../PageHeader";
import Loader from '../../../common/Loader';
import { useForm, Controller } from 'react-hook-form';
import { getToken, triggerAlert, formattedDateTime, removePTags, handleTableRowClick, pageReload, get_user_menu_permission } from '../../../utils';
import Pagination from "../../PaginationComponent";
import { Link, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AutocompleteSearch from "../../AutocompleteSearch";
import AutoUserSearch from '../../AutoUserSearch';
import { menuIdData } from '../../../utils/constants';
import Editor from "../../Editor";

const RepliesList = () => {

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const token = getToken();
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [custNotes, setCustNoteList] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [showEditForm, setShowEditForm] = useState(false);
    //const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const editorConfiguration = {
        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
    };
    const { control, register, handleSubmit, formState: { errors }, setValue, reset, clearErrors } = useForm();
    const [editorInstance, setEditorInstance] = useState(null);

    const mainHeading = 'Customer Notes';
    const heading = `Support / Customer Notes`;
    const pageSize = process.env.REACT_APP_API_SHOW_ENTRIES;

    // Menu Permissions
    const [addPermission, setAddPermission] = useState(false);
    const [editPermission, setEditPermission] = useState(false);
    const [deletePermission, setDeletePermission] = useState(false);
    //   const [exportPermission, setExportPermission] = useState(false);

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        if (data) {
            clearErrors('editorContent');
        }
        setValue('editorContent', data);
    };

    const handleOnFocus = () => {
        if (!dataLoaded) {
            searchCustomers();
            setDataLoaded(true);
        }
    };


    const handleOnClear = () => {
        setValue('user', '');
    };

    const handleOnSelect1 = (item) => {
        setValue('user', item.id);
        if (item.id) {
            clearErrors('user');
        }
    };

    let autoCompleteProps = {
        items: items,
        fuseOptions: { keys: ["user_name"] },
        placeholder: 'Search user...',
        handleOnSelect: handleOnSelect1,
        resultString: "user_name",
        handleOnFocus: handleOnFocus,
        handleOnClear: handleOnClear,
        inputSearchString: selectedRow ? selectedRow.customer_name : ""
    };

    const searchCustomers = async () => {
        try {
            const response = await axios.get(apiBaseUrl + `dashboard/get_all_customers`, token);
            const response_data = response.data;
            if (response_data.error_code === 200) {
                const itemsArray = response_data.results
                //console.log('response_itemsArray', itemsArray);
                setItems(itemsArray);
            }
            else if (response.status === 204) {
                setItems([]);
            } else {
                triggerAlert('error', 'Oops...', "Couldn't get the user data");
            }
        } catch (error) {
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    };

    const fetchCustNotesList = async () => {
        try {
            setIsLoading(true);
            const ticketsListData = await axios.get(`${apiBaseUrl}/customer_notes/list_data?page_size=${pageSize}&page_number=${pageNum}`, token);
            const responseStatus = ticketsListData.status;
            if (responseStatus == 200) {
                const itemsArray = ticketsListData.data.results.data;
                setCustNoteList(itemsArray);
                const total_pages = ticketsListData.data.results.pagination.total_pages;
                setTotalPageCount(total_pages);
                setIsLoading(false);
            } else {
                throw new Error("Something went wrong..");
            }
        } catch (err) {
            triggerAlert("error", err.message);
        }
    }

    const deleteReplies = () => {
        if (selectedRowId) {
            //console.log(selectedRowId);
            Swal.fire({
                icon: 'warning',
                title: 'Do you want to delete data?',
                showCancelButton: true,
                confirmButtonText: 'Delete',
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    var id = selectedRowId;
                    try {
                        const response = await axios.get(apiBaseUrl + `customer_notes/delete_data?notes_id=${id}`, token);
                        const response_data = response.data;
                        if (response_data.error_code == "200") {
                            triggerAlert('success', 'success', response_data.message);
                            pageReload();
                        } else {
                            triggerAlert('error', 'Oops...', 'Something went wrong..');
                        }
                    } catch (error) {
                        triggerAlert('error', 'Oops...', 'Something went wrong..');
                    }
                }
            })
        } else {
            triggerAlert('error', 'Oops...', 'Please select a row');
        }
    };

    const handlePageClick = (selected) => {
        const selectedPage = selected.selected;
        setPageNum(selectedPage + 1);
    };

    let props = {
        pageCount: totalPageCount,
        handlePageClick: handlePageClick,
        selectedPage: pageNum - 1
    }

    const handleEditForm = (e) => {
        if (selectedRow) {
            setShowEditForm(true);
            setValue("user", selectedRow.user_id);
            setValue("editorContent", selectedRow.notes);
        } else {
            triggerAlert('error', 'Oops...', 'Please select a row');
        }
    }




    const handleEditorReady = (editor) => {
        editor.setData(selectedRow?.notes || '');
    };


    const formSubmit = async (data) => {
        try {
            setIsLoading(true);
            const params = {
                'user_id': data.user,
                'notes': data.editorContent
            };
            const response = await axios.put(apiBaseUrl + `customer_notes/update_data/${selectedRow.notes_id}/update`, params, token);
            if (response.status === 200) {
                triggerAlert('success', 'Success', `Customer Note Updated Successfully!!!`);
                //fetchCustNotesList();
                setIsLoading(false);
                setShowEditForm(false);
                pageReload();
            } else {
                setIsLoading(false);
                throw new Error('Unable to Updated Customer Note Successfully!!!');
            }
        } catch (err) {
            setIsLoading(false);
            triggerAlert('error', 'Oops...', err.message);
        }
    };

    useEffect(() => {
        fetchCustNotesList();
        const menu_id = menuIdData?.customer_notes;
        setAddPermission(get_user_menu_permission(menu_id, 'add'));
        setEditPermission(get_user_menu_permission(menu_id, 'edit'));
        setDeletePermission(get_user_menu_permission(menu_id, 'delete'));
        // setExportPermission(get_user_menu_permission(menu_id, 'export'));
    }, []);




    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />
                <div className="main-content">
                    {isLoading ? <div className='loader-overlay text-white'>
                        <Loader />
                    </div> : ""}
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}
                            <div className="row mt-5">
                                <div className="col-12">
                                    {
                                        !showEditForm ? (
                                            <>
                                                <div className="card">
                                                    {(addPermission || editPermission || deletePermission) &&
                                                        <div className="card-header">
                                                            <div className="row">
                                                                <div className="col-sm-12 col-md-11">
                                                                </div>

                                                                <div class="btn-group   mb-3 float-end col-md-1">
                                                                    <button type="button" class="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        Action <i class="mdi mdi-chevron-down"></i>
                                                                    </button>
                                                                    <div class="dropdown-menu dropdown-menu-end py-2">
                                                                        {addPermission && <Link to="/support/customer_notes/create_customer_notes" class="dropdown-item"><i class="bx bx-user"></i> Create </Link>}
                                                                        {editPermission && <Link to="#" onClick={(e) => handleEditForm(e)} class="dropdown-item"><i class="far fa-edit font-size-14"></i> Edit</Link>}
                                                                        {deletePermission && <Link to="#" onClick={deleteReplies} class="dropdown-item"><i class="bx bx-trash"></i> Delete</Link>}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="card-body">
                                                        <table className="table table-bordered dt-responsive  table-responsive  nowrap w-100">
                                                            <thead style={{ backgroundColor: '#ededed' }}>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Notes</th>
                                                                    <th>User</th>
                                                                    <th>Updated By</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {custNotes.length === 0 ? (
                                                                    <tr>
                                                                        <td colSpan="12" className="text-center">No data available</td>
                                                                    </tr>
                                                                ) : (
                                                                    custNotes.map((list, index) => (
                                                                        <tr onClick={() => handleTableRowClick(list, selectedRow, setSelectedRow, selectedRowId, setSelectedRowId, list.notes_id)} className={selectedRowId === list.notes_id ? 'row_selected' : ''}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{removePTags(list.notes)}</td>
                                                                            <td>{list.customer_name}</td>
                                                                            <td>{list.updated_by}</td>
                                                                        </tr>
                                                                    ))
                                                                )}
                                                            </tbody>
                                                        </table>
                                                        <hr />
                                                        <Pagination {...props} />
                                                    </div>
                                                </div>
                                            </>
                                        ) : (

                                            <div className="card">

                                                <div className="card-body p-4">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div>
                                                                <form id="creditCardForm" key={2} novalidate="novalidate" enctype="multipart/form-data" onSubmit={handleSubmit(formSubmit)}>
                                                                    <div className="row">

                                                                        <div class="col-md-4" style={{ position: 'relative' }}>
                                                                            <div class="mb-3" style={{ position: 'relative', zIndex: 100 }}>
                                                                                <label class="form-label" for="formrow-lname-input">User <span style={{ color: 'red' }}>*</span></label>
                                                                                {/* <AutoUserSearch {...autoCompleteProps} {...register('user', { required: 'User is required' })} /> */}
                                                                                <Controller control={control} name="user" id="user" rules={{ required: "User is required", }} render={({ field }) => (
                                                                                    <AutoUserSearch onSelect={(user) => {
                                                                                        setValue('user', user ? user.user_id : null);

                                                                                    }} value={field.value} />

                                                                                )}
                                                                                />
                                                                                {errors.user && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.user.message}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <label
                                                                                class="form-label"
                                                                                for="formrow-lname-input"
                                                                            >
                                                                                Note{" "}
                                                                                <span style={{ color: "red" }}>*</span>
                                                                            </label>
                                                                            <Controller
                                                                                name="editorContent"
                                                                                control={control}
                                                                                rules={{ required: 'Content is required' }}
                                                                                render={({ field, fieldState }) => (
                                                                                    <>
                                                                                        <Editor

                                                                                            onChange={field.onChange}
                                                                                            value={field.value}
                                                                                            setContents={field.value} // Add this line to set initial content
                                                                                        />

                                                                                        <div style={{ color: 'red' }}>{fieldState?.error?.message}</div>
                                                                                    </>
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
                                                                        <button class="btn btn-warning w-md" type="button" onClick={() => setShowEditForm(false)}>Cancel</button>
                                                                        <button class="btn btn-primary w-md me-md-2" type="submit">Update</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                                {/* <!-- end col --> */}
                            </div>
                        </div>
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}
            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </div>
    );
}

export default RepliesList;