import React, { useEffect, useState } from "react";
import Header from '../../../common/Header'
import Sidebar from '../../../common/Sidebar'
import Footer from '../../../common/Footer'
import PageHeader from '../../PageHeader'
import PaginationComponent from "../../PaginationComponent";
import axios from "axios"; 
import { Table } from 'react-bootstrap';
import { triggerAlert, handleTableRowClick, ConfirmationAlert, getToken,formattedDateTime , get_user_menu_permission} from "../../../utils";
import Loader from '../../../common/Loader'
import { useNavigate , Link } from 'react-router-dom'  
import {menuIdData} from '../../../utils/constants'
function UserDepartment() {

    const api_url       = process.env.REACT_APP_API_CUSTOMER_URL;
	const show50Entries = process.env.REACT_APP_API_SHOW_ENTRIES;
    const mainHeading   = "User Department";
    const heading       = "Admin / User Department";
	const token         = getToken();
    const navigate      = useNavigate();

    const [isLoading, setIsLoading]         = useState(false);
    const [listData, setListData]           = useState([]); 
	const [selectedRow, setSelectedRow] 	= useState(null);
	const [selectedRowId, setSelectedRowId] = useState(null); 
	const [pageCount, setPageCount]         = useState(0);
	const [pageSlNo, setPageSlNo]           = useState(0);
	const [currentPage, setCurrentPage]     = useState(0);
	const [perPageLimit, setPerPageLimit]   = useState(show50Entries);
	const [searchQuery, setSearchQuery]     = useState('');
    // Menu Permissions
    const [addPermission, setAddPermission]         = useState(false);
    const [editPermission, setEditPermission]       = useState(false);
    const [deletePermission, setDeletePermission]   = useState(false);

    const fetchListData = async (page,searchkey="") => { 
        if(!searchkey){
            setIsLoading(true);
        }
        
        const per_page = perPageLimit; 
        try { 
            setPageSlNo((page - 1) * per_page);
            const response      = await axios.get(api_url + 'manage_admin/department_list?page_size='+per_page+'&page_number='+page+'&keyword='+searchkey,token);  
            const response_data = response.data 
            if (response.status === 200) {  
                const itemsArray    = response_data.results.data || {};  
                const total_pages   = response_data.results.total_pages; 
                setPageCount(total_pages); 
                setListData(itemsArray); 
                setIsLoading(false);
            } else if (response.status === 204) {
                setListData([]); 
                setIsLoading(false); 
                setPageCount(0); 
            }
            else {
                setListData([]); 
                setIsLoading(false); 
                setPageCount(0); 
                // triggerAlert('error', 'Oops...', 'Something went wrong here..');
            }

        } catch (error) { 
            setListData([]); 
            setIsLoading(false); 
            setPageCount(0); 
            // triggerAlert('error', 'Oops...', 'Something went wrong..');
        }
    };

    // Delete Trunk
    const deleteData =  () => { 
		if(selectedRowId){
			//////////////////////////////////////// 
			ConfirmationAlert('You want to continue!', 'Continue', async () => {
                 
                try { 
                    const response = await axios.delete(api_url + 'manage_admin/delete_department?dep_id='+selectedRowId,token); 
                    
                    const response_data = response.data;
                    if (response_data.error_code === 201) {
                        triggerAlert('success', 'success', 'Data deleted Successfully!!'); 
                        fetchListData(1);
                    } else {
                        triggerAlert('error', 'Oops...', 'Something went wrong..');
                    }
                } catch (error) {
                    triggerAlert('error', 'Oops...', 'Something went wrong..');
                }
            })

		
		}else{ 
			triggerAlert('error','Oops...','Please select a row');
		}
		
	};

    const editData =  () => {   
		if(selectedRowId){
			//////////////////////////////////////// 
            
            navigate(`/manage_admin/user_department/manage_user_department?action=update&edit_id=${selectedRowId}`, { state: selectedRow });

		
		}else{ 
			triggerAlert('error','Oops...','Please select a row');
		}
		
	};

    const handlePageClick = (selected) => { 
		const selectedPage = selected.selected;
		setCurrentPage(selectedPage);
		fetchListData(selectedPage + 1,searchQuery); // Increment the page number by 1 for server-side pagination
	};

    // const handlePageChange = event => {
	// 	if (event.target.value) {
    //         setCurrentPage(0);
	// 		setPerPageLimit(event.target.value);
	// 		setPageLimitSelected(event.target.value);
	// 	} else {
	// 		setPerPageLimit(10);
	// 		setPageLimitSelected(10);
	// 	}
	// };


    let pgntn_props = {
		pageCount: pageCount,
		handlePageClick: handlePageClick,
		selectedPage: currentPage
	}

  
	const getStatus = (status) => {
		switch (status) {
			case '1':
				return 'Active';
			case '2':
				return 'Inactive';
		};

	}
    const handleKeySearch = (e) => {
        
		setCurrentPage(0);
		setSearchQuery(e.target.value);
		const searchkey = e.target.value;
		fetchListData(1,searchkey); // Update search results on every change
	};

    // Menu Permissions
    useEffect(() => { 
        const user_department = menuIdData.user_department;
        console.log(user_department)
        setAddPermission(get_user_menu_permission(user_department,'add'));
        setEditPermission(get_user_menu_permission(user_department,'edit'));
        setDeletePermission(get_user_menu_permission(user_department,'delete'));
	}, []);

    useEffect(() => { 
		setCurrentPage(0);
		fetchListData(currentPage + 1); // Fetch data for the initial page
	}, [perPageLimit]);
    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */} 
                                <>
                                     
                                    <div className="row  ">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header">


                                                    {(addPermission || editPermission) &&
                                                    <div className="btn-group float-end col-md-1  ml-10">
                                                        <button type="button" className="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{"marginLeft": "10px"}}>
                                                            Action <i className="mdi mdi-chevron-down"></i>
                                                        </button>
                                                        <div className="dropdown-menu dropdown-menu-end  ">
                                                            { addPermission && 
                                                            <Link to="/manage_admin/user_department/manage_user_department?action=add" className="dropdown-item"  >Create Department</Link>
                                                            }
                                                            { editPermission && 
                                                            <a className="dropdown-item hand_icon" onClick={editData}  > Edit</a>
                                                            }
                                                            { deletePermission && 
                                                            <a className="dropdown-item hand_icon" onClick={deleteData}> Delete</a>
                                                            }
                                                        </div>
                                                    </div> 
                                                    }
                                                </div>
                                                <div className="card-body">
                                                    <div id="datatable_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                        <div className="row">
															{/* <div className="col-sm-12 col-md-6">
																<div className="dataTables_length" id="datatable_length">
																	<label>Show&nbsp;
																		<select name="example_length" aria-controls="example" className="table_length" onChange={handlePageChange} value={pageLimitSelected}>
																			<option value={10}>10</option>
																			<option value={20}>20</option>
																			<option value={50}>50</option>
																			<option value={100}>100</option>
																		</select> entries
																	</label>
																</div>
															</div>   */}
															<div className="col-sm-12 col-md-12 mb-2">
																<div className="btn-group float-end ">
																	<input type="search" placeholder="Search..." value={searchQuery} className="form-control form-control-sm" aria-controls="example" onChange={handleKeySearch} />
																	&nbsp;&nbsp;
																</div>
															</div>
														</div>
                                                        <Table style={{ width: '100%' }} className="table-responsive table-bordered">
                                                            <thead style={{ backgroundColor: '#ededed' }}>
                                                                <tr>
                                                                    <th scope="col" width="">#</th>
                                                                    <th scope="col">Name</th>
                                                                    <th scope="col">Date</th>
                                                                    <th scope="col">Status</th>  
                                                                </tr>
                                                            </thead>
                                                            {isLoading ? (
                                                                <div className='loader-overlay text-white'>
                                                                    <Loader />
                                                                </div>

                                                            ) : (
                                                                <tbody>
                                                                        {listData.length > 0 ? (
                                                                            listData.map((item, index) => (
                                                                            <tr onClick={() => handleTableRowClick(item, selectedRow, setSelectedRow, selectedRowId, setSelectedRowId , item.id )}  className={selectedRowId === item.id ? 'row_selected' : ''}    >
                                                                                {/* <th scope="row">{pageSlNo + index + 1}</th> */}
                                                                                <th scope="row">{pageSlNo + index + 1}</th>
                                                                                <td>{item.name}</td>
                                                                                <td>{formattedDateTime(item.updated_at)}</td>
                                                                                <td>
                                                                                {item.status == 1 ? (
                                                                                    <span className="btn btn-success btn-sm btn-rounded waves-effect waves-light">Active</span>
                                                                                ) : (
                                                                                    <span className="btn btn-danger btn-sm btn-rounded waves-effect waves-light">{getStatus(item.status)}</span>
                                                                                )}
                                                                                </td> 
                                                                                 
                                                                                   
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan="4" className="text-center">No data available</td>
                                                                        </tr>
                                                                    )}

                                                                </tbody>
                                                            )}
                                                        </Table>
                                                        <hr />
                                                        
							                            {/* Pagination Controls */}
							                            <PaginationComponent {...pgntn_props} />
                                                    </div>

                                                </div>
                                            </div>
                                        </div> {/* <!-- end col -->*/}
                                    </div>
                                </> 



                        </div> {/* container-fluid */}
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </>
    );
}

export default UserDepartment;
