import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import Footer from "../common/Footer";

function ComingSoon() {
  return (
	<>
    <div id="layout-wrapper">

       
		<Header />


		<Sidebar />
        
         {/* ============================================================== */}
        {/* Start right Content here */}
        {/* ============================================================== */} 
        <div className="main-content">

			<div className="page-content">
				<div className="container-fluid">

					{/* start page title */}
					<div className="row">
						<div className="col-12">
							<div className="page-title-box d-sm-flex align-items-center justify-content-between">
								<h4 className="mb-sm-0 font-size-18">Coming Soon</h4>

								 

							</div>
						</div>
					</div>
					{/* end page title */}

					<div className="row mt-4">
						<div className="col-lg-12">
							<div className="card">

								<div className="card-body p-4">

									<div className="row">
										<div className="col-lg-12">
											<div>

												<h4>Coming Soon</h4>
											</div>
										</div>

									</div>

									 


								</div>
							</div>
						</div>
					</div>

				</div> {/* container-fluid */}
			</div>
             {/* End Page-content */}


           
			<Footer />


        </div>
         {/* end main content*/} 

    </div>
     {/* END layout-wrapper */}




     {/* Right bar overlay*/} 
    <div className="rightbar-overlay"></div>
	</>
  );
}

export default ComingSoon;
