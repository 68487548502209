import React, { useEffect, useState } from 'react'
import Header from '../../common/Header';
import Sidebar from '../../common/Sidebar';
import PageHeader from '../PageHeader';
import Footer from '../../common/Footer';
import { downloadDataInCsv, exportToCsv, formatDate, formattedDateTime, getToken, get_user_menu_permission, simpleAlert, triggerAlert } from '../../utils';
import { Controller, useForm } from 'react-hook-form';
import AutoUserSearch from '../AutoUserSearch';
import Loader from '../../common/Loader';
import { Table } from 'react-bootstrap';
import Pagination from '../PaginationComponent';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { menuIdData } from '../../utils/constants';
export default function TransactionHistory() {
  const api_url = process.env.REACT_APP_API_BASE_URL;
  const show50Entries = process.env.REACT_APP_API_SHOW_ENTRIES;
  const token = getToken();
  const mainHeading = "Transaction History";
  const heading = "Billing / Transaction History";
  const { register, handleSubmit, formState: { errors }, setValue, watch, reset, control } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [period_start, setPeriodStart] = useState("");
  const [period_end, setPeriodEnd] = useState("");
  const [minEndDate, setMinEndDate] = useState(period_start ? new Date(period_start) : null);

  const [isSearchBtnDisabled, setIsSearchBtnDisabled] = useState(true);
  const [customerId, setcustomerId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageSlNo, setPageSlNo] = useState(0);
  const [perPageLimit, setPerPageLimit] = useState(show50Entries);
  const [pageLimitSelected, setPageLimitSelected] = useState(10);

  // Menu Permissions
  //  const [addPermission, setAddPermission] = useState(false);
  //  const [editPermission, setEditPermission] = useState(false);
  //  const [deletePermission, setDeletePermission] = useState(false);
  const [exportPermission, setExportPermission] = useState(false);
  const fetchTransactionDetails = async (page_number, searchkey) => {
    // if (customerId) {
    const page_size = perPageLimit;
    setIsLoading(true);
    try {

      let apiUrl = `${api_url}billing/get_transaction_history`;
      let params = `?page_size=${page_size || 10}&page_number=${page_number || 1}`;
      if (customerId) params += `&customer_id=${customerId}`;
      if (searchkey) params += `&keyword=${searchkey}`;
      const startDate = formatDate(period_start, 'dd-mm-yyyy');
      const endDate = formatDate(period_end, 'dd-mm-yyyy');
      if (period_start !== '' && period_end !== '') {
        params += `&from_date=${startDate}&to_date=${endDate}`;
      }
      const response = await axios.get(apiUrl + params, token);
      const response_data = response.data;
      if (response_data.error_code === 200) {
        const itemsArray = response_data.results.data;
        const total_pages = response_data.results.pagination.total_pages;
        setPageCount(total_pages);
        setTransactionDetails(itemsArray);
        setIsLoading(false);
      } else if (response.status === 204) {
        setTransactionDetails([]);
        setIsLoading(false);
      } else {
        triggerAlert('error', 'Oops...', "Couldn't get the details");
      }
    } catch (error) {
      //console.log(error)
      setIsLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
    // } else {
    //   simpleAlert('Select User!!')
    // }

  }

  const onSearch = () => {
    // console.log(data)
    if (customerId) {
      fetchTransactionDetails(currentPage + 1)
    } else {
      simpleAlert('Select User!!')
    }
  }

  //for export fetching full transaction history
  const fetchUserTotalTransaction = async () => {
    if (customerId) {


      try {

        let apiUrl = `${api_url}billing/get_transaction_history_download_data`;
        let params = "";
        if (customerId) params += `?customer_id=${customerId}`;
        if (searchQuery) params += `&keyword=${searchQuery}`;
        const startDate = formatDate(period_start, 'dd-mm-yyyy');
        const endDate = formatDate(period_end, 'dd-mm-yyyy');
        if (period_start !== '' && period_end !== '') {
          params += `&from_date=${startDate}&to_date=${endDate}`;
        }
        //  console.log(apiUrl + params);
        // return true
        const response = await axios.get(apiUrl + params, token);
        const response_data = response.data;
        if (response_data.error_code === 200) {
          const itemsArray = response_data.results;
          exportToCsv(itemsArray, 'transaction_history_report.csv');
          setIsLoading(false);
        } else if (response.status === 204) {
          setIsLoading(false);
        } else {
          triggerAlert('error', 'Oops...', "Couldn't export the file");
        }
      } catch (error) {
        // console.log(error)
        setIsLoading(false);
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
    } else {
      simpleAlert('Select User!!')
    }
  }

  const exportToExcel = () => {
    if (transactionDetails.length > 0) {
      fetchUserTotalTransaction();
    } else {
      simpleAlert('No Data to download')
    }

  }


  const handleUserSelect = (userData) => {

    // You can use the userData in your logic
    setcustomerId(userData.user_id);
    setPageCount(0);
    setTransactionDetails([]);
    setCurrentPage(0);
    setPerPageLimit(show50Entries);
    setPageLimitSelected(10);
    setIsSearchBtnDisabled(false);
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    const searchkey = e.target.value;
    fetchTransactionDetails(currentPage + 1, searchkey);

  };

  const handlePageChange = event => {
    setCurrentPage(0);
    if (event.target.value) {
      setPerPageLimit(event.target.value);
      setPageLimitSelected(event.target.value);
    } else {
      setPerPageLimit(10);
      setPageLimitSelected(10);
    }
  };

  const handlePageClick = (selected) => {

    const selectedPage = selected.selected;
    // console.log("selectedPage", selectedPage)
    setCurrentPage(selectedPage);
    fetchTransactionDetails(selectedPage + 1); // Increment the page number by 1 for server-side pagination
  };

  let pgntn_props = {
    pageCount: pageCount,
    handlePageClick: handlePageClick,
    selectedPage: currentPage
  }

  var limit = 50;

  useEffect(() => {
    const menu_id = menuIdData?.transaction_history;
    // setAddPermission(get_user_menu_permission(menu_id, 'add'));
    // setEditPermission(get_user_menu_permission(menu_id, 'edit'));
    // setDeletePermission(get_user_menu_permission(menu_id, 'delete'));
    setExportPermission(get_user_menu_permission(menu_id, 'export'));
  }, []);

  return (
    <>
      <div id="layout-wrapper">

        <Header />
        <Sidebar />

        {/* <!-- ============================================================== -->
            <!-- Start right Content here -->
            <!-- ============================================================== --> */}
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <PageHeader mainHeading={mainHeading} heading={heading} />



              <div className="row mt-5">
                <div className="col-12">
                  <div className="card">

                    <div className="card-body">
                      <form data-select2-id="21" noValidate="novalidate" onSubmit={handleSubmit(onSearch)} >
                        <div className="row g-3" data-select2-id="20">
                          <div className="col-md-3  d-flex flex-column">
                            <label className="form-label">From</label>
                            <Controller className="px-3" control={control} name="period_start" render={({ field }) => (
                              <DatePicker className="px-3 form-control" placeholderText='MM/DD/YYYY' selected={field.value} {...register('period_start')}
                                onChange={(date) => {
                                  setPeriodStart(date);
                                  field.onChange(date);
                                  setMinEndDate(date); // Update minDate for period_end 
                                  setIsSearchBtnDisabled(!date || !period_end || !customerId);
                                  // if (date) setSelectedTab('datewise');

                                }} showMonthDropdown showYearDropdown maxDate={new Date()} autoComplete='off' />
                            )} />
                            {errors.period_start && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.period_start.message}</div>}
                          </div>
                          <div className="col-md-3  d-flex flex-column">
                            <label className="form-label">To</label>
                            <Controller control={control} name="period_end" render={({ field }) => (
                              <DatePicker className="px-3 form-control" placeholderText='MM/DD/YYYY' selected={field.value}  {...register('period_end')}
                                onChange={(date) => {
                                  setPeriodEnd(date); // Update the state variable
                                  field.onChange(date);// Update the form value
                                  setIsSearchBtnDisabled(!period_start || !date || !customerId);
                                  // if (date) setSelectedTab('datewise');

                                }} showMonthDropdown showYearDropdown maxDate={new Date()} minDate={minEndDate} autoComplete='off' />
                            )} />
                            {errors.period_end && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.period_end.message}
                            </div>}
                          </div>
                          <div className="col-md-3">
                            <label className="form-label" htmlFor="User">User</label>
                            <AutoUserSearch onSelect={handleUserSelect} />
                            {/* {selectedUser && <p>Selected User: {selectedUser.label}</p>} */}
                          </div>


                          <div className="col-md-3 d-flex justify-content-between">
                            <div>
                              <label className="form-label" for="formtabs-last-name">&nbsp;</label>
                              <div className="button-items">
                                <button type="submit" className="btn btn-primary w-md"
                                  disabled={isSearchBtnDisabled}
                                >Search</button>
                              </div>
                            </div>
                            {transactionDetails?.length > 0 &&
                              <div>
                                <label className="form-label" for="formtabs-last-name">&nbsp;</label>
                                <div className="button-items">
                                  {exportPermission &&
                                    <button className="btn btn-success w-md px-3" type="button" onClick={exportToExcel}>
                                      <i className="mdi mdi-file-export-outline" style={{ fontSize: 'large' }}></i> Export to Excel</button>
                                  }
                                </div>
                              </div>
                            }

                          </div>
                          {/* {transactionDetails?.length > 0 &&
                            <div className="col-md-12 d-flex flex-column align-items-end">
                              <label className="form-label" for="formtabs-last-name">&nbsp;</label>
                              <div className="button-items">
                                <button className="btn btn-success w-md px-3" type="button" onClick={exportToExcel}>
                                  <i className="mdi mdi-file-export-outline" style={{ fontSize: 'large' }}></i> Export to Excel</button>
                              </div>
                            </div>
                          } */}
                        </div>
                      </form>
                      <div className="row mt-5">
                        {/* <div className="col-sm-12 col-md-6">
                                                        <div className="dataTables_length" id="datatable_length">
                                                            <label>Show&nbsp;
                                                                <select name="example_length" aria-controls="example" classNameName="table_length" onChange={handlePageChange} value={pageLimitSelected}>
                                                                    <option value={10}>10</option>
                                                                    <option value={20}>20</option>
                                                                    <option value={50}>50</option>
                                                                    <option value={100}>100</option>
                                                                </select> entries
                                                            </label>
                                                        </div>
                                                    </div> by sahiti*/}
                        <div className="col-sm-12 col-md-12 mb-2">
                          <div className="btn-group float-end col-md-2">
                            <input type="search" placeholder="Search by Description" value={searchQuery} className="form-control form-control-sm" aria-controls="example" onChange={handleChange} />
                            &nbsp;&nbsp;
                          </div>
                        </div>
                      </div>
                      <Table id="datatable" className="nowrap w-100 dt-responsive table-responsive table-bordered">
                        <thead style={{ backgroundColor: '#ededed' }}>
                          <tr>
                            <th>#</th>
                            <th>Transaction Date</th>

                            <th>Description </th>
                            <th>Units</th>
                            <th>Unit Cost	</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th>Balance Amount</th>


                          </tr>
                        </thead>
                        {isLoading ? (
                          <div className='loader-overlay text-white'>
                            <Loader />
                          </div>

                        ) : (

                          <tbody>
                            {transactionDetails?.length > 0 ? (
                              transactionDetails.map((item, index) => (
                                <tr key={item.payment_id}>
                                  <th scope="row">{(currentPage) * perPageLimit + index + 1}</th>
                                  <td>{item.created_date ? formattedDateTime(item.created_date) : '-'} </td>
                                  <td>
                                    {item.description ? (item.description.length > limit ? item.description.slice(0, limit) + '...' : item.description) : '-'}
                                  </td>
                                  <td>{item.unit ? item.unit : '-'} </td>
                                  <td>{item.unit_cost ? '$' + item.unit_cost : '-'}</td>
                                  <td> {item.payment_type == "credit" ? (
                                    <span className="text-success font-size-14">+${item.amount_collected ? item.amount_collected : '-'}</span>
                                  ) : (
                                    <span className="text-danger font-size-14">-${item.amount_collected ? item.amount_collected : '-'}</span>
                                  )}</td>
                                  <td> {item.payment_type == "credit" ? (
                                    <span className="badge bg-success rounded-pill font-size-12">Credit</span>
                                  ) : (
                                    <span className="badge bg-danger rounded-pill font-size-12">Debit</span>
                                  )} </td>
                                  <td >${item.current_balance ? item.current_balance : '-'} </td>

                                </tr>


                              ))
                            ) : (
                              <tr>
                                <td colSpan="8" className="text-center">No data available</td>
                              </tr>
                            )}

                          </tbody>
                        )}
                      </Table>

                      <hr />
                      <Pagination {...pgntn_props} />
                    </div>
                  </div>
                </div> {/* end col */}
              </div> {/* end row */}



            </div>
            {/* <!-- container-fluid --> */}
          </div>
          {/* <!-- End Page-content --> */}

          <Footer />
        </div>
        {/* <!-- end main content--> */}
      </div>
      {/* <!-- END layout-wrapper*/}

      {/* <!-- Right bar overlay--> */}
      <div className="rightbar-overlay"></div>
    </>
  )
}
