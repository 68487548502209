import Swal from "sweetalert2";
import moment from 'moment-timezone';


export const login = (token, username, email, user_type, admin_id) => {
    localStorage.setItem('admin_user_token', token);
    localStorage.setItem('username', username);
    localStorage.setItem('email', email);
    localStorage.setItem('user_type', user_type);
    localStorage.setItem('admin_id', admin_id);
}

export const logout = () => {
    localStorage.removeItem('admin_user_token');
    localStorage.removeItem('username');
    localStorage.removeItem('email');
    localStorage.removeItem('user_type');

    localStorage.removeItem('menu_id_add');
    localStorage.removeItem('menu_id_edit');
    localStorage.removeItem('menu_id_delete');
    localStorage.removeItem('excel_export');
    localStorage.removeItem('admin_menu_data');
    localStorage.removeItem('admin_permission_data');
    localStorage.removeItem('modulepermissions');
    localStorage.removeItem('admin_id');

    return true;
}

export const isLogin = () => {
    //return true;
    if (localStorage.getItem('admin_user_token')) {
        return true;
    }

}
export const getCustomerId = () => {
    const customerId = parseInt(localStorage.getItem('customer_id'), 10);

    if (customerId) {
        return customerId;
    } else {
        return null;
    }
};


export const getToken = () => {
    const token = localStorage.getItem('admin_user_token');
    if (token) {
        return {

            headers: {
                Authorization: `${token}`
            }
        }
    } else {
        return null;
    }
};

///////////// Common swal alert function //////////////
export const triggerAlert = (icon, title, message) => {
    return Swal.fire({
        icon: icon,
        title: title,
        text: message,
        timer: 3000,
    })

}

export const simpleAlert = (text) => {
    return Swal.fire({
        text: text,
        timer: 3000,
    })
}


export const AlertWithButton = async (title, htmlCode, buttonText, buttonFunc) => {
    const result = await Swal.fire({
        title: title,
        html: htmlCode,
        confirmButtonText: buttonText,
        timer: 3000,
    });
    if (result.isConfirmed) {
        buttonFunc();
    }
}

export const ConfirmationAlert = async (title, buttonText, buttonFunc) => {
    const result = await Swal.fire({
        title: 'Are you sure?',
        text: title,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: buttonText,
        timer: null//3000,
    });
    if (result.isConfirmed) {
        buttonFunc();
    }
}

export const triggerAlertHtml = (icon, title, message, htmlCode) => {
    return Swal.fire({
        icon: icon,
        title: title,
        text: message,
        html: htmlCode,
        timer: 6000
    })

}
//////////////// Format Date as required/////////////////
export const formatDate = (dateInput, format) => {
    // If the input is a string, convert it to a Date object
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;

    // Check if the date is not null and is a valid Date object
    if (!date || isNaN(date.getTime())) {
        return '-';
    } else {

        const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
        let monthName = month[date.getUTCMonth()];
        const yyyy = date.getFullYear();

        switch (format) {
            case 'dd-mm-yyyy':
                return `${dd}-${mm}-${yyyy}`;
            case 'yyyy-mm-dd':
                return `${yyyy}-${mm}-${dd}`;
            case 'dd/mm/yyyy':
                return `${dd}/${mm}/${yyyy}`;
            case 'mm/dd/yyyy':
                return `${mm}/${dd}/${yyyy}`;
            case 'month dd, yyyy':
                return `${monthName} ${dd}, ${yyyy}`;
            // Add more format cases as needed
            default:
                return `${dd}-${mm}-${yyyy}`;
        }
    }
};
export const pageReload = () => {
    setTimeout(() => {
        window.location.reload();
    }, 1000);

}

//Download data in excel csv format
export const exportToCsv = (data, filename) => {
    // const csvContent = "data:text/csv;charset=utf-8," +
    //     Object.keys(data[0]).join(",") + "\n" +
    //     data.map(row => Object.values(row).join(",")).join("\n");

    // const encodedUri = encodeURI(csvContent);
    // const link = document.createElement("a");
    // link.setAttribute("href", encodedUri);
    // link.setAttribute("download", filename + ".csv");
    // document.body.appendChild(link);
    // link.click();
    //commented by sahiti
    const csvContent = Object.keys(data[0]).join(",") + "\n" +
        data.map(row => Object.values(row).join(",")).join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv' }); // for large amount of data more than 3000 rows
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename + ".csv");
    document.body.appendChild(link);
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
};

export const handleTableRowClick = (value, selectedRow, setSelectedRow, selectedRowId, setSelectedRowId, row_id) => {

    if (selectedRowId === row_id) {
        setSelectedRow();
        setSelectedRowId();
    } else {
        setSelectedRow(value);
        setSelectedRowId(row_id);
    }

};

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

//Convert 60s to 00:01:00 
export function secondsToTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}


//////////////////Convert periodStart and periodEnd to UNIX timestamp/////////////////////
export function getPeriodStartAndEndInUnixTimestamp(data) {

    const from = data.period_start;
    const fromDateObject = new Date(from);
    const startDateMoment = moment(fromDateObject, "YYYY-MM-DD HH:mm:ss");

    // Convert the input date to UTC using moment-timezone
    const istDate = startDateMoment.tz("America/New_York");

    // Calculate the Unix timestamp using the UTC date
    const period_start = Math.floor(istDate / 1000);

    const to = data.period_end;
    const toDateObject = new Date(to);
    const endDateMoment = moment(toDateObject, "YYYY-MM-DD HH:mm:ss");

    // Convert the input date to UTC using moment-timezone
    const eDate = endDateMoment.tz("America/New_York");

    // Calculate the Unix timestamp using the UTC date
    const period_end = Math.floor(eDate / 1000);

    return { period_start, period_end };
}

export const capitalizeFirst = str => {
    // Split the input string by underscores and then capitalize each word
    const words = str.split('_').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });
    // Join the capitalized words back together
    return words.join(' ');
};

/////////////Download data in csv format with specific columns and grand total /////////////////

export const downloadDataInCsv = async (data, fileName, customHeaders = {}, grandTotalColumn) => {
    // Extract columns from customHeaders
    const selectedColumns = Object.keys(customHeaders);

    // Convert data to CSV format
    let csvContent = '';

    // Use customHeaders for the header row
    const headerRow = selectedColumns.map(key => customHeaders[key]);
    csvContent += headerRow.join(',') + '\n';

    // Add data rows
    for (const rowData of data) {
        const rowValues = [];
        for (const key of selectedColumns) {
            let value = rowData[key];
            if (typeof value === 'string' && value.includes(",")) {
                value = `"${value}"`;
            }
            rowValues.push(value);
        }
        csvContent += rowValues.join(',') + '\n';
    }

    // Calculate the grand total for the specified column
    const grandTotal = data.reduce((total, row) => total + parseFloat(row[grandTotalColumn] || 0), 0);

    // Add the grand total row
    const grandTotalRow = selectedColumns.map(key => {
        if (key === grandTotalColumn) {
            return `Grand Total: ${grandTotal.toFixed(3)}`;
        }
        return ''; // Empty string for non-grandTotalColumn columns
    });
    csvContent += grandTotalRow.join(',') + '\n';

    // Create a Blob object with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a download link and trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
};

export const formattedDateTime = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
};

export const removePTags = (inputString) => {
    if (inputString) {
        // Remove HTML tags
        const withoutPTags = inputString.replace(/<\/?[^>]+(>|$)/g, "");
        // Remove &nbsp;
        const removeBraces = withoutPTags.replace(/&nbsp;/g, '');
        return removeBraces;
    }
}

export const convertDocIdsToStr = (documents) => {
    const documentIds = [];
    if (documents) {
        documents.forEach((doc) => {
            documentIds.push(doc.value);
        });
        return documentIds.join(",");
    } else {
        return "";
    }
}

export const sortColumnData = (sortOrder, data, field) => {
    const order = sortOrder === 'asc' ? 'desc' : 'asc';
    const sortedData = [...data].sort((a, b) => {
        if (a[field] < b[field]) return order === 'asc' ? -1 : 1;
        if (a[field] > b[field]) return order === 'asc' ? 1 : -1;
        return 0;
    });
    return sortedData;
}

// Admin user menu and permission related function
export const set_user_menu_permission = (menu_id_add, menu_id_edit, menu_id_delete, excel_export) => {
    localStorage.setItem('menu_id_add', menu_id_add);
    localStorage.setItem('menu_id_edit', menu_id_edit);
    localStorage.setItem('menu_id_delete', menu_id_delete);
    localStorage.setItem('excel_export', excel_export);
}

export const get_user_menu_permission = (menu_id, type) => {
    let has_permission = false;
    if (type == "add") {
        let add_permissions = localStorage.getItem('menu_id_add');
        has_permission = add_permissions?.includes(menu_id);
    } else if (type == "edit") {
        let edit_permissions = localStorage.getItem('menu_id_edit');
        has_permission = edit_permissions?.includes(menu_id);
    } else if (type == "delete") {
        let delete_permissions = localStorage.getItem('menu_id_delete');
        has_permission = delete_permissions?.includes(menu_id);
    } else if (type == "export") {
        let excel_export_permissions = localStorage.getItem('excel_export');
        has_permission = excel_export_permissions?.includes(menu_id);
    } else if (type == "child_id") {
        let child_permissions = localStorage.getItem('admin_permission_data');
        if (child_permissions) {
            const parsedData = JSON.parse(child_permissions);
            has_permission = parsedData?.child_id?.includes(menu_id);
        }
    }
    return has_permission;
}

export const set_menu_details = (menu_data) => {
    localStorage.setItem('admin_menu_data', JSON.stringify(menu_data));
}


export const set_user_permission = (permission_data) => {
    localStorage.setItem('admin_permission_data', JSON.stringify(permission_data));
}

export const validateFileSize = (value) => {
    if (!value) return true; // Allow empty files
    const fileSize = value[0]?.size; // Access the size property of the File object
    const maxFileSize = 1024 * 1024; // 1 MB
    if (fileSize > maxFileSize) {
        return 'File size exceeds the maximum allowed size (1 MB).';
    }
    return true;
};
/////Transforming strings//////

export function transformText(text, transformation) {
    switch (transformation) {
        case 'lowercase':
            return text.toLowerCase();
        case 'uppercase':
            return text.toUpperCase();
        case 'capitalize':
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        default:
            throw new Error(`Invalid transformation: ${transformation}`);
    }
}

export const formatingDateTime = (dateString, format) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Format the date according to the provided format
    switch (format) {
        case 'YYYY-MM-DD HH:mm:ss':
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        case 'MM/DD/YYYY HH:mm:ss':
            return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
        // Add more cases for additional formats as needed
        default:
            // Default to YYYY-MM-DD, HH:mm:ss format
            return `${year}-${month}-${day}, ${hours}:${minutes}:${seconds}`;
    }
};


export const downloadFile = (url, filename) => {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            // Create a temporary link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;

            // Programatically click the link to trigger the download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(error => console.error('Error downloading file:', error));
};
