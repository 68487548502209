import React, { useEffect, useState } from 'react'
import Header from '../../common/Header'
import Sidebar from '../../common/Sidebar'
import PageHeader from '../PageHeader'
import Footer from '../../common/Footer'
import { getToken, simpleAlert, triggerAlert } from '../../utils'
import { Controller, useForm } from 'react-hook-form'
import AutoUserSearch from '../AutoUserSearch'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { MaxLengthValidation, createPattern, numberSpecialChar, onlyAlphabetsandSpaces, onlyNumbers } from '../../utils/constants'
import PdfGen from './PdfGen'

export default function CreateInvoice() {
    const api_url = process.env.REACT_APP_API_BASE_URL;
    const token = getToken();
    const mainHeading = "Create Invoices";
    const heading = "Billing / Create Invoices";
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, setValue, watch, reset, control } = useForm();
    const [customerId, setcustomerId] = useState(null);
    const [services, setServices] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [sendInvoiceId, setSendInvoiceId] = useState(null);

    const createInvoice = async (data) => {
        data.status = "draft"
        console.log(data)
        try {
            const response = await axios.post(`/apiV1/create-invoice/`, data);
            const response_data = response.data;
            if (response) {
                
                triggerAlert('success', 'Success', "Successfully created invoice");
                navigate('/billing/invoice');
            }
            else if (response.status === 204) {
                // setServices([])
                triggerAlert('error', 'Oops...', "Unable to create a invoice");
            } else {
                triggerAlert('error', 'Oops...', "Unable to create a invoice");
            }
        } catch (error) {
            const response_data = error?.response?.data
            if (response_data) {
                triggerAlert("error", "Oops...", response_data.message);
            } else {
                triggerAlert("error", "Oops...", "Something went wrong..");

            }
        }
         
    }

    const getRefillServices = async () => {
        try {
            const response = await axios.get(api_url + `billing/get_refill_service_list`, token);
            const response_data = response.data;
            if (response_data.error_code === 200) {
                const itemsArray = response_data.results
                setServices(itemsArray)

            }
            else if (response.status === 204) {
                setServices([])
            } else {
                triggerAlert('error', 'Oops...', "Couldn't get the services list");
            }
        } catch (error) {
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    }

    const handleUserSelect = (selected) => {
        setValue(selected ? selected.value : null)
        //alert(selected)
        //console.log("selected",selected)
        // You can use the userData in your logic
        // setcustomerId(selected ? selected.user_id : null);
        //setcustomerId(selected ? selected.value : null);
        // setPageCount(0);
        // setPaymentDetails([]);
        // setCurrentPage(0);
        // setPerPageLimit(show50Entries);
        // setPageLimitSelected(10);
        // setIsSearchBtnDisabled(false);
        setcustomerId(selected.user_id);
    };

    const formReset = () => {
        reset();
          
    }
    const [subscriptionlist, setsubscriptionlist] = useState([])
    const fetchSubscriptions = async ( ) => {
        try {
            const response = await axios.get(`apiV1/add-subscription-plan/`);
            const response_data = response.data
            if (response.status === 200) {
                const itemsArray = response_data.results;
                setsubscriptionlist(itemsArray);
            }  

        } catch (error) {
            setsubscriptionlist([]);
        }
    };
    useEffect(() => {
        fetchSubscriptions();
        //getRefillServices();

    }, [])
    const getvaluesfromsub = (id) =>{
         
        let subdetails = subscriptionlist.filter((item)=> item.id == id)
        setValue('invoice_amount', subdetails[0].amount)
        setValue('credits', subdetails[0].total_credits)
    }

    return (
        <>
            <div id="layout-wrapper">

                <Header />
                <Sidebar />

                {/* <!-- ============================================================== -->
                <!-- Start right Content here -->
                <!-- ============================================================== --> */}
                <div className="main-content" style={{ minHeight: "100vh" }}>
                    <div className="page-content">
                        <div className="container-fluid">
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {sendInvoiceId && <PdfGen data={sendInvoiceId} type='create_refill_invoice' customer_id={customerId} />} {/* Render YourComponent if apiData is available */}
                            <div className="row  mt-4">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">

                                            {/* <!-- Tab panes --> */}

                                            <form data-select2-id="21" noValidate="novalidate" onSubmit={handleSubmit(createInvoice)}>
                                                <div className="row g-3" data-select2-id="20">
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="formtabs-first-name">User <span className='text-danger'>*</span></label>

                                                        <Controller control={control} key={forceUpdate} name="customer" id="customer" rules={{ required: "User is required", }} render={({ field }) => (
                                                            <AutoUserSearch onSelect={(user) => {
                                                                setValue('customer', user ? user.user_id : null);
                                                                setcustomerId(user ? user.user_id : null);
                                                            }} />

                                                        )}
                                                        />
                                                        {errors.customer && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.customer.message}</div>}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor='subscription'>Subscription <span className='text-danger'>*</span></label>
                                                        <select className="form-select"  {...register('subscription', { required: 'Subscription is required' })} onChange={(e)=>getvaluesfromsub(e.target.value)} >
                                                            <option value="">Select</option>
                                                            {subscriptionlist.map(item => (
                                                                <option key={item.id} value={item.id}>{item.plan_name}</option>
                                                            ))}
                                                        </select>
                                                        
                                                        {errors.subscription && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.subscription.message}</div>)}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="form-label">Invoice Amount  </label>
                                                        <input type="number" min={0} className="form-control" {...register('invoice_amount')} placeholder="Enter Invoice Amount"  disabled />
                                                        {errors.invoice_amount && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.invoice_amount.message}</div>)}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="formtabs-first-name">Credits </label>
                                                        <input type="number" min={0} className="form-control" {...register('credits')} disabled placeholder="Enter Invoice Amount" name='credits' />
                                                        {/* <select className="form-select" name="service_type" disabled {...register('service_type', { required: 'Reason is required' })} aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            {services.map(item => (
                                                                <option key={item.service_id} value={item.service_name}>{item.service_name}</option>
                                                            ))}
                                                        </select> */}
                                                        {errors.service_type && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.service_type.message}</div>}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="subject">Subject  </label>
                                                        <textarea
                                                            {...register('subject' )}
                                                            id="basicpill-address-input"
                                                            className="form-control"
                                                            rows="1"
                                                            name='subject'
                                                            placeholder="Enter Subject.."
                                                        />
                                                        {errors.subject && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.subject.message}</div>)}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor='plan_tax'>Plan Tax <span className='text-danger'>*</span></label>
                                                        <div className="input-group">
                                                            <input type="number" min={0} className="form-control" {...register('tax', {
                                                                required: 'Plan Tax is required',
                                                                pattern: numberSpecialChar('.'),
                                                                maxLength: MaxLengthValidation(10),
                                                            })} placeholder="Enter Taxes & Fees" name='tax' />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text" id="inputGroupPrepend">%</span>
                                                            </div>
                                                        </div>
                                                        {errors.tax && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.tax.message}</div>)}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor='plan_tax'>Due Date  </label>
                                                        <div className="input-group">
                                                            <input type="date" min={0} className="form-control" {...register('due_date')}  name='due_date' />
                                                             
                                                        </div>
                                                        {errors.due_date && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.due_date.message}</div>)}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="comments">Comment <span className='text-danger'>*</span></label>
                                                        <textarea
                                                            {...register('comments', {
                                                                required: 'comments are required',
                                                                // pattern: createPattern('!@')
                                                                pattern: onlyAlphabetsandSpaces
                                                            })}
                                                            id="basicpill-address-input"
                                                            className="form-control"
                                                            rows="1"
                                                            name='comments'
                                                            placeholder="Enter Comment.."
                                                        />
                                                        {errors.comments && (<div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.comments.message}</div>)}
                                                    </div>
                                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
                                                        <button className="btn btn-primary w-md me-md-2" type="submit">Create</button>
                                                        <button className="btn btn-warning w-md" type="button" onClick={formReset}>Reset</button>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Comments</h4>
                                        </div>
                                        <div className="card-body">
                                            <div id="ckeditor-classic"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
                                <button className="btn btn-primary w-md me-md-2" type="button">Create</button>
                                    <button className="btn btn-warning w-md" type="button">Cancel</button>
                                    
                                </div>
                                <!-- end col -->
                            </div> */}
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}

                    <Footer />
                </div>
                {/* <!-- end main content--> */}
            </div>
            {/* <!-- END layout-wrapper*/}

            {/* <!-- Right bar overlay--> */}
            <div className="rightbar-overlay"></div>
        </>
    )
}
