import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../../common/Header";
import Sidebar from "../../../common/Sidebar";
import Footer from "../../../common/Footer";
import PageHeader from "../../PageHeader";
import Loader from "../../../common/Loader";
import {
  getToken,
  triggerAlert,
  getBase64,
  convertDocIdsToStr,
  validateFileSize,
} from "../../../utils";
import AutocompleteSearch from "../../AutocompleteSearch";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";

import AutoUserSearch from "../../AutoUserSearch";
import AutoSearchSelect from "../../CommonSelectDynamic";
import Editor from "../../Editor";
// import '../specialStyles.css';

const Create_tickets = () => {
  const editorConfiguration = {
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "blockQuote",
    ],
  };



  const api_url = process.env.REACT_APP_API_BASE_URL;
  const customer_api_url = process.env.REACT_APP_API_CUSTOMER_URL;
  const token = getToken();
  const [items, setItems] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  //const [customerId, setcustomerId] = useState([]);
  const [ticketCategory, setTicketCategory] = useState(null);
  const [ticketSubCategory, setTicketSubCategory] = useState(null);
  const [ticketStatusList, setTicketStatusList] = useState(null);
  const [ticketPriorityList, setTicketPriorityList] = useState(null);
  const [departmentList, setDepartmentList] = useState(null);
  const [assignUserList, setAssignUserList] = useState(null);
  const [helpDocumentList, setHelpDocumentList] = useState(null);
  const [CCEmailList, setCCEmailList] = useState(null);
  const [checkedEmails, setCheckedEmails] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [ticketName, setTicketName] = useState([]);
  const [editorInstance, setEditorInstance] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    clearErrors,
    reset,
  } = useForm();
  //console.log(token);

  const mainHeading = "Create Tickets";
  const heading = "Support / Create Tickets";

  const handleCheckboxChange = (emailValue) => {
    const updatedCheckedEmails = [...checkedEmails];
    // Toggle the email value in the array
    if (updatedCheckedEmails.includes(emailValue)) {
      updatedCheckedEmails.splice(updatedCheckedEmails.indexOf(emailValue), 1);
    } else {
      updatedCheckedEmails.push(emailValue);
    }

    setCheckedEmails(updatedCheckedEmails);
  };

  // const handleOnFocus = () => {
  //     if (!dataLoaded) {
  //         searchCustomers();
  //         setDataLoaded(true);
  //     }
  // };

  // const handleOnClear = () => {
  //     setValue('user','');
  // };

  // const handleOnSelect1 = (item) => {
  //     setValue('user', item.id);
  //     if (item.id) {
  //         clearErrors('user');
  //         fetchCCEmailList(item.id);
  //     }
  // };

  // let autoCompleteProps = {
  //     items: items,
  //     fuseOptions: { keys: ["user_name"] },
  //     // handleOnSearch: handleOnSearch,
  //     placeholder: 'Search user...',
  //     handleOnSelect: handleOnSelect1,
  //     resultString: "user_name",
  //     // handleOnHover: handleOnHover,
  //     handleOnFocus: handleOnFocus,
  //     handleOnClear: handleOnClear,
  //     // formatResult: formatResult,
  //     // searchQuery: searchQuery,
  // };

  // const searchCustomers = async () => {
  //     try {
  //         const response = await axios.get(api_url + `dashboard/get_all_customers`, token);
  //         const response_data = response.data;
  //         if (response_data.error_code === 200) {
  //             const itemsArray = response_data.results
  //             //console.log('response_itemsArray', itemsArray);
  //             setItems(itemsArray);
  //         }
  //         else if (response.status === 204) {
  //             setItems([]);
  //         } else {
  //             triggerAlert('error', 'Oops...', "Couldn't get the user data");
  //         }
  //     } catch (error) {
  //         triggerAlert("error", "Oops...", "Something went wrong..");
  //     }
  // };

  const fetchTicketCategory = async () => {
    try {
      const response = await axios.get('/apiV1/get_ticket_parent_category/');
      //console.log(response);
      const response_data = response.data;
      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        setTicketCategory(itemsArray);
      } else if (response.status === 204) {
        setTicketCategory([]);
      } else {
        triggerAlert("error", "Oops...", "Couldn't get the ticket category.");
      }
    } catch (error) {
      console.log(error);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const fetchTicketSubCategory = async (id) => {
    try {
      const response = await axios.get(`/apiV1/get_ticket_category?parent_category=${id}`);
      const response_data = response.data.results;
      if (response.status === 200) {
        const itemsArray = response_data;
        setTicketSubCategory(itemsArray);
      } else if (response.status === 204) {
        setTicketSubCategory([]);
      } else {
        triggerAlert("error", "Oops...", "Couldn't get the ticket category.");
      }
    } catch (error) {
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const fetchDepartmentList = async () => {
    try {
      const response = await axios.get('/apiV1/role-dropdown/');
      const response_data = response.data;
      if (response.status === 200) {
        const itemsArray = response_data;
        setDepartmentList(itemsArray);
      } else if (response.status === 204) {
        setDepartmentList([]);
      } else {
        triggerAlert("error", "Oops...", "Couldn't get the department list.");
      }
    } catch (error) {
      console.log(error);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const fetchTicketStatusList = async () => {
    try {
      const response = await axios.get('/apiV1/supports/get_ticket_status_list/');
      //console.log(response);
      const response_data = response.data;
      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        setTicketStatusList(itemsArray);
      } else if (response.status === 204) {
        setTicketStatusList([]);
      } else {
        triggerAlert(
          "error",
          "Oops...",
          "Couldn't get the ticket status list."
        );
      }
    } catch (error) {
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const fetchTicketPriorityList = async () => {
    try {
      const response = await axios.get('/apiV1/supports/get_ticket_priority_list');
      //console.log(response);
      const response_data = response.data;
      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        setTicketPriorityList(itemsArray);
      } else if (response.status === 204) {
        setTicketPriorityList([]);
      } else {
        triggerAlert(
          "error",
          "Oops...",
          "Couldn't get the ticket priority list."
        );
      }
    } catch (error) {
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const handleAssignTo = async (e) => {
    try {
      const department_id = e.target.value;
      const response = await axios.get(`/apiV1/supports/get_all_admin_users_list?role_id=${department_id}`);
      const response_data = response.data;
      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        setAssignUserList(itemsArray);
      } else if (response.status === 204) {
        setAssignUserList([]);
      } else {
        triggerAlert("error", "Oops...", "Couldn't get the assign user list.");
      }
    } catch (error) {
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const fetchHelpDocumentList = async () => {
    try {
      const response = await axios.get(
        api_url + `supports/get_help_documents_list`,
        token
      );
      const response_data = response.data;
      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        const docuOptions = [];
        itemsArray.forEach((element) => {
          docuOptions.push({
            value: element.help_id,
            label: element.help_name,
          });
        });
        setHelpDocumentList(docuOptions);
      } else if (response.status === 204) {
        setHelpDocumentList([]);
      } else {
        triggerAlert(
          "error",
          "Oops...",
          "Couldn't get the help documents list."
        );
      }
    } catch (error) {
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const fetchCCEmailList = async (id) => {
    try {
      const response = await axios.get(`/apiV1/supports/get_cc_email_list?user_id=${id}`);
      const response_data = response.data;
      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        setCCEmailList(itemsArray);
      } else if (response.status === 204) {
        setCCEmailList([]);
      } else {
        triggerAlert("error", "Oops...", "Couldn't get the CC Email list.");
      }
    } catch (error) {
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const handleUser = (selected) => {
    // console.log('ksvk', selected.value);
    setValue("user", selected ? selected.user_id : null);
    if (selected?.user_id) {
      fetchCCEmailList(selected.user_id);
    }
  };

  const handleFileChange = (e, index) => {
    const files = e.target.files;
    if (files) {
      const filesArray = Array.from(files);
      if (filesArray.length > 0) {
        //console.log("ok");
        const fileName = filesArray[0].name.split(".")[0];
        const fileExt = filesArray[0].name.split(".")[1];
        const fileSize = filesArray[0].size;
        //console.log(fileSize);
        // Check file extensions for each selected file
        const maxSizeInBytes = 1024 * 1024; // 1MB
        const allowedExtensions = [
          ".doc",
          ".docx",
          ".pdf",
          ".jpg",
          ".png",
          ".jpeg",
          ".csv",
          ".txt",
          ".xlsx",
          ".mp4",
          ".mov",
          ".avi",
          ".mkv",
          ".wmv",
          ".flv",
          ".webm",
          ".m4v",
          ".3gp",
          ".ogg",
        ];
        //const allowedExtensionsPattern = /\.(docx?|pdf|jpe?g|png|csv|txt|xlsx|mp4|mov|avi|mkv|wmv|flv|webm|m4v|3gp|ogg)$/i;
        const isValidFiles = filesArray.every((file) => {
          //allowedExtensionsPattern.test(file.name.split('.').pop().toLowerCase())
          const fileExtension = file.name.split(".").pop().toLowerCase();
          return allowedExtensions.includes("." + fileExtension);
        });
        //console.log(isValidFiles);
        if (isValidFiles) {
          if (fileSize < maxSizeInBytes) {
            // Convert each selected file to base64
            Promise.all(filesArray.map((file) => getBase64(file))).then(
              (base64Array) => {
                // Set the selectedFiles state with the base64-encoded files
                setSelectedFiles((currentFiles) => {
                  const updatedFiles = [...currentFiles];
                  // updatedFiles[index] = base64Array;
                  updatedFiles[index] = {
                    file_name: fileName,
                    file_type: fileExt,
                    file_size: fileSize,
                    file: base64Array[0],
                  };
                  return updatedFiles;
                });
              }
            );
            document.getElementById("submit").removeAttribute("disabled");
          } else {
            triggerAlert(
              "error",
              "Oops...",
              "File size should not be more than 2MB"
            );
            document.getElementById("submit").setAttribute("disabled", "true");
          }
        } else {
          // Show an error message or handle the invalid file type
          triggerAlert(
            "error",
            "Oops...",
            "Invalid file type. Please select another file."
          );
          document.getElementById("submit").setAttribute("disabled", "true");
        }
      }
      //console.log(imageData);
    }
  };

  const handleCategory = async (e) => {
    //console.log(e.target.value);
    const id = e.target.value;
    if (id > 0) {
      fetchTicketSubCategory(id);
    }
    const selectedCategory = ticketCategory.find(
      (category) => category.id == id
    );
      setTicketName(selectedCategory.parent_cat_name);
    
    // setTicketName(selectedCategory.parent_cat_name);
  };

  const ticketSubmit = async (data) => {
    // console.log("data", data);
    try {
      // setIsLoading(true);
      const formCCEmail = data.cc_mail;

      const newCCEmails = [...checkedEmails, formCCEmail];
      const trimmedCCEmails = newCCEmails
        .map((email) => email.trim())
        .filter((email) => email !== "");
      // console.log("trimmedCCEmails", trimmedCCEmails);
      const uniqueCCEmails = [...new Set(trimmedCCEmails)];
      let ccEmailString = "";
      // console.log("uniqueCCEmails", uniqueCCEmails);
      if (uniqueCCEmails.length === 1) {
        ccEmailString = uniqueCCEmails[0];
      } else {
        ccEmailString = uniqueCCEmails.join(",");
      }
      const params = {
        ticket_category_id: data.ticket_category,
        ticket_sub_category_id: data.ticket_sub_category,
        ticket_desc: data.editorContent,
        ticket_priority: data.ticket_priority,
        ticket_assignto: data.assign_user,
        // 'status': data.ticket_status,
        ticket_name: ticketName,
        ticket_type: ticketName, 
        base64_files: selectedFiles ? selectedFiles : [],
        user_id: data.user,
        cc_email: uniqueCCEmails.join(","),
        // to_email: ccEmailString,
        // cc_email: 'teja.d@pranathiss.com',
        // help_document: convertDocIdsToStr(data.help_documents),
        status: "1", // for Open status
      };
      
      // return;
      const response = await axios.post('/apiV1/supports/create_ticket',params);
      if (response.status === 201) {
        setIsLoading(false);
        triggerAlert("success", "Success", `Ticket Added Successfully!!!`);
        navigate("/support/manage_tickets");
      } else {
        throw new Error("Unable to Add Ticket Successfully!!!");
      }
    } catch (err) {
      triggerAlert("error", "Oops...", err.message);
    }
  };

  const handleEditorChange = (content) => {
    if (content) {
      clearErrors('editorContent');
    }
    setValue('editorContent', content);
  };

  useEffect(() => {
    //searchCustomers();
    fetchTicketCategory();
    fetchDepartmentList();
    fetchTicketStatusList();
    fetchTicketPriorityList();
    // fetchHelpDocumentList();
    // fetchCCEmailList();
  }, []);

  console.log(errors);

  useEffect(() => {
    if (editorInstance) {
      const editor = editorInstance;
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    }
  }, [editorInstance]);



  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div className="main-content">
          {isLoading ? (
            <div className="loader-overlay text-white">
              <Loader />
            </div>
          ) : (
            ""
          )}
          <div className="page-content">
            <div className="container-fluid">
              {/* start page title */}
              <PageHeader mainHeading={mainHeading} heading={heading} />
              {/* end page title */}

              <div className="row  mt-4">
                <div className="col-xl-12">
                  <div className="">
                    <div className="card-body">
                      <form
                        data-select2-id="21"
                        onSubmit={handleSubmit(ticketSubmit)}
                      >
                        <div className="row g-3" data-select2-id="20">
                          <div className="col-md-4" style={{ position: 'relative', zIndex: 100 }}>
                            <label
                              className="form-label"
                              htmlFor="formtabs-first-name"
                            >
                              User <span style={{ color: "red" }}>*</span>
                            </label>
                            {/* <AutocompleteSearch {...autoCompleteProps} {...register('user', { required: 'User is required' })} /> */}
                            <Controller
                              name="user"
                              {...register("user", {
                                required: "User is required",
                              })}
                              control={control}
                              render={({ field }) => (
                                <AutoUserSearch
                                  onSelect={(user) => {
                                    setValue(
                                      "user",
                                      user ? user.user_id : null
                                    );
                                    clearErrors("user");
                                    handleUser(user);
                                    // setcustomerId(user? user.user_id : null);
                                  }}
                                />
                              )}
                            />
                            {errors.user && (
                              <p style={{ color: "red" }}>
                                {errors.user.message}
                              </p>
                            )}
                          </div>
                          <div className="col-md-4" style={{ position: 'relative', zIndex: 100 }}>
                            <label
                              className="form-label"
                              htmlFor="formtabs-first-name"
                            >
                              Ticket Category{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-select"
                              {...register("ticket_category", {
                                required: "Ticket Category is required",
                              })}
                              onChange={(e) => {
                                if (e.target.value) {
                                  clearErrors("ticket_category");
                                }
                                handleCategory(e);
                              }}
                            >
                              <option value="">Select Ticket Category</option>
                              {ticketCategory &&
                                ticketCategory.map((category, index) => (
                                  <option key={index} value={category.id}>
                                    {category.parent_cat_name}
                                  </option>
                                ))}
                            </select>
                            {errors.ticket_category && (
                              <p style={{ color: "red" }}>
                                {errors.ticket_category.message}
                              </p>
                            )}
                          </div>

                          <div className="col-md-4" style={{ position: 'relative', zIndex: 100 }}>
                            <label
                              className="form-label"
                              htmlFor="formtabs-first-name"
                            >
                              Ticket Sub Category{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-select"
                              {...register("ticket_sub_category", {
                                required: "Ticket Sub Category is required",
                              })}
                              onChange={(e) => {
                                if (e.target.value) {
                                  clearErrors("ticket_sub_category");
                                }
                              }}
                            >
                              <option value="">
                                Select Ticket Sub Category
                              </option>
                              {ticketSubCategory &&
                                ticketSubCategory.map((subCategory, index) => (
                                  <option
                                    key={index}
                                    value={subCategory.id}
                                  >
                                    {subCategory.ticket_category_name}
                                  </option>
                                ))}
                            </select>
                            {errors.ticket_sub_category && (
                              <p style={{ color: "red" }}>
                                {errors.ticket_sub_category.message}
                              </p>
                            )}
                          </div>

                          <div className="col-md-4">
                            <label className="form-label">
                              CC Mail
                              {/* <span style={{ color: 'red' }}>*</span> */}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              id="example-number-input"
                              {...register("cc_mail", {
                                // required: 'CC Email is required',
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: "Invalid email address",
                                },
                              })}
                              placeholder="Enter CC Mail"
                            />
                            {errors.cc_mail && (
                              <p style={{ color: "red" }}>
                                {errors.cc_mail.message}
                              </p>
                            )}
                            {/* <p><a href="#">Manage CC List</a></p> */}
                          </div>

                          {/* <div className="col-md-4">
                                                          <label className="form-label" htmlFor="formtabs-first-name">Ticket Status <span style={{ color: 'red' }}>*</span></label>
                                                          <select className="form-select" aria-label="Default select example" {...register('ticket_status', { required: 'Ticket Status is required' })}>
                                                              <option value="">Select Ticket Status</option>
                                                              {ticketStatusList && ticketStatusList.map((status, index) => (
                                                                  <option key={index} value={status.status_id}>{status.name
                                                                  }</option>
                                                              ))}
                                                          </select>
                                                          {errors.ticket_status && <p style={{ color: 'red' }}>{errors.ticket_status.message}</p>}
                                                      </div> */}
                          <div className="col-md-4">
                            <label
                              className="form-label"
                              htmlFor="formtabs-first-name"
                            >
                              Ticket Status{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              {...register(
                                "ticket_status"
                                // { required: 'Ticket Status is required' }
                              )}
                              value={"1"}
                              disabled
                            >
                              <option value="">Select Ticket Status</option>
                              <option value="1">Open</option>
                            </select>
                            {errors.ticket_status && (
                              <p style={{ color: "red" }}>
                                {errors.ticket_status.message}
                              </p>
                            )}
                          </div>

                          <div className="col-md-4" style={{ position: 'relative', zIndex: 100 }}>
                            <label
                              className="form-label"
                              htmlFor="formtabs-first-name"
                            >
                              Ticket Priority{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              {...register("ticket_priority", {
                                required: "Ticket Priority is required",
                              })}
                            >
                              <option value="">Select Ticket Priority</option>
                              {ticketPriorityList &&
                                ticketPriorityList.map((priority, index) => (
                                  <option
                                    key={index}
                                    value={priority.priority_id}
                                  >
                                    {priority.name}
                                  </option>
                                ))}
                            </select>
                            {errors.ticket_priority && (
                              <p style={{ color: "red" }}>
                                {errors.ticket_priority.message}
                              </p>
                            )}
                          </div>

                          <div className="col-md-4" style={{ position: 'relative', zIndex: 20 }}>
                            <label
                              className="form-label"
                              htmlFor="formtabs-first-name"
                            >
                              Department <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              {...register("ticket_department", {
                                required: "Ticket Department is required",
                              })}
                              onChange={(e) => {
                                clearErrors("ticket_department");
                                setValue("ticket_department", e.target.value);
                                handleAssignTo(e);
                              }}
                            >
                              <option value="">Select department</option>
                              {departmentList &&
                                departmentList.map((department, index) => (
                                  <option key={index} value={department.id}>
                                    {department.name}
                                  </option>
                                ))}
                            </select>
                            {errors.ticket_department && (
                              <p style={{ color: "red" }}>
                                {errors.ticket_department.message}
                              </p>
                            )}
                          </div>

                          <div className="col-md-4" style={{ position: 'relative', zIndex: 100 }}>
                            <label className="form-label">
                              Assign To <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              {...register("assign_user", {
                                required: "Assign User is required",
                              })}
                            >
                              <option value="">Select User</option>
                              {assignUserList &&
                                assignUserList.map((assignUser, index) => (
                                  <option key={index} value={assignUser.id}>
                                    {assignUser.username}, {assignUser.email} 
                                  </option>
                                ))}
                            </select>
                            {errors.assign_user && (
                              <p style={{ color: "red" }}>
                                {errors.assign_user.message}
                              </p>
                            )}
                          </div>

                          {/* <div className="col-md-4" style={{ position: 'relative', zIndex: 100 }}>
                            <label className="form-label">
                              Customer Help Documents{" "}
                            </label>
                            <Select
                              isMulti
                              options={helpDocumentList}
                              {...register("help_documents")}
                              onChange={(selectedOptions) => {
                                if (selectedOptions) {
                                  clearErrors("help_documents");
                                }
                                // Set the value in the form state when the selection changes
                                setValue("help_documents", selectedOptions);
                              }}
                            />
                          </div> */}

                          <div className="row">
                            {/* <div className="col-lg-12"> */}
                            {/* <div className="col-md-12"> */}
                            <label className="form-label mt-2 mb-2">
                              CC Emails
                            </label>
                            {CCEmailList &&
                              CCEmailList.map((emailList, index) => (
                                <div
                                  key={emailList.value}
                                  className="col-md-4 mb-1"
                                >
                                  <input
                                    type="checkbox"
                                    key={index}
                                    value={emailList.email_id}
                                    id={emailList.email_id}
                                    checked={checkedEmails.includes(
                                      emailList.email_id
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(emailList.email_id)
                                    }
                                    className="m-1"
                                  />
                                  <label htmlFor={emailList.email_id}>
                                    {emailList.email_id}
                                  </label>
                                </div>
                              ))}
                            {/* {!isAtLeastOneCheckboxChecked() && <div style={{ color: 'red' }}>Select at least one email</div>} */}
                            {/* </div> */}
                            {/* </div> */}
                          </div>

                          <div className="row mt-2">
                            <div className="col-lg-12">
                              <div className="card">
                                <div className="card-header">
                                  <h4 className="card-title">
                                    Ticket Description{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </h4>
                                </div>
                                <div className="card-body">
                                  <Controller
                                    name="editorContent"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Content is required" }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <Editor

                                          onChange={(content) => {
                                            field.onChange(content); // Update form value with editor content
                                            handleEditorChange(content); // Handle editor data change
                                          }}
                                          value={field.value} // Ensure the editor value reflects the form value
                                        />


                                        <div style={{ color: "red" }}>
                                          {fieldState?.error?.message}
                                        </div>
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mb-4 mt-4 row">
                            {[0, 1, 2, 3].map((index) => (
                              <div key={index} className="col-md-3">
                                <label
                                  className="form-label"
                                  htmlFor={`formFile-${index}`}
                                >
                                  Upload
                                </label>
                                {/* <input
                                                                      className="form-control mb-1"
                                                                      type="file"
                                                                      id={`formFile-${index}`}
                                                                      accept=".docx, .pdf, .jpg, .jpeg, .png, .csv, .txt, .xlsx, .mp4, .mov, .avi, .mkv, .wmv, .flv, .webm, .m4v, .3gp, .ogg"
                                                                      {...register(`file${index}`, {
                                                                          validate: {
                                                                              validFormat: (value) => {
                                                                                  if (value[0]?.name) {
                                                                                      const allowedExtensions = /\.(docx?|pdf|jpe?g|png|csv|txt|xlsx|mp4|mov|avi|mkv|wmv|flv|webm|m4v|3gp|ogg)$/i;
                                                                                      if (!value) return true; // Allow empty files
                                                                                      if (!allowedExtensions.test(value[0]?.name)) {
                                                                                          return 'Invalid file format. Please upload a valid file.';
                                                                                      }
                                                                                  }
                                                                                  return true;
                                                                              },
                                                                              validSize: validateFileSize
                                                                          }
                                                                      })}
                                                                      onChange={(e) => handleFileChange(e, index)}
                                                                  /> */}
                                <input
                                  className="form-control mb-1"
                                  type="file"
                                  id={`formFile-${index}`}
                                  accept=".docx, .doc, .pdf"
                                  {...register(`file${index}`, {
                                    validate: {
                                      validFormat: (value) => {
                                        if (value[0]?.name) {
                                          const allowedExtensions =
                                            /\.(docx?|pdf)$/i;
                                          if (!value) return true; // Allow empty files
                                          if (
                                            !allowedExtensions.test(
                                              value[0]?.name
                                            )
                                          ) {
                                            return "Invalid file format. Please upload a valid file.";
                                          }
                                        }
                                        return true;
                                      },
                                      validSize: (value) => {
                                        if (value[0]?.size) {
                                          const maxSize = 2 * 1024 * 1024; // 2 MB in bytes
                                          if (value[0].size > maxSize) {
                                            return "File size exceeds the limit (2 MB). Please upload a smaller file.";
                                          }
                                        }
                                        return true;
                                      },
                                    },
                                  })}
                                  onChange={(e) => handleFileChange(e, index)}
                                />
                                {errors[`file${index}`] && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {errors[`file${index}`].message}
                                  </div>
                                )}
                                {/* Additional logic can be added here to display file information or errors */}
                              </div>
                            ))}
                            <div>
                              <p className="text-danger">
                                **Note: (Upload .doc,.docx, .pdf only and upto 2
                                MB)
                              </p>
                            </div>

                            <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4 mb-3">
                              <button
                                className="btn btn-primary w-md"
                                type="submit"
                                id="submit"
                              >
                                Create Ticket
                              </button>
                              <button
                                className="btn btn-warning w-md me-md-2"
                                type="button"
                                onClick={() => reset()}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Page-content */}
          <Footer />
        </div>
        {/* end main content*/}
      </div>
      {/* END layout-wrapper */}
      {/* Right bar overlay*/}
      <div className="rightbar-overlay"></div>
    </div>
  );
};

export default Create_tickets;
