import React, { useState, useEffect } from "react";
import Header from "../../common/Header";
import Sidebar from "../../common/Sidebar";
import Footer from "../../common/Footer";
import PageHeader from "../PageHeader";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import {
  ConfirmationAlert,
  capitalizeFirst,
  getToken,
  get_user_menu_permission,
  pageReload,
  simpleAlert,
  triggerAlert,
} from "../../utils";
import { Table } from "react-bootstrap";
import Loader from "../../common/Loader";
import Pagination from "../PaginationComponent";
import AutoUserSearch from "../AutoUserSearch";
import AutoSearchSelect from "../CommonSelectDynamic";
import CommonSelect from "../CommonSelectStatic";
import { didType, localOpt, menuIdData } from "../../utils/constants";
import RateCenterSearch from "./rateCenterSearch";
import IntlRateCenterSearch from "./intlrateCenterSearch";
export default function OrderDids() {
  const api_url = process.env.REACT_APP_API_BASE_URL;
  const api_url_admin = process.env.REACT_APP_API_BASE_URL;
  const customer_api_url = process.env.REACT_APP_API_CUSTOMER_URL;
  const show50Entries = Number(process.env.REACT_APP_API_SHOW_ENTRIES);
  const token = getToken();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
    control,
  } = useForm();
  const [isLoading, setIsLoading] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const [data, setData] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  // const [routes, setRoutes] = useState([]);
  const [rateCenterData, setRateCenterData] = useState([]);
  const [orderlist, setOrderlist] = useState([]);
  const [orderDidRate, setOrderDidRate] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isButtollDisabled, setIsButtollDisabled] = useState(true);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [selectValue, setSelectValue] = useState("");
  const [customerId, setcustomerId] = useState(null);
  // const [items, setItems] = useState([]);
  // const [dataLoaded, setDataLoaded] = useState(false);
  const [sessKey, setSessKey] = useState("");
  const [formData, setFormData] = useState({
    searchselect: "",
    stateselect: "",
    ratecenter: "",
    npanxxx: "",
  });
  const [orderedDids, setOrderedDids] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showOrderDidPage, setShowOrderDidPage] = useState(true);
  const [showOrderConfirmed, setShowOrderConfirmed] = useState(false);

  const types = selectedTab;
  const mainHeading = showOrderDidPage
    ? "Order DIDs"
    : !showOrderDidPage && !showOrderConfirmed
      ? "Selected DIDs"
      : showOrderConfirmed
        ? "Ordered DIDs"
        : "Order DIDs";
  const heading = `DIDs / ${showOrderDidPage
    ? "Order DIDs"
    : !showOrderDidPage && !showOrderConfirmed
      ? "Selected DIDs"
      : showOrderConfirmed
        ? "Ordered DIDs"
        : "Order DIDs"
    }`;

  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageSlNo, setPageSlNo] = useState(0);
  const [perPageLimit, setPerPageLimit] = useState(show50Entries);
  // const [pageLimitSelected, setPageLimitSelected] = useState(50);
  const [countryData, setCountryData] = useState([]);
  const [intlRatecenterData, setIntlRatecenterData] = useState([]);
  const [isIntlOrder, setIsIntlOrder] = useState(true);
  // Menu Permissions
  const [addPermission, setAddPermission] = useState(false);

  const handleTabChange = (selectedOption) => {
    // console.log("handleTabChange", selectedOption);
    setValue('did_type', selectedOption ? selectedOption.value : null)
    setSelectedTab(selectedOption ? selectedOption.value : null);
    const tab = selectedOption ? selectedOption.value : null;
    if (tab === "local") {
      setOrderlist([]);
      setPageCount(0);
      setRateCenterData([]);
      setSessKey("");
      setSelectedCountry("");
      setShowTable(false);
      setIsButtonDisabled(true);
      setSearchQuery("");
      setSelectedCheckboxes({});
      setCurrentPage(0);
      setShowOrderConfirmed(false);
    } else if (tab === "toll_free") {
      setOrderlist([]);
      setPageCount(0);
      setRateCenterData([]);
      setSessKey("");
      setShowTable(false);
      setIsButtollDisabled(true);
      setSearchQuery("");
      setCurrentPage(0);
      setSelectedCheckboxes({});
      setShowOrderConfirmed(false);
      // Reset the form data to clear the keyword input field
      // reset();
      setValue("keyword", "");
      setValue("keywordCus", "");
      setValue("tollfree_type", "");
    } else if (tab === "vfax") {
      setOrderlist([]);
      setPageCount(0);
      setRateCenterData([]);
      setSessKey("");
      setSelectedCountry("");
      setShowTable(false);
      setIsButtonDisabled(true);
      setSearchQuery("");
      setCurrentPage(0);
      setSelectedCheckboxes({});
      setShowOrderConfirmed(false);
    } else if (tab === "international") {
      setOrderlist([]);
      setPageCount(0);
      setRateCenterData([]);
      setSessKey("");
      setShowTable(false);
      setIsIntlOrder(true);
      setSearchQuery("");
      setCurrentPage(0);
      setSelectedCheckboxes({});
      // Reset the form data to clear the keyword input field
      setShowOrderConfirmed(false);
      fetchInternationalCountryList();
      // reset();
      setValue("internationalCountrySelect", "");
      setValue("intlRatecenterSelect", "");
    }
  };

  const fetchStateData = async () => {
    // setIsLoading(true);
    try {
      const response = await axios.get(
        customer_api_url + `services/available_state_list`,
        token
      );
      const response_data = response.data;
      // setIsLoading(false);
      if (response_data.error_code === 200) {
        const itemsArray = Object.values(response_data.results);
        setData(itemsArray);
      } else if (response.status === 204) {
        setData([]);
        // setIsLoading1(false);
      } else {
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
    } catch (error) {
      // setIsLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };
  // International order

  const fetchInternationalCountryList = async () => {
    // setIsLoading(true);
    try {
      const response = await axios.get(
        customer_api_url + `services/get_international_country_list`,
        token
      );
      const response_data = response.data;
      if (response_data.error_code === 200) {
        const itemsArray = response_data.results;
        setCountryData(itemsArray);
      } else if (response.status === 204) {
        setCountryData([]);
        // setIsLoading(false);
      } else {
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
    } catch (error) {
      // setIsLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };
  const getInternationalrateCenter = async (country_select) => {
    setIsLoading(true);
    try {
      const api_input = {
        country: country_select.st,
        country_name: country_select.country,
        customer_id: customerId,
      };
      const response = await axios.post(
        customer_api_url + `services/get_international_ratecenters_list`,
        api_input,
        token
      );

      const response_data = response.data;
      if (response_data.error_code === 200) {
        let itemsArray = [];
        const rcValue = response_data.results.rc;
        const serviceCharge = response_data.results.service_charge;
        setIsLoading(false);
        if (Array.isArray(rcValue)) {
          // If rcValue is an array, use it directly
          itemsArray.rc = rcValue;
        } else if (typeof rcValue === "string") {
          // If rcValue is a string, convert it to an array with a single element
          itemsArray.rc = [rcValue];
        }
        itemsArray.service_charge = serviceCharge;
        setIntlRatecenterData(itemsArray);
      } else if (response.status === 204) {
        setIntlRatecenterData([]);

        setIsLoading(false);
      } else {
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
    } catch (error) {
      setIsLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const internationslCountrySelection = (selected) => {
    // console.log('internationslCountrySelection', selected)

    watchInlCountry = null;
    setValue('internationalCountrySelect', selected ? selected.value : null);

    setValue('intlRatecenterSelect', null);
    // console.log('intlRatecenterSelect', watch('intlRatecenterSelect'))
    setOrderlist([]);
    setSessKey("");
    setPageCount(0);
    setSearchQuery("");
    setCurrentPage(0);
    setSelectedCheckboxes({});
    setIsIntlOrder(true);
    // const selectedCountry = JSON.parse(event.target.value);
    // if (selectedCountry) {
    //   getInternationalrateCenter(selectedCountry);
    // }
  };

  const intRateCenterSelection = (selected) => {
    // console.log('intlRatecenterSelect', selected ? selected.value : null);
    setValue('intlRatecenterSelect', selected ? selected.value : null);
    setOrderlist([]);
    setSessKey("");
    setPageCount(0);
    setSearchQuery("");
    setCurrentPage(0);
    setSelectedCheckboxes({});
    const intratecenter = selected ? selected.value : null;
    if (intratecenter === '') {
      setIsIntlOrder(true);
    } else {
      setIsIntlOrder(false);
    }
  };

  const intlOrder = async (data) => {
    // console.log("datainIntlOrder", data);
    // const selectedCountry = JSON.parse(data.internationalCountrySelect);
    // const selectedRateCenter = JSON.parse(data.intlRatecenterSelect);
    const selectedCountry = data.internationalCountrySelect;
    const selectedRateCenter = data.intlRatecenterSelect;

    ConfirmationAlert(
      // There will be a $${selectedRateCenter.serviceCharge} fee for each DID.
      `
       Would you like to continue ?`,
      "Continue",
      async () => {
        setIsLoading(true);
        try {
          const api_input = {
            country: selectedCountry.st,
            country_name: selectedCountry.country,
            ratecenter: selectedRateCenter,
            customer_id: customerId,
          };
          const response = await axios.post(
            customer_api_url + `services/order_international_did`,
            api_input,
            token
          );
          setIsLoading(false);
          const response_data = response.data;
          if (response_data.error_code === 200) {
            const OrderedNumbers = response_data.results;
            setOrderedDids(OrderedNumbers);
            setShowOrderConfirmed(true);
          } else if (response.status === 204) {
            setOrderedDids([]);
            setIsLoading(false);
          } else {
            triggerAlert("error", "Oops...", "Something went wrong..");
          }
        } catch (error) {
          setIsLoading(false);
          console.log(error);
          triggerAlert("error", "Oops...", "Something went wrong..");
        }
      }
    );
  };

  // International order end

  const fetchRateCenterData = async (stateSelected) => {
    // setIsLoading(true);
    const api_input = {
      state: stateSelected,
    };
    try {
      const response = await axios.post(
        customer_api_url + `services/ratecenter_list`,
        api_input,
        token
      );
      const response_data = response.data;
      // setIsLoading(false);
      if (response_data.error_code === 200) {
        const itemsArray = Object.values(response_data.results);
        setRateCenterData(itemsArray);
      } else if (response.status === 204) {
        setRateCenterData([]);
        // setIsLoading(false);
      } else {
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
    } catch (error) {
      // setIsLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  const handleDropdownChange = (selectedOpt) => {
    setValue('searchselect', selectedOpt ? selectedOpt.value : null)
    const selectedValue = selectedOpt ? selectedOpt.value : null;
    setSelectValue(selectedOpt ? selectedOpt.value : null);
    if (selectedValue === "state") {
      // fetchStateData();
      // setValue("state", "");
      setIsButtonDisabled(true);
      setOrderlist([]);
      setSessKey("");
      setPageCount(0);
      setSearchQuery("");
      setCurrentPage(0);
      setSelectedCheckboxes({});
    } else if (selectedValue === "areacode") {
      setValue("ratecenter", "");
      setIsButtonDisabled(true);
      setOrderlist([]);
      setSessKey("");
      setPageCount(0);
      setSearchQuery("");
      setCurrentPage(0);
      setSelectedCheckboxes({});
    } else if (selectedValue === "npanxxx") {
      setValue("npanxxx", "");
      setValue("ratecenter", "");
      setIsButtonDisabled(true);
      setOrderlist([]);
      setSessKey("");
      setPageCount(0);
      setSearchQuery("");
      setCurrentPage(0);
      setSelectedCheckboxes({});
    }
    setSelectedCountry(selectedValue);
  };

  const stateselection = (selected) => {

    setValue('rateCenterSelect', '') //to call rateCenterSelection api everytime 
    setOrderlist([]);
    setSessKey("");
    setPageCount(0);
    setSearchQuery("");
    setCurrentPage(0);
    setSelectedCheckboxes({});
    setValue("stateselect", selected ? selected.value : null);
    const state = selected ? selected.value : null;
    if (state === "") {
      setIsButtonDisabled(true);
    } else {
      // fetchRateCenterData(state);
      setIsButtonDisabled(true);
    }
  };

  const rateCenterSelection = (selected) => {
    setOrderlist([]);
    setSessKey("");
    setPageCount(0);
    setSearchQuery("");
    setCurrentPage(0);
    setSelectedCheckboxes({});

    const stateselect = selected ? selected.value : null;
    setValue('rateCenterSelect', stateselect)

    if (stateselect === "") {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };

  // const tollfreeTypeChange = () => {
  //     setOrderlist([]);
  //     setSessKey('')
  //     setPageCount(0);
  //     setSearchQuery('');
  //     setCurrentPage(0);
  //     setSelectedCheckboxes({})

  // };

  const areaselection = (event) => {
    setOrderlist([]);
    setSessKey("");
    setPageCount(0);
    setSearchQuery("");
    setCurrentPage(0);
    setSelectedCheckboxes({});

    const codes = event.target.value?.trim();
    // console.log("areaselection", codes)

    if (codes === "") {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
      setValue('ratecenter', codes)
    }
  };

  const npaselection = (event) => {
    setOrderlist([]);
    setSessKey("");
    setPageCount(0);
    setSearchQuery("");
    setCurrentPage(0);
    setSelectedCheckboxes({});

    const npa = event.target.value?.trim();
    setValue('npa', npa);
    if (npa === "") {
      setIsButtonDisabled(true);
    }
    // else {
    //     setIsButtonDisabled(false);
    // }
  };

  const npxselection = (event) => {
    setOrderlist([]);

    setSessKey("");
    setPageCount(0);
    setSearchQuery("");
    setCurrentPage(0);
    setSelectedCheckboxes({});

    const nxx = event.target.value?.trim();
    setValue('nxx', nxx)
    if (nxx === "") {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };

  const keywordsearch = (selectedOption) => {
    setValue('keyword', selectedOption ? selectedOption.value : null);
    setSessKey("");
    setSearchQuery("");
    setOrderlist([]);
    setPageCount(0);
    setCurrentPage(0);
    setSelectedCheckboxes({});

    const tollfree = selectedOption ? selectedOption.value : null;
    if (tollfree === "") {
      setIsButtollDisabled(true);
    } else {
      setIsButtollDisabled(false);
    }
  };
  const keywordCustom = (event) => {
    setSessKey("");
    setSearchQuery("");
    setOrderlist([]);
    setPageCount(0);
    setCurrentPage(0);
    setSelectedCheckboxes({});

    const tollfreeCus = event.target.value?.trim();
    setValue('keywordCus', tollfreeCus)
    if (tollfreeCus === "") {
      setIsButtollDisabled(true);
    } else {
      setIsButtollDisabled(false);
    }
  };

  const onSubmit = async (data) => {
    // console.log(data, "OnsubmitData");
    setFormData(data);
    switch (types) {
      case "local":
        serviceSearch(data, currentPage + 1);
        break;
      case "toll_free":
        tollfreeSearch(data, currentPage + 1);
        break;
      case "vfax":
        vfaxSearch(data, currentPage + 1);
        break;
      // case 'international':
      //     serviceSearch(data, currentPage + 1);
      //     break;

      default:
        break;
    }
  };



  // order localDids
  const serviceSearch = async (data, page, searchkey) => {
    setPerPageLimit(perPageLimit);

    // setSelectedCheckboxes({});

    const per_page = perPageLimit;

    try {
      setShowTable(true);
      setIsLoading(true);
      setPageSlNo((page - 1) * per_page);
      if (data.searchselect === "areacode") {
        const datas = {
          customer_id: customerId,
          type: data.searchselect,
          areacode: data.ratecenter,
          sess_key: sessKey,
          page: page,
          per_page: per_page,
          search_number: searchkey ? searchkey : "",
        };
        const response = await axios.post(
          customer_api_url + "services/local_did_list",
          datas,
          token
        );
        const response_data = response.data;
        setIsLoading(false);
        const total_pages = response_data.results.pagination.total_pages;
        if (response_data.error_code === 200) {
          setPageCount(total_pages);
          const orderArray = Object.values(response_data.results.data);
          setOrderlist(orderArray);
          setOrderDidRate(response_data.results.user_rate_data);
          setSessKey(response_data.results.sess_key);
        } else if (response.status === 204) {
          setPageCount(0);
          setOrderlist([]);
          setOrderDidRate([]);
          setSessKey("");
        }
      } else if (data.searchselect === "state") {
        const datas = {
          state: data.stateselect,
          customer_id: customerId,
          type: data.searchselect,
          sess_key: sessKey,
          page: page,
          per_page: per_page,
          search_number: searchkey ? searchkey : "",
          rate_center: data.rateCenterSelect,
        };
        const response = await axios.post(
          customer_api_url + "services/local_did_list",
          datas,
          token
        );
        const response_data = response.data;
        setIsLoading(false);
        const total_pages = response_data.results.pagination.total_pages;
        if (response_data.error_code === 200) {
          setPageCount(total_pages);
          const orderArray = Object.values(response_data.results.data);
          setOrderlist(orderArray);
          setOrderDidRate(response_data.results.user_rate_data);
          setSessKey(response_data.results.sess_key);
        } else if (response.status === 204) {
          setPageCount(0);
          setOrderlist([]);
          setOrderDidRate([]);
          setSessKey("");
        }
      } else if (data.searchselect === "npanxxx") {
        const datas = {
          customer_id: customerId,
          type: data.searchselect,
          areacode: data.npa,
          npanxxx: data.nxx,
          sess_key: sessKey,
          page: page,
          per_page: per_page,
          search_number: searchkey ? searchkey : "",
        };

        const response = await axios.post(
          customer_api_url + "services/local_did_list",
          datas,
          token
        );
        const response_data = response.data;

        setIsLoading(false);
        const total_pages = response_data.results.pagination.total_pages;
        if (response_data.error_code === 200) {
          setPageCount(total_pages);
          const orderArray = Object.values(response_data.results.data);
          setOrderlist(orderArray);
          setOrderDidRate(response_data.results.user_rate_data);
          setSessKey(response_data.results.sess_key);
        } else if (response.status === 204) {
          setPageCount(0);
          setOrderlist([]);
          setOrderDidRate([]);
          setSessKey("");
        }
      } else {
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  //order tollfreeDids
  const tollfreeSearch = async (data, page, searchkey) => {
    setShowTable(true);
    // setSelectedCheckboxes({});
    setIsLoading(true);
    const per_page = perPageLimit;
    try {
      setPageSlNo((page - 1) * per_page);
      if (data.tollfree_type === "tollfree" && data.keyword !== "") {
        const toll_free = {
          keyword: data.keyword,
          customer_id: customerId,
          sess_key: sessKey,
          page: page,
          per_page: per_page,
          search_number: searchkey ? searchkey : "",
        };
        const response = await axios.post(
          customer_api_url + "services/tollfree_did_list",
          toll_free,
          token
        );
        const response_data = response.data;
        setIsLoading(false);
        const total_pages = response_data.results.pagination.total_pages;
        if (response_data.error_code === 200) {
          setPageCount(total_pages);
          const orderArray = Object.values(response_data.results.data);
          setOrderlist(orderArray);
          setOrderDidRate(response_data.results.user_rate_data);
          setSessKey(response_data.results.sess_key);
        } else if (response.status === 204) {
          setPageCount(0);
          setOrderlist([]);
          setOrderDidRate([]);
          setSessKey("");
        }
      } else if (
        data.tollfree_type === "custom_tollfree" &&
        data.keywordCus !== ""
      ) {
        const toll_free = {
          keyword: data.keywordCus,
          customer_id: customerId,
          sess_key: sessKey,
          page: page,
          per_page: per_page,
          search_number: searchkey ? searchkey : "",
        };
        const response = await axios.post(
          customer_api_url + "services/get_tollfree_vanity",
          toll_free,
          token
        );
        const response_data = response.data;
        setIsLoading(false);
        const total_pages = response_data.results.pagination.total_pages;
        if (response_data.error_code === 200) {
          setPageCount(total_pages);
          const orderArray = Object.values(response_data.results.data);
          setOrderlist(orderArray);
          setOrderDidRate(response_data.results.user_rate_data);
          setSessKey(response_data.results.sess_key);
        } else if (response.status === 204) {
          setPageCount(0);
          setOrderlist([]);
          setOrderDidRate([]);
          setSessKey("");
        }
      } else {
        setIsLoading(false);
        triggerAlert("error", "Oops...", "Something went wrong...");
      }
    } catch (error) {
      setIsLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong...");
    }
  };

  //for VFax orderDids
  const vfaxSearch = async (data, page, searchkey) => {

    setShowTable(true);
    // setSelectedCheckboxes({});
    setIsLoading(true);
    const per_page = perPageLimit;

    try {
      setPageSlNo((page - 1) * per_page);
      if (data.searchselect === "state") {
        const datas = {
          state: data.stateselect,
          customer_id: customerId,
          type: data.searchselect,
          sess_key: sessKey,
          page: page,
          per_page: per_page,
          search_number: searchkey ? searchkey : "",
          rate_center: data.rateCenterSelect,
        };
        const response = await axios.post(
          customer_api_url + "services/vfax_did_list",
          datas,
          token
        );
        const response_data = response.data;
        setIsLoading(false);
        const total_pages = response_data.results.pagination.total_pages;
        if (response_data.error_code === 200) {
          setPageCount(total_pages);
          const orderArray = Object.values(response_data.results.data);
          setOrderlist(orderArray);
          setOrderDidRate(response_data.results.user_rate_data);
          setSessKey(response_data.results.sess_key);
        } else if (response.status === 204) {
          setPageCount(0);
          setOrderlist([]);
          setOrderDidRate([]);
          setSessKey("");
        }
      }
      // else if (data.searchselect === 'areacode') {
      //     const datas = {
      //         customer_id: String(customer_id),
      //         type: data.searchselect,
      //         areacode: data.ratecenter,
      //         sess_key: sessKey,
      //         page: page,
      //         per_page: per_page,
      //         search_number: searchkey ? searchkey : ''
      //     };
      //     const response = await axios.post(customer_api_url + 'services/vfax_did_list', datas);
      //     const response_data = response.data;
      //     setIsLoading(false);
      //     const total_pages = response_data.results.pagination.total_pages;
      //     if (response_data.error_code === 200) {
      //         setPageCount(total_pages);
      //         const orderArray = Object.values(response_data.results.data);
      //         setOrderlist(orderArray);
      //         setOrderDidRate(response_data.results.user_rate_data);
      //         setSessKey(response_data.results.sess_key);
      //     }
      // }
      // else if (data.searchselect === 'npanxxx') {
      //     const datas = {
      //         customer_id: String(customer_id),
      //         type: data.searchselect,
      //         areacode: data.npa,
      //         npanxxx: data.nxx,
      //         sess_key: sessKey,
      //         page: page,
      //         per_page: per_page,
      //         search_number: searchkey ? searchkey : ''
      //     };

      //     const response = await axios.post(customer_api_url + 'services/vfax_did_list', datas);
      //     const response_data = response.data;

      //     setIsLoading(false);
      //     const total_pages = response_data.results.pagination.total_pages;
      //     if (response_data.error_code === 200) {
      //         setPageCount(total_pages);
      //         const orderArray = Object.values(response_data.results.data);
      //         setOrderlist(orderArray);
      //         setOrderDidRate(response_data.results.user_rate_data);
      //         setSessKey(response_data.results.sess_key);
      //     }
      // }
      else {
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
    } catch (error) {
      setIsLoading(false);
      triggerAlert("error", "Oops...", "Something went wrong..");
    }
  };

  //for ordering numbers after confirmation
  const handleOrderConfirming = async () => {
    const selectedValues = Object.values(selectedCheckboxes).filter(
      (checkbox) => checkbox.isChecked
    );

    const datasArray = [];
    const customTollfree = watch("tollfree_type");
    const insert_order = {
      customer_id: customerId,
      line_type: types,
      custom_tollfree_status:
        customTollfree === "custom_tollfree" ? true : false,
      selected_did: [],
    };
    for (const value of selectedValues) {
      // Perform an action for each selected value
      const datas = {
        tn: value["number"],
        provider: value["provider"],
      };
      datasArray.push(datas);
    }
    insert_order["selected_did"] = datasArray;

    setIsLoading(true);
    try {
      const response = await axios.post(
        customer_api_url + "services/order_did",
        insert_order,
        token
      );
      // const response = await axios.post(customer_api_url + 'services/order_did_mail', insert_order, token);

      const response_data = response.data;
      setIsLoading(false);
      if (response_data.error_code === 200) {
        setSelectedCheckboxes({});

        const OrderedNumbers = response_data.results;
        setOrderedDids(OrderedNumbers);
        setShowOrderConfirmed(true);

        // Call Routes API and store the response data in routes state
        // try {
        //     const api_input = {
        //         u: "admin",
        //         user_id: 885
        //     };
        //     const routesResponse = await axios.post(customer_api_url + `switches/list/`, api_input);
        //     const routesData = routesResponse.data;
        //     console.log('routeData', routesData)
        //     if (routesData.error_code === 200) {
        //         // Assuming routesData.results contains the routes array
        //         console.log('rotesIP', routesData.results.page.devices.device.ipaddr)
        //         const itemsArray = Object.values(routesData.results.page.devices.device);

        //         setRoutes(itemsArray);
        //     } else {
        //         // Handle error if necessary
        //         triggerAlert('error', 'Oops...', 'Unable to get Routes..');
        //     }
        // } catch (routesError) {
        //     // Handle error if necessary
        //     triggerAlert('error', 'Oops...', 'Something went wrong..');
        // }
        // triggerAlert('succees', 'Success', "Successfully ordered the number.");
      } else if (response.status === 204) {
        setSelectedCheckboxes({});
      } else {
        setSelectedCheckboxes({});
        triggerAlert("error", "Oops...", "Something went wrong..");
      }
    } catch (error) {
      setSelectedCheckboxes({});
      setIsLoading(false);
      triggerAlert("error", "Oops...", error.response.data.message);
    }
  };

  //checkbox func
  const handleCheckboxChange = (event, number, provider) => {
    const isChecked = event.target.checked;

    setSelectedCheckboxes((prevState) => {
      // Create a copy of the previous state
      const updatedState = { ...prevState };

      // If checkbox is checked, add it to the state
      if (isChecked) {
        updatedState[number] = {
          number: number,
          provider: provider,
          isChecked: true,
        };
      } else {
        // If checkbox is unchecked, remove it from the state
        delete updatedState[number];
      }

      // Return the updated state
      return updatedState;
    });
  };
  const handleClearClick = () => {
    reset();
    setValue("did_type", "");
    // setValue("tollfree_type", " ");
    // setValue("keyword", " ");
    setcustomerId(null);
    // setValue("searchselect", " ");
    // setValue("stateselect", " ");
    // setValue("rateCenterSelect", " ");
    // setValue("internationalCountrySelect", " ");
    // setValue("intlRatecenterSelect", " ");
    // setValue("ratecenter", " ");
    setValue("customer_id", '');
    // setForceUpdate((prevState) => !prevState);
    setPageCount(0);
    setOrderlist([]);
    setOrderDidRate([]);
    setSessKey("");

    //setSelectedOption('');

    setSelectValue(" ");
    setSelectedCheckboxes({});
    setSelectedTab("");
    setShowTable(false);
  };

  const handleCheckAllChange = (event) => {
    const isChecked = event.target.checked;
    const newSelectedCheckboxes = {};
    if (isChecked) {
      orderlist.forEach((row) => {
        newSelectedCheckboxes[row.number] = {
          number: row.number,
          provider: row.provider,
          isChecked: true,
        };
      });
    }

    setSelectedCheckboxes(newSelectedCheckboxes);
  };

  //for bulk order button
  const handleBulkOrderClick = () => {
    // setSelectedCheckboxes({});
    const selectedCheckboxesKeys = Object.keys(selectedCheckboxes);
    // console.log(selectedCheckboxesKeys);
    if (selectedCheckboxesKeys.length > 0) {
      setShowOrderDidPage(!showOrderDidPage); //hide OrderDidPage
    } else {
      simpleAlert("Please Select Atleast One DID");
      setShowOrderDidPage(showOrderDidPage);
    }
  };

  //back func from OrderConfirm
  const handleOrderConfmBack = () => {
    pageReload();
  };

  const handlePageClick = (selected) => {
    const selectedPage = selected.selected;

    setCurrentPage(selectedPage);

    switch (types) {
      case "local":
        serviceSearch(formData, selectedPage + 1); // Increment the page number by 1 for server-side pagination
        break;
      case "toll_free":
        tollfreeSearch(formData, selectedPage + 1); // Increment the page number by 1 for server-side pagination
        break;
      case "vfax":
        vfaxSearch(formData, selectedPage + 1);
        break;
      // case 'international':
      //     serviceSearch(data, currentPage + 1);
      //     break;

      default:
        break;
    }
  };

  // const handlePageChange = event => {
  //     console.log('handlePageChangeEvent', event.target.value);
  //     const noOfEntries = Number(event.target.value);
  //     if (event.target.value) {
  //         console.log('Number(event.target.value)', Number(event.target.value))
  //         setPerPageLimit(noOfEntries);
  //         setPageLimitSelected(noOfEntries);
  //     } else {
  //         setPerPageLimit(25);
  //         setPageLimitSelected(25);
  //     }

  //     // console.log('perPageLimit- handlePageChangeEvent', perPageLimit);
  //     // if (perPageLimit) {

  //     //     switch (types) {
  //     //         case 'local':
  //     //             serviceSearch(formData, 1);
  //     //             break;
  //     //         case 'toll_free':
  //     //             tollfreeSearch(formData, 1);
  //     //             break;
  //     //         case 'vfax':
  //     //             vfaxSearch(formData, 1);
  //     //             break;
  //     //         // case 'international':
  //     //         //     serviceSearch(formData, 1);
  //     //         //     break;

  //     //         default:
  //     //             break;
  //     //     }
  //     // }

  // };

  let pgntn_props = {
    pageCount: pageCount,
    handlePageClick: handlePageClick,
    selectedPage: currentPage,
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    const searchkey = e.target.value;

    switch (types) {
      case "local":
        serviceSearch(formData, 1, searchkey); // Update search results on every change
        break;
      case "toll_free":
        tollfreeSearch(formData, 1, searchkey); // Update search results on every change
        break;
      case "vfax":
        vfaxSearch(formData, 1, searchkey); // Update search results on every change
        break;
      // case 'international':
      //     serviceSearch(formData, 1, searchkey);
      //     break;

      default:
        break;
    }
  };

  const handleUserSelect = (user) => {
    reset();
    // You can use the userData in your logic
    // console.log("userData", user, user.user_id);
    setcustomerId(user ? user.user_id : null);
    setValue("customer_id", user ? user.user_id : null);
    setValue("did_type", "");
    setSelectedTab("");

    setOrderlist([]);
    setPageCount(0);
    setRateCenterData([]);
    setSessKey("");
    setSelectedCountry("");
    setShowTable(false);
    setIsButtonDisabled(true);
    setSearchQuery("");
    setSelectedCheckboxes({});
    setCurrentPage(0);
    setShowOrderConfirmed(false);
  };

  // const searchCustomers = async () => {
  //   try {

  //     const response = await axios.get(api_url + `dashboard/get_all_customers`, token);
  //     const response_data = response.data;

  //     if (response_data.error_code === 200) {
  //       const itemsArray = response_data.results
  //       console.log('response_itemsArray', itemsArray);
  //       setItems(itemsArray);
  //     }
  //     else if (response.status === 204) {
  //       setItems([]);
  //     } else {
  //       triggerAlert('error', 'Oops...', "Couldn't get the customer details");
  //     }
  //   } catch (error) {
  //     triggerAlert("error", "Oops...", "Something went wrong..");
  //   }
  // };

  // const handleOnSelect = (item) => {
  //   console.log(item);
  //   setcustomerId(item.id);
  // };

  // const handleOnFocus = () => {
  //   // console.log("Focused");
  //   if (!dataLoaded) {
  //     searchCustomers();
  //     setDataLoaded(true);
  //   }

  // };

  // const handleOnClear = () => {
  //   // console.log("Cleared");
  //   setValue('did_type', '')
  //   setSelectedTab("")
  //   reset();
  //   setOrderlist([]);
  //   setPageCount(0);
  //   setRateCenterData([]);
  //   setSessKey('');
  //   setSelectedCountry('')
  //   setShowTable(false);
  //   setIsButtonDisabled(true);
  //   setSearchQuery('');
  //   setSelectedCheckboxes({})
  //   setCurrentPage(0);
  //   setShowOrderConfirmed(false);

  // };

  // let autoCompleteProps = {
  //   items: items,
  //   fuseOptions: { keys: ["company_name"] },
  //   placeholder: 'Search user...',
  //   handleOnSelect: handleOnSelect,
  //   resultString: "company_name",
  //   handleOnFocus: handleOnFocus,
  //   handleOnClear: handleOnClear,
  // }

  useEffect(() => {
    setCurrentPage(0);
    // fetchStateData(); // Fetch data for the initial page
    const menu_id = menuIdData?.order_dids;
    setAddPermission(get_user_menu_permission(menu_id, 'add'));
    // setEditPermission(get_user_menu_permission(menu_id, 'edit'));
    // setDeletePermission(get_user_menu_permission(menu_id, 'delete'));
    // setExportPermission(get_user_menu_permission(menu_id, 'export'));
  }, []);

  const watchStateSelected = watch('stateselect');
  let watchInlCountry = watch('internationalCountrySelect')
  // console.log('watchInlCountry', watchInlCountry)
  const tollfreeOpt = [
    { value: 'tollfree', label: 'Toll free' },
    { value: 'custom_tollfree', label: 'Custom Toll free' },
  ]
  const tollfreeNpaOpt = [
    { value: '800', label: '800' },
    { value: '833', label: '833' },
    { value: '844', label: '844' },
    { value: '855', label: '855' },
    { value: '866', label: '866' },
    { value: '877', label: '877' },
    { value: '888', label: '888' },
  ]
  const vfaxOpt = [
    { value: 'state', label: 'Search By State' },
  ]



  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />

        <div className="main-content" style={{ minHeight: "100vh" }}>
          <div className="page-content">
            <div className="container-fluid">
              {/* start page title */}
              <PageHeader mainHeading={mainHeading} heading={heading} />
              {/* end page title */}
              {showOrderDidPage && (
                <>
                  <div className="row  mt-4">
                    <div className="col-xl-12">
                      <div className="card-body">
                        {/* <form data-select2-id="21"> */}
                        <div className="row g-3" data-select2-id="20">
                          <div className="col-md-3">
                            <label
                              className="form-label"
                              htmlFor="formtabs-first-name"
                            >
                              User <span style={{ color: "red" }}>*</span>
                            </label>
                            <Controller
                              // key={forceUpdate}
                              name="customer_id"
                              {...register("customer_id", {
                                required: "User is required",
                              })}
                              control={control}
                              render={({ field }) => (
                                // <AutoSearchSelect
                                //   key={forceUpdate}
                                //   value={field.value}
                                //   apiUrl={
                                //     api_url_admin +
                                //     "dashboard/get_all_customers"
                                //   }
                                //   onSelect={handleUserSelect}
                                //   placeholder="Search Username or Company"
                                //   mapOption={(result) => ({
                                //     value: result.id,
                                //     label:
                                //       result.user_name +
                                //       ", " +
                                //       result.company_name.substring(0, 15),
                                //     m4_account_id: result.m4_account_id,
                                //     mor_account_id: result.mor_account_id,
                                //     // additionalField: result.additionalField,
                                //     // Add additional fields as needed
                                //   })}
                                // />
                                <AutoUserSearch
                                  onSelect={handleUserSelect}
                                  value={field.value}
                                />
                              )}
                            />
                            {errors.customer_id && (
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "14px",
                                  marginTop: "5px",
                                }}
                              >
                                {errors.customer_id.message}
                              </div>
                            )}
                            {/* <AutoUserSearch onSelect={handleUserSelect} /> */}
                          </div>

                          <div className="col-md-3">
                            <label
                              className="form-label"
                              htmlFor="formtabs-first-name"
                            >
                              DID Type <span style={{ color: "red" }}>*</span>
                            </label>
                            {/* <select
                              className="form-select"
                              name="did_type"
                              {...register("did_type", {
                                required: "DID type is required",
                              })}
                              aria-label="Default select example"
                              onChange={handleTabChange}
                            >
                              <option value="" hidden>
                                Select DID Type
                              </option>
                              <option value="local">Local DIDs</option>
                              <option value="toll_free">Toll free DIDs</option>
                              <option value="vfax">Vfax DIDs</option>
                              <option value="international">
                                International DIDs
                              </option>
                            </select> */}
                            <Controller
                              name="did_type"
                              {...register('did_type',
                                { required: "DID type is required", }
                              )}
                              control={control}

                              render={({ field }) => (
                                <CommonSelect
                                  options={didType}
                                  value={field.value}
                                  placeholder="Select DID Type"
                                  onChange={handleTabChange}

                                />
                              )}
                            />
                            {errors.did_type && (
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "14px",
                                  marginTop: "5px",
                                }}
                              >
                                {errors.did_type.message}
                              </div>
                            )}
                          </div>
                          {types === "local" && (
                            <form
                              className="col-md-12 d-flex repeater"
                              noValidate="novalidate"
                              onSubmit={handleSubmit(onSubmit)}
                            // onKeyDown={(e) => handleKeyDown(e)}
                            >
                              <div className="col-md-2 mx-2">
                                <label className="form-label">
                                  Select Option
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                {/* <select
                                  className="form-select"
                                  name="searchselect"
                                  {...register("searchselect", {
                                    required: "Option is required",
                                  })}
                                  onChange={handleDropdownChange}
                                  aria-label="Default select example"
                                >
                                  <option value="" hidden>
                                    Select
                                  </option>
                                  <option value="state">Search By State</option>
                                  <option value="areacode">
                                    Search By AreaCode
                                  </option>
                                  <option value="npanxxx">
                                    Search By NPANXX
                                  </option>
                                </select> */}
                                <Controller
                                  name="searchselect"
                                  {...register('searchselect',
                                    { required: "Option is required" }
                                  )}
                                  control={control}
                                  defaultValue={null} // Set the default value
                                  render={({ field }) => (
                                    <CommonSelect
                                      options={localOpt}
                                      value={field.value}
                                      placeholder="Select"
                                      onChange={handleDropdownChange}

                                    />
                                  )}
                                />
                                {errors.searchselect && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {errors.searchselect.message}
                                  </div>
                                )}
                              </div>
                              {selectedCountry === "state" && (
                                <>
                                  <div className="col-md-2 mx-2">
                                    <label className="form-label">
                                      State{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    {/* <select
                                      className="form-select"
                                      name="stateselect"
                                      {...register("stateselect", {
                                        required: "State is required",
                                      })}
                                      onChange={stateselection}
                                      aria-label="Default select example"
                                    >
                                      <option value="" hidden>
                                        Select State
                                      </option>
                                      {data.map((item, index) => (
                                        <option value={item}>{item}</option>
                                      ))}
                                    </select> */}
                                    <Controller
                                      name="stateselect"
                                      {...register('stateselect', { required: "State is required" })}
                                      control={control}
                                      render={({ field }) => (
                                        <AutoSearchSelect
                                          {...field}
                                          apiUrl={customer_api_url + `services/available_state_list`}
                                          // onSelect={(value) => field.onChange(value)}
                                          onSelect={stateselection}
                                          placeholder="Select State"
                                          mapOption={result => ({
                                            value: result,
                                            label: result,
                                            // service_charge: result.service_charge,
                                            // additionalField: result.additionalField,
                                            // Add additional fields as needed
                                          })}
                                        />
                                      )}
                                    />
                                    {errors.stateselect && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.stateselect.message}
                                      </div>
                                    )}
                                  </div>
                                  {watchStateSelected &&
                                    <div className="col-md-2 mx-2">
                                      <label className="form-label">
                                        Rate Center{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      {/* <select
                                      className="form-select"
                                      name="rateCenterSelect"
                                      {...register("rateCenterSelect", {
                                        required: "Rate center is required",
                                      })}
                                      onChange={rateCenterSelection}
                                      aria-label="Default select example"
                                    >
                                      <option value="" hidden>
                                        Select Rate Center
                                      </option>
                                      {rateCenterData.map((item, index) => (
                                        <option value={item}>{item}</option>
                                      ))}
                                    </select> */}
                                      <Controller
                                        name="rateCenterSelect"
                                        {...register('rateCenterSelect', { required: "Rate center is required" })}
                                        control={control}
                                        render={({ field }) => (

                                          <RateCenterSearch
                                            apiUrl={customer_api_url + `services/ratecenter_list`}
                                            apiInput={{
                                              state: watchStateSelected,
                                            }}
                                            onSelect={rateCenterSelection}
                                            value={field.value}
                                            placeholder="Select Rate Center"
                                            mapOption={result => ({
                                              value: result,
                                              label: result,
                                              // additionalField: result.additionalField,
                                              // Add additional fields as needed
                                            })}
                                          />
                                        )}
                                      />
                                      {errors.rateCenterSelect && (
                                        <div
                                          style={{
                                            color: "red",
                                            fontSize: "14px",
                                            marginTop: "5px",
                                          }}
                                        >
                                          {errors.rateCenterSelect.message}
                                        </div>
                                      )}
                                    </div>
                                  }
                                </>
                              )}

                              {selectedCountry === "areacode" && (
                                <div className="col-md-2 mx-2">
                                  <label className="form-label">
                                    Enter Area Code{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    name="ratecenter"
                                    className="form-control credit-card-mask"
                                    {...register("ratecenter", {
                                      required: "Area Code is required",
                                    })}
                                    onChange={areaselection}
                                    placeholder="Enter Area Code"
                                    type="text"
                                  />
                                  {errors.ratecenter && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors.ratecenter.message}
                                    </div>
                                  )}
                                </div>
                              )}
                              {selectedCountry === "npanxxx" && (
                                <>
                                  <div className="col-md-2 mx-2">
                                    <label className="form-label">
                                      Enter NPA{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      name="npa"
                                      className="form-control credit-card-mask"
                                      {...register("npa", {
                                        required: "NPA is required",
                                      })}
                                      onChange={npaselection}
                                      placeholder="Enter NPA"
                                      type="text"
                                    />
                                    {errors.npa && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.npa.message}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-2 mx-2">
                                    <label className="form-label">
                                      Enter NXX{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      name="nxx"
                                      className="form-control credit-card-mask"
                                      {...register("nxx", {
                                        required: "NXX is required",
                                      })}
                                      onChange={(e) => npxselection(e)}
                                      placeholder="Enter NXX"
                                      type="text"
                                    />
                                    {errors.nxx && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.nxx.message}
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}

                              <div className="col-md-2 mx-2">
                                <label
                                  className="form-label"
                                  for="formtabs-last-name"
                                >
                                  &nbsp;
                                </label>
                                <div className="button-items">
                                  <button
                                    type="submit"
                                    disabled={isButtonDisabled}
                                    className="btn btn-primary w-md"
                                  >
                                    Search
                                  </button>
                                  &nbsp;&nbsp;
                                  <button
                                    type="button"
                                    className="btn btn-warning w-md"
                                    onClick={handleClearClick}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </form>
                          )}
                          {types === "toll_free" && (
                            <form
                              className="col-md-12 d-flex repeater"
                              noValidate="novalidate"
                              onSubmit={handleSubmit(onSubmit)}
                            // onKeyDown={(e) => handleKeyDown(e)}
                            >
                              <div className="col-md-2 mx-2">
                                <label className="form-label">
                                  Select Type{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                {/* <select
                                  className="form-select"
                                  name="tollfree_type"
                                  {...register("tollfree_type", {
                                    required: "Toll Free Type is required",
                                  })}
                                  aria-label="Default select example"
                                >
                                  <option value="" hidden>
                                    Select
                                  </option>
                                  <option value="tollfree">Toll free</option>
                                  <option value="custom_tollfree">
                                    Custom Toll free
                                  </option>
                                </select> */}

                                <Controller
                                  name="tollfree_type"
                                  {...register('tollfree_type',
                                    { required: "Toll Free Type is required" }
                                  )}
                                  control={control}
                                  render={({ field }) => (
                                    <CommonSelect
                                      options={tollfreeOpt}
                                      value={field.value}
                                      placeholder="Select"
                                      onChange={(selectedOption) => {
                                        setValue('tollfree_type', selectedOption ? selectedOption.value : null);
                                      }}
                                    />
                                  )}
                                />
                                {errors.tollfree_type && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {errors.tollfree_type.message}
                                  </div>
                                )}
                              </div>

                              {watch("tollfree_type") === "tollfree" && (
                                <div className="col-md-2 mx-2">
                                  <label className="form-label">
                                    Select NPA{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  {/* <select
                                    className="form-select"
                                    name="keyword"
                                    {...register("keyword", {
                                      required: "keyword is required",
                                    })}
                                    onChange={keywordsearch}
                                    aria-label="Default select example"
                                  >
                                    <option value="" hidden>
                                      Select
                                    </option>
                                    <option value="800">800</option>
                                    <option value="833">833</option>
                                    <option value="844">844</option>
                                    <option value="855">855</option>
                                    <option value="866">866</option>
                                    <option value="877">877</option>
                                    <option value="888">888</option>
                                  </select> */}
                                  <Controller
                                    name="keyword"
                                    {...register('keyword',
                                      { required: "keyword is required" }
                                    )}
                                    control={control}
                                    render={({ field }) => (
                                      <CommonSelect
                                        options={tollfreeNpaOpt}
                                        value={field.value}
                                        placeholder="Select"
                                        onChange={keywordsearch}

                                      />
                                    )}
                                  />
                                  {errors.keyword && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors.keyword.message}
                                    </div>
                                  )}
                                </div>
                              )}

                              {watch("tollfree_type") === "custom_tollfree" && (
                                <div className="col-md-2 mx-2 ">
                                  <label
                                    className="form-label"
                                    htmlFor="keywordCus"
                                  >
                                    Enter Keyword{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    {...register("keywordCus", {
                                      required: "Keyword is required",
                                    })}
                                    placeholder="Enter Keyword"
                                    name="keywordCus"
                                    onChange={keywordCustom}
                                  />
                                  {errors.keywordCus && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors.keywordCus.message}
                                    </div>
                                  )}
                                </div>
                              )}

                              <div className="col-md-2 mx-2">
                                <label
                                  className="form-label"
                                  for="formtabs-last-name"
                                >
                                  &nbsp;
                                </label>
                                <div className="button-items">
                                  <button
                                    type="submit"
                                    disabled={isButtollDisabled}
                                    className="btn btn-primary w-md"
                                  >
                                    Search
                                  </button>
                                  &nbsp;&nbsp;
                                  <button
                                    type="button"
                                    className="btn btn-warning w-md"
                                    onClick={handleClearClick}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </form>
                          )}
                          {types === "vfax" && (
                            <form
                              className="col-md-12 d-flex repeater"
                              noValidate="novalidate"
                              onSubmit={handleSubmit(onSubmit)}
                            // onKeyDown={(e) => handleKeyDown(e)}
                            >
                              <div className="col-md-2 mx-2">
                                <label className="form-label">
                                  Select Option
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                {/* <select
                                  className="form-select"
                                  name="searchselect"
                                  {...register("searchselect", {
                                    required: "Option is required",
                                  })}
                                  onChange={handleDropdownChange}
                                  aria-label="Default select example"
                                >
                                  <option value="" hidden>
                                    Select
                                  </option>
                                  <option value="state">Search By State</option>
                                  <option value="areacode">
                                    Search By AreaCode
                                  </option>
                                  <option value="npanxxx">
                                    Search By NPANXX
                                  </option>
                                </select> */}
                                <Controller
                                  name="searchselect"
                                  {...register('searchselect',
                                    { required: "Option is required" }
                                  )}
                                  control={control}
                                  defaultValue={null} // Set the default value
                                  render={({ field }) => (
                                    <CommonSelect
                                      options={vfaxOpt}
                                      value={field.value}
                                      placeholder="Select"
                                      onChange={handleDropdownChange}

                                    />
                                  )}
                                />
                                {errors.searchselect && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {errors.searchselect.message}
                                  </div>
                                )}
                              </div>
                              {selectedCountry === "state" && (
                                <>
                                  <div className="col-md-2 mx-2">
                                    <label className="form-label">
                                      State{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    {/* <select
                                      className="form-select"
                                      name="stateselect"
                                      {...register("stateselect", {
                                        required: "State is required",
                                      })}
                                      onChange={stateselection}
                                      aria-label="Default select example"
                                    >
                                      <option value="" hidden>
                                        Select State
                                      </option>
                                      {data.map((item, index) => (
                                        <option value={item}>{item}</option>
                                      ))}
                                    </select> */}
                                    <Controller
                                      name="stateselect"
                                      {...register('stateselect', { required: "State is required" })}
                                      control={control}
                                      render={({ field }) => (
                                        <AutoSearchSelect
                                          {...field}
                                          apiUrl={customer_api_url + `services/available_state_list`}
                                          // onSelect={(value) => field.onChange(value)}
                                          onSelect={stateselection}
                                          placeholder="Select State"
                                          mapOption={result => ({
                                            value: result,
                                            label: result,
                                            // service_charge: result.service_charge,
                                            // additionalField: result.additionalField,
                                            // Add additional fields as needed
                                          })}
                                        />
                                      )}
                                    />
                                    {errors.stateselect && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {errors.stateselect.message}
                                      </div>
                                    )}
                                  </div>
                                  {watchStateSelected &&
                                    <div className="col-md-2 mx-2">
                                      <label className="form-label">
                                        Rate Center{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      {/* <select
                                      className="form-select"
                                      name="rateCenterSelect"
                                      {...register("rateCenterSelect", {
                                        required: "Rate center is required",
                                      })}
                                      onChange={rateCenterSelection}
                                      aria-label="Default select example"
                                    >
                                      <option value="" hidden>
                                        Select Rate Center
                                      </option>
                                      {rateCenterData.map((item, index) => (
                                        <option value={item}>{item}</option>
                                      ))}
                                    </select> */}
                                      <Controller
                                        name="rateCenterSelect"
                                        {...register('rateCenterSelect', { required: "Rate center is required" })}
                                        control={control}
                                        render={({ field }) => (

                                          <RateCenterSearch
                                            apiUrl={customer_api_url + `services/ratecenter_list`}
                                            apiInput={{
                                              state: watchStateSelected,
                                            }}
                                            onSelect={rateCenterSelection}
                                            value={field.value}
                                            placeholder="Select Rate Center"
                                            mapOption={result => ({
                                              value: result,
                                              label: result,
                                              // additionalField: result.additionalField,
                                              // Add additional fields as needed
                                            })}
                                          />
                                        )}
                                      />
                                      {errors.rateCenterSelect && (
                                        <div
                                          style={{
                                            color: "red",
                                            fontSize: "14px",
                                            marginTop: "5px",
                                          }}
                                        >
                                          {errors.rateCenterSelect.message}
                                        </div>
                                      )}
                                    </div>
                                  }
                                </>
                              )}
                              <div className="col-md-2 mx-2">
                                <label
                                  className="form-label"
                                  for="formtabs-last-name"
                                >
                                  &nbsp;
                                </label>
                                <div className="button-items">
                                  <button
                                    type="submit"
                                    disabled={isButtonDisabled}
                                    className="btn btn-primary w-md"
                                  >
                                    Search
                                  </button>
                                  &nbsp;&nbsp;
                                  <button
                                    type="button"
                                    className="btn btn-warning w-md"
                                    onClick={handleClearClick}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </form>
                          )}
                          {types === "international" && (
                            <form
                              className="col-md-12 d-flex repeater"
                              noValidate="novalidate"
                              onSubmit={handleSubmit(intlOrder)}
                            // onKeyDown={handleKeyDown}
                            >
                              <div className="col-md-2 mx-2">
                                <label className="form-label">
                                  Select Country{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                {/* <select
                                  className="form-select"
                                  name="internationalCountrySelect"
                                  {...register("internationalCountrySelect", {
                                    required: "Country is required",
                                  })}
                                  onChange={internationslCountrySelection}
                                  aria-label="Default select example"
                                >
                                  <option value="" hidden>
                                    Select Country
                                  </option>
                                  {countryData.map((country, index) => (
                                    <option
                                      key={country.st}
                                      value={JSON.stringify(country)}
                                    >
                                      {country.country}
                                    </option>
                                  ))}
                                </select> */}

                                <Controller
                                  name="internationalCountrySelect"
                                  {...register('internationalCountrySelect', { required: "Country is required" })}
                                  control={control}
                                  render={({ field }) => (
                                    <AutoSearchSelect
                                      {...field}
                                      apiUrl={customer_api_url + `services/get_international_country_list`}
                                      // onSelect={(value) => field.onChange(value)}
                                      onSelect={internationslCountrySelection}
                                      placeholder="Select Country"
                                      mapOption={result => ({
                                        value: result,
                                        label: result.country,
                                        // service_charge: result.service_charge,
                                        // additionalField: result.additionalField,
                                        // Add additional fields as needed
                                      })}
                                    />
                                  )}
                                />
                                {errors.internationalCountrySelect && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {errors.internationalCountrySelect.message}
                                  </div>
                                )}
                              </div>
                              {watchInlCountry &&
                                <div className="col-md-2 mx-2">
                                  <label className="form-label">
                                    Select Ratecenter{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  {/* <select
                                  className="form-select"
                                  name="intlRatecenterSelect"
                                  {...register("intlRatecenterSelect", {
                                    required: "Ratecenter is required",
                                  })}
                                  onChange={intRateCenterSelection}
                                  aria-label="Default select example"
                                >
                                  <option value="" hidden>
                                    Select Ratecenter
                                  </option>
                                  {intlRatecenterData?.rc?.map(
                                    (rcValue, index) => (
                                      <option
                                        key={rcValue}
                                        value={JSON.stringify({
                                          rcValue,
                                          serviceCharge:
                                            intlRatecenterData.service_charge,
                                        })}
                                      >
                                        {rcValue}
                                      </option>
                                    )
                                  )}
                                </select> */}
                                  <Controller
                                    name="intlRatecenterSelect"
                                    {...register('intlRatecenterSelect', { required: "Rate center is required" })}
                                    control={control}
                                    render={({ field }) => (

                                      <IntlRateCenterSearch
                                        apiUrl={customer_api_url + `services/get_international_ratecenters_list`}
                                        apiInput={{
                                          country: watchInlCountry.st,
                                          country_name: watchInlCountry.country,
                                          customer_id: customerId,
                                        }}
                                        onSelect={intRateCenterSelection}
                                        value={field.value}
                                        placeholder="Select Rate Center"
                                        mapOption={result => ({
                                          value: result,
                                          label: result,
                                          // additionalField: result.additionalField,
                                          // Add additional fields as needed
                                        })}
                                      />
                                    )}
                                  />
                                  {errors.intlRatecenterSelect && (
                                    <div
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {errors.intlRatecenterSelect.message}
                                    </div>
                                  )}
                                </div>
                              }
                              <div className="col-md-2 mx-2">
                                <label
                                  className="form-label"
                                  for="formtabs-last-name"
                                >
                                  &nbsp;
                                </label>
                                <div className="button-items">
                                  {addPermission &&
                                    <button
                                      type="submit"
                                      disabled={isIntlOrder}
                                      className="btn btn-primary w-md"
                                    >
                                      Order
                                    </button>
                                  }
                                  &nbsp;&nbsp;
                                  <button
                                    type="button"
                                    className="btn btn-warning w-md"
                                    onClick={handleClearClick}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </form>
                          )}
                          {addPermission &&
                            orderlist.length > 0 ? (
                            <div className="col-md-12 ">
                              <div className="button-items d-flex justify-content-end">
                                <button
                                  type="button"
                                  className="btn btn-success w-md mx-3"
                                  onClick={handleBulkOrderClick}
                                >
                                  Order
                                </button>
                              </div>
                            </div>
                          ) : null

                          }
                        </div>
                        {/* </form> */}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      {showTable && (
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-end mb-2">
                              {orderlist.length > 0 ? (
                                <div>
                                  {/* <div className="col-md-12">
                                  <div className="button-items d-flex justify-content-end">
                                    <button type="button" className="btn btn-success w-md mx-3" onClick={handleBulkOrderClick}>Order</button>
                                    <button type="button" className="btn btn-warning w-md" onClick={handleClearClick}>Cancel</button>
                                  </div>
                                </div> */}
                                  <div className="btn-group col-md-12 mt-2">
                                    <input
                                      type="search"
                                      placeholder="Search..."
                                      value={searchQuery}
                                      className="form-control form-control-sm"
                                      aria-controls="example"
                                      onChange={handleSearchChange}
                                    />
                                    &nbsp;&nbsp;
                                    {/* <input type="text" placeholder="Search..." value={searchQuery} onChange={handleChange}  />  */}
                                  </div>
                                </div>
                              ) : null}

                              {/* <div className="dataTables_length" id="example_length">
                                                                        <label>Show
                                                                            <select name="example_length" aria-controls="example" className="table_length" onChange={handlePageChange} value={pageLimitSelected}>
                                                                                <option value="25">25</option>
                                                                                <option value="50">50</option>
                                                                                <option value="75">75</option>
                                                                                <option value="100">100</option>
                                                                            </select> entries
                                                                        </label>
                                                                    </div> */}
                            </div>
                            <Table
                              id="datatable"
                              className="nowrap w-100 dt-responsive table-responsive table-bordered"
                            >
                              <thead style={{ backgroundColor: "#ededed" }}>
                                <tr>
                                  <th>
                                    <div className="form-check ">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckDefault"
                                        checked={
                                          Object.keys(selectedCheckboxes)
                                            ?.length > 0
                                            ? Object.keys(selectedCheckboxes)
                                              ?.length === orderlist.length
                                            : false
                                        }
                                        onChange={handleCheckAllChange}
                                      />
                                    </div>
                                  </th>
                                  <th>{capitalizeFirst(types)} DIDs</th>
                                  <th>MRC</th>
                                  <th>Set Up Fee</th>
                                  <th>Per Minute Fee</th>
                                  {/* <th>Status</th> */}
                                </tr>
                              </thead>
                              {isLoading ? (
                                <div className="loader-overlay text-white">
                                  <Loader />
                                </div>
                              ) : (
                                <tbody>
                                  {orderlist.length === 0 ? (
                                    <tr>
                                      <td colSpan="6" className="text-center">
                                        No data available
                                      </td>
                                    </tr>
                                  ) : (
                                    orderlist.map((did, index) => (
                                      <tr key={did.number}>
                                        <th scope="row">
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              value=""
                                              id={`flexCheckDefault${did.number}`}
                                              checked={
                                                selectedCheckboxes[did.number]
                                                  ?.isChecked || false
                                              }
                                              onChange={(e) =>
                                                handleCheckboxChange(
                                                  e,
                                                  did.number,
                                                  did.provider
                                                )
                                              }
                                            />
                                          </div>
                                        </th>
                                        <td>{did.number}</td>
                                        <input
                                          type="hidden"
                                          value={did.provider}
                                        />
                                        <td>$ {orderDidRate.dids_cost} </td>
                                        <td>$ {orderDidRate.did_setup_fee} </td>
                                        <td>
                                          $ {orderDidRate.did_per_minute_fee}
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              )}
                            </Table>
                            <hr />
                            <Pagination {...pgntn_props} />
                            {orderlist.length > 0 ? (
                              <div className="col-md-12 mt-5">
                                <div className="button-items d-flex justify-content-end">
                                  {addPermission &&
                                    <button
                                      type="button"
                                      className="btn btn-success w-md mx-3"
                                      onClick={handleBulkOrderClick}
                                    >
                                      Order
                                    </button>
                                  }
                                  <button
                                    type="button"
                                    className="btn btn-warning w-md"
                                    onClick={handleClearClick}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>
                    {/*  <!-- end col -->*/}
                  </div>{" "}
                  {/* <!-- end row -->*/}
                </>
              )}

              {!showOrderDidPage && !showOrderConfirmed && (
                //  {/* OrderConfirmation start */}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="mb-4 h2-card">
                          Selected {capitalizeFirst(types)} DIDs
                        </h4>
                        {/* {selectedDids.length > 0 && ( */}
                        <div className="table-responsive">
                          <Table
                            id="datatable"
                            className="nowrap w-100 dt-responsive table-responsive table-bordered"
                          >
                            <thead style={{ backgroundColor: "#ededed" }}>
                              <tr>
                                {/* <th>S.No</th> */}
                                <th>{capitalizeFirst(types)} DIDs</th>
                                <th>Provider</th>
                                <th>MRC</th>
                                <th>Set Up Fee</th>
                                <th>Per Minute Fee</th>
                                <th>Total</th>
                              </tr>
                            </thead>

                            <tbody>
                              {Object.values(selectedCheckboxes).map(
                                (checkbox, index) => (
                                  <tr key={index}>
                                    {/* <td>{checkbox.index}</td> */}
                                    <td>{checkbox.number}</td>
                                    <td>
                                      {checkbox.provider === "V"
                                        ? "Vitelity"
                                        : checkbox.provider === "VI"
                                          ? "VoIP Innovations"
                                          : checkbox.provider === "I"
                                            ? "Inteliquent"
                                            : checkbox.provider === "B"
                                              ? "Bandwidth"
                                              : "-"}
                                    </td>
                                    <td>{orderDidRate.dids_cost}</td>
                                    <td>{orderDidRate.did_setup_fee}</td>
                                    <td>{orderDidRate.did_per_minute_fee}</td>
                                    <td>
                                      {(
                                        parseFloat(orderDidRate.dids_cost) +
                                        parseFloat(orderDidRate.did_setup_fee) +
                                        parseFloat(
                                          orderDidRate.did_per_minute_fee
                                        )
                                      ).toFixed(4)}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                            {/* )} */}
                          </Table>
                        </div>
                        {/* )} */}
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-2">
                          <button
                            className="btn btn-success px-5 py-1"
                            type="button"
                            onClick={() =>
                              ConfirmationAlert(
                                "You want to confirm the Order!!",
                                "Confirm",
                                handleOrderConfirming
                              )
                            }
                          >
                            Order
                          </button>
                          <button
                            className="btn btn-warning  px-5"
                            type="button"
                            onClick={() => {
                              setShowOrderDidPage(!showOrderDidPage);
                              setShowTable(true);
                              setValue("customer_id", customerId);
                            }}
                          >
                            Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                // {/* OrderConfirmation end */}
              )}
              {showOrderConfirmed && (
                //  {/* OrderConfirmed start */}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="mb-4 h4-card">
                          Ordered {capitalizeFirst(types)} DIDs
                        </h4>
                        {/* {selectedDids.length > 0 && ( */}
                        <div className="table-responsive">
                          <Table className="nowrap w-100 dt-responsive table-responsive table-bordered">
                            <thead style={{ backgroundColor: "#ededed" }}>
                              <tr>
                                {/* <th>S.No</th> */}
                                <th>{capitalizeFirst(types)} DIDs</th>
                                <th>Provider</th>
                                <th>Description</th>
                                <th>Order Status</th>
                                {/* <th>Routing</th> */}
                              </tr>
                            </thead>
                            {isLoading ? (
                              <Loader />
                            ) : (
                              <tbody>
                                {orderedDids.length === 0 ? (
                                  <tr>
                                    <td colSpan="6" className="text-center">
                                      No data available
                                    </td>
                                  </tr>
                                ) : (
                                  orderedDids.map((orderedDid, index) => (
                                    <tr key={index}>
                                      <td>{orderedDid.line}</td>
                                      <td>
                                        {orderedDid.provider === "V"
                                          ? "Vitelity"
                                          : orderedDid.provider === "VI"
                                            ? "VoIP Innovations"
                                            : orderedDid.provider === "I"
                                              ? "Inteliquent"
                                              : orderedDid.provider === "B"
                                                ? "Bandwidth"
                                                : "-"}
                                      </td>
                                      <td>{orderedDid.description}</td>
                                      <td
                                        className={
                                          orderedDid.order_status === 0
                                            ? "text-danger"
                                            : "text-success"
                                        }
                                      >
                                        {orderedDid.order_status === 0
                                          ? "Failed"
                                          : "Completed"}
                                      </td>
                                      {/* <td>
                                                                        <select className="form-select" name="selectRoute" {...register('selectRoute')} aria-label="Default select example">
                                                                            <option value=" " hidden>Select Route</option>
                                                                            {routes.map((route, index) => (
                                                                                <option value={route.ipaddr}>{route.ipaddr}</option>
                                                                            ))}
                                                                        </select>
                                                                    </td> */}
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            )}
                          </Table>
                        </div>
                        {selectedTab === "international" ? null : (
                          <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-2">
                            <button
                              className="btn btn-warning  btn-md me-md-2 px-5"
                              type="button"
                              onClick={handleOrderConfmBack}
                            >
                              Back
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                // {/* OrderConfirmed end */}
              )}
            </div>{" "}
            {/* container-fluid */}
          </div>
          {/* End Page-content */}

          <Footer />
        </div>
        {/* end main content*/}
      </div>
      {/* END layout-wrapper */}

      {/* Right bar overlay*/}
      <div className="rightbar-overlay"></div>
    </>
  );
}
