import React, { useEffect, useState } from 'react'
import Footer from '../../common/Footer'
import PageHeader from '../PageHeader'
import Header from '../../common/Header'
import Sidebar from '../../common/Sidebar'
import { Table } from 'react-bootstrap'
import AutocompleteSearch from '../AutocompleteSearch'
import axios from 'axios'
import { formatDate, getToken, simpleAlert, triggerAlert } from '../../utils'
import { Controller, useForm } from 'react-hook-form'
// for DatePicker
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../../common/Loader'
import Pagination from '../PaginationComponent'
import AutoUserSearch from "../AutoUserSearch";
import { useLocation, useNavigate } from "react-router-dom";
export default function RequestStatus() {

    const api_url = process.env.REACT_APP_API_BASE_URL;
    const token = getToken();
    const mainHeading = 'Request Status';
    const heading = 'Number Portability / Request Status';
    const { register, handleSubmit, formState: { errors }, setValue, watch, reset, control } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const state = useLocation();
    const navigate = useNavigate();
    const [count, setCount] = useState(() => {
        const storedCount = localStorage.getItem('num_req_count');
        return storedCount ? parseInt(storedCount, 10) : 0;
    });
    const [data, setData] = useState([]);
    const [items, setItems] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [customerId, setcustomerId] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const currentDate = new Date();
    const [period_start, setPeriodStart] = useState(currentDate);
    const [period_end, setPeriodEnd] = useState(currentDate);
    const [minEndDate, setMinEndDate] = useState(period_start ? new Date(period_start) : null);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [pageSlNo, setPageSlNo] = useState(0);
    const [perPageLimit, setPerPageLimit] = useState(10);
    const [pageLimitSelected, setPageLimitSelected] = useState(10);

    const fetchNumberPortingData = async (page_number, searchkey, custId = '') => {
        // if (customerId) {
        // console.log('numberPData', data);
        // console.log('formatedStrt', formatDate(data.period_start, 'dd-mm-yyyy'));
        // console.log('formatedEnd', formatDate(data.period_end, 'dd-mm-yyyy'));

        setIsLoading(true);
        const page_size = perPageLimit;
        const status = watch('port_status');
        // const period_start = watch('period_start');
        // const period_end = watch('period_end');
        const startDate = formatDate(period_start, 'dd-mm-yyyy');
        const endDate = formatDate(period_end, 'dd-mm-yyyy');

        try {

            let apiUrl = `${api_url}number_portability/get_number_portability_data`;

            // Set default values for page_size and page_number
            let params = `?page_size=${page_size || 10}&page_number=${page_number || 1}`;

            // Add port_status, customer_id, and searchkey if they are present
            if (custId) params += `&customer_id=${custId}`;
            if (status && customerId && startDate && endDate && searchkey) {
                // params += `&port_status=${status}&customer_id=${customerId}&start_date=${startDate}&end_date=${endDate}&keyword=${searchkey}`;
                params += `&customer_id=${customerId}&start_date=${startDate}&end_date=${endDate}&keyword=${searchkey}`;
            } else if (status && customerId && startDate && endDate) {
                // params += `&port_status=${status}&customer_id=${customerId}&start_date=${startDate}&end_date=${endDate}`;
                params += `&customer_id=${customerId}&start_date=${startDate}&end_date=${endDate}`;
            } else if (searchkey) {
                params += `&keyword=${searchkey}`;
            } else {
                console.error('Invalid Parameters');
            }
            const response = await axios.get(apiUrl + params, token);

            const response_data = response.data;
            // console.log('response_dataNP', response_data);

            if (response_data.error_code === 200) {
                const itemsArray = response_data.results.data
                //console.log('response_itemsArray', itemsArray);
                const total_pages = response_data.results.pagination.total_pages;
                setPageCount(total_pages);
                setData(itemsArray);
                setIsLoading(false);
            } else if (response.status === 204) {
                setData([]);
                setPageCount(0);
                setIsLoading(false);
            } else {
                triggerAlert('error', 'Oops...', "Couldn't get the details");
            }
        } catch (error) {
            setIsLoading(false);
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
        // } else {
        //     simpleAlert('Search and Select the User!!');
        // }
    };

    const onSubmit = (data) => {
        // console.log(customerId);
        // console.log(data);
        if (customerId) {
            fetchNumberPortingData(currentPage + 1);
        } else {
            simpleAlert('Search and Select the User!!');
        }
    }

    const handleChange = (e) => {
        setSearchQuery(e.target.value);
        const searchkey = e.target.value;
        fetchNumberPortingData(1, searchkey); // Update search results on every change

    };

    const handleUserSelect1 = (userData) => {
        // console.log('hghjhjh', userData);
        const user_id = userData ? userData.user_id : null;
        setcustomerId(user_id);

    };


    const searchCustomers = async () => {
        try {

            const response = await axios.get(api_url + `dashboard/get_all_customers`, token);
            const response_data = response.data;

            if (response_data.error_code === 200) {
                const itemsArray = response_data.results
                //console.log('response_itemsArray', itemsArray);
                setItems(itemsArray);
            }
            else if (response.status === 204) {
                setItems([]);
            } else {
                triggerAlert('error', 'Oops...', "Couldn't get the customer details");
            }
        } catch (error) {
            triggerAlert("error", "Oops...", "Something went wrong..");
        }
    };

    const handleOnSelect = (item) => {
        //  console.log(item);
        setcustomerId(item.value);
        setPageCount(0);
        setData([]);
        setCurrentPage(0);
        setPerPageLimit(10);
    };

    const handleOnFocus = () => {
        //console.log("Focused");
        if (!dataLoaded) {
            searchCustomers();
            setDataLoaded(true);
        }

    };

    const handleOnClear = () => {
        //  console.log("Cleared");
        // setAssignedDids([]);
    };

    let autoCompleteProps = {
        items: items,
        fuseOptions: { keys: ["company_name"] },
        placeholder: 'Search user...',
        handleOnSelect: handleOnSelect,
        resultString: "company_name",
        handleOnFocus: handleOnFocus,
        handleOnClear: handleOnClear,
    }

    const handlePageChange = event => {
        setCurrentPage(0);
        if (event.target.value) {
            setPerPageLimit(event.target.value);
            setPageLimitSelected(event.target.value);
        } else {
            setPerPageLimit(10);
            setPageLimitSelected(10);
        }
    };

    const handlePageClick = (selected) => {
        //console.log(selected);
        const selectedPage = selected.selected;
        setCurrentPage(selectedPage);
        fetchNumberPortingData(selectedPage + 1, '', state?.state?.user_id); // Increment the page number by 1 for server-side pagination
    };

    let pgntn_props = {
        pageCount: pageCount,
        handlePageClick: handlePageClick,
        selectedPage: currentPage
    }

    const handleUserSelect = (userData) => {
        setcustomerId(userData.user_id);
        setPageCount(0);
        setData([]);
        setCurrentPage(0);
        setPerPageLimit(10);
    };

    useEffect(() => {
        // console.log(customerId);
        setCurrentPage(0);
        if (state?.state?.user_id && count == 0) {
            fetchNumberPortingData(1, '', state?.state?.user_id);
            setcustomerId(state?.state?.user_id);
            setValue('user_id', state.state.user_id);
            localStorage.setItem('num_req_count', "1");
        } else if (state?.state?.user_id && count > 0) {
            const newLocation = { ...state, state: null };
            navigate(newLocation.pathname, { state: null });
            localStorage.setItem('num_req_count', "0");
            setcustomerId('');
            fetchNumberPortingData(currentPage + 1);
        } else {
            fetchNumberPortingData(currentPage + 1);
        }
        // if (state?.state?.user_id) {
        //     console.log(state?.state?.user_id);
        // 	fetchNumberPortingData(1,'',state?.state?.user_id);
        // } else {
        //     fetchNumberPortingData(currentPage + 1);
        // }
    }, [perPageLimit])
    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* start page title */}
                            <PageHeader mainHeading={mainHeading} heading={heading} />
                            {/* end page title */}

                            <div className="row  mt-4">
                                <div className="col-xl-12">
                                    <div className="">
                                        <div className="card-body">

                                            {/*Tab panes */}

                                            <form data-select2-id="21" noValidate="novalidate" onSubmit={handleSubmit(onSubmit)}>
                                                <div className="row g-3" data-select2-id="20">
                                                    <div className="col-md-3">
                                                        <label className="form-label" htmlFor="formtabs-first-name">Search & Select User <span style={{ color: 'red' }}>*</span></label>
                                                        <Controller control={control} name="user_id" id="user_id" rules={{ required: "User is required", }} render={({ field }) => (


                                                            <AutoUserSearch onSelect={(user) => {
                                                                // console.log('ushjhf', user);															 
                                                                // if (user.user_id) {
                                                                setValue('user_id', user ? user.user_id : null);
                                                                setcustomerId(user ? user.user_id : null);
                                                                // } else {
                                                                //     setValue('user_id', null);

                                                                // }
                                                            }}
                                                                value={field.value}
                                                            />

                                                        )}
                                                        />
                                                        {errors.user_id && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.user_id.message}</div>}

                                                    </div>

                                                    {/* <div className="col-md-2">
                                                        <label className="form-label" htmlFor="formtabs-first-name">Vendor</label>
                                                        <select className="form-select" aria-label="Default select example">
                                                            <option selected="">Select Payment</option>
                                                            <option>Credit Card</option>
                                                            <option>E-Check</option>
                                                            <option>Others</option>
                                                        </select>
                                                    </div> */}
                                                    <div className="col-md-2">
                                                        <label className="form-label" htmlFor="formtabs-first-name">Status <span style={{ color: 'red' }}>*</span></label>
                                                        <select className="form-select" name="port_status" {...register('port_status', { required: 'DID type is required' })} aria-label="Default select example"
                                                            onChange={() => {
                                                                setPageCount(0);
                                                                setData([]);
                                                                setCurrentPage(0);
                                                                setPerPageLimit(10);
                                                            }}
                                                        >
                                                            <option value="" hidden>Select Status</option>
                                                            <option value="COMPLETED" >Complete</option>
                                                            <option value="INCOMPLETED">Incomplete</option>

                                                        </select>
                                                        {errors.port_status && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.port_status.message}</div>}
                                                    </div>
                                                    <div className="col-md-2 d-flex flex-column">
                                                        <label htmlFor="fromDate" className="form-label">From <span style={{ color: 'red' }}>*</span></label>

                                                        <Controller className="px-3" control={control} name="period_start" render={({ field }) => (
                                                            <DatePicker className="px-3 form-control" placeholderText='MM/DD/YYYY' selected={field.value} {...register('period_start', { required: 'From Date is required' })}
                                                                onChange={(date) => {
                                                                    setPeriodStart(date);
                                                                    field.onChange(date);
                                                                    setMinEndDate(date); // Update minDate for period_end 
                                                                    setPageCount(0);
                                                                    setData([]);
                                                                    setCurrentPage(0);
                                                                    setPerPageLimit(10);
                                                                }} showMonthDropdown showYearDropdown maxDate={new Date()} autoComplete='off' />
                                                        )} />
                                                        {errors.period_start && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.period_start.message}</div>}
                                                    </div>
                                                    <div className="col-md-2 d-flex flex-column">
                                                        <label htmlFor="toDate" className="form-label">To <span style={{ color: 'red' }}>*</span></label>

                                                        <Controller control={control} name="period_end" render={({ field }) => (
                                                            <DatePicker className="px-3 form-control" placeholderText='MM/DD/YYYY' selected={field.value}  {...register('period_end', { required: 'To Date is required' })}
                                                                onChange={(date) => {
                                                                    setPeriodEnd(date); // Update the state variable
                                                                    field.onChange(date);// Update the form value 
                                                                    setPageCount(0);
                                                                    setData([]);
                                                                    setCurrentPage(0);
                                                                    setPerPageLimit(10);
                                                                }} showMonthDropdown showYearDropdown maxDate={new Date()} minDate={minEndDate} autoComplete='off' />
                                                        )} />
                                                        {errors.period_end && <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>{errors.period_end.message}
                                                        </div>}
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label className="form-label" htmlFor="formtabs-last-name">&nbsp;</label>
                                                        <div className="button-items">
                                                            <button type="submit" className="btn btn-primary w-md">Search</button>
                                                        </div>

                                                    </div>

                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="row mt-5">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="datatable_length">
                                                        <label>Show
                                                            <select name="example_length" aria-controls="example" classNameName="table_length" onChange={handlePageChange} value={pageLimitSelected}>
                                                                <option value={10}>10</option>
                                                                <option value={20}>20</option>
                                                                <option value={50}>50</option>
                                                                <option value={100}>100</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="btn-group float-end ">
                                                        <input type="search" placeholder="Search..." value={searchQuery} className="form-control form-control-sm" aria-controls="example" onChange={handleChange} />
                                                        &nbsp;&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            <Table id="datatable" className="nowrap w-100 dt-responsive table-responsive table-bordered">
                                                <thead style={{ backgroundColor: '#ededed' }}>
                                                    <tr>
                                                        <th>#</th>
                                                        {/* <th>Username</th> */}
                                                        <th>Company Name</th>
                                                        <th>Number</th>
                                                        <th>Provider</th>
                                                        <th>Submitted Date</th>
                                                        <th>Foc date</th>
                                                        <th>Request Date</th>
                                                        <th>Status</th>
                                                        {/* <th>Actions</th> */}
                                                    </tr>
                                                </thead>
                                                {isLoading ? (
                                                    <div className='loader-overlay text-white'>
                                                        <Loader />
                                                    </div>

                                                ) : (
                                                    <tbody>
                                                        {data.length > 0 ? (
                                                            data.map((did, index) => (
                                                                <tr>
                                                                    <th scope="row">{(currentPage) * pageLimitSelected + index + 1}</th>
                                                                    {/* <td>{did.company_name}</td> */}
                                                                    <td>{did.company_name}</td>
                                                                    <td>{did.num_port}</td>
                                                                    <td>{did.prov_name}</td>
                                                                    <td>{did.submitted_date ? formatDate(did.submitted_date, 'dd-mm-yyyy') : '-'}</td>
                                                                    <td>{did.foc_date ? formatDate(did.foc_date, 'dd-mm-yyyy') : '-'}</td>
                                                                    <td>{did.did_crt_dat ? formatDate(did.did_crt_dat, 'dd-mm-yyyy') : '-'}</td>
                                                                    <td>{did.status ? did.status : '-'}</td>

                                                                    {/* <td>
                                                                        <a className="btn btn-sm btn-default p-0" title="Edit"><i
                                                                            className="fas   fas fa-trash-alt text-danger fa-eye-c"></i></a>
                                                                        <a className="btn btn-sm btn-default p-0 mx-2" title="Edit"><i
                                                                            className="fas  fas fa-edit fa-edit-c "></i></a>
                                                                    </td> */}
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="8" className="text-center">No data available</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                )}
                                            </Table>
                                            <hr />
                                            <Pagination {...pgntn_props} />
                                        </div>
                                    </div>
                                </div> {/*end col */}
                            </div> {/*end row */}
                        </div> {/* container-fluid */}
                    </div>
                    {/* End Page-content */}
                    <Footer />
                </div>
                {/* end main content*/}

            </div>
            {/* END layout-wrapper */}

            {/* Right bar overlay*/}
            <div className="rightbar-overlay"></div>
        </>
    )
}
