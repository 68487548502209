import React, { useEffect, useState } from 'react'
import companyLogo from './companyLogo.png'
import { formatDate } from '../../utils'

export default function InvoiceView() {
    const searchParams = new URLSearchParams(window.location.search);
    const stateString = searchParams.get('state');
    const state = JSON.parse(decodeURIComponent(stateString));
    const invoiceHtml = state?.invoice_cat
    const [invoiceTotalCost, setInvoiceTotalCost] = useState(0);
    //console.log(state, 'state')

    useEffect(() => {
        if (state) {
            let calculatedPlansCost = 0;
            let calculatedTotalCost = 0;
            //console.log(state, 'state')

            const invoiceTaxCost = parseFloat(state.plan_tax, 10) || 0;

            if (state.invoice_cat === 'I') {
                state.plan_option.forEach((plan) => {
                    const planTotal = parseFloat(plan.total) || 0;
                    calculatedPlansCost += planTotal;
                });
                // calculatedTotalCost = invoiceTaxCost + calculatedPlansCost;
            } else {
                calculatedPlansCost = parseFloat(state.invoice_amount, 10) || 0;
                // calculatedTotalCost = invoiceTaxCost + calculatedPlansCost;
            }
            const taxAmount = (calculatedPlansCost * invoiceTaxCost) / 100;

            // console.log('taxAmount', taxAmount)

            // Add tax amount to the calculatedPlansCost
            calculatedTotalCost = calculatedPlansCost + taxAmount;
            // setTotalPlansCost(calculatedPlansCost);
            setInvoiceTotalCost(calculatedTotalCost);
        }

    }, [state])
    return (
        <div>
           
                <div id='invoice-preview-card-data'>
                    <div >
                        <div className="card invoice-preview-card px-5 py-2" style={{ border: "3px solid #ee6724" }}>
                            <div className='whole_body'>
                                <div className="card-body px-0 py-0">
                                    <div className="row d-flex justify-content-between flex-wrap mb-2">
                                        <div className="col-4 mb-xl-0 mb-4 d-flex flex-column">

                                            <span className="mb-0 fw-semibold">Vitel Global Communications LLC., </span>
                                            <span className="mb-0">295 Durham Avenue, Suite D,</span>
                                            <span className="mb-0">South Plainfiled, New Jersey, USA.</span>
                                            <span className="mb-0">Tel:732-444-3132, Fax:732-444-3436</span>
                                            <span className="mb-0">info@vitelglobal.com</span>
                                        </div>
                                        <div className="col-4 d-flex svg-illustration justify-content-center flex-column align-items-center">
                                            <img src={companyLogo} width="250" alt='Vitelglobal' />
                                            <div className='mt-5 fw-semibold'>INVOICE</div>
                                        </div>

                                        <div className='col-4 d-flex flex-column justify-content-center align-items-end'>
                                            {/* <h5 className="fw-semibold mb-0">INVOICE #{state?.invoice_number ? state.invoice_number
                                                        : '-'}</h5> */}
                                            <div className="mb-0 pt-1">
                                                <span>Invoice:</span>
                                                <span className="fw-semibold">    #{state?.invoice_number ? state.invoice_number : '-'}</span>
                                            </div>
                                            <div className="mb-0 pt-1">
                                                <span>Date:</span>
                                                <span className="fw-semibold">    {state?.invoice_date ? formatDate(state?.invoice_date, 'dd-mm-yyyy') : '-'}</span>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <hr className="my-0 mb-3" />
                                <div className="card-body" style={{ border: "1px solid #ee6724" }}>
                                    <div className="row" >
                                        <div className="col-md-12">
                                            <h5 className="mb-4 h5-card text-center">Customer Information</h5>
                                            <table className='col-md-10'>
                                                <tbody class="row">
                                                    <tr class="d-flex justify-content-around">
                                                        <td className="col-3">Company Name :</td>
                                                        <td className='col-7'>{state?.company_name}</td>
                                                    </tr>
                                                    <tr class="d-flex justify-content-around">
                                                        <td className="col-3">Contact Name :</td>
                                                        <td className="col-7" >{state?.user_name}</td>
                                                    </tr>
                                                    <tr class="d-flex justify-content-around">
                                                        <td className="col-3">Email Id :</td>
                                                        <td className="col-7" >{state?.email}</td>
                                                    </tr>
                                                    <tr class="d-flex justify-content-around">
                                                        <td className="col-3">Address :</td>
                                                        <td className="col-7" >{state?.address + ', ' + state?.zipcode + ', ' + state?.city + ', ' + state?.state + ', ' + state?.country + '.'} </td>
                                                    </tr>
                                                    <tr class="d-flex justify-content-around">
                                                        <td className="col-3">Phone :</td>
                                                        <td className="col-7" >{state?.phone}</td>
                                                    </tr>
                                                    <tr class="d-flex justify-content-around">
                                                        <td className="col-3">Plan Type :</td>
                                                        <td className="col-7" >Pay As You Go</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <h5 className="mb-4 mt-3  h5-card text-center">Billing Information</h5>
                                <div className="table-responsive border-top">
                                    <table className="table m-0 p-0 ">
                                        <thead style={{ backgroundColor: "#ee6724", color: "#fff !important" }}>
                                            <tr>
                                                <th class=" p-0">DESCRIPTION</th>
                                                <th class=" p-0">QUANTITY</th>
                                                <th class=" p-0">UNIT PRICE</th>
                                                <th class=" p-0">BILL MONTHS</th>
                                                <th class=" p-0">TOTAL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* {state?.plan_option.map((plan) => (
                                                <tr style={{ backgroundColor: plan.description === 'Number of Paid DIDs' ? 'white' : '#efefef' }}>
                                                    <td className="text-nowrap p-0">{plan?.description}</td>
                                                    <td className="text-nowrap p-0">{plan?.quantity}</td>
                                                    <td className='p-0'>$ {plan?.unit_price}</td>
                                                    <td className='p-0'>{plan?.billing_months}</td>
                                                    <td className='p-0'>$ {plan?.total}</td>
                                                </tr>
                                            ))} */}
                                            {/* <tr>
                                                        <td className="text-nowrap">Subtotal</td>
                                                        <td className="text-nowrap"></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>$ {totalPlansCost}</td>
                                                    </tr> */}
                                            <tr>
                                                <td className="text-nowrap p-0">Tax</td>
                                                <td className="text-nowrap p-0"></td>
                                                <td className='p-0'></td>
                                                <td className='p-0'>{state?.plan_tax}%</td>
                                                <td className='p-0'>${((state?.invoice_amount * state?.plan_tax) / 100).toFixed(3)}</td>
                                            </tr>
                                            {state?.card_type !== "AmExCard" ? (
                                                <>
                                                    {state?.discount_status === 'Y' ? (
                                                        <>
                                                            <tr>
                                                                <td className="text-nowrap p-0">Discount</td>
                                                                <td className="text-nowrap p-0"></td>
                                                                <td className='p-0'></td>
                                                                <td className='p-0'></td>
                                                                <td className='p-0'>$ {state?.discount_amt}</td>
                                                            </tr>
                                                            <tr style={{ backgroundColor: '#ee6724', color: '#fff' }}>
                                                                <td className="text-nowrap p-0">Grand Total</td>
                                                                <td className="text-nowrap p-0"></td>
                                                                <td className='p-0'></td>
                                                                <td className='p-0'></td>
                                                                <td className='p-0'>$ {(invoiceTotalCost - state?.discount_amt).toFixed(3)}</td>
                                                            </tr>
                                                        </>
                                                    ) :
                                                        <tr style={{ backgroundColor: '#ee6724', color: '#fff' }}>
                                                            <td className="text-nowrap p-0">Grand Total</td>
                                                            <td className="text-nowrap p-0"></td>
                                                            <td className='p-0'></td>
                                                            <td className='p-0'></td>
                                                            <td className='p-0'>$ {(invoiceTotalCost).toFixed(3)}</td>
                                                        </tr>

                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    {state.discount_status === 'Y' ? (
                                                        <>
                                                            <tr>
                                                                <td className="text-nowrap p-0">Discount</td>
                                                                <td className="text-nowrap p-0"></td>
                                                                <td className='p-0'></td>
                                                                <td className='p-0'></td>
                                                                <td className='p-0'>$ {state?.discount_amt}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-nowrap p-0">AmExCard Processing Fee - 4%</td>
                                                                <td className="text-nowrap p-0"></td>
                                                                <td className='p-0'></td>
                                                                <td className='p-0'></td>
                                                                <td className='p-0'>$ {(((invoiceTotalCost - state?.discount_amt) * 0.04)).toFixed(3)}</td>
                                                            </tr>
                                                            <tr style={{ backgroundColor: '#ee6724', color: '#fff' }}>
                                                                <td className="text-nowrap p-0">Grand Total</td>
                                                                <td className="text-nowrap p-0"></td>
                                                                <td className='p-0'></td>
                                                                <td className='p-0'></td>
                                                                <td className='p-0'>$ {(((invoiceTotalCost - state?.discount_amt) * 0.04) + (invoiceTotalCost - state?.discount_amt)).toFixed(3)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='p-0 mt-3' align="left" height="15" colspan="5">Note : From January 1st, 2016, payments through Amex credit cards would attract 4% processing fee and you will be charged accordingly. If you would like to avoid the charges, we request you to move to other Debit/Credit cards or e-check payments.</td>
                                                            </tr>

                                                        </>
                                                    ) : (
                                                        <>
                                                            <tr>
                                                                <td className="text-nowrap p-0">AmExCard Processing Fee - 4%</td>
                                                                <td className="text-nowrap p-0"></td>
                                                                <td className='p-0'></td>
                                                                <td className='p-0'></td>
                                                                <td className='p-0'>$ {(invoiceTotalCost * 0.04).toFixed(3)}</td>
                                                            </tr>
                                                            <tr style={{ backgroundColor: '#ee6724', color: '#fff' }}>
                                                                <td className="text-nowrap p-0">Grand Total</td>
                                                                <td className="text-nowrap p-0"></td>
                                                                <td className='p-0'></td>
                                                                <td className='p-0'></td>
                                                                <td className='p-0'>$ {((invoiceTotalCost * 0.04) + invoiceTotalCost).toFixed(3)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='p-0 mt-3' align="left" height="15" colspan="5">Note : From January 1st, 2016, payments through Amex credit cards would attract 4% processing fee and you will be charged accordingly. If you would like to avoid the charges, we request you to move to other Debit/Credit cards or e-check payments.</td>
                                                            </tr>

                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-body mx-3">
                                    <div className="row">
                                        <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                                            <span className="fw-semibold">Thanks for your business</span>
                                            <span>Scroll down for detailed invoice.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                     
                </div>
           
            {invoiceHtml === 'R' &&
                // <>Refill Invoice</>
                <div id='invoice-preview-card-data'>
                    <div >
                        <div className="card invoice-preview-card px-5 py-2" style={{ border: "3px solid #ee6724" }}>
                            <div className='whole_body'>
                                <div className="card-body px-0 py-0">
                                    <div className="row d-flex justify-content-between flex-wrap mb-2">
                                        <div className="col-4 mb-xl-0 mb-4 d-flex flex-column">

                                            <span className="mb-0 fw-semibold">Vitel Global Communications LLC., </span>
                                            <span className="mb-0">295 Durham Avenue, Suite D,</span>
                                            <span className="mb-0">South Plainfiled, New Jersey, USA.</span>
                                            <span className="mb-0">Tel:732-444-3132, Fax:732-444-3436</span>
                                            <span className="mb-0">info@vitelglobal.com</span>
                                        </div>
                                        <div className="col-4 d-flex svg-illustration justify-content-center flex-column align-items-center">
                                            <img src={companyLogo} width="250" alt='Vitelglobal' />
                                            <div className='mt-5 fw-semibold'>INVOICE</div>
                                        </div>

                                        <div className='col-4 d-flex flex-column justify-content-center align-items-end'>
                                            <div className="mb-0 pt-1">
                                                <span>Invoice:</span>
                                                <span className="fw-semibold">    #{state?.invoice_number ? state.invoice_number : '-'}</span>
                                            </div>
                                            <div className="mb-0 pt-1">
                                                <span>Date:</span>
                                                <span className="fw-semibold">    {state?.invoice_date ? formatDate(state?.invoice_date, 'dd-mm-yyyy') : '-'}</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <hr className="my-0 mb-3" />
                                <div className="card-body" style={{ border: "1px solid #ee6724" }}>
                                    <div className="row" >
                                        <div className="col-md-12">
                                            <h5 className="mb-4 h5-card text-center">Customer Information</h5>
                                            <table className='col-md-10'>
                                                <tbody class="row">
                                                    <tr class="d-flex justify-content-around">
                                                        <td className="col-3">Company Name :</td>
                                                        <td className='col-7'>{state?.company_name}</td>
                                                    </tr>
                                                    <tr class="d-flex justify-content-around">
                                                        <td className="col-3">Contact Name :</td>
                                                        <td className="col-7" >{state?.user_name}</td>
                                                    </tr>
                                                    <tr class="d-flex justify-content-around">
                                                        <td className="col-3">Email Id :</td>
                                                        <td className="col-7" >{state?.email}</td>
                                                    </tr>
                                                    <tr class="d-flex justify-content-around">
                                                        <td className="col-3">Address :</td>
                                                        <td className="col-7" >{state?.address + ', ' + state?.zipcode + ', ' + state?.city + ', ' + state?.state + ', ' + state?.country + '.'} </td>
                                                    </tr>
                                                    <tr class="d-flex justify-content-around">
                                                        <td className="col-3">Phone :</td>
                                                        <td className="col-7" >{state?.phone}</td>
                                                    </tr>
                                                    <tr class="d-flex justify-content-around">
                                                        <td className="col-3">Plan Type :</td>
                                                        <td className="col-7" >Pay As You Go</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <h5 className="mb-4 mt-3  h5-card text-center">Billing Information</h5>
                                <div className="table-responsive border-top">
                                    <table className="table m-0 p-0 ">
                                        <thead style={{ backgroundColor: "#ee6724", color: "#fff" }}>
                                            <tr>
                                                <th class=" p-0">DESCRIPTION</th>
                                                {/* <th class=" p-0">QUANTITY</th> */}
                                                <th class=" p-0">UNIT PRICE</th>
                                                {/* <th class=" p-0">BILL MONTHS</th> */}
                                                <th class=" p-0">TOTAL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-nowrap p-0">{state?.comments}</td>

                                                <td className='p-0'>$ {state?.invoice_amount}</td>

                                                <td className='p-0'>$ {state?.invoice_amount}</td>
                                            </tr>
                                            {/* {state?.plan_option.map((plan) => (
                                                                <tr>
                                                                    <td className="text-nowrap p-0">{plan?.comments}</td>
                                                                    
                                                                    <td className='p-0'>$ {plan?.unit_price}</td>
                                                                    
                                                                    <td className='p-0'>$ {plan?.total}</td>
                                                                </tr>
                                                            ))} */}
                                            <tr>
                                                <td className="text-nowrap p-0">Tax</td>
                                                <td className="text-nowrap p-0">{state?.plan_tax}%</td>
                                                <td className='p-0'>${((state?.invoice_amount * state?.plan_tax) / 100).toFixed(3)}</td>
                                            </tr>
                                            {state?.card_type !== "AmExCard" ? (
                                                <>
                                                    {state?.discount_status === 'Y' ? (
                                                        <>
                                                            <tr>
                                                                <td className="text-nowrap p-0">Discount</td>
                                                                <td className="text-nowrap p-0"></td>
                                                                {/* <td className='p-0'></td>
                                                                                <td className='p-0'></td> */}
                                                                <td className='p-0'>$ {state?.discount_amt}</td>
                                                            </tr>
                                                            <tr style={{ backgroundColor: '#ee6724', color: '#fff' }}>
                                                                <td className="text-nowrap p-0">Grand Total</td>
                                                                <td className="text-nowrap p-0"></td>
                                                                {/* <td className='p-0'></td>
                                                                                <td className='p-0'></td> */}
                                                                <td className='p-0'>$ {(invoiceTotalCost - state?.discount_amt).toFixed(3)}</td>
                                                            </tr>
                                                        </>
                                                    ) :
                                                        <tr style={{ backgroundColor: '#ee6724', color: '#fff' }}>
                                                            <td className="text-nowrap p-0">Grand Total</td>
                                                            <td className="text-nowrap p-0"></td>
                                                            {/* <td className='p-0'></td>
                                                                            <td className='p-0'></td> */}
                                                            <td className='p-0'>$ {(invoiceTotalCost).toFixed(3)}</td>
                                                        </tr>

                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    {state.discount_status === 'Y' ? (
                                                        <>
                                                            <tr>
                                                                <td className="text-nowrap p-0">Discount</td>
                                                                <td className="text-nowrap p-0"></td>
                                                                {/* <td className='p-0'></td>
                                                                                <td className='p-0'></td> */}
                                                                <td className='p-0'>$ {state?.discount_amt}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-nowrap p-0">AmExCard Processing Fee - 4%</td>
                                                                <td className="text-nowrap p-0"></td>
                                                                {/* <td className='p-0'></td>
                                                                                <td className='p-0'></td> */}
                                                                <td className='p-0'>$ {((invoiceTotalCost - state?.discount_amt) * 0.04).toFixed(3)}</td>
                                                            </tr>
                                                            <tr style={{ backgroundColor: '#ee6724', color: '#fff' }}>
                                                                <td className="text-nowrap p-0">Grand Total</td>
                                                                <td className="text-nowrap p-0"></td>
                                                                {/* <td className='p-0'></td>
                                                                                <td className='p-0'></td> */}
                                                                <td className='p-0'>$ {(((invoiceTotalCost - state?.discount_amt) * 0.04) + (invoiceTotalCost - state?.discount_amt)).toFixed(3)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='p-0 mt-3' align="left" height="15" colspan="5">Note : From January 1st, 2016, payments through Amex credit cards would attract 4% processing fee and you will be charged accordingly. If you would like to avoid the charges, we request you to move to other Debit/Credit cards or e-check payments.</td>
                                                            </tr>

                                                        </>
                                                    ) : (
                                                        <>
                                                            <tr>
                                                                <td className="text-nowrap p-0">AmExCard Processing Fee - 4%</td>
                                                                <td className="text-nowrap p-0"></td>
                                                                {/* <td className='p-0'></td>
                                                                                <td className='p-0'></td> */}
                                                                <td className='p-0'>$ {(invoiceTotalCost * 0.04).toFixed(3)}</td>
                                                            </tr>
                                                            <tr style={{ backgroundColor: '#ee6724', color: '#fff' }}>
                                                                <td className="text-nowrap p-0">Grand Total</td>
                                                                <td className="text-nowrap p-0"></td>
                                                                {/* <td className='p-0'></td>
                                                                                <td className='p-0'></td> */}
                                                                <td className='p-0'>$ {((invoiceTotalCost * 0.04) + invoiceTotalCost).toFixed(3)}</td>
                                                            </tr>
                                                            <tr className='mt-4'>
                                                                <td className='p-0 mt-3' align="left" height="15" colspan="5">Note : From January 1st, 2016, payments through Amex credit cards would attract 4% processing fee and you will be charged accordingly. If you would like to avoid the charges, we request you to move to other Debit/Credit cards or e-check payments.</td>
                                                            </tr>

                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-body mx-3">
                                    <div className="row">
                                        <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                                            <span className="fw-semibold">Thanks for your business</span>
                                            <span>Scroll down for detailed invoice.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><br />
                </div>
            }
        </div>
    )
}
