import Header from "../../../common/Header";
import Sidebar from "../../../common/Sidebar";
import Footer from "../../../common/Footer";
import PageHeader from "../../PageHeader";
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { getToken, triggerAlert, handleTableRowClick, formattedDateTime, pageReload, simpleAlert, get_user_menu_permission, downloadFile } from '../../../utils';
import Swal from "sweetalert2";
import { useNavigate, Link, useLocation } from "react-router-dom";
import AutoUserSearch from "../../AutoUserSearch";
import Loader from "../../../common/Loader";
import Pagination from "../../PaginationComponent";
import { useForm, Controller } from 'react-hook-form';
import AutoSearchSelect from '../../CommonSelectDynamic'
import { menuIdData } from "../../../utils/constants";

function DocumentList() {
	const api_url = process.env.REACT_APP_API_BASE_URL;
	const api_url_admin = process.env.REACT_APP_API_BASE_URL;
	const token = getToken();
	const navigate = useNavigate();
	const location = useLocation();
	const mainHeading = 'User Documents';
	const heading = 'Others / User Documents';
	// Fetch Data Api integration 
	const [selectedRow, setSelectedRow] = useState(null);
	const [selectedRowId, setSelectedRowId] = useState(null);
	const [posts, setPosts] = useState([]);
	const [customerId, setcustomerId] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [totalPageCount, setTotalPageCount] = useState(0);
	const [pageNum, setPageNum] = useState(1);
	const pageSize = process.env.REACT_APP_API_SHOW_ENTRIES;
	const { control, register, handleSubmit, formState: { errors }, setValue, reset, clearErrors } = useForm();

	// Menu Permissions
	const [addPermission, setAddPermission] = useState(false);
	const [editPermission, setEditPermission] = useState(false);
	const [deletePermission, setDeletePermission] = useState(false);
	const [exportPermission, setExportPermission] = useState(false);

	const fetchDocData = async () => {
		//console.log('sdfsfr', customerId); 
		setIsLoading(true);
		try {
			if (customerId) {
				const response = await axios.get(api_url + `user_document_list?page_size=${pageSize}&page_number=${pageNum}&customer_id=${customerId}`, token);
				const data = response.data.results.data;
				setIsLoading(false);
				setPosts(data);
				const total_pages = response.data.results.pagination.total_pages;
				setTotalPageCount(total_pages);
			} else {
				setIsLoading(false);
				setPosts([]);
			}
		} catch (error) {
			setIsLoading(false);
			triggerAlert('error', 'Oops...', 'Something went wrong..');
		}
		// else{
		// 	simpleAlert('Search and Select the User!!');
		// }
	}

	const handleEditForm = (e) => {
		if (selectedRow) {
			navigate('/others/manage_user_documents?mode=update', { state: selectedRow });
		} else {
			triggerAlert('error', 'Oops...', 'Please select a row');
		}
	}

	const deleteUsrDoc = () => {
		if (selectedRowId) {
			Swal.fire({
				icon: 'warning',
				title: 'Do you want to delete data?',
				showCancelButton: true,
				confirmButtonText: 'Delete',
			}).then(async (result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					var id = selectedRowId;
					try {
						const response = await axios.get(api_url + `delete_user_document?user_doc_id=${id}`, token);
						const response_data = response.data;
						if (response_data.error_code == "200") {
							triggerAlert('success', 'success', response_data.message);
							//fetchCategoriesList();
							pageReload();
						} else {
							triggerAlert('error', 'Oops...', response_data.message);
						}
					} catch (error) {
						triggerAlert('error', 'Oops...', 'Something went wrong..');
					}
				}
			})
		} else {
			triggerAlert('error', 'Oops...', 'Please select a row');
		}
	};

	// const getDocumentName = (document_id) => {
	// 	switch (document_id) {
	// 		case 1:
	// 		  return 'Master Service Agreement';
	// 		case 2:
	// 		  return 'Signed Quote';
	// 		case 3:
	// 		  return 'Others';
	// 		case 'null':
	// 		  return 'No Document Available';
	// 		default:
	// 		  return 'Unknown Document';
	//     }
	// }

	const handleUserSelect = (userData) => {
		setcustomerId(userData.value);
		setPageNum(1);
	}

	const handlePageClick = (selected) => {
		const selectedPage = selected.selected;
		setPageNum(selectedPage + 1);
	};


	let props = {
		pageCount: totalPageCount,
		handlePageClick: handlePageClick,
		selectedPage: pageNum - 1
	}

	const refreshState = () => {
		const newLocation = { ...location, state: null };
		navigate(newLocation.pathname, { state: null });
		pageReload();
	}


	useEffect(() => {
		fetchDocData();
		if (location?.state?.uid) {
			setcustomerId(location?.state?.uid);
			//setPageNum(1);
			setValue('user', location?.state?.uid);
		}
	}, [pageNum, customerId]);

	useEffect(() => {
		const menu_id = menuIdData?.user_documents;
		setAddPermission(get_user_menu_permission(menu_id, 'add'));
		setEditPermission(get_user_menu_permission(menu_id, 'edit'));
		setDeletePermission(get_user_menu_permission(menu_id, 'delete'));
		setExportPermission(get_user_menu_permission(menu_id, 'export'));
	}, []);

	const handleDownload = (url) => {
		const filename = url?.split("/")?.pop();
		downloadFile(url, filename);
	};


	return (
		<>
			<div id="layout-wrapper">
				<Header />
				<Sidebar />

				<div className="main-content">
					{isLoading ? <div className='loader-overlay text-white'>
						<Loader />
					</div> : ""}
					<div className="page-content">
						<div className="container-fluid">
							{/* start page title */}
							<PageHeader mainHeading={mainHeading} heading={heading} />

							<div class="row  mt-4">
								<div class="col-xl-12">
									<div class="card-body">
										<form data-select2-id="21">
											<div class="row g-3" data-select2-id="20">
												<div class="col-md-4">
													<label class="form-label" for="formtabs-first-name">User</label>
													{/* <AutoUserSearch onSelect={handleUserSelect} /> */}
													<Controller
														name="user"
														{...register('user'
														)}
														control={control}
														render={({ field }) => (
															<AutoSearchSelect
																// {...field}
																value={field.value}
																apiUrl={api_url_admin + 'dashboard/get_all_customers'}
																onSelect={handleUserSelect}
																placeholder="Search Username or Company"
																mapOption={result => ({
																	value: result.id,
																	label: result.user_name + ', ' + result.company_name.substring(0, 15),
																	m4_account_id: result.m4_account_id,
																	mor_account_id: result.mor_account_id
																	// additionalField: result.additionalField,
																	// Add additional fields as needed
																})}
															/>
														)}
													/>
												</div>
												<div class="col-md-4">
													<label class="form-label" for="formtabs-last-name">&nbsp;</label>
													<div class="button-items">
														<button type="button" class="btn btn-warning w-md" onClick={() => refreshState()}>Cancel</button>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div class="row mt-5">
								<div class="col-12">
									<div class="card">
										{(addPermission || editPermission || deletePermission) &&
											<div className="card-header">
												<div className="row">
													<div className="col-sm-12 col-md-11">
													</div>

													<div className="btn-group   mb-3 float-end col-md-1">
														<button type="button" className="btn btn-primary w-md btn-md dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
															Action <i className="mdi mdi-chevron-down"></i>
														</button>
														<div className="dropdown-menu dropdown-menu-end py-2">
															{addPermission && <Link to="/others/manage_user_documents?mode=add" className="dropdown-item"><i class="bx bx-plus"></i> Create </Link>}
															{editPermission && <a href="#" onClick={(e) => handleEditForm(e)} className="dropdown-item"><i className="far fa-edit font-size-14 text-black"></i> Edit</a>}
															{deletePermission && <Link to="#" onClick={deleteUsrDoc} className="dropdown-item"><i className="bx bx-trash"></i> Delete</Link>}
														</div>
													</div>

												</div>
											</div>
										}
										<div class="card-body">
											<table id="datatable" class="table table-bordered dt-responsive  table-responsive  nowrap w-100">
												<thead>
													<tr>
														<th>ID</th>
														<th>Document Name</th>
														<th>Updated Date</th>
														<th>Download</th>
													</tr>
												</thead>
												<tbody>
													{posts.length > 0 ? (
														posts.map((post, index) => (
															<tr onClick={() => handleTableRowClick(post, selectedRow, setSelectedRow, selectedRowId, setSelectedRowId, post.id)} className={selectedRowId === post.id ? 'row_selected' : ''}>
																<td>{index + 1} </td>
																<td>{post.user_document_name}</td>
																<td>{formattedDateTime(post.updated_at)} </td>
																<td>
																	{exportPermission ?
																		<a
																			// href={post.user_document_file}
																			href='#/'
																			onClick={(e) => {
																				e.preventDefault(); // Prevent the default action (redirect)
																				handleDownload(post.user_document_path);
																			}}
																		>

																			<i class="mdi mdi-cloud-download-outline"></i></a>
																		: <span className="text-danger">Permission required!</span>
																	}
																</td>
															</tr>
														))
													) : (
														<tr>
															<td colSpan="4" class="text-center">No Data is Present.</td>
														</tr>
													)}
												</tbody>
											</table>
											<hr />
											<Pagination {...props} />
										</div>
									</div>
								</div>
							</div>





						</div> {/* container-fluid */}
					</div>
					{/* End Page-content */}
					<Footer />
				</div>
				{/* end main content*/}

			</div>
			{/* END layout-wrapper */}




			{/* Right bar overlay*/}
			<div className="rightbar-overlay"></div>
		</>
	);
}

export default DocumentList;
